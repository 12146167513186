import { useNavigate } from '@reach/router';
import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { IconSmallCircle } from '@hub-fe/common/Icons';
import { PermissionLevelsDropdown } from '@hub-fe/common/PermissionLevelsDropdown';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import { EMPTY_HEADER, IReactTableRow } from '@hub-fe/common/ReactTable/ReactTableUtils';
import { ServiceTileTag } from '@hub-fe/common/ServiceTileTag';
import { participantStatusIndicator } from '@hub-fe/common/displayedStatusesConfig';
import {
    ParticipantPermission,
    ParticipantTrustLevel,
} from '@hub-fe/common/generated/clients/damlhub';
import { standardizeParticipantId } from '@hub-fe/common/utils/standardizeParticipantId';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import './ParticipantsTable.scss';

const tableHeaders = [
    { label: 'Participant ID' },
    EMPTY_HEADER,
    { label: 'name' },
    { label: 'Permission Level' },
    { label: 'Status' },
    EMPTY_HEADER,
];
interface ParticipantsTableProps {
    data: {
        participantId: string;
        permissionLevel: ParticipantPermission;
        trustLevel: ParticipantTrustLevel;
        isOwned?: boolean;
        isPlaceholder?: boolean;
        participantName?: string;
        status?: ObjectStatus;
    }[];
}
interface DisableButtonWithStateProps {
    serviceId: string;
    participantId: string;
}
const DisableButtonWithState: React.FC<DisableButtonWithStateProps> = ({
    serviceId,
    participantId,
}) => {
    const [isLoading, setLoading] = React.useState(false);
    const { damlHubClient } = useAppContext();
    const onClick = async () => {
        setLoading(true);
        await damlHubClient.synchronizersApi.setParticipantPermissions({
            serviceId,
            setParticipantPermissionRequest: {
                permissionLevel: 'Disabled',
                participantId,
            },
        });
    };

    return (
        <button className="secondary-smaller warning" onClick={onClick}>
            {isLoading ? 'Disabling' : 'Disable'}
        </button>
    );
};
export const ParticipantsTable: React.FC<ParticipantsTableProps> = ({ data }) => {
    const { serviceId } = useServiceParams();

    const nav = useNavigate();
    if (!serviceId) {
        return <></>;
    }
    const onAdd = (pid: string) => {
        history.push(`add-participant?participantId=${pid}`);
    };

    const placeholderRow: IReactTableRow[] = [
        {
            rowData: [
                {
                    sortKey: 'placeholder',
                    renderAs: (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <p className="p2">
                                No participants allowed yet. Manage participant permissions
                            </p>

                            <div onClick={() => nav('../settings')}>
                                <p
                                    className="p2"
                                    style={{ textDecoration: 'underline', marginLeft: 4 }}
                                >
                                    here
                                </p>
                            </div>
                        </div>
                    ),
                },
            ],
        },
    ];
    const isEmpty = !data.length;
    const rowData: IReactTableRow[] = data?.map(row => {
        const fullParticipantId = standardizeParticipantId(row.participantId);

        return {
            rowData: [
                {
                    sortKey: fullParticipantId,
                    renderAs: (
                        <div className="tag-container">
                            <CopyableDisplay value={fullParticipantId}></CopyableDisplay>
                        </div>
                    ),
                },
                {
                    renderAs: (
                        <div className="tag-container">
                            {row.isOwned ? <ServiceTileTag tagLabel="Your Participant" /> : null}
                        </div>
                    ),
                },
                {
                    sortKey: row.participantName,
                    renderAs: <div>{row.participantName}</div>,
                },
                {
                    sortKey: row.permissionLevel,
                    renderAs: row?.isPlaceholder ? (
                        <button
                            onClick={() => onAdd(fullParticipantId)}
                            className="secondary-smaller"
                        >
                            Add Permission
                        </button>
                    ) : (
                        <PermissionLevelsDropdown
                            participantId={fullParticipantId}
                            value={row.permissionLevel}
                            serviceId={serviceId}
                        />
                    ),
                },
                {
                    sortKey: 'status',
                    renderAs: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {row.status && (
                                <IconSmallCircle
                                    color={
                                        participantStatusIndicator[row.status]?.color || 'disabled'
                                    }
                                />
                            )}
                            {row.status && (
                                <p className="p2 status-text">
                                    {participantStatusIndicator[row.status]?.label || ''}
                                </p>
                            )}
                        </div>
                    ),
                },
                {
                    sortKey: 'delete',
                    renderAs: !row.isPlaceholder ? (
                        !row.isPlaceholder && (
                            <DisableButtonWithState
                                serviceId={serviceId}
                                participantId={row.participantId}
                            />
                        )
                    ) : (
                        <></>
                    ),
                },
            ],
        };
    });
    const displayedRowData = isEmpty ? placeholderRow : rowData;
    const displayedTableHeaders = isEmpty ? [{ label: '' }] : tableHeaders;

    return (
        <div className="participants-table">
            <ReactTable
                tableHeaders={displayedTableHeaders}
                isSortable
                tableRows={displayedRowData}
            />
        </div>
    );
};
