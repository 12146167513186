import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { useAppContext } from '@hub-fe/app/AppContext';
import Expander from '@hub-fe/common/Expander';
import {
    IconLock,
    IconRightArrow,
    IconSidebarGlobe,
    IconSidebarDeployments,
    IconSidebarUser,
} from '@hub-fe/common/Icons';
import apiTokens, { ADMIN_LEDGER, SITE_PARTY } from '@hub-fe/common/apiTokens';
import { JWT } from '@hub-fe/common/jwt-lib';
import { NavLink, Redirect, Route, Router } from '@hub-fe/common/routing';
import { getAccountSidebarTabFormUrl } from '@hub-fe/common/routing/RoutingContext';
import { capitalizePhrase } from '@hub-fe/common/utils';
import { useDismissableElement } from '@hub-fe/common/utilsReact';
import { Footer } from '@hub-fe/footer/Footer';

import AccountProfile from './AccountProfile';
import { AccountSettingsSynchronizers } from './AccountSettingsSynchronizers';
import AccountSubdomains from './AccountSubdomains';
import LedgerHistory from './LedgerHistory';
import PersonalAccessCredentials from './PersonalAccessCredentials';
import Quotas from './Quotas';
import SiteCredentials from './SiteCredentials';

const SideBar = () => {
    const { isProTier, isConnectUiEnabled } = useAppContext();
    return (
        <nav className="side-bar">
            <NavLink className="row side-bar-link" to="profile" activeClassName="active">
                <IconSidebarUser /> Profile
                <IconRightArrow />
            </NavLink>
            {isProTier && (
                <NavLink className="row side-bar-link" to="subdomains" activeClassName="active">
                    <IconSidebarGlobe /> Subdomains
                    <IconRightArrow />
                </NavLink>
            )}
            <NavLink className="row side-bar-link" to="ledger-history" activeClassName="active">
                <IconSidebarDeployments /> Ledger History
                <IconRightArrow />
            </NavLink>
            <NavLink className="row side-bar-link" to="quotas" activeClassName="active">
                <IconSidebarDeployments /> Quotas
                <IconRightArrow />
            </NavLink>
            {isConnectUiEnabled && (
                <NavLink className="row side-bar-link" to="synchronizers" activeClassName="active">
                    <IconSidebarDeployments /> Saved Synchronizers
                    <IconRightArrow />
                </NavLink>
            )}
            <NavLink
                className="row side-bar-link"
                to="personal-access-credentials"
                activeClassName="active"
            >
                <IconLock /> Personal Access Credentials
                <IconRightArrow />
            </NavLink>
            <NavLink className="row side-bar-link" to="credentials" activeClassName="active">
                <IconLock /> Site Credentials (Deprecated)
                <IconRightArrow />
            </NavLink>
        </nav>
    );
};

const AccountDetails: React.FC = () => {
    const { isProTier } = useAppContext();

    const [adminToken, setAdminToken] = useState<JWT>('');
    const [accountDetailsMenuOpen, setAccountDetailsMenuOpen] = useState(false);

    const { refDismissable, refControl } = useDismissableElement<HTMLDivElement, HTMLDivElement>(
        () => setAccountDetailsMenuOpen(false)
    );

    const segment = getAccountSidebarTabFormUrl();

    let pages = [
        { label: 'Profile', icon: <IconSidebarUser /> },
        { label: 'Ledger History', icon: <IconSidebarDeployments /> },
        { label: 'Personal Access Credentials', icon: <IconLock /> },
        { label: 'Site Credentials', icon: <IconLock /> },
    ];

    if (isProTier) {
        pages = [...pages, { label: 'Subdomains', icon: <IconSidebarGlobe /> }];
    }

    const currentPage = pages.find(
        p => p.label === capitalizePhrase(segment?.split('-').join(' ') || '')
    );

    useEffect(() => {
        apiTokens
            .getToken(ADMIN_LEDGER, SITE_PARTY)
            .then(token => setAdminToken(token))
            .catch(() => setAdminToken(''));
    }, []);

    return (
        <div className="account-details">
            <div className="account-details-title">
                <h1>Account Settings</h1>
            </div>
            <div
                ref={refControl}
                className={classNames('icon-right icon-left menu-control', {
                    expanded: accountDetailsMenuOpen,
                })}
                onClick={() => setAccountDetailsMenuOpen(!accountDetailsMenuOpen)}
            >
                {currentPage?.icon}
                {currentPage?.label}
                <Expander expanded={accountDetailsMenuOpen} />
            </div>
            <div ref={refDismissable}>
                {accountDetailsMenuOpen && (
                    <div className="menu" onClick={() => setAccountDetailsMenuOpen(false)}>
                        {pages
                            .filter(page => page.label !== currentPage?.label)
                            .map(p => (
                                <NavLink
                                    key={p.label}
                                    className="ghost menu-item"
                                    to={p.label.toLowerCase().split(' ').join('-')}
                                >
                                    <h3 className="icon-left">
                                        {p.icon}
                                        {p.label}
                                    </h3>
                                </NavLink>
                            ))}
                    </div>
                )}
            </div>
            <div className="account-details-body">
                <SideBar />
                <div className="body-column">
                    <Router>
                        <Route
                            path="profile/*"
                            render={() => <AccountProfile adminToken={adminToken} />}
                        />
                        {isProTier && (
                            <Route path="subdomains/*" render={() => <AccountSubdomains />} />
                        )}
                        <Route path="ledger-history" render={() => <LedgerHistory />} />
                        <Route
                            path="synchronizers"
                            render={() => <AccountSettingsSynchronizers />}
                        />

                        <Route path="quotas" render={() => <Quotas />} />
                        <Route
                            path={'personal-access-credentials'}
                            render={() => <PersonalAccessCredentials />}
                        />
                        <Route path={'credentials'} render={() => <SiteCredentials />} />
                        <Route default render={() => <Redirect to="profile" />}></Route>
                    </Router>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default AccountDetails;
