import React from 'react';

import { useAppContext } from '@hub-fe/app/AppContext';
import { getActiveServices } from '@hub-fe/common/utils/getActiveServices';

import { ScratchpadTile } from '../ScratchpadTile';
import './Scratchpads.scss';

export const Scratchpads: React.FC = () => {
    const { scratchpads, tempScratchpads } = useAppContext();
    const scratchpadsToRender = [
        ...getActiveServices(scratchpads),
        ...Object.values(tempScratchpads || []),
    ];
    const renderedItems = scratchpadsToRender.map(sp => (
        <div key={sp.info.id} className="grid-item">
            <ScratchpadTile serviceId={sp.info.id} scratchpad={sp} />
        </div>
    ));
    return <div className="scratchpad-grid">{renderedItems}</div>;
};
