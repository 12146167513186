import React from 'react';

import './DetailsTable.scss';

interface DetailsTableProps {
    tableHeader?: string;
    data: { icon?: React.ReactNode; label: string; renderAs: JSX.Element }[];
}
export const DetailsTable: React.FC<DetailsTableProps> = ({ tableHeader, data }) => {
    const rows = data.map((row, i) => (
        <div key={row.label + i} className="details-row">
            <div className="row-content">
                {row.icon}
                <p className="label">{row.label}</p>
                {row.renderAs}
            </div>
        </div>
    ));
    return (
        <div className="details-table">
            {tableHeader && <div className="header-container">{<h3>{tableHeader}</h3>}</div>}
            {rows}
        </div>
    );
};
