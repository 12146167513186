import React from 'react';

import { ConnectToSynchronizer } from './ConnectToSynchronizer';
import './ConnectionInfoTile.scss';
import Modal from './Modal';

export const ConnectionInfoTile: React.FC = () => {
    const [open, setOpen] = React.useState(false);
    const onOpen = () => {
        setOpen(!open);
    };
    const close = () => {
        setOpen(false);
    };

    return (
        <div className="tile connection-info-tile">
            <h3>Connection Info</h3>
            <div className="text-area">
                <h4>You are not connect to any domain</h4>
                <p>To participate in the Network you must be connected to synchronizer.</p>
            </div>
            <button onClick={onOpen} className="primary">
                Connect
            </button>
            <Modal title="" className="modal-connect" show={open} onRequestClose={close}>
                <ConnectToSynchronizer onCancel={close} />
            </Modal>
        </div>
    );
};
