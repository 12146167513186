import React from 'react';

import { IDeploymentStatus } from '@hub-client-api';

import { StateIndicator } from '@hub-fe/ledger/Deployments/DeployedArtifactInstanceStateIndicator';

import { LoadingDots } from './Animations/Animations';
import { history } from './ControllableBrowserRouter';
import { IconRightArrow } from './Icons';
import './MonitorDeploymentTile.scss';

interface MonitorDeploymentTileProps {
    status?: IDeploymentStatus;
    desc: string;
    label: string | React.ReactNode;
    to: string;
    caption: string;
    children?: React.ReactNode;
}

export const MonitorDeploymentTile: React.FC<MonitorDeploymentTileProps> = ({
    status,
    desc,
    label,
    to,
    caption,
    children,
}) => {
    return (
        <div className="monitor-deployment-container">
            <div className="monitor-deployment-tile">
                <div className="service-container">
                    <div className="header">
                        {status?.runState ? (
                            <StateIndicator
                                disableLabel
                                artifactDeploymentStates={[status.runState]}
                            />
                        ) : (
                            <p className="p2 loading-status">
                                Waiting for status <LoadingDots />
                            </p>
                        )}
                        <p className="p2 bold">{label}</p>
                    </div>

                    <div>
                        <p className="p2">{desc}</p>
                    </div>
                </div>
                <div
                    onClick={() => {
                        history.push(to);
                    }}
                    className="view-logs"
                >
                    <div className="container">
                        <p className="caption">{caption}</p>

                        <IconRightArrow />
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};
