import React from 'react';
import { Helmet } from 'react-helmet';

import AccountDetails from '@hub-fe/account/AccountDetails';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import ErrorBoundary from '@hub-fe/common/ErrorBoundary';
import ScrollContextProvider from '@hub-fe/common/ScrollContext';
import { ServiceLayout } from '@hub-fe/common/ServiceLayout';
import { Redirect, Route, Router } from '@hub-fe/common/routing';
import { siteName } from '@hub-fe/common/siteInfo.variants';
import * as urls from '@hub-fe/common/urls';
import { logScopeDisplay } from '@hub-fe/common/utils';
import { isNewService } from '@hub-fe/common/utils/isNewService';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import LedgerExplorer from '@hub-fe/explorer/LedgerExplorer';
import { MessageProvider } from '@hub-fe/messages/MessageContext';
import { ChooseServiceType, ServiceType } from '@hub-fe/workspace/ChooseServiceType';
import Workspace from '@hub-fe/workspace/Workspace';
import { WorkspaceNew } from '@hub-fe/workspace/WorkspaceNew';

import CreateNewProjectPage from '../workspace/CreateNewProjectPage';
import { AppProvider, LoginStage, useAppContext, AppType } from './AppContext';
import AppControls from './AppControls';
import { ApplicationErrorPage } from './ErrorPages';
import TermsPage from './TermsPage';

// Needed for appcues
// https://docs.appcues.com/en_US/366344-installation-developers
declare global {
    interface Window {
        Appcues: any;
    }
}
declare global {
    interface Window {
        AppcuesSettings: any;
    }
}

const TermsAcceptedRoutes: React.FC<{ isProTier?: boolean }> = () => {
    const { isConnectUiEnabled } = useAppContext();
    const { service } = useServiceParams();

    React.useEffect(() => {
        if (
            !isConnectUiEnabled &&
            (window.location.pathname.indexOf('/console/nodes') > -1 ||
                (service && isNewService(service)))
        ) {
            history.replace('/console/projects');
            window.AppcuesSettings = { enableUrlDetection: true };
        }
    }, [isConnectUiEnabled, service]);
    return (
        <Router>
            <Route
                path="/"
                render={() => <Redirect to={isConnectUiEnabled ? 'nodes' : 'projects'} />}
            />
            <Route
                path={'/*'}
                render={() => (isConnectUiEnabled ? <WorkspaceNew /> : <Workspace />)}
            />
            <Route
                path="ledger/:ledgerId/*"
                render={() => <ServiceLayout serviceType={ServiceType.LEDGER} />}
            />
            <Route
                path="scratchpad/:serviceId/*"
                render={() => <ServiceLayout serviceType={ServiceType.SCRATCHPAD} />}
            />
            <Route
                path={'participant/:serviceId/*'}
                render={() => <ServiceLayout serviceType={ServiceType.PARTICIPANT} />}
            />
            <Route
                path={'synchronizer/:serviceId/*'}
                render={() => <ServiceLayout serviceType={ServiceType.SYNC_DOMAIN} />}
            />
            <Route path="ledger-explorer/*" render={() => <LedgerExplorer />} />
            <Route path="account/*" render={() => <AccountDetails />} />
            <Route path="terms/*" render={() => <TermsPage />} />
            <Route path={'projects/create-project'} render={() => <CreateNewProjectPage />} />
            <Route
                path={'projects/create-ledger/:projectId'}
                render={() => <CreateNewProjectPage />}
            />
            <Route path={'create/*'} render={() => <ChooseServiceType />} />
        </Router>
    );
};

const TermsNotAcceptedRoutes = () => (
    <Router>
        <Route path="terms/*" render={() => <TermsPage />} />
        <Route default render={() => <Redirect to="terms" />} />
    </Router>
);

const NoFreeTierRoutes = () => (
    <Router>
        <Route default render={() => <Redirect to="/free-tier-removed" />} />
    </Router>
);

const AppToplevel = () => {
    logScopeDisplay('daml hub');
    const { isFreeTier } = useAppContext();

    // This ID is used to identify the application root element when
    // displaying modals. Identifying the application root element
    // enables react-modal to instruct screen readers to avoid reading
    // the whole window contents when a modal pops up. This also
    // eliminates a console warning that is issued when the root
    // element is not specified to a modal. If you change or remove
    // this ID, be sure to appropriately modify the call sites for
    // react-modal to accomodate.
    return (
        <div id="daml-hub-app-root" className="app default">
            <ErrorBoundary>
                <AppControls isFreeTier={isFreeTier} />
                <div className="router-body">
                    <AppRoutes />
                </div>
            </ErrorBoundary>
        </div>
    );
};

const AppRoutes = () => {
    const { loginInfo, isProTier, isFreeTier } = useAppContext();

    if (loginInfo.stage === LoginStage.FETCHING) {
        // We're in the process of acquiring that first site token for the user; stay tuned...
        return <div className="app-routes-loading"></div>;
    } else if (loginInfo.stage === LoginStage.LOGGED_IN) {
        // User is authenticated
        if (isFreeTier) {
            return <NoFreeTierRoutes />;
        } else if (loginInfo.claims?.hubClaims.tosAccepted) {
            return <TermsAcceptedRoutes isProTier={isProTier} />;
        } else {
            return <TermsNotAcceptedRoutes />;
        }
    } else {
        // We have logged out. They shouldn't even be on the console any more.
        if (window.location.hostname === 'localhost') {
            // For localhost ONLY, a logout simply moves us back to the root page
            return <Redirect to="/" />;
        } else {
            window.location.href = urls.root;
            return null as never;
        }
    }
};

const App: React.FC = () => {
    /* To avoid infinitely looping in the case where the error page
     * itself throws an error, it is specifically rendered outside the
     * main error boundary. This means that error page errors aren't
     * handled gracefully, but if we're in that situation, there isn't
     * that much grace going around to begin with.
     */

    return (
        <AppProvider appType={AppType.CONSOLE}>
            <MessageProvider>
                <ScrollContextProvider>
                    <Helmet>
                        <title>{siteName} Console</title>
                    </Helmet>
                    <Router basepath="/console">
                        <Route path="error/*" render={() => <ApplicationErrorPage />} />
                        <Route default render={() => <AppToplevel />} />
                    </Router>
                </ScrollContextProvider>
            </MessageProvider>
        </AppProvider>
    );
};

export default App;
