import React from 'react';

import { IEntity } from '@hub-client-api';

import BackwardsLink from '@hub-fe/common/BackwardsLink';
import { FileTag } from '@hub-fe/common/Tag';

const ActionHeader: React.FC<{
    title?: string | JSX.Element;
    body?: string;
    fileTagEntity?: IEntity;
    linkBackTo?: string;
    titleButtons?: JSX.Element[];
    enableStandardBack?: boolean;
}> = ({ enableStandardBack, body, title, fileTagEntity, linkBackTo, titleButtons }) => {
    const pageTitle = typeof title === 'string' ? <h1>{title}</h1> : title;
    return (
        <div className="action-header">
            {enableStandardBack && <BackwardsLink />}
            {linkBackTo && <BackwardsLink url={linkBackTo} />}
            <div className="title">
                {pageTitle}
                {fileTagEntity && <FileTag entity={fileTagEntity} />}
                {titleButtons?.map(b => (
                    <div key={b.key} className="title-button">
                        {b}
                    </div>
                ))}
                <div className="body">{body}</div>
            </div>
        </div>
    );
};

export default ActionHeader;
