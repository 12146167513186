import React, { useEffect, useState } from 'react';

import { dalGetTemplateDetails } from '@hub-client-api';

import TemplateDropdown from '@hub-fe/app/TemplateDropdown';
import BackwardsLink from '@hub-fe/common/BackwardsLink';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import PartyDropdown, {
    formatSelectionData,
    filterPartyOptions,
} from '@hub-fe/common/PartyDropdown';
import { IRecordProps } from '@hub-fe/common/TemplateFieldsInterfaces';
import { oneOnly } from '@hub-fe/common/utils';
import { isNewService } from '@hub-fe/common/utils/isNewService';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import { useLedgerActions, useLedgerContext } from '../LedgerContext';
import { getFilterQueryString, useContractFilterQuery } from './ActiveContractsSearch';
import ContractTemplateForm from './ContractTemplateForm/ContractTemplateForm';
import { useIsHighEventCount } from './utils';

const CreateContracts: React.FC = () => {
    const {
        ledgerId,
        selectedParty,
        ledger,
        ownerParties = [],
        defaultParties,
        isLedgerOwner,
    } = useLedgerContext();
    const { submitCommandCreate } = useLedgerActions();
    const { filterQueryString, contractTemplates } = useContractFilterQuery();
    const [currentParty, setCurrentParty] = useState(selectedParty);
    const [currentTemplate, setCurrentTemplate] = useState(oneOnly(contractTemplates));
    const [recordInputProps, setRecordInputProps] = useState<IRecordProps | undefined>();
    const { service } = useServiceParams();
    const isParticiapnt = !!service && isNewService(service);
    const enableHighEventCountView = useIsHighEventCount();
    useEffect(() => {
        const checkForTemplateDetails = async () => {
            if (!currentParty || !currentTemplate) {
                return;
            }

            const { name, type, definitions } = await dalGetTemplateDetails(
                ledgerId,
                currentTemplate,
                currentParty,
                isParticiapnt
            );

            const recordInputProps: IRecordProps = {
                label: name,
                type: { record: { fields: type, typeArgs: [] } },
                definitions,
                variables: {},
            };
            setRecordInputProps(recordInputProps);
        };

        checkForTemplateDetails();
    }, [currentTemplate, currentParty, ledgerId]);

    const rootUrl = `/console/${service}/${ledgerId}/live-data${
        filterQueryString ? `?${filterQueryString}` : ''
    }`;

    const partyOptions = formatSelectionData(
        filterPartyOptions(ownerParties, defaultParties, !isLedgerOwner, !isLedgerOwner, true)
    );

    const onSubmitHighEventCount = () => {
        const qs = currentTemplate && getFilterQueryString(currentParty, [currentTemplate]);

        if (!ledger) {
            return;
        }
        const newRoute = `/console/${service}/${ledger.info.id}/live-data/`;
        qs && history.push(newRoute + '?' + qs);
    };

    return (
        <div className="create-contracts">
            <div className="create-contracts-body">
                <BackwardsLink url={enableHighEventCountView ? '' : rootUrl} />
                <h1>Add Contract</h1>
                <div className="select-fields">
                    <div className="template-selector row">
                        <div className="selector-field" id="template-dropdown">
                            <p className="p2"> Template </p>
                            <TemplateDropdown
                                selectedTemplate={currentTemplate}
                                defaultToFirstTemplate
                                onChange={templateName => setCurrentTemplate(templateName)}
                            />
                        </div>
                        <p className="p2 submitted-as">submitted as:</p>
                        <div className="selector-field" id="party-dropdown">
                            <p className="p2"> Party </p>
                            <PartyDropdown
                                selectedParty={currentParty}
                                onChange={party => setCurrentParty(party?.value)}
                                ledgerStatus={ledger?.info.status.ledger}
                                partyOptions={partyOptions}
                            />
                        </div>
                    </div>
                </div>
                {recordInputProps && currentTemplate && currentParty && (
                    <ContractTemplateForm
                        definitions={recordInputProps.definitions}
                        label={recordInputProps.label}
                        onSubmit={onSubmit}
                        // this type object can be simplified when we rip out RecordInput
                        type={recordInputProps.type.record.fields}
                    />
                )}
            </div>
        </div>
    );

    function onSubmit(payload: object, onError: () => void) {
        if (!currentTemplate || !currentParty) {
            return;
        }

        const command = {
            templateId: currentTemplate,
            payload,
        };

        submitCommandCreate(
            command,
            enableHighEventCountView ? onSubmitHighEventCount : routeBackToActiveContracts,
            onError,
            currentParty
        );
    }

    function routeBackToActiveContracts(contractId?: string) {
        const allActiveContractsPath = `/console/${service}/${ledgerId}/live-data/${
            contractId ? encodeURIComponent(contractId) : ''
        }?party=${currentParty}`;
        history.push(allActiveContractsPath);
    }
};

export default CreateContracts;
