import classNames from 'classnames';
import React, { useState } from 'react';

import {
    useAppContext,
    useAppActions,
    IPersonalAccessCredentialInfo,
} from '@hub-fe/app/AppContext';
import Expander from '@hub-fe/common/Expander';
import ExpirationDateDropdown from '@hub-fe/common/ExpirationDateDropdown';
import ExternalLink from '@hub-fe/common/ExternalLink';
import { IconClose, IconRadio, IconWarning } from '@hub-fe/common/Icons';
import InformationBlock from '@hub-fe/common/InformationBlock';
import { getExpirationDateFormatted, toHoursAndMinutes } from '@hub-fe/common/Timing';
import {
    PersonalAccessCredential,
    CreatePersonalAccessCredentialRequest,
} from '@hub-fe/common/generated/clients/damlhub';
import * as urls from '@hub-fe/common/urls';
import { useMessages } from '@hub-fe/messages/MessageContext';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import { PacSecret } from './PacSecret';
import { SelectServiceAndUserId } from './SelectServiceAndUserId';

enum ScopeEnum {
    ACCOUNT = 'site',
    LEDGER = 'ledger:data',
}

function convertUnixToDate(unix?: number): Date | undefined {
    return unix ? new Date(unix * 1000) : undefined;
}

function convertDateToUnix(date?: Date): number | undefined {
    return date ? Math.floor(date.getTime() / 1000) : undefined;
}

const DEFAULT_REQUEST_CONFIG = {
    name: '',
    scope: ScopeEnum.ACCOUNT,
};

const dedupe = (
    pacsWithSecret: IPersonalAccessCredentialInfo[], // newly created pacs
    pacsWithoutSecret: IPersonalAccessCredentialInfo[] // pacs retrieved from fetch
) => {
    if (pacsWithSecret.length === 0) {
        return pacsWithoutSecret;
    }
    let deduped = pacsWithoutSecret;
    for (const pac of pacsWithSecret) {
        deduped = deduped.filter(p => p.pacId !== pac.pacId);
    }
    return [...pacsWithSecret, ...deduped];
};

const PersonalAccessCredentials: React.FC<{}> = () => {
    const [showCreateNewPACs, setShowCreateNewPACs] = useState<boolean>(false);
    const { personalAccessCredentials } = useAppContext();
    const [newpacs, setnewpacs] = React.useState([] as IPersonalAccessCredentialInfo[]);
    const [PACsConfig, setPACsConfig] = useState<
        CreatePersonalAccessCredentialRequest & { serviceType?: ServiceType }
    >(DEFAULT_REQUEST_CONFIG);
    const { error } = useMessages();
    const {
        createPersonalAccessCredential,
        deletePersonalAccessCredential,
        fetchPersonalAccessCredential,
    } = useAppActions();

    const displayed = React.useMemo(
        () => dedupe(newpacs, personalAccessCredentials),
        [newpacs, personalAccessCredentials]
    );

    React.useEffect(() => {
        const interval = setInterval(() => fetchPersonalAccessCredential(), 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const onDelete = React.useCallback(
        async (pacId: string, pacName: string) => {
            try {
                await deletePersonalAccessCredential(pacId);
                if (newpacs.find(pac => pac.pacId === pacId)) {
                    const filtered = newpacs.filter(pac => pac.pacId !== pacId);
                    setnewpacs([...filtered]);
                }
            } catch {
                error(`An error occurred while deleting Personal Access Credential ${pacName}`);
            }
        },
        [newpacs]
    );

    const onCreatePAC = async (config: CreatePersonalAccessCredentialRequest) => {
        if (!verifyPACsRequestConfig(config)) {
            error('Missing required fields in Personal Access Credential request configuration.');
            return;
        }

        const newPAC = await createPersonalAccessCredential(config);
        if (newPAC) {
            setnewpacs(prev => [...prev, newPAC]);
            setShowCreateNewPACs(false);
            setPACsConfig(DEFAULT_REQUEST_CONFIG);
        }
    };

    return (
        <div className="personal-access-credentials">
            <div className="title">
                <h2>Personal Access Credentials</h2>
                {personalAccessCredentials.length > 0 && !showCreateNewPACs && (
                    <button className="secondary" onClick={() => setShowCreateNewPACs(true)}>
                        Create New Personal Access Credential
                    </button>
                )}
            </div>
            <p>
                {personalAccessCredentials.length === 0
                    ? 'There are no personal access credentials associated with this account.'
                    : 'This is a list of personal access credentials associated with your account.'}
            </p>
            <p className="row">
                To learn more about personal access credentials, read our{' '}
                <ExternalLink
                    to={urls.docs + '/quickstart/console#personal-access-credentials'}
                    icon
                >
                    {' '}
                    docs{' '}
                </ExternalLink>
            </p>
            {personalAccessCredentials.length === 0 && !showCreateNewPACs && (
                <button className="row" onClick={() => setShowCreateNewPACs(true)}>
                    Create New Personal Access Credential
                </button>
            )}
            {personalAccessCredentials.length > 0 && (
                <InformationBlock warning>
                    <p>To keep your account secure, keep these credentials private.</p>
                </InformationBlock>
            )}

            {showCreateNewPACs && (
                <CreateNewPACsTile
                    PACsConfig={PACsConfig}
                    setPACsConfig={setPACsConfig}
                    onRequestClose={() => {
                        setShowCreateNewPACs(false);
                        setPACsConfig(DEFAULT_REQUEST_CONFIG);
                    }}
                    onCreatePAC={onCreatePAC}
                />
            )}
            {personalAccessCredentials.length > 0 && (
                <AllPACsTable PACs={displayed} onDelete={onDelete} />
            )}
        </div>
    );
};

function verifyPACsRequestConfig(config?: CreatePersonalAccessCredentialRequest): boolean {
    if (config?.name === '' || !config?.secretExpiresAt) {
        return false;
    }
    if (config?.scope === ScopeEnum.LEDGER) {
        return !!config.ledger?.ledgerId && !!config.ledger.user;
    }
    return true;
}

const permissions: { [key: string]: string } = {
    [ScopeEnum.ACCOUNT]: 'Daml Hub Actions',
    [ScopeEnum.LEDGER]: 'Ledger Data',
};

const AllPACsTable: React.FC<{
    PACs: IPersonalAccessCredentialInfo[];
    onDelete: (pacId: string, pacName: string) => void;
}> = React.memo(({ PACs, onDelete }) => {
    const { ledgers } = useAppContext();

    const PACsRow: React.FC<{ p: IPersonalAccessCredentialInfo }> = ({ p }) => {
        const ledger = ledgers.find(l => l.info.id === p.ledger?.ledgerId);
        const pacSecret = (p as PersonalAccessCredential).pacSecret;
        const pacPairB64 = Buffer.from(`${p.pacId}:${pacSecret}`).toString('base64');

        const actions = (
            <button
                onClick={() => onDelete(p.pacId, p.name)}
                className="secondary-smaller delete warning"
            >
                Delete
            </button>
        );

        const secretExpiresAtDate = convertUnixToDate(p.secretExpiresAt);
        const isExpired = checkIsExpired(secretExpiresAtDate);

        return (
            <div className={classNames('pacs-row', { expired: isExpired })}>
                <div className="pacs-name">
                    <div className="name-details">
                        <h4>{p.name}</h4>
                        <p className="pac p2">{p.pacId}</p>
                    </div>
                    <div className="token-actions narrow-width">{actions}</div>
                </div>
                <div className="pacs-permissions">
                    <div className="scope">
                        <p className="narrow-width-label p2">Scope: </p>{' '}
                        <p className=" p2">
                            {p.scope ? permissions[p.scope] : 'Site Actions or User Data'}
                        </p>
                    </div>
                    <div className="ledger">
                        <p>{ledger?.info.name}</p>
                        {p.scope === ScopeEnum.LEDGER && <p>ID: {p.ledger?.ledgerId}</p>}
                    </div>
                </div>
                <div className="p2 expiry">
                    <p className="narrow-width-label p2">Secret Expiry: </p>
                    <p className="p2 ">
                        {isExpired || !secretExpiresAtDate
                            ? 'Expired'
                            : `Expires on
                        ${getExpirationDateFormatted(secretExpiresAtDate)}`}
                    </p>
                </div>
                <div className="p2 expiry">
                    <p className="narrow-width-label p2">Token Expiry: </p>
                    <p className="p2">{toHoursAndMinutes(p.tokenExpiresIn)}</p>
                </div>
                <div className="token-actions wide-width">{actions}</div>
                {pacSecret && (
                    <PacSecret pacPairB64={pacPairB64} rootUrl={urls.root} hasScope={!!p.scope} />
                )}
            </div>
        );
    };

    const sortedPACs = PACs.sort((a, b) => {
        const d1 = convertUnixToDate(a.idIssuedAt);
        const d2 = convertUnixToDate(b.idIssuedAt);

        if (d1 && d2) {
            return d2.getTime() - d1.getTime();
        }
        return 0;
    });

    return (
        <div className="pacs-grid">
            <div className="pacs-heading">
                <h4>Name</h4>
                <h4>Permissions</h4>
                <h4>Personal Access Credential Expiry</h4>
                <h4>Token Expiry</h4>
            </div>

            {sortedPACs.map((p, i) => (
                <PACsRow p={p} key={i} />
            ))}
        </div>
    );
});

const CreateNewPACsTile: React.FC<{
    onRequestClose: () => void;
    PACsConfig: CreatePersonalAccessCredentialRequest & { serviceType?: ServiceType };
    setPACsConfig: (
        newConf: CreatePersonalAccessCredentialRequest & { serviceType?: ServiceType }
    ) => void;
    onCreatePAC: (config: CreatePersonalAccessCredentialRequest) => void;
}> = ({ onRequestClose, PACsConfig, setPACsConfig, onCreatePAC }) => {
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [showDateMaxExpiryWarning, setShowDateMaxExpiryWarning] = useState(false);

    return (
        <div className="tile create-new-pacs-tile">
            <button className="close ghost" onClick={onRequestClose}>
                <IconClose />
            </button>
            <div className="field">
                <h4>
                    Name <span className="required-field-asterisk">*</span>
                </h4>
                <input
                    className="input p2"
                    value={PACsConfig.name}
                    placeholder="Name"
                    autoFocus
                    onChange={evt =>
                        setPACsConfig({ ...PACsConfig, name: evt.target.value.trim() })
                    }
                />
            </div>

            <div className="field">
                <h4>
                    Select Expiration <span className="required-field-asterisk">*</span>
                </h4>
                <ExpirationDateDropdown
                    expiration={convertUnixToDate(PACsConfig.secretExpiresAt)}
                    onSetExpiration={exp => onSetExpiration(exp)}
                />
                {showDateMaxExpiryWarning && (
                    <p className="p2 expiration-date-warning row icon-left">
                        <IconWarning /> Expiration date must be within 3 years of today.
                    </p>
                )}
            </div>

            {
                <div className="field scope">
                    <h4>
                        Select Scope: <span className="required-field-asterisk">*</span>
                    </h4>
                    <div
                        className="radio-option"
                        onClick={() => setPACsConfig({ ...PACsConfig, scope: ScopeEnum.ACCOUNT })}
                    >
                        <div className="option-title">
                            <IconRadio filled={PACsConfig.scope === ScopeEnum.ACCOUNT} />
                            <h4> Take Daml Hub Site Actions</h4>
                        </div>

                        <div>
                            <p className="p2 description">
                                Mint account tokens to take actions such as creating projects,
                                ledgers, or services, and uploading artifacts to the workspace
                                through the{' '}
                                <ExternalLink to={urls.docs + '/site-api'} icon>
                                    {' '}
                                    Daml Hub Site API{' '}
                                </ExternalLink>
                                . Does not include access to ledger data.{' '}
                            </p>
                        </div>
                    </div>
                    <div
                        className="radio-option"
                        onClick={() => setPACsConfig({ ...PACsConfig, scope: ScopeEnum.LEDGER })}
                    >
                        <div className="option-title">
                            <IconRadio filled={PACsConfig.scope === ScopeEnum.LEDGER} />
                            <h4>Access User's Data:</h4>
                        </div>
                        <div>
                            <p className="p2 description">
                                Mint user tokens to act as and read as the user's parties on a
                                ledger or service through the{' '}
                                <ExternalLink to={urls.docs + '/app-api'} icon>
                                    {' '}
                                    Daml Hub Application API{' '}
                                </ExternalLink>
                                .
                            </p>
                        </div>
                    </div>
                    <div
                        className="radio-option"
                        onClick={() => setPACsConfig({ ...PACsConfig, scope: undefined })}
                    >
                        <div className="option-title">
                            <IconRadio filled={PACsConfig.scope === undefined} />
                            <h4>Take Daml Hub Site Actions or Access User's Data</h4>
                        </div>
                        <div>
                            <p className="p2 description">
                                Mint either account tokens to take actions through the{' '}
                                <ExternalLink to={urls.docs + '/site-api'} icon>
                                    {' '}
                                    Daml Hub Site API{' '}
                                </ExternalLink>{' '}
                                or mint user tokens to act as and read as a user's parties on a
                                ledger or service through the{' '}
                                <ExternalLink to={urls.docs + '/app-api'} icon>
                                    {' '}
                                    Daml Hub Application API{' '}
                                </ExternalLink>
                                ..
                            </p>
                        </div>
                    </div>
                </div>
            }

            {PACsConfig.scope === ScopeEnum.LEDGER && (
                <>
                    <SelectServiceAndUserId
                        serviceId={PACsConfig.ledger?.ledgerId}
                        serviceType={PACsConfig.serviceType}
                        onUserChange={val =>
                            PACsConfig.ledger?.ledgerId !== undefined &&
                            setPACsConfig({
                                ...PACsConfig,
                                ledger: {
                                    ...PACsConfig.ledger,
                                    user: val?.value,
                                },
                            })
                        }
                        onServiceChange={val => {
                            return (
                                val !== null &&
                                setPACsConfig({
                                    ...PACsConfig,
                                    serviceType: val.serviceType,
                                    ledger: {
                                        ledgerId: val.value,
                                        user: PACsConfig.ledger?.user,
                                    },
                                })
                            );
                        }}
                        isDisabled={!PACsConfig.ledger}
                    />
                </>
            )}

            <div className="field advanced-options">
                <button
                    className="p2 advanced-options-expander ghost icon-left"
                    onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                >
                    <Expander expanded={showAdvancedOptions} /> Advanced Options
                </button>
                {showAdvancedOptions && (
                    <div className="field">
                        <h4>How long will short-lived tokens be valid for?</h4>
                        <InputWithTimeUnit
                            PACsConfig={PACsConfig}
                            onSubmit={(tokenExpiresIn: number) =>
                                setPACsConfig({
                                    ...PACsConfig,
                                    tokenExpiresIn,
                                })
                            }
                        />
                    </div>
                )}
            </div>
            <div className="field create-button">
                <button
                    onClick={() => onCreatePAC(PACsConfig)}
                    disabled={!verifyPACsRequestConfig(PACsConfig)}
                >
                    Create
                </button>
            </div>
        </div>
    );

    function onSetExpiration(exp?: Date) {
        const now = new Date();
        if (exp) {
            if (exp <= now) {
                exp?.setHours(23, 59, 59);
                setPACsConfig({
                    ...PACsConfig,
                    secretExpiresAt: convertDateToUnix(exp),
                });
                setShowDateMaxExpiryWarning(false);
            }
            const inThreeYears = new Date(now.setFullYear(now.getFullYear() + 3));
            if (exp > inThreeYears) {
                setPACsConfig({
                    ...PACsConfig,
                    secretExpiresAt: undefined,
                });
                setShowDateMaxExpiryWarning(true);
            } else {
                setPACsConfig({
                    ...PACsConfig,
                    secretExpiresAt: convertDateToUnix(exp),
                });
                setShowDateMaxExpiryWarning(false);
            }
        }
    }
};

const InputWithTimeUnit: React.FC<{
    PACsConfig: CreatePersonalAccessCredentialRequest;
    onSubmit: (val: number) => void;
}> = ({ PACsConfig, onSubmit }) => {
    const [showHoursMinMaxExpiryWarning, setShowHoursMinMaxExpiryWarning] = useState(false);
    return (
        <>
            <div className="time-unit-input">
                <input
                    className="input p2"
                    value={
                        PACsConfig.tokenExpiresIn && (PACsConfig.tokenExpiresIn / 3600)?.toString()
                    }
                    min={0}
                    max={24}
                    placeholder="Duration"
                    type="number"
                    onChange={evt => {
                        onChange(+evt.target.value);
                        if (+evt.target.value < 90 / 3600 || +evt.target.value > 24) {
                            setShowHoursMinMaxExpiryWarning(true);
                        } else {
                            setShowHoursMinMaxExpiryWarning(false);
                        }
                    }}
                />
                <p className="p2 time-unit"> Hours</p>
            </div>
            {showHoursMinMaxExpiryWarning && (
                <p className="p2 expiration-hours-warning row icon-left">
                    <IconWarning /> Duration needs to be between 90 seconds and 24 hours.
                </p>
            )}
        </>
    );

    function onChange(val: number) {
        onSubmit(val * 3600);
    }
};

function checkIsExpired(validTo?: Date) {
    if (!validTo) {
        return true;
    }
    const from = Date.now();
    const diffMs = validTo.valueOf() - from.valueOf();
    return diffMs <= 0;
}

export default PersonalAccessCredentials;
