import { gte as semverGte } from 'semver';

import { artifactTypes, IEntity, LedgerBackendEnum } from './server';

export const MIN_CANTON_SDK_VERSION = '1.17.0';

interface IValidationErorr {
  name: string;
  sdkVersion: string;
}

function isValidSdkVersion(sdkVersion: string, backend: string): boolean {
  switch (backend) {
    case LedgerBackendEnum.CANTON:
      return semverGte(sdkVersion, MIN_CANTON_SDK_VERSION);
    default:
      return true;
  }
}

export function validateEntity(entity: IEntity, backend: string): IValidationErorr[] {
  switch (entity.tag) {
    case artifactTypes.MODEL_ARTIFACT:
      return isValidSdkVersion(entity.value.sdkVersion, backend)
        ? []
        : [{ name: entity.value.entityName, sdkVersion: entity.value.sdkVersion }];
    case artifactTypes.DABL_PACKAGE_ARTIFACT:
      return entity.value.entities.flatMap(e => validateEntity(e.entity, backend));
    default:
      return [];
  }
}
