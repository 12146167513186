import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { IArcadeAppInfo, IArcadeQueryResponse } from '@hub-client-api';

import ArcadeAppTile, { IDeployAppFn } from './ArcadeAppTile';

const AppArcade: FunctionComponent<{
    sectionTitle: JSX.Element;
    loadingTileCount: number;
    arcadeApps?: IArcadeQueryResponse;
    deployedArtifactHashes?: string[];
    deployArcadeApp: IDeployAppFn;
    subheading?: JSX.Element;
    emptyWorkspace?: boolean;
    verticalLayout?: boolean;
    selectedApp?: IArcadeAppInfo;
}> = props => {
    const {
        sectionTitle,
        loadingTileCount,
        arcadeApps,
        deployedArtifactHashes = [],
        deployArcadeApp,
        subheading,
        emptyWorkspace,
        verticalLayout,
        selectedApp,
    } = props;

    let body = arcadeApps?.map(appVersionInfos => (
        <ArcadeAppTile
            key={appVersionInfos[0].artifactHash}
            appVersionInfos={appVersionInfos}
            deployedArtifactHashes={deployedArtifactHashes}
            deployArcadeApp={deployArcadeApp}
            emptyWorkspace={emptyWorkspace}
            verticalLayout={verticalLayout}
            selected={selectedApp && appVersionInfos.includes(selectedApp)}
        />
    ));

    if (!arcadeApps) {
        body = [...Array(loadingTileCount)].map((_, i) => (
            <div className="blank-tile" key={i}></div>
        ));
    }

    return (
        <div
            className={classNames(
                'app-arcade',
                { 'vertical-layout': verticalLayout },
                { 'non-selected': !selectedApp }
            )}
        >
            {sectionTitle}
            {subheading}
            <section className="sample-tiles">{body}</section>
        </div>
    );
};

export default AppArcade;
