import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import { ConnectionInfoTile } from '@hub-fe/common/ConnectionInfoTile';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { DetailsTable } from '@hub-fe/common/DetailsTable';
import {
    IconBell,
    IconDateCreated,
    IconRocket,
    IconSidebarDeployments,
    IconSidebarGlobe,
    IconSidebarUser,
    IconSmallCircle,
} from '@hub-fe/common/Icons';
import InformationBlock from '@hub-fe/common/InformationBlock';
import LoadingPage from '@hub-fe/common/LoadingPage';
import { MonitorDeploymentTile } from '@hub-fe/common/MonitorDeploymentTile';
import { SetupTile } from '@hub-fe/common/SetupTile';
import { Storage } from '@hub-fe/common/Storage';
import { TwoColLayout } from '@hub-fe/common/TwoColLayout';
import { containsDamlModel } from '@hub-fe/common/utils/containsDamlModel';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import en from '../../locales/en.json';
import './ParticipantOverview.scss';

export const ParticipantOverview: React.FC = () => {
    const { participants } = useAppContext();
    const { pqsInstance, deployedArtifactInstances, eventCount, subdomains } = useLedgerContext();
    const { participantStatus, pqsStatus } = useLedgerContext();
    const { serviceId } = useServiceParams();

    const hasDamlModel = deployedArtifactInstances
        ? containsDamlModel(deployedArtifactInstances)
        : false;

    if (!serviceId) {
        return null;
    }

    const participant = participants.find(p => p.info.id === serviceId);
    if (!participant) {
        return <LoadingPage statusText="Loading Participant" />;
    }

    const isStarting = participant.info.status.ledger === ObjectStatus.STARTING;
    const loadingText =
        'Service is starting. Connecting to synchronizer will be available once the service is ready.';
    const hasConnection = participant.info.connection?.joined === true;
    const isPendingConnection =
        participant.info.connection && participant.info.connection.joined === false;
    const synchronizerUrl = participant.info?.connection?.synchronizerUrl;
    const date = new Date(participant.info.createdAt);
    const formattedDate = date.toLocaleDateString('en-US');
    const defaultSubdomain = subdomains?.default?.domain;
    const synchronizerDetails = [
        {
            icon: <IconSidebarGlobe />,
            label: 'Synchronizer Url',
            renderAs: <p className="value p2">{synchronizerUrl} </p>,
        },
    ];

    const participantDetails = [
        {
            icon: <IconSidebarUser />,
            label: 'Name',
            renderAs: <p className="value p2">{participant.info.name} </p>,
        },
        {
            icon: <IconSidebarUser />,
            label: 'Service ID',
            renderAs: (
                <div className="value">
                    <CopyableDisplay value={participant.info.id} />
                </div>
            ),
        },
        {
            icon: <IconSidebarDeployments />,
            label: 'Participant ID',
            renderAs: (
                <div className="value">
                    <CopyableDisplay value={participant.info.participantId} />
                </div>
            ),
        },
        {
            icon: <IconBell filled={false} />,
            label: 'Event Count',
            renderAs: <p className="value p2">{eventCount || 0}</p>,
        },
        {
            icon: <IconDateCreated />,
            label: 'Date Created',
            renderAs: <p className="value p2">{formattedDate}</p>,
        },
        {
            icon: <IconRocket />,
            label: 'Canton Version',
            renderAs: <p className="value p2">{participant.info.sdkVersion}</p>,
        },
        {
            icon: <IconRocket />,
            label: 'PQS Version',
            renderAs: <p className="value p2">{participant.info.pqsVersion || 'unknown'}</p>,
        },
        {
            icon: <IconSidebarDeployments />,
            label: 'Default Participant Domain',
            renderAs: (
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={defaultSubdomain ? `https://${defaultSubdomain}` : '#'}
                    className="value p2"
                >
                    {subdomains?.default?.domain}
                </a>
            ),
        },
    ];
    const col1 = (
        <>
            {!hasConnection && !isPendingConnection && <ConnectionInfoTile />}
            {!pqsInstance && <SetupTile />}

            <DetailsTable tableHeader="Participant Node Details" data={participantDetails} />
            {hasConnection && (
                <>
                    <h2>Connected Synchronizer Details</h2>
                    <DetailsTable data={synchronizerDetails} />
                </>
            )}

            <Storage disks={participant.info.status.disks} />

            <h2>Monitoring</h2>
            <div className="monitor-tile">
                <MonitorDeploymentTile
                    to="participant"
                    status={participantStatus}
                    label="Participant"
                    desc={en.defaultDeployments.participantSupportText}
                    caption="View Logs"
                />
            </div>
            {pqsInstance && (
                <>
                    <div className="monitor-tile">
                        <MonitorDeploymentTile
                            desc={'View PQS settings and logs.'}
                            status={pqsStatus}
                            label={<div>PQS</div>}
                            to="pqs/logs"
                            caption="View Details"
                        >
                            {!hasDamlModel && (
                                <InformationBlock enableBackground fullWidth>
                                    <p>PQS instance will not start until a dar is deployed.</p>
                                    <a href="./deployments">
                                        <p style={{ textDecoration: 'underline' }}>
                                            Go to Deployments
                                        </p>
                                    </a>
                                </InformationBlock>
                            )}
                        </MonitorDeploymentTile>
                    </div>
                </>
            )}
        </>
    );

    const connection = (
        <div className="connection">
            <div className="header-layout">
                <div className="header-container">
                    <h1>Overview</h1>
                    {isPendingConnection && (
                        <div className="status-container left-space">
                            <IconSmallCircle color="yellow" />
                            <p className="p2">Pending Connection</p>
                        </div>
                    )}
                </div>
            </div>
            {isStarting ? (
                <InformationBlock enableBackground enableLoading>
                    <p className="p2">{loadingText}</p>
                </InformationBlock>
            ) : (
                col1
            )}
        </div>
    );
    return <TwoColLayout col1={connection} />;
};
