import React from 'react';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { IconAddPlus } from '@hub-fe/common/Icons';
import LoadingButton from '@hub-fe/common/LoadingButton';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import { EMPTY_HEADER } from '@hub-fe/common/ReactTable/ReactTableUtils';

import './AccountSettingsSynchronizers.scss';
import { SaveSynchronizerInputForm } from './SaveSynchronizerInputForm';

const tableHeaders = [{ label: 'Name' }, { label: 'Synchronizer Url' }, EMPTY_HEADER];
interface DeleteButtonProps {
    name: string;
}
const DeleteButton: React.FC<DeleteButtonProps> = ({ name }) => {
    const [isDeleting, setDeleting] = React.useState(false);
    const { deleteSavedSynchronizer } = useAppActions();
    const onDelete = async (name: string) => {
        setDeleting(true);
        await deleteSavedSynchronizer(name);
        setDeleting(false);
    };
    return (
        <LoadingButton
            onClick={() => onDelete(name)}
            loading={isDeleting}
            className="secondary-smaller warning"
        >
            Delete
        </LoadingButton>
    );
};

export const AccountSettingsSynchronizers: React.FC = () => {
    const [show, setShow] = React.useState(false);
    const onShow = () => {
        setShow(true);
    };
    const onClose = () => {
        setShow(false);
    };

    const { savedSynchronizers } = useAppContext();
    const existingNames = savedSynchronizers.map(ssd => ssd.name);
    const rows = savedSynchronizers
        .map(ssd => ({
            rowData: [
                { sortKey: 'Name', renderAs: <p>{ssd.name}</p> },
                {
                    sortKey: 'Synchronizer Url',
                    renderAs: <CopyableDisplay value={ssd.synchronizerUrl} />,
                },
                {
                    sortKey: 'Delete',
                    renderAs: <DeleteButton name={ssd.name} />,
                },
            ],
        }))
        .reverse();
    return (
        <div className="synchronizers">
            <div className="title-container">
                <h2>Synchronizers</h2>
                <button onClick={onShow} className="secondary-smaller icon-left button-spacing">
                    <IconAddPlus />
                    Add Synchronizer
                </button>
            </div>
            {show && (
                <div style={{ display: 'flex' }}>
                    <SaveSynchronizerInputForm onClose={onClose} existingNames={existingNames} />
                </div>
            )}
            <div className="parties">
                <div className="identities-table">
                    <ReactTable tableHeaders={tableHeaders} isSortable tableRows={rows} />
                </div>
            </div>
        </div>
    );
};
