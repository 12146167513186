import React from 'react';

import { IResourceType, ResourceTokenType } from '@hub-client-api';

import { getResourceTypeName } from '@hub-fe/admin/AdminContext';
import { useAppContext } from '@hub-fe/app/AppContext';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import { IReactTableRow } from '@hub-fe/common/ReactTable/ReactTableUtils';

const Quotas: React.FC<{}> = () => {
    const { quotas } = useAppContext();

    const quotaKeys = Object.keys(quotas);

    const quotaRows: IReactTableRow[] =
        quotaKeys.map(resourceType => {
            const quota: IResourceType = quotas[resourceType];
            const allotted = quota.tokens.length + quota.spentTokens.length;
            return {
                rowData: [
                    {
                        sortKey: getResourceTypeName(resourceType as ResourceTokenType),
                        displayNarrowViewHeader: true,
                    },
                    { sortKey: allotted, displayNarrowViewHeader: true },
                    { sortKey: quota.spentTokens.length, displayNarrowViewHeader: true },
                    { sortKey: quota.tokens.length, displayNarrowViewHeader: true },
                ],
            };
        }) || [];

    return (
        <div className="quotas">
            <div className="title">
                <h2>Quotas</h2>
            </div>
            <ReactTable
                tableRows={quotaRows}
                tableHeaders={[
                    { label: 'Resource Type' },
                    { label: 'Allotted' },
                    { label: 'Used' },
                    { label: 'Remaining' },
                ]}
                emptyTableContent={<p className="p2">There are no quotas to view.</p>}
            />
        </div>
    );
};

export default Quotas;
