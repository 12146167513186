import React, { FunctionComponent } from 'react';

import * as urls from '@hub-fe/common/urls';

import ExternalLink from './ExternalLink';

interface IProps {
    className?: string;
    topic: string;
    children?: React.ReactNode;
}

const DocsLinkConsole: FunctionComponent<IProps> = props => {
    const docsLink = urls.docs + '/quickstart/console#' + props.topic;

    return (
        <ExternalLink
            className={`p2 docs-link inline-link ${props.className || ''}`}
            to={docsLink}
            icon
        >
            {props.children}
        </ExternalLink>
    );
};

export default DocsLinkConsole;
