import React, { useState } from 'react';

import { ObjectStatus } from '@hub-client-api';

import { isLedgerData, useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import LoadingButton from '@hub-fe/common/LoadingButton';
import { SettingsField, SettingsPanel } from '@hub-fe/common/SettingsPage';
import ToolTip from '@hub-fe/common/ToolTip';
import { routeToWorkspace } from '@hub-fe/common/routing/RoutingContext';
import { capitalizePhrase } from '@hub-fe/common/utils';

import { useLedgerContext } from '../LedgerContext';

enum LedgerActionEnum {
    PAUSE = 'pause',
    DELETE = 'delete',
    RESUME = 'resume',
}

const LedgerActions: React.FC = () => {
    const { ledger } = useLedgerContext();

    const [showLedgerActionModal, setShowLedgerActionModal] = useState<{
        show: boolean;
        action?: LedgerActionEnum;
    }>({ show: false });

    if (!ledger) {
        return null;
    }

    return (
        <SettingsPanel title="Ledger Actions" className="ledger-actions">
            <SettingsField>
                <LedgerPauseResumeButton
                    onClick={() =>
                        setShowLedgerActionModal({
                            show: true,
                            action:
                                ledger.objectStatus === ObjectStatus.PAUSED
                                    ? LedgerActionEnum.RESUME
                                    : LedgerActionEnum.PAUSE,
                        })
                    }
                />
            </SettingsField>
            <SettingsField>
                <LedgerDeleteButton
                    onClick={() =>
                        setShowLedgerActionModal({ show: true, action: LedgerActionEnum.DELETE })
                    }
                />
            </SettingsField>
            {showLedgerActionModal.show && showLedgerActionModal.action && (
                <LedgerActionModal
                    onRequestClose={() =>
                        setShowLedgerActionModal({ show: false, action: undefined })
                    }
                    action={showLedgerActionModal.action}
                />
            )}
        </SettingsPanel>
    );
};

const LedgerActionModal: React.FC<{
    onRequestClose: () => void;
    action: LedgerActionEnum;
}> = ({ onRequestClose, action }) => {
    const { deleteLedger, pauseLedger, resumeLedger } = useAppActions();

    const { activeLedgers } = useAppContext();
    const { project, ledger } = useLedgerContext();

    const actionTitle = `${capitalizePhrase(action || '')} Ledger`;
    const lastLedger =
        activeLedgers.filter(({ info }) => info.projectId === project?.info.id)?.length <= 1;

    return (
        <ConfirmActionModal
            show
            onRequestClose={onRequestClose}
            title={actionTitle}
            bodyMessage={`Are you sure you want to ${action} this ledger?`}
            infoMessage={
                lastLedger && action === LedgerActionEnum.DELETE
                    ? 'You are deleting the last ledger in this project.'
                    : undefined
            }
            action={actionTitle}
            onSubmitAction={handleLedgerAction}
            deletingAction
        />
    );

    function handleLedgerAction() {
        if (!ledger || !isLedgerData(ledger)) return;
        switch (action) {
            case LedgerActionEnum.DELETE:
                deleteLedger(ledger);
                routeToWorkspace();
                break;
            case LedgerActionEnum.PAUSE:
            case LedgerActionEnum.RESUME:
                if (ledger.objectStatus === ObjectStatus.PAUSED) {
                    resumeLedger(ledger);
                } else {
                    pauseLedger(ledger);
                }
                break;
        }

        onRequestClose();
    }
};

interface ILedgerActionProps {
    onClick: () => void;
    shortLabel?: boolean;
}

const LedgerDeleteButton: React.FC<ILedgerActionProps> = ({ onClick, shortLabel }) => {
    const { isLedgerCollaborator } = useLedgerContext();
    return (
        <div className="ledger-action delete icon-right">
            <button
                className="secondary-smaller warning"
                onClick={onClick}
                disabled={isLedgerCollaborator}
            >
                Delete {shortLabel ? '' : 'Ledger'}
            </button>
            {isLedgerCollaborator && (
                <ToolTip
                    changeDirectionAtSmallWidth
                    caption={
                        'As a collaborator on this ledger, you cannot delete it. Only the ledger owner can delete this ledger.'
                    }
                />
            )}
        </div>
    );
};

const LedgerPauseResumeButton: React.FC<ILedgerActionProps> = ({ onClick, shortLabel }) => {
    const { ledger } = useLedgerContext();

    if (!ledger) {
        return null;
    }

    const pauseOrResuming =
        ledger.objectStatus === ObjectStatus.PAUSING ||
        ledger.objectStatus === ObjectStatus.RESUMING;

    return (
        <LoadingButton
            className="secondary-smaller"
            disabled={
                ledger.objectStatus === ObjectStatus.STARTING ||
                ledger.info.status.ledger === ObjectStatus.SUSPENDED
            }
            loading={pauseOrResuming}
            onClick={onClick}
            loadingContent={
                ledger.objectStatus === ObjectStatus.PAUSING
                    ? `Pausing ${shortLabel ? '' : 'Ledger'}`
                    : ledger.objectStatus === ObjectStatus.RESUMING
                    ? `Resuming ${shortLabel ? '' : 'Ledger'}`
                    : undefined
            }
        >
            {ledger.objectStatus === ObjectStatus.PAUSED ||
            ledger.info.status.ledger === ObjectStatus.SUSPENDED
                ? `Resume ${shortLabel ? '' : 'Ledger'}`
                : `Pause ${shortLabel ? '' : 'Ledger'}`}
        </LoadingButton>
    );
};

export default LedgerActions;
