import React, { useEffect, useState } from 'react';

import { ArtifactDeploymentState, dalGetPqsLogStream } from '@hub-client-api';

import LogViewer from '@hub-fe/common/LogViewer';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';
import { useMessages } from '@hub-fe/messages/MessageContext';

import InformationBlock from './InformationBlock';
import { containsDamlModel } from './utils/containsDamlModel';

export const PqsLogViewer: React.FC = () => {
    const { ledgerId, pqsStatus, pqsInstance, deployedArtifactInstances } = useLedgerContext();
    const { service } = useServiceParams();

    const isOff = pqsStatus?.runState === ArtifactDeploymentState.STATE_OFF;

    const msg = useMessages();
    const [logStream, setLogStream] = useState<ReadableStream>();

    useEffect(() => {
        if (!pqsInstance || !ledgerId || isOff) {
            return;
        }

        if (!logStream) {
            console.log('=== starting logs ===', { logStream });

            dalGetPqsLogStream(ledgerId)
                .then(stream => setLogStream(stream))
                .catch(() =>
                    msg.error(`There was an error obtaining logs for the service ${ledgerId}`)
                );
        }
    }, [ledgerId, logStream]);

    if (!service) {
        return null;
    }
    const downloadFileName = 'log' + '-' + ledgerId + '-' + '.log';
    if (!deployedArtifactInstances || !containsDamlModel(deployedArtifactInstances))
        return (
            <InformationBlock enableBackground>
                <p>
                    PQS instance will not start until the first DAR has been uploaded to your
                    ledger. Upload a DAR from the <a href="./deployments">Deployments page</a>
                </p>
            </InformationBlock>
        );

    if (!pqsInstance) {
        return (
            <InformationBlock enableBackground>
                <p>PQS Instance not detected yet.</p>
            </InformationBlock>
        );
    }
    if (pqsStatus?.runState === ArtifactDeploymentState.STATE_OFF) {
        return (
            <InformationBlock enableBackground>
                <p>This PQS instance is either paused or starting up.</p>
            </InformationBlock>
        );
    }
    return (
        <div className="default-deployment-viewer">
            <LogViewer logStream={logStream} downloadFileName={downloadFileName} />
        </div>
    );
};
