import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { IFileInfo } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import { FileUploadButton, FileUploadDropzone } from '@hub-fe/common/FileUpload';
import { IconCheckbox, IconChevronExpandRight } from '@hub-fe/common/Icons';
import { ArtifactFileTag } from '@hub-fe/common/Tag';
import { siteName } from '@hub-fe/common/siteInfo.variants';
import { findSetDifference } from '@hub-fe/common/utils';

import CollectionFileMenu from './CollectionFileMenu';

const CollectionFile: React.FC<{
    file: IFileInfo;
    selected: boolean;
    onFileSelect: (selected: boolean, fileName: string) => void;
}> = props => {
    const file = props.file;
    const handleClick = () => {
        props.onFileSelect(!props.selected, file.file_name);
    };

    function handleDragStart(evt: any, fileName: string) {
        evt.dataTransfer.setData('text', fileName);
    }

    return (
        <div onClick={handleClick} className="collection-file">
            <div className="collection-file-checkbox">
                <IconCheckbox checked={props.selected} />
            </div>
            <div
                className="p2 collection-file-filename tile"
                draggable
                onDragStart={e => handleDragStart(e, file.file_name)}
            >
                <b>{file.file_name}</b>
                {file.file_tag && <ArtifactFileTag tag={file.file_tag} />}
            </div>
        </div>
    );
};

const Collections: React.FC = () => {
    const [selectedFiles, setSelectedFiles] = useState(new Set<string>());
    const { collectionVisible, files, isConnectUiEnabled } = useAppContext();
    const { toggleCollectionVisible, uploadWorkspaceFile } = useAppActions();

    useEffect(() => {
        const allFiles = new Set(files.map(file => file.file_name));
        const staleFiles = findSetDifference(selectedFiles, allFiles);

        if (staleFiles.size > 0) {
            setSelectedFiles(findSetDifference(selectedFiles, staleFiles));
        }
    }, [selectedFiles, files]);

    function onFileSelect(selected: boolean, fileName: string) {
        const newSet = new Set(selectedFiles);
        selected ? newSet.add(fileName) : newSet.delete(fileName);
        setSelectedFiles(newSet);
    }

    function onSelectAllFiles(selected: boolean) {
        const newSet = new Set(selected ? files.map(file => file.file_name) : null);
        setSelectedFiles(newSet);
    }

    return (
        <div
            className={classNames('collections', {
                expanded: collectionVisible,
                marginTop: !isConnectUiEnabled,
                narrow: isConnectUiEnabled,
            })}
            onClick={() => !collectionVisible && toggleCollectionVisible()}
        >
            <div
                className="collapse"
                onClick={() => collectionVisible && toggleCollectionVisible()}
            >
                <div className="title">
                    <h2>{isConnectUiEnabled ? 'Files' : 'Collections'}</h2>
                    <div
                        className={classNames(
                            { 'button ghost': collectionVisible },
                            'collections-expand-button',
                            { expanded: collectionVisible }
                        )}
                    >
                        <IconChevronExpandRight />
                    </div>
                </div>
                {!isConnectUiEnabled && (
                    <p className="p2 row details">Your files uploaded to {siteName}.</p>
                )}
            </div>

            <FileUploadDropzone
                className={classNames('collections-body', {
                    expanded: collectionVisible,
                })}
                onUploadRequest={file => uploadWorkspaceFile(file)}
            >
                {collectionVisible && (
                    <>
                        <div className="upload-details">
                            <FileUploadButton
                                onUploadRequest={file => uploadWorkspaceFile(file)}
                                ghostButton
                            />
                            <p className="p2">or drag and drop</p>
                        </div>

                        {files.length > 0 && (
                            <div
                                className="select-all"
                                onClick={() =>
                                    onSelectAllFiles(files.length !== selectedFiles.size)
                                }
                            >
                                <IconCheckbox checked={files.length === selectedFiles.size} />
                                <p>File:</p>
                            </div>
                        )}

                        {files.length > 0 && (
                            <CollectionFileMenu
                                allSelected={files.length === selectedFiles.size}
                                fileNames={Array.from(selectedFiles)}
                                onFileMenuActionComplete={() => onSelectAllFiles(false)}
                                numberSelected={selectedFiles.size || 0}
                            />
                        )}
                        <div className="collections-files">
                            {files.map(file => (
                                <CollectionFile
                                    key={file.file_name}
                                    file={file}
                                    selected={selectedFiles.has(file.file_name)}
                                    onFileSelect={onFileSelect}
                                />
                            ))}
                        </div>
                    </>
                )}
                {!isConnectUiEnabled && !collectionVisible && (
                    <p className="p2 details row">
                        Expand to view or drag and drop files onto ledgers.
                    </p>
                )}
            </FileUploadDropzone>
        </div>
    );
};

export default Collections;
