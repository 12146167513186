import React from 'react';

import LoadingButton from '@hub-fe/common/LoadingButton';
import Modal from '@hub-fe/common/Modal';
import { NameValidationBlock, isNameValid } from '@hub-fe/common/RenameModal';

import { useLedgerActions, useLedgerContext } from '../LedgerContext';
import './AddPartyModal.scss';

interface AddPartyModalProps {
    onRequestClose: () => void;
}
export const AddPartyModal: React.FC<AddPartyModalProps> = ({ onRequestClose }) => {
    const [hint, setHint] = React.useState('');
    const [displayName, setDisplayName] = React.useState<undefined | string>();
    const { isLedgerCollaborator } = useLedgerContext();
    const [isLoading, setLoading] = React.useState(false);
    const { addParty, updateParties } = useLedgerActions();
    const { ownerParties } = useLedgerContext();
    const existingIdentifierHints = ownerParties?.map(party => party.identifier.split('::')[0]);
    const onAddParty = async () => {
        setLoading(true);
        await addParty(hint, displayName, isLedgerCollaborator);
        updateParties();
        onRequestClose();
        setLoading(false);
    };

    const validationState = isNameValid(hint, existingIdentifierHints || []);
    return (
        <Modal
            className="add-party-modal value-entry-modal"
            onRequestClose={onRequestClose}
            show
            title="Add Party"
        >
            <p className="p2">Identifier Hint</p>

            <input
                autoFocus
                type="text"
                value={hint}
                onChange={e => setHint(e.target.value.trim())}
                placeholder="Enter Identifier Hint"
            />
            <NameValidationBlock uniqueName validationState={validationState} />
            <div className="display-name-block">
                <p className="p2">Display Name</p>
                <input
                    type="text"
                    value={displayName}
                    onChange={e => setDisplayName(e.target.value)}
                    placeholder="Enter Display Name"
                />
                <div>
                    <i className="p2 caption">* Optional</i>
                </div>
            </div>
            <div className="buttons" style={{ marginLeft: 'auto' }}>
                <LoadingButton
                    loading={isLoading}
                    onClick={onAddParty}
                    disabled={!validationState.isValid}
                >
                    Add
                </LoadingButton>
            </div>
        </Modal>
    );
};
