import React, { useState, useEffect } from 'react';

import ExpirationDateDropdown from '@hub-fe/common/ExpirationDateDropdown';
import { IconAddPlus, IconClose } from '@hub-fe/common/Icons';
import LoadingButton from '@hub-fe/common/LoadingButton';
import PartyDropdown, {
    formatSelectionData,
    filterPartyOptions,
} from '@hub-fe/common/PartyDropdown';
import { getExpirationDateFormatted } from '@hub-fe/common/Timing';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';

export enum ConfigWizardSteps {
    LABEL = 'label',
    EXPIRATION = 'expiration',
    PARTY = 'party',
}

const ConfigurationWizard: React.FC<{
    label: string;
    steps: ConfigWizardSteps[];
    requiredSteps?: ConfigWizardSteps[];
    onSubmit: (configMap: Map<ConfigWizardSteps, any>, onFinish: () => void) => void;
}> = ({ label, steps, requiredSteps, onSubmit }) => {
    const blankConfig = new Map(steps.map(s => [s, undefined]));
    const firstStep = steps[0];

    const [configMap, setConfigMap] = useState<Map<ConfigWizardSteps, any>>(blankConfig);
    const [showConfigWizard, setShowConfigWizard] = useState(false);
    const [currentStep, setCurrentStep] = useState<ConfigWizardSteps>(firstStep);
    const [saving, setSaving] = useState<boolean>(false);

    const currentStepIndex = steps.indexOf(currentStep);
    const goToNextStep = () => setCurrentStep(steps[currentStepIndex + 1]);
    const goToPreviousStep = () => setCurrentStep(steps[currentStepIndex - 1]);
    const disableNext = requiredSteps?.includes(currentStep) && !configMap.get(currentStep);

    useEffect(() => {
        if (!showConfigWizard) {
            setConfigMap(blankConfig);
            setCurrentStep(firstStep);
        }
    }, [showConfigWizard]);

    return showConfigWizard ? (
        <div className="configuration-wizard">
            <div className="tile new-config-panel">
                <div className="title">
                    <h4>
                        {label} Creation
                        {configMap.get(ConfigWizardSteps.LABEL) &&
                            `: ${configMap.get(ConfigWizardSteps.LABEL)}`}
                    </h4>
                    <button className="ghost" onClick={() => setShowConfigWizard(false)}>
                        <IconClose />
                    </button>
                </div>
                <StepRenderer
                    step={currentStep}
                    stepValue={configMap.get(currentStep)}
                    onChangeConfig={updateConfigMap}
                    currentPageNumber={currentStepIndex + 1}
                />
                <div className="actions">
                    {currentStepIndex > 0 ? (
                        <button className="secondary" onClick={goToPreviousStep}>
                            Back
                        </button>
                    ) : (
                        <button className="secondary" onClick={() => setShowConfigWizard(false)}>
                            Cancel
                        </button>
                    )}
                    {currentStepIndex < steps.length - 1 ? (
                        <button disabled={disableNext} onClick={goToNextStep}>
                            Next
                        </button>
                    ) : (
                        <LoadingButton
                            disabled={disableNext}
                            className={'secondary'}
                            loading={saving}
                            onClick={onCreate}
                            loadingContent={'Creating'}
                        >
                            Create
                        </LoadingButton>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <div className="row">
            <button
                className="secondary-smaller p2 icon-left"
                onClick={() => setShowConfigWizard(true)}
            >
                <IconAddPlus /> Create {label}
            </button>
        </div>
    );

    function updateConfigMap(value: any) {
        const updatedMap = new Map(configMap);
        updatedMap.set(currentStep, value);
        setConfigMap(updatedMap);
    }

    function onCreate(): void {
        setSaving(true);
        onSubmit(configMap, () => {
            setSaving(false);
            setShowConfigWizard(false);
        });
    }
};

const StepRenderer: React.FC<{
    step: ConfigWizardSteps;
    stepValue?: any;
    onChangeConfig: (val?: any) => void;
    currentPageNumber: number;
}> = ({ step, onChangeConfig, stepValue, currentPageNumber }) => {
    switch (step) {
        case ConfigWizardSteps.EXPIRATION:
            return (
                <ExpirationDateStep
                    onSetExpiration={onChangeConfig}
                    expiration={stepValue}
                    currentPageNumber={currentPageNumber}
                />
            );
        case ConfigWizardSteps.LABEL:
            return (
                <LabelStep
                    onSetLabel={onChangeConfig}
                    label={stepValue}
                    currentPageNumber={currentPageNumber}
                />
            );
        case ConfigWizardSteps.PARTY:
            return (
                <PartyStep
                    onSetParty={onChangeConfig}
                    party={stepValue}
                    currentPageNumber={currentPageNumber}
                />
            );
    }
};

const ExpirationDateStep: React.FC<{
    onSetExpiration: (expiration?: Date) => void;
    expiration?: Date;
    currentPageNumber: number;
}> = ({ onSetExpiration, expiration, currentPageNumber }) => {
    return (
        <div className="step">
            <p>{currentPageNumber}. Set an expiration date </p>
            <ExpirationDateDropdown onSetExpiration={onSetExpiration} expiration={expiration} />
            {!!expiration && (
                <p className="p2 expire-time">
                    This credential will expire on {getExpirationDateFormatted(expiration)}.
                </p>
            )}
        </div>
    );
};

const PartyStep: React.FC<{
    onSetParty: (party: string) => void;
    party?: string;
    currentPageNumber: number;
}> = ({ onSetParty, party, currentPageNumber }) => {
    const { ledger, ownerParties = [], defaultParties, isLedgerOwner } = useLedgerContext();
    const partyOptions = formatSelectionData(
        filterPartyOptions(ownerParties, defaultParties, true, !isLedgerOwner)
    );

    return (
        <div className="step">
            <p>{currentPageNumber}. Select Party</p>
            <PartyDropdown
                selectedParty={party}
                onChange={p => onSetParty(p.value)}
                partyOptions={partyOptions}
                ledgerStatus={ledger?.info.status.ledger}
            />
        </div>
    );
};

const LabelStep: React.FC<{
    onSetLabel: (label: string) => void;
    label?: string;
    currentPageNumber: number;
}> = ({ onSetLabel, label, currentPageNumber }) => (
    <div className="step">
        <p>{currentPageNumber}. Label this a credential (optional)</p>
        <input
            className="input p2"
            value={label}
            placeholder="Label"
            onChange={evt => onSetLabel(evt.target.value.trim())}
        />
    </div>
);

export default ConfigurationWizard;
