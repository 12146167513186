import classNames from 'classnames';
import React from 'react';

import { ILedgerParty } from '@hub-client-api';

import TemplateDropdown from '@hub-fe/app/TemplateDropdown';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import PartyDropdown, { formatSelectionData } from '@hub-fe/common/PartyDropdown';
import { PartyDetails } from '@hub-fe/common/generated/clients/damlhub';
import { parseTemplateName } from '@hub-fe/common/utils';

import {
    getFilterQueryString,
    useContractFilterQuery,
} from './ActiveContracts/ActiveContractsSearch';
import './ContractSearchPanel.scss';
import { useLedgerContext } from './LedgerContext';

interface ContractSearchPanelProps {
    parties: ILedgerParty[] | PartyDetails[];
    onSearchClick: () => void;
    showSearchRes: boolean;
}
export const ContractSearchPanel: React.FC<ContractSearchPanelProps> = ({
    parties,
    onSearchClick,
    showSearchRes,
}) => {
    const { contractFilter, party } = useContractFilterQuery();
    const [selectedTemplate, setSelectedTemplate] = React.useState<string | undefined>();
    const { selectedParty, ledger } = useLedgerContext();
    const partyOptions = formatSelectionData(parties);

    React.useEffect(() => {
        if (contractFilter.length > 0) {
            setSelectedTemplate(contractFilter[0]);
        }
    }, []);

    const [currentParty, setCurrentParty] = React.useState(selectedParty);

    function handleRemoveValue(inputValue: string) {
        if (contractFilter.findIndex(x => x === inputValue) >= 0) {
            changeContractFilterQuery(contractFilter.filter(x => x !== inputValue));
        }
    }

    function changeContractFilterQuery(newFilter: string[]) {
        const qs = getFilterQueryString(party, newFilter);
        const search = qs ? `?${qs}` : '';
        history.push(search);
    }
    const onChange = (filterItem: string) => {
        selectedTemplate && handleRemoveValue(selectedTemplate);
        setSelectedTemplate(filterItem);

        if (contractFilter.findIndex(x => x === filterItem) === -1) {
            changeContractFilterQuery([filterItem]);
        }
    };

    const onClick = () => {
        if (!selectedTemplate) {
            return;
        }
        if (contractFilter.findIndex(x => x === selectedTemplate) === -1) {
            changeContractFilterQuery([selectedTemplate]);
        }
        onSearchClick();
    };
    const val = partyOptions?.find(i => i.value === selectedParty);
    const { templateName } = parseTemplateName(selectedTemplate || '');
    return (
        <>
            <div className={classNames('query-container', { hide: !showSearchRes })}>
                <div className="selected-field">{val?.label}</div>
                <div className="selected-field">{templateName}</div>
                <button className="spacing button secondary-smaller" onClick={onSearchClick}>
                    Change query
                </button>
            </div>
            <div className={classNames('search-container', { hide: showSearchRes })}>
                <div className="field-row">
                    <h4 className="field-name">Party</h4>
                    <div className="field-value">
                        <PartyDropdown
                            selectedParty={currentParty}
                            onChange={val => {
                                if (val) {
                                    setCurrentParty(val);
                                    if (val.value === selectedParty) {
                                        !val?.userPartyItem &&
                                            history.replace(`?party=${val?.value}`);
                                    } else {
                                        !val?.userPartyItem && history.push(`?party=${val?.value}`);
                                    }
                                }
                            }}
                            ledgerStatus={ledger?.info.status.ledger}
                            partyOptions={partyOptions}
                        />
                    </div>
                </div>
                <div className="field-row">
                    <h4 className="field-name">Template</h4>
                    <div className="field-value">
                        <TemplateDropdown
                            selectedTemplate={selectedTemplate}
                            className="element"
                            disabledDefaultSelect
                            onChange={sel => onChange(sel)}
                        />
                    </div>
                </div>
                <div className="field-row">
                    <div style={{ marginTop: '8px' }}>
                        <button onClick={onClick} disabled={!selectedTemplate}>
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
