import { ILedgerData, IParticipantData, LedgerSizeEnum } from '@hub-client-api';

import { isLedgerData, isParticipantData } from '@hub-fe/app/AppContext';

import { CreateServiceRequestSizeEnum } from '../generated/clients/damlhub';

export const isLargeLedgerOrParticipant = (service: ILedgerData | IParticipantData): boolean => {
  if (isParticipantData(service)) {
    return service.info.participantSize === CreateServiceRequestSizeEnum.Large;
  }
  if (isLedgerData(service)) {
    return service.info.ledgerSize === LedgerSizeEnum.LARGE;
  }
  return false;
};
