import domains from '@hub-fe/common/domains';

export enum CopyActionsEnum {
  JWT = 'JWT',
  CURL = 'curl',
  FETCH = 'fetch',
  GRPCURL = 'grpcurl',
}
export const formatCopyToken = (
  format: string,
  ledgerId: string,
  defaultDomain: string,
  token: string,
  identifier: string,
  isUser?: boolean
): string | undefined => {
  const partyGrpcurl = `grpcurl -H "Authorization: Bearer ${token}" \\
  -d '{"ledgerId": "${ledgerId}", "filter": {"filtersByParty":[{"key": "${identifier}"}]} }' \\
  ${ledgerId}.${__APP_DOMAIN__}:443 \\
  com.daml.ledger.api.v1.ActiveContractsService/GetActiveContracts `;

  const userGrpcurl = `grpcurl -H "Authorization: Bearer ${token}" \
  ${ledgerId}.${__APP_DOMAIN__}:443 \
  com.daml.ledger.api.v1.admin.UserManagementService.GetUser`;

  switch (format) {
    case CopyActionsEnum.JWT:
      return token;

    case CopyActionsEnum.CURL:
      return `curl -H "Authorization: Bearer ${token}" ${defaultDomain}/v1/query`;

    case CopyActionsEnum.FETCH:
      const options = {
        credentials: 'include',
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${token}`,
        },
        mode: 'cors',
      };
      return `fetch("https://${domains.ledger(ledgerId)}/v1/query", ${JSON.stringify(options)})`;
    case CopyActionsEnum.GRPCURL:
      if (isUser) {
        return userGrpcurl;
      }
      return partyGrpcurl;
    default:
      return undefined;
  }
};
