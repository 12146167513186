import React from 'react';

import { useAppContext } from '@hub-fe/app/AppContext';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { EmptyWorkspace } from '@hub-fe/common/EmptyWorkspace';
import LoadingPage from '@hub-fe/common/LoadingPage';
import {
    WorkSpaceNewLayout,
    WorkspacePageEnum,
    defaultServicePath,
} from '@hub-fe/common/WorkspaceNewLayout';
import { Route, Router } from '@hub-fe/common/routing';
import { getActiveServices } from '@hub-fe/common/utils/getActiveServices';

import { ConnectionsTab } from './ConnectionsTab';
import { Scratchpads } from './Scratchpads/Scratchpads';

export const WorkspaceNew: React.FC = () => {
    const {
        isConnectUiEnabled,
        participants,
        synchronizers,
        scratchpads,
        tempParticipants,
        tempSynchronizers,
        tempScratchpads,
        isInitializing,
    } = useAppContext();
    const hasActiveParticipants =
        !!getActiveServices(participants).length || Object.values(tempParticipants).length;
    const hasActiveSynchronizers =
        !!getActiveServices(synchronizers).length || Object.values(tempSynchronizers).length;
    const hasActiveScratchpads =
        !!getActiveServices(scratchpads).length || Object.values(tempScratchpads).length;
    const enableEmptyWorkSpace =
        !hasActiveScratchpads && !hasActiveParticipants && !hasActiveSynchronizers;

    const onKeyDown = React.useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Enter' && isConnectUiEnabled) {
                history.push(defaultServicePath);
            }
        },
        [isConnectUiEnabled]
    );
    React.useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);
    if (isInitializing) {
        return <LoadingPage statusText="Loading Workspace" />;
    }
    return (
        <WorkSpaceNewLayout enableEmptyWorkspace={enableEmptyWorkSpace}>
            <Router>
                <Route
                    path={WorkspacePageEnum.SCRATCHPAD}
                    render={() => (!hasActiveScratchpads ? <EmptyWorkspace /> : <Scratchpads />)}
                />
                <Route
                    default
                    path={WorkspacePageEnum.NODES}
                    render={() =>
                        !hasActiveParticipants && !hasActiveSynchronizers ? (
                            <EmptyWorkspace />
                        ) : (
                            <ConnectionsTab />
                        )
                    }
                />
            </Router>
        </WorkSpaceNewLayout>
    );
};
