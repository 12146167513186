import React, { useCallback, useEffect, useState } from 'react';

import ConfigurationWizard, { ConfigWizardSteps } from '@hub-fe/common/ConfigurationWizard';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import ExternalLink from '@hub-fe/common/ExternalLink';
import LoadingButton from '@hub-fe/common/LoadingButton';
import LoadingPage from '@hub-fe/common/LoadingPage';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import { EMPTY_HEADER } from '@hub-fe/common/ReactTable/ReactTableUtils';
import { getTimeLeft } from '@hub-fe/common/Timing';
import * as urls from '@hub-fe/common/urls';

import { useLedgerActions, useLedgerContext } from '../LedgerContext';

const ServiceAccounts: React.FC = () => {
    const { ownerParties, serviceAccounts, isLedgerCollaborator } = useLedgerContext();
    const { createServiceAccount, deleteServiceAccount } = useLedgerActions();
    const [deleting, setDeleting] = useState<string[]>([]);

    useEffect(() => {
        const updated = deleting.reduce((acc: string[], id: string) => {
            if (!serviceAccounts?.find(({ creds }) => creds.find(({ credId }) => credId === id))) {
                acc = [...acc, id];
            }
            return acc;
        }, []);
        setDeleting(updated);
    }, [serviceAccounts]);

    const getOwnedParty = useCallback(
        (partyId: string) => ownerParties?.find(({ identifier }) => identifier === partyId),
        [ownerParties]
    );

    if (!ownerParties || !serviceAccounts) {
        return (
            <div className="service-accounts">
                <LoadingPage statusText={'Loading Service Accounts'} />
            </div>
        );
    }

    const serviceAccountRows = serviceAccounts?.flatMap(sa =>
        sa.creds.map(({ cred, credentialLabel, credId, validTo }) => {
            const party = getOwnedParty(sa.serviceAccount);
            const isDeleting = deleting.includes(credId);
            return {
                rowData: [
                    {
                        sortKey: credentialLabel,
                        renderAs: (
                            <div>
                                <p className="p2">{credentialLabel}</p>
                                {party && <p className="p2">Party: {party?.displayName}</p>}
                            </div>
                        ),
                    },
                    {
                        sortKey: credId,
                        renderAs: (
                            <div>
                                {cred && (
                                    <CopyableDisplay
                                        ghostButton
                                        value={btoa(credId + ':' + cred)}
                                    />
                                )}
                                {validTo && (
                                    <p className="caption">
                                        {getTimeLeft(validTo)
                                            ? `Expires in ${getTimeLeft(validTo)}`
                                            : 'Expired'}
                                    </p>
                                )}
                                {cred && (
                                    <p className="caption">
                                        This credential will no longer be copyable upon page
                                        refresh.
                                    </p>
                                )}
                            </div>
                        ),
                    },
                    {
                        sortKey: 'delete',
                        renderAs: (
                            <LoadingButton
                                disabled={isDeleting}
                                className="secondary-smaller warning"
                                loading={isDeleting}
                                loadingContent="Deleting"
                                onClick={() => handleDeleteCred(credId)}
                            >
                                Delete
                            </LoadingButton>
                        ),
                    },
                ],
            };
        })
    );

    return (
        <div className="service-accounts">
            <h2>Service Accounts</h2>
            {isLedgerCollaborator && (
                <div>
                    <p className="subheading-link">
                        Ledger Collaborators cannot create new service accounts or delete service
                        accounts created by the Ledger Owner
                    </p>
                    <p className="row">
                        Consider using Personal Access Credentials instead. To learn more, read our{' '}
                        <ExternalLink
                            to={urls.docs + '/quickstart/console#personal-access-credentials'}
                            icon
                        >
                            docs
                        </ExternalLink>
                    </p>
                </div>
            )}
            {!isLedgerCollaborator && (
                <>
                    <p className="subheading-link">
                        Generate a service account credential to interact with external services as
                        a particular party. Read more in our{' '}
                        <ExternalLink icon to={urls.docs}>
                            docs
                        </ExternalLink>
                    </p>
                    <ConfigurationWizard
                        label={'Service Account'}
                        steps={[ConfigWizardSteps.PARTY, ConfigWizardSteps.LABEL]}
                        requiredSteps={[ConfigWizardSteps.PARTY]}
                        onSubmit={handleGenerateCredentials}
                    />
                    <div className="domains">
                        <ReactTable
                            defaultStyle="roomy-rows"
                            tableHeaders={[{ label: 'Label' }, { label: 'Cred ID' }, EMPTY_HEADER]}
                            tableRows={serviceAccountRows}
                        />
                    </div>
                </>
            )}
        </div>
    );

    function handleDeleteCred(credId: string) {
        setDeleting([...deleting, credId]);
        deleteServiceAccount(credId);
    }

    function handleGenerateCredentials(
        configMap: Map<ConfigWizardSteps, any>,
        onFinish: () => void
    ) {
        const party = configMap.get(ConfigWizardSteps.PARTY);
        const label = configMap.get(ConfigWizardSteps.LABEL);
        if (party) {
            createServiceAccount(party, label);
            onFinish();
        }
    }
};

export default ServiceAccounts;
