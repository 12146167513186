import { IParticipantData } from '@hub-client-api';

import { SynchronizerParticipantsResponse } from '../generated/clients/damlhub';

export const getDisabledOwnedParticipants = (args: {
  ownedParticipants: IParticipantData[];
  allowedParticipants?: SynchronizerParticipantsResponse['participants'];
}): IParticipantData[] => {
  const { ownedParticipants, allowedParticipants } = args;
  const allowedParticipantByPid: { [key: string]: string } = {};
  allowedParticipants?.forEach(
    a => (allowedParticipantByPid[`PAR::${a.participantId}`] = a.permissionLevel)
  );
  const disabledOwnedParticipants = ownedParticipants.filter(
    owned =>
      allowedParticipantByPid[owned.info.participantId] === 'Disabled' ||
      allowedParticipantByPid[owned.info.participantId] === undefined
  );
  return disabledOwnedParticipants;
};
