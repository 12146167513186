import copy from 'clipboard-copy';
import React, { useState } from 'react';

import { ObjectStatus } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import { CopyActions } from '@hub-fe/common/CopyActions';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import DownloadButton from '@hub-fe/common/DownloadButton';
import { IconAddPlus } from '@hub-fe/common/Icons';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import {
    IReactTableRow,
    IReactTableData,
    EMPTY_HEADER,
} from '@hub-fe/common/ReactTable/ReactTableUtils';
import ToolTip from '@hub-fe/common/ToolTip';
import { downloadFile, getPartyInfoJson } from '@hub-fe/common/utils';
import { formatCopyToken } from '@hub-fe/common/utils/formatCopyToken';

import AddIdentityModal, { IdentityTypeEnum } from '../ActiveContracts/AddIdentityModal';
import { useLedgerActions, useLedgerContext } from '../LedgerContext';

const Parties: React.FC<{}> = () => {
    const {
        isLedgerOwner,
        ownerParties = [],
        userParties = [],
        defaultParties,
        ledger,
        ledgerId,
        isLedgerCollaborator,
        subdomains,
    } = useLedgerContext();
    const { userInfo } = useAppContext();
    const { mintDamlPartyToken } = useLedgerActions();

    const [showAddIdentityModal, setShowAddIdentityModal] = useState(false);

    let partyRows: IReactTableRow[] = [];
    if (isLedgerOwner) {
        partyRows = [
            ...partyRows,
            ...userParties.map(p => ({
                rowData: [
                    {
                        sortKey: p.identifier === p.displayName ? 'no alias' : p.displayName,
                        renderAs: p.identifier.startsWith(ledgerId) ? (
                            <div className="connected-party">
                                <i className="p2 display-name">
                                    {p.displayName || 'Canton System Party'}
                                </i>
                            </div>
                        ) : (
                            <div className="connected-party">
                                <i className="p2 display-name">{p.displayName || p.identifier}</i>
                            </div>
                        ),
                    },
                    { sortKey: p.identifier, renderAs: <CopyableDisplay value={p.identifier} /> },
                    {
                        sortKey: 'Copy',
                        renderAs: (
                            <CopyActions handleCopy={format => handleCopy(format, p.identifier)} />
                        ),
                    },
                ],
            })),
        ];
    }

    partyRows = [
        ...partyRows,
        ...ownerParties
            .filter(
                ({ identifier: i }) =>
                    i !== defaultParties?.userAdmin?.identifier &&
                    i !== defaultParties?.publicParty?.identifier &&
                    i !== ''
            )
            .map(p => {
                const isCollaboratorParty =
                    p.displayName === userInfo?.userName && isLedgerCollaborator;

                const ownerPartyRowCells = [
                    isCollaboratorParty
                        ? {
                              sortKey: p.displayName,
                              renderAs: (
                                  <div className="collaborator-party">
                                      <p className="p2"> {p.displayName}</p>

                                      <ToolTip
                                          caption={
                                              'This party is owned by you, the ledger collaborator.'
                                          }
                                      />
                                  </div>
                              ),
                          }
                        : { sortKey: p.displayName },
                    {
                        sortKey: p.identifier,
                        renderAs: (
                            <div className="collaborator-party">
                                <CopyableDisplay value={p.identifier} />
                                {isCollaboratorParty && (
                                    <div className="shared-ledger-tag p2">Collaborator Party</div>
                                )}
                            </div>
                        ),
                    },
                    {
                        sortKey: 'Copy',
                        renderAs: (
                            <CopyActions handleCopy={format => handleCopy(format, p.identifier)} />
                        ),
                    },
                ];

                return { rowData: ownerPartyRowCells };
            }),
    ];

    if (defaultParties?.publicParty) {
        let publicPartyRowCells: IReactTableData[] = [
            { sortKey: `"${defaultParties?.publicParty.displayName}"` },
            {
                sortKey: defaultParties?.publicParty.identifier,
                renderAs: <CopyableDisplay value={defaultParties?.publicParty.identifier} />,
            },
        ];

        if (isLedgerOwner) {
            publicPartyRowCells = [
                ...publicPartyRowCells,
                {
                    sortKey: 'Copy',
                    renderAs: (
                        <CopyActions
                            handleCopy={format =>
                                handleCopy(format, defaultParties?.publicParty?.identifier)
                            }
                        />
                    ),
                },
            ];
        }
        partyRows = [...partyRows, { rowData: publicPartyRowCells }];
    }

    if (defaultParties?.userAdmin) {
        let userAdminPartyCells: IReactTableData[] = [
            { sortKey: `"${defaultParties?.userAdmin.displayName}"` },
            {
                sortKey: defaultParties?.userAdmin.identifier,
                renderAs: <CopyableDisplay value={defaultParties?.userAdmin.identifier} />,
            },
        ];

        if (isLedgerOwner) {
            userAdminPartyCells = [
                ...userAdminPartyCells,
                {
                    sortKey: 'Copy',
                    renderAs: (
                        <CopyActions
                            handleCopy={format =>
                                handleCopy(format, defaultParties?.userAdmin?.identifier)
                            }
                        />
                    ),
                },
            ];
        }
        partyRows = [...partyRows, { rowData: userAdminPartyCells }];
    }

    const ledgerIsRunning = ledger?.info.status.ledger === ObjectStatus.RUNNING;

    const headerButtons: JSX.Element[] = [<DownloadParties key="download-button" />];

    if (ledgerIsRunning) {
        isLedgerOwner &&
            headerButtons.push(
                <button
                    className="secondary-smaller icon-left add-party-button"
                    onClick={() => setShowAddIdentityModal(true)}
                    key="add-party-button"
                >
                    <IconAddPlus /> Add Party
                </button>
            );
    }

    return (
        <div className="parties">
            <div className="identities-table">
                <ReactTable
                    tableHeaders={[{ label: 'Alias' }, { label: 'Party' }, EMPTY_HEADER]}
                    tableRows={partyRows}
                    headerButtons={headerButtons}
                    isSortable
                />
            </div>
            {showAddIdentityModal && (
                <AddIdentityModal
                    onRequestClose={() => setShowAddIdentityModal(false)}
                    identityType={IdentityTypeEnum.PARTY}
                />
            )}
        </div>
    );

    function handleCopy(format: string, identifier?: string) {
        if (!subdomains?.default || !identifier) {
            return;
        }

        mintDamlPartyToken(identifier).then(token => {
            if (subdomains.default) {
                const content = formatCopyToken(
                    format,
                    ledgerId,
                    subdomains?.default.domain,
                    token,
                    identifier
                );

                if (content) {
                    copy(content);
                }
            }
        });
    }
};

const DownloadParties = () => {
    const { ownerParties = [], ledgerId, ledger } = useLedgerContext();
    const doDownload = () =>
        getPartyInfoJson(ownerParties, ledgerId).then(partyInfo => {
            const jsonContent =
                'data:text/json;charset=utf-8,' + JSON.stringify(partyInfo, null, 4);
            const encodedUri = encodeURI(jsonContent);
            downloadFile(encodedUri, 'parties.json');
        });

    return (
        <DownloadButton
            label="Download parties.json"
            onClick={doDownload}
            disabled={ledger?.info.status.ledger !== ObjectStatus.RUNNING || !ownerParties}
        />
    );
};

export default Parties;
