import classNames from 'classnames';
import React from 'react';

import { IScratchpadData, ObjectStatus } from '@hub-client-api';

import { InfiniteLoadingWheel } from '@hub-fe/common/Animations/Animations';
import { CopyButton } from '@hub-fe/common/ClipboardButtons';
import { IconSmallCircle } from '@hub-fe/common/Icons';
import { ServiceTileTag } from '@hub-fe/common/ServiceTileTag';
import {
    disableClickThrough,
    scratchpadStatusInidcator,
    serviceTransientStates,
} from '@hub-fe/common/displayedStatusesConfig';
import { NavLink } from '@hub-fe/common/routing';
import { useDragDrop } from '@hub-fe/common/utils/useDragDrop';

import { ServiceType } from './ChooseServiceType';
import './ScratchpadTile.scss';

interface ScratchpadTileProps {
    serviceType?: ServiceType;
    serviceId: string;
    capacity?: string;
    appIcon?: React.ReactNode;
    scratchpad: IScratchpadData;
}

const statusWithLoadingDots = [ObjectStatus.PAUSING, ObjectStatus.RESUMING, ObjectStatus.STARTING];
export const ScratchpadTile: React.FC<ScratchpadTileProps> = ({
    serviceType,
    serviceId,
    capacity,
    appIcon,
    scratchpad,
}) => {
    const status = scratchpad.objectStatus || scratchpad.info.status.ledger;
    const ledgerStatus = scratchpad.objectStatus || scratchpad.info.status.ledger;
    const color = scratchpadStatusInidcator[status]?.color || 'disabled';
    const hasTransientState = serviceTransientStates.includes(status);
    const isSharedService = !!scratchpad.info.secondaryOwners.length;
    const target = `/console/scratchpad/${scratchpad.info.id}/overview`;
    const serviceAccessDisabled = disableClickThrough(status);
    const { dragProps } = useDragDrop({ ledger: scratchpad, serviceAccessDisabled });
    const appTile = (
        <div {...dragProps} className={classNames('app-tile', { disabled: serviceAccessDisabled })}>
            <div className="header">
                <h4>{scratchpad.info.name}</h4>
                {isSharedService && <ServiceTileTag tagLabel="Shared Service" />}
                <div className="app-type">
                    {appIcon && <div className="icon">{appIcon}</div>}
                    <p className="p2">{serviceType}</p>
                </div>
            </div>
            <div className="row">
                <p className="p2 participant-id">Service ID: {serviceId}</p>
                <CopyButton content={serviceId} ghost />
            </div>
            <div className="app-properties">
                {hasTransientState ? <InfiniteLoadingWheel /> : <IconSmallCircle color={color} />}
                <div className="spacing-left">
                    <p className="p2">
                        {ledgerStatus}
                        {statusWithLoadingDots.includes(ledgerStatus) ? '...' : ''}
                    </p>
                </div>
                {capacity && <div className="divider p2">|</div>}
                <p className="spacing-left p2">{capacity}</p>
            </div>
        </div>
    );
    if (serviceAccessDisabled) {
        return <a>{appTile}</a>;
    }

    return <NavLink to={target}>{appTile}</NavLink>;
};
