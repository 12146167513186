import React from 'react';

import { IEntityInfo, IPublishedDeployedArtifactInfo, LedgerBackendEnum } from '@hub-client-api';

import InformationBlock from '@hub-fe/common/InformationBlock';
import { FileTag } from '@hub-fe/common/Tag';
import { validateEntity, MIN_CANTON_SDK_VERSION } from '@hub-fe/common/artifacts';
import { getEntityDisplayName, getEntityType } from '@hub-fe/common/utils';

import { useLedgerContext } from '../LedgerContext';
import DeploymentInstanceConfigurator from './DeploymentInstanceConfigurator';

enum artifactDescriptionEnum {
    'DablPackage' = 'Finish this deployment by clicking Deploy Instance.',
    'DamlModel' = 'Deploy this Model to your ledger.',
    'LedgerBot' = 'Select a party for this automation to run as.',
    'DamlTrigger' = 'Select a party and Daml trigger to launch the first instance of this artifact.',
    'UserWebsite' = `Make your website live and discoverable. We provide a Daml Hub subdomain`,
}

enum publishedArtifactDescriptionEnum {
    'DamlTrigger' = 'Select a Daml trigger to launch the first instance of this artifact.',
}

const InlineDeploymentInstanceConfig: React.FC<{
    artifact: IEntityInfo;
    publishedDeployedArtifact?: IPublishedDeployedArtifactInfo;
}> = ({ artifact, publishedDeployedArtifact }) => {
    const { isLedgerOwner, ledger } = useLedgerContext();

    const { entity } = artifact;

    const displayName = getEntityDisplayName(entity);
    const entityType = getEntityType(entity);
    const description = isLedgerOwner
        ? artifactDescriptionEnum[entityType]
        : entityType === 'LedgerBot'
        ? undefined
        : entityType === 'DamlTrigger'
        ? publishedArtifactDescriptionEnum[entityType]
        : undefined;

    const validationErrors = ledger ? validateEntity(entity, ledger.info.backend) : [];

    return (
        <tr className="inline-deployment-config">
            <td>
                <p className="display-name">
                    {displayName}
                    <FileTag entity={entity} />
                </p>
                {validationErrors.length === 0 && description && (
                    <p className="p2 row">{description}</p>
                )}
            </td>
            <td>
                {validationErrors.length > 0 ? (
                    <div className="validation-errors">
                        <InformationBlock warning>Invalid Artifact</InformationBlock>
                        <div className="row">
                            {validationErrors.map((e, i) => (
                                <p className="p2 validation-error" key={i}>
                                    SDK version<b> {e.sdkVersion} </b>is below the minimum SDK
                                    version<b> {MIN_CANTON_SDK_VERSION} </b> allowed on{' '}
                                    {LedgerBackendEnum.CANTON} ledgers.
                                </p>
                            ))}
                        </div>
                    </div>
                ) : (
                    <DeploymentInstanceConfigurator
                        artifact={artifact}
                        inline
                        publishedDeployedArtifact={publishedDeployedArtifact}
                    />
                )}
            </td>
        </tr>
    );
};

export default InlineDeploymentInstanceConfig;
