import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import { SettingsTable } from '@hub-fe/common/SettingsTable';
import { Storage } from '@hub-fe/common/Storage';
import Toggle from '@hub-fe/common/Toggle';
import { TwoColLayout } from '@hub-fe/common/TwoColLayout';
import { pauseButtonDisplay } from '@hub-fe/common/displayedStatusesConfig';
import { routeToConnections } from '@hub-fe/common/routing/RoutingContext';
import { capitalizePhrase } from '@hub-fe/common/utils';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';
import LedgerDomains from '@hub-fe/ledger/LedgerSettings/LedgerDomains';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import en from '../../../locales/en.json';
import { useAppActions, useAppContext } from '../AppContext';

export const ParticipantSettings: React.FC = () => {
    const { deleteParticipant, pauseParticipant, resumeParticipant, updateServiceProductionTag } =
        useAppActions();
    const { participants } = useAppContext();
    const { serviceId } = useServiceParams();
    const { isLedgerPrimaryOwner } = useLedgerContext();
    const participant = participants.find(p => p.info.id === serviceId);
    const isProd = !!participant?.info.production;
    const isPaused = participant?.objectStatus === ObjectStatus.PAUSED;
    const status = participant?.objectStatus || '';
    const [showServiceActionModal, setShowServiceActionModal] = React.useState<{
        show: boolean;
        actionLabel: string;
        action?: (arg: { info: { id: string } }) => void;
    }>({ show: false, actionLabel: '' });
    const onCloseModal = () => {
        setShowServiceActionModal({ show: false, actionLabel: '' });
    };
    const onDelete = async () => {
        serviceId &&
            setShowServiceActionModal({
                actionLabel: 'delete',
                show: true,
                action: async () => {
                    participant && (await deleteParticipant(participant));
                    routeToConnections();
                },
            });
    };
    const onPause = () => {
        setShowServiceActionModal({
            actionLabel: 'pause',
            show: true,
            action: async () => {
                participant && (await pauseParticipant(participant));
                onCloseModal();
            },
        });
    };
    const onResume = () => {
        participant && resumeParticipant(participant);
    };
    const toggleProd = async () => {
        if (!serviceId) {
            return;
        }
        await updateServiceProductionTag({
            serviceId: serviceId,
            enableProduction: !isProd,
            serviceType: ServiceType.PARTICIPANT,
        });
    };

    const settings = [
        {
            title: 'Tag Service as Production',
            desc: en.serviceSettings.tagServiceAsProductionDesc,
            actionElement: (
                <Toggle
                    on={isProd}
                    onLabel="Enabled"
                    labelPosition="left"
                    offLabel="Off"
                    onColor="green"
                    onChanged={toggleProd}
                    togglingLabel={false ? 'Disabling' : 'Enabling'}
                />
            ),
        },
        {
            title: 'Pause',
            desc: en.serviceSettings.pauseDesc,
            actionElement: (
                <button
                    onClick={isPaused ? onResume : onPause}
                    className="button secondary-smaller"
                    disabled={status === ObjectStatus.PAUSING || status === ObjectStatus.RESUMING}
                >
                    {pauseButtonDisplay[status] || 'Pause'}
                </button>
            ),
        },
        {
            title: 'Delete',
            desc: en.serviceSettings.deleteDesc,
            actionElement: (
                <button
                    disabled={!isLedgerPrimaryOwner}
                    onClick={onDelete}
                    className="button secondary-smaller warning"
                >
                    Delete
                </button>
            ),
        },
    ];
    const col1 = (
        <>
            <h1>Settings</h1>
            <SettingsTable data={settings} />
            {participant && <Storage disks={participant?.info.status.disks} />}
            <div className="participant-subdomains">
                <LedgerDomains disableCustomWebDomain service="Participant" />
            </div>
            <ConfirmActionModal
                onSubmitAction={() =>
                    participant &&
                    showServiceActionModal.action &&
                    showServiceActionModal.action(participant)
                }
                title={`${capitalizePhrase(showServiceActionModal.actionLabel)} Participant`}
                action={capitalizePhrase(showServiceActionModal.actionLabel)}
                deletingAction
                bodyMessage={`Are you sure you want to ${showServiceActionModal.actionLabel} this Participant?`}
                show={showServiceActionModal.show}
                onRequestClose={onCloseModal}
            />
        </>
    );
    return <TwoColLayout col1={col1} />;
};
