import React from 'react';

import { IDisks } from '@hub-client-api';

import { DiskUtilizations } from './DiskUtilizations';

interface StorageProps {
    disks: IDisks;
}
export const Storage: React.FC<StorageProps> = ({ disks }) => {
    const displayed: React.ReactNode[] = [];
    type keys = keyof IDisks;
    const serviceKeys: Array<keys> = Object.keys(disks) as keys[];
    serviceKeys.forEach(key => {
        if (disks[key] !== null) {
            //@ts-ignore, backend returns byteCapacity for Synchronizers
            const bytesCapacity = disks[key].bytesCapacity || disks[key].byteCapacity;
            const bytesUsed = disks[key]?.bytesUsed || 0;
            displayed.push(
                <DiskUtilizations
                    serviceName={key}
                    key={key}
                    bytesCapacity={bytesCapacity}
                    bytesUsed={bytesUsed}
                />
            );
        }
    });
    if (displayed.length === 0) {
        return null;
    }
    return (
        <div>
            <h2>Storage</h2>
            {displayed}
        </div>
    );
};
