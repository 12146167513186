import React from 'react';

import './TwoColLayout.scss';

interface TwoColLayoutProps {
    col1?: React.ReactNode;
    col2?: React.ReactNode;
}

export const TwoColLayout: React.FC<TwoColLayoutProps> = ({ col1, col2 }) => {
    return (
        <div className="two-col-layout">
            <div className="col-1">{col1 || null}</div>
            <div className="col-2">{col2 || null}</div>
        </div>
    );
};
