import React, { useState } from 'react';

import { ObjectStatus } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { IconAddPlus } from '@hub-fe/common/Icons';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import { EMPTY_HEADER, IReactTableRow } from '@hub-fe/common/ReactTable/ReactTableUtils';
import ToolTip from '@hub-fe/common/ToolTip';

import { AddPartyModal } from '../ActiveContracts/AddPartyModal';
import { useLedgerContext } from '../LedgerContext';

const Parties: React.FC<{}> = () => {
    const { isLedgerOwner, ownerParties = [], ledger, isLedgerPrimaryOwner } = useLedgerContext();
    const { userInfo } = useAppContext();
    const [showAddIdentityModal, setShowAddIdentityModal] = useState(false);

    const partyRows: IReactTableRow[] = ownerParties.map(p => {
        const isCollaboratorParty = p.displayName === userInfo?.userName;
        const rowData = [
            {
                sortKey: p.displayName || p.identifier + 'identifier',
                renderAs: (
                    <div className="collaborator-party">
                        <p className="p2"> {p.displayName || p.identifier}</p>

                        {!isLedgerPrimaryOwner && isCollaboratorParty && (
                            <ToolTip
                                caption={'This party is owned by you, the service collaborator.'}
                            />
                        )}
                    </div>
                ),
            },
            {
                sortKey: p.identifier,
                renderAs: (
                    <div className="collaborator-party">
                        <CopyableDisplay value={p.identifier} />
                        {!isLedgerPrimaryOwner && isCollaboratorParty && (
                            <div className="shared-ledger-tag p2">Collaborator Party</div>
                        )}
                    </div>
                ),
            },
            {
                sortKey: p.isLocal ? 'Local' : 'Remote',
                renderAs: <p className="p2">{p.isLocal ? 'Local' : 'Remote'}</p>,
            },
        ];

        return {
            rowData,
        };
    });
    const ledgerIsRunning = ledger?.info.status.ledger === ObjectStatus.RUNNING;
    const headerButtons: JSX.Element[] = [];

    if (ledgerIsRunning) {
        isLedgerOwner &&
            headerButtons.push(
                <button
                    className="secondary-smaller icon-left add-party-button"
                    onClick={() => setShowAddIdentityModal(true)}
                    key="add-party-button"
                >
                    <IconAddPlus /> Add Party
                </button>
            );
    }
    const tableHeaders = [
        { label: 'Display Name' },
        { label: 'Identifier Hint' },
        { label: 'Host' },
        EMPTY_HEADER,
    ];

    return (
        <div className="parties">
            <div className="identities-table">
                <ReactTable
                    tableHeaders={tableHeaders}
                    tableRows={partyRows}
                    headerButtons={headerButtons}
                    isSortable
                />
            </div>
            {showAddIdentityModal && (
                <AddPartyModal onRequestClose={() => setShowAddIdentityModal(false)} />
            )}
        </div>
    );
};

export default Parties;
