import React from 'react';

import { useAppContext } from '@hub-fe/app/AppContext';
import { SideBar } from '@hub-fe/common/Sidebar/SideBar';
import { getScratchpadSideBarLinks } from '@hub-fe/common/Sidebar/config';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';

export const ScratchpadSideBar: React.FC = () => {
    const { isProTier } = useAppContext();

    const { isLedgerOwner } = useLedgerContext();
    const sideBarLinks = getScratchpadSideBarLinks({
        isProTier,
        isLedgerOwner,
    });

    return <SideBar sideBarLinks={sideBarLinks} />;
};
