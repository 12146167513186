import { useNavigate } from '@reach/router';
import classNames from 'classnames';
import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import { InfiniteLoadingWheel } from '@hub-fe/common/Animations/Animations';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { IconSmallCircle } from '@hub-fe/common/Icons';
import { ServiceTileTag } from '@hub-fe/common/ServiceTileTag';
import {
    disableClickThrough,
    participantStatusIndicator,
    serviceTransientStates,
} from '@hub-fe/common/displayedStatusesConfig';
import { useDragDrop } from '@hub-fe/common/utils/useDragDrop';

import './ParticipantNodeTile.scss';

interface ParticipantNodeTileProps {
    name: string;
    serviceId: string;
    capacity: string;
    status: ObjectStatus;
    isProd?: boolean;
    participantId: string;
    isSharedService?: boolean;
}

export const ParticipantNodeTile: React.FC<ParticipantNodeTileProps> = ({
    name,
    serviceId,
    capacity,
    status,
    isProd,
    participantId,
    isSharedService,
}) => {
    const hasTransientState = serviceTransientStates.includes(status);
    const disable = disableClickThrough(status);
    const nav = useNavigate();
    const target = `/console/participant/${serviceId}/overview`;
    const { participants } = useAppContext();
    const service = participants.find(p => p.info.id === serviceId);
    if (!service && status !== ObjectStatus.LOADING) {
        return null;
    }

    const isPendingConnection =
        service?.info.connection && service.info.connection.joined === false;
    const serviceAccessDisabled =
        status === ObjectStatus.DELETING ||
        status === ObjectStatus.PAUSED ||
        status === ObjectStatus.LOADING;
    const dragDrop = service && useDragDrop({ ledger: service, serviceAccessDisabled });
    const displayedCapacity = capacity === 'small' ? 'Standard Capacity' : 'High Capacity';

    const tile = (
        <div {...dragDrop?.dragProps}>
            <div className="main-info">
                <div className="header">
                    <h4>{name}</h4>
                    {isSharedService && <ServiceTileTag tagLabel={'Shared Service'} />}

                    <div className="status">
                        <div className="color-indicator">
                            {hasTransientState ? (
                                <div>
                                    <InfiniteLoadingWheel />
                                </div>
                            ) : status === ObjectStatus.UNCONNECTED && !isPendingConnection ? (
                                <button className="secondary-smaller">Connect</button>
                            ) : (
                                <IconSmallCircle
                                    color={participantStatusIndicator[status]?.color || 'disabled'}
                                />
                            )}
                        </div>
                        {isPendingConnection ? (
                            <p className="p2">
                                {participantStatusIndicator[ObjectStatus.PENDING_CONNECTION]
                                    ?.label || ''}
                            </p>
                        ) : status !== ObjectStatus.UNCONNECTED ? (
                            <p className="p2">{participantStatusIndicator[status]?.label || ''}</p>
                        ) : null}
                    </div>
                </div>
                <div className="participantId-container">
                    <p className="p2 serviceId">Participant Id: </p>
                    <div
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    >
                        <CopyableDisplay
                            enableTransparentBackground
                            value={participantId}
                            ghostButton
                        />
                    </div>
                </div>
            </div>
            <div
                className={classNames('p2', 'other-info', {
                    prod: isProd,
                    largeCap: capacity === 'large' && !isProd,
                })}
            >
                {isProd && (
                    <>
                        <p className="p2" style={{ fontWeight: 'bold' }}>
                            Production
                        </p>
                        <div className="info-divider">|</div>
                    </>
                )}
                <p className="p2">{displayedCapacity}</p>
            </div>
        </div>
    );
    return (
        <div
            className="participant-node-tile"
            onClick={() => {
                !disable && nav(target);
            }}
        >
            {tile}
        </div>
    );
};
