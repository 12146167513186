import React from 'react';

import ExternalLink from '@hub-fe/common/ExternalLink';
import { IconTertiaryArrow } from '@hub-fe/common/Icons';
import * as urls from '@hub-fe/common/urls';

const LearningResources: React.FC = () => {
    return (
        <div className="learning-resources">
            <div className="row">
                <h2>Learning Resources</h2>
            </div>
            <div className="resources">
                <ResourceTile
                    title="Daml Community Forum"
                    description="Discuss anything Daml-related (language, tooling, APIs, Daml Hub deployment) with the community."
                    url={urls.support}
                />
                <ResourceTile
                    title="Daml Docs"
                    description="Install the SDK and learn how to build an app with Daml."
                    url={urls.daml.docs}
                />
                <ResourceTile
                    title="Daml Hub Docs"
                    description="Console Overview and API documentation for deploying with Daml Hub."
                    url={urls.docs}
                />
            </div>
        </div>
    );
};

const ResourceTile = (props: { title: string; description: string; url: string }) => {
    return (
        <ExternalLink to={props.url} className="resource tile">
            <div>
                <h4>{props.title}</h4>
                <p className="p2">{props.description}</p>
            </div>
            <IconTertiaryArrow size="large" />
        </ExternalLink>
    );
};

export default LearningResources;
