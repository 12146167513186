import React, { useState } from 'react';

import { AddUserModal } from '@hub-fe/common/AddUser';
import ValueEntryModal from '@hub-fe/common/ValueEntryModal';

import { useLedgerActions, useLedgerContext } from '../LedgerContext';

export enum IdentityTypeEnum {
    PARTY = 'Party',
    USER = 'User',
}

interface IProps {
    onSubmit?: (val: string) => void;
    onRequestClose: () => void;
    identityType: IdentityTypeEnum;
}

const AddIdentityModal: React.FC<IProps> = ({ onRequestClose, onSubmit, identityType }) => {
    const { isLedgerCollaborator, users = [] } = useLedgerContext();
    const { addParty, createUser, updateUsers, updateParties } = useLedgerActions();
    const [createdIdentity, setCreatedIdentity] = useState('' as string);
    const validateValue = (name: string) => {
        if (!name) {
            return `Please type a ${identityType === IdentityTypeEnum.PARTY ? 'name' : 'User ID'}.`;
        }
        if (/^[a-zA-Z0-9:\-_]+$/.exec(name) == null) {
            return 'Please choose a name with no special characters or spaces.';
        }
        if (users.find(({ userId }) => userId === name)) {
            return 'Please choose a unique name.';
        }

        return undefined;
    };

    const doAddIdentity = async (name: string) => {
        setCreatedIdentity(name);
        if (identityType === IdentityTypeEnum.PARTY) {
            await addParty(name, undefined, isLedgerCollaborator, () => {
                onSubmit && onSubmit(name);
            });
            updateParties();
        } else if (identityType === IdentityTypeEnum.USER) {
            await createUser({ userId: name });
            onRequestClose();
            await updateUsers();
        }
    };

    const userPlaceholderText = 'Enter a valid User ID';
    const partyPlaceholderText = `${identityType} alias`;
    const displayedPlaceholderText =
        identityType === IdentityTypeEnum.USER ? userPlaceholderText : partyPlaceholderText;
    return identityType === IdentityTypeEnum.USER ? (
        <AddUserModal onRequestClose={onRequestClose} />
    ) : (
        <ValueEntryModal
            onRequestClose={onRequestClose}
            title={`Add ${identityType}`}
            placeholder={displayedPlaceholderText}
            validateValue={validateValue}
            onSubmit={doAddIdentity}
            successText={`${identityType} Created: ${createdIdentity}`}
            infoText={
                isLedgerCollaborator && identityType === IdentityTypeEnum.PARTY
                    ? `You are creating this ${identityType} on behalf of the ledger owner.`
                    : undefined
            }
        />
    );
};

export default AddIdentityModal;
