import React from 'react';

const SuggestiveInput: React.FC<{
    suggestions: string[];
    isDisabled?: boolean;
    placeholder?: string;
    value?: string;
    onChange: (val: string) => void;
}> = ({ suggestions, placeholder, value, onChange, isDisabled }) => {
    const activeSuggestions = value ? suggestions.filter(s => s.startsWith(value)) : [];
    return (
        <div className="suggestive-input">
            <input
                type="text"
                className="input p2"
                value={value}
                placeholder={placeholder}
                onChange={evt => onChange(evt.target.value)}
            />
            {!isDisabled && (
                <div className="active-suggestions">
                    {activeSuggestions.map(s => (
                        <div
                            key={s}
                            className="suggestion p2"
                            onClick={() => onChange(s.split('.')[0])}
                        >
                            <p className="p2 suggestion-text">{s}</p>
                            <p className="p2 reserved">Reserved</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SuggestiveInput;
