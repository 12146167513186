import React from 'react';

import { IArcadeAppInfo } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import AppArcade from '@hub-fe/common/AppArcade';
import { Route, Router } from '@hub-fe/common/routing';
import DatadogIntegration from '@hub-fe/ledger/Integrations/DatadogIntegration';
import IntegrationArcade from '@hub-fe/ledger/Integrations/IntegrationArcade';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

const Arcades: React.FC = () => {
    const { ledger, deployedArtifacts, serviceType } = useLedgerContext();
    const { integrations } = useAppContext();
    const { deployArtifactToLedger } = useAppActions();
    if (!ledger) return null;
    const isLedger = serviceType === ServiceType.LEDGER;
    const deployedArtifactHashes = deployedArtifacts?.map(a => a.entityInfo.artifactHash);
    return (
        <>
            <IntegrationArcade />
            {isLedger && (
                <AppArcade
                    sectionTitle={<h1>Integrations (Legacy)</h1>}
                    loadingTileCount={5}
                    subheading={
                        <div>
                            <p>Select integrations to add to your application.</p>
                        </div>
                    }
                    arcadeApps={integrations}
                    deployedArtifactHashes={deployedArtifactHashes}
                    deployArcadeApp={app => installIntegration(app)}
                />
            )}
        </>
    );

    function installIntegration(app: IArcadeAppInfo) {
        if (ledger) {
            deployArtifactToLedger(ledger, {
                app,
            });
        }
    }
};

const IntegrationBrowser: React.FC = () => {
    return (
        <div className="integration-browser">
            <Router>
                <Route path={'/datadog'} render={() => <DatadogIntegration />} />
                <Route default render={() => <Arcades />} />
            </Router>
        </div>
    );
};

export default IntegrationBrowser;
