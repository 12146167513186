import React from 'react';

import { ILedgerParty } from '@hub-client-api';

import { useLedgerActions, useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import { IBaseSelectOption } from './BaseSelect';
import PartyDropdown, { filterPartyOptions, formatSelectionLedgerPartyData } from './PartyDropdown';
import './UpdatePrimaryParty.scss';

interface UpdatePrimaryPartyProps {
    currPrimaryParty: string;
    userId: string;
    toggleOpen: () => void;
}
export const UpdatePrimaryParty: React.FC<UpdatePrimaryPartyProps> = React.memo(
    ({ userId, currPrimaryParty, toggleOpen }) => {
        const {
            ledger,
            isLedgerOwner,
            ownerParties = [],
            defaultParties,
            userParties,
        } = useLedgerContext();
        const { updatePrimaryParty, updateUsers } = useLedgerActions();
        const [primaryParty, setPrimaryParty] = React.useState<ILedgerParty>();
        const onSave = async () => {
            if (!primaryParty) {
                return;
            }
            try {
                await updatePrimaryParty({ userId, primaryParty: primaryParty.identifier });
                updateUsers();
            } finally {
                toggleOpen();
            }
        };
        const otherParties = userParties || [];
        const allParties = [...ownerParties, ...otherParties];
        const parties = filterPartyOptions(
            allParties,
            defaultParties,
            !isLedgerOwner,
            !isLedgerOwner,
            true
        );
        const partyOptions = formatSelectionLedgerPartyData(parties);
        return (
            <div className="container">
                <div className="dropdown">
                    <PartyDropdown
                        selectedParty={currPrimaryParty}
                        onChange={(p: IBaseSelectOption<ILedgerParty>) => setPrimaryParty(p.value)}
                        partyOptions={partyOptions}
                        ledgerStatus={ledger?.info.status.ledger}
                    />
                </div>
                <button style={{ marginLeft: 4 }} className="primary-smaller" onClick={onSave}>
                    update
                </button>
                <button style={{ marginLeft: 4 }} className="secondary" onClick={toggleOpen}>
                    cancel
                </button>
            </div>
        );
    }
);
