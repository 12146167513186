import React, { useState } from 'react';

import { ISecondaryOwner } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { UserIdentifierIcon } from '@hub-fe/common/Icons';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import { EMPTY_HEADER } from '@hub-fe/common/ReactTable/ReactTableUtils';
import { getInitialsFromName } from '@hub-fe/common/utils';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';
import ShareLedgerActionModal from '@hub-fe/workspace/ShareLedgerActionModal';

import { useLedgerActions, useLedgerContext } from './LedgerContext';

const Collaborators: React.FC = () => {
    const { userInfo } = useAppContext();
    const { ledger, isLedgerCollaborator, serviceType } = useLedgerContext();
    if (!serviceType) {
        return null;
    }
    const isLedger = serviceType === ServiceType.LEDGER;
    const placeholderMessage = `There are currently no collaborators on this ${
        isLedger ? 'ledger' : 'service'
    }.`;
    const { removeCollaborator } = useLedgerActions();
    const { removeCollaboratorForService } = useAppActions();
    const [showAddCollaboratorModal, setShowAddCollaboratorModal] = useState(false);
    const [showLeaveLedgerModal, setShowLeaveLedgerModal] = useState(false);
    const [collaboratorToRemove, setCollaboratorToRemove] = useState<ISecondaryOwner>();
    const ownerInfo = ledger?.info.owner;

    if (!ledger || !userInfo || !ownerInfo) {
        return null;
    }
    const leaveLedger = () => {
        if (isLedger) {
            removeCollaborator(userInfo?.userId);
            history.push('/console/shared-ledgers');
        } else {
            removeCollaboratorForService({
                userId: userInfo.userId,
                serviceId: ledger.info.id,
                serviceType,
            });
            serviceType === ServiceType.PARTICIPANT
                ? history.push('/console/nodes')
                : history.push('/console/scratchpads');
        }
    };

    let collaborators = ledger.info.secondaryOwners;

    if (isLedgerCollaborator) {
        collaborators = [
            ...ledger.info.secondaryOwners,
            {
                name: ownerInfo.userName,
                userId: ownerInfo.userId,
                userParty: ownerInfo.userParty,
            },
        ];
    }

    collaborators = collaborators.sort((a, b) => (a.name < b.name ? -1 : 1));

    const collaboratorRows = collaborators.map(so => {
        let rowData = [
            {
                sortKey: so.name,
                renderAs: (
                    <div className="user-identifier icon-left">
                        <UserIdentifierIcon name={getInitialsFromName(so.name)} />
                        <div className="identifier-info p2">
                            <div className="user-name">
                                {so.name}
                                {ownerInfo.userId === so.userId && (
                                    <div className="shared-ledger-tag p2">Owner</div>
                                )}
                            </div>
                            <div className="p2 user-id">{so.userId}</div>
                        </div>
                    </div>
                ),
                displayNarrowViewHeader: false,
            },
        ];

        if (!isLedgerCollaborator) {
            rowData = [
                ...rowData,
                {
                    sortKey: 'Remove',
                    renderAs: (
                        <button
                            className="secondary-smaller warning"
                            onClick={() => setCollaboratorToRemove(so)}
                        >
                            Remove
                        </button>
                    ),
                    displayNarrowViewHeader: false,
                },
            ];
        }

        if (isLedgerCollaborator && userInfo?.userId === so.userId) {
            rowData = [
                ...rowData,
                {
                    sortKey: 'Remove',
                    renderAs: (
                        <button
                            className="secondary-smaller warning"
                            onClick={() => setShowLeaveLedgerModal(true)}
                        >
                            {`Leave ${isLedger ? 'ledger' : 'service'}`}
                        </button>
                    ),
                    displayNarrowViewHeader: false,
                },
            ];
        }

        return {
            rowData,
        };
    });

    return (
        <div className="collaborators">
            <div className="collaborators-heading">
                <h1>Collaborators</h1>
                {isLedgerCollaborator ? undefined : (
                    <button
                        className="add-collaborator-button"
                        onClick={() => setShowAddCollaboratorModal(true)}
                    >
                        Add Collaborators
                    </button>
                )}
            </div>
            <ReactTable
                emptyTableContent={<p className="p2">{placeholderMessage}</p>}
                tableHeaders={[{ label: 'Name' }, EMPTY_HEADER]}
                tableRows={collaboratorRows}
            />
            {showAddCollaboratorModal && (
                <ShareLedgerActionModal
                    ledgerId={ledger.info.id}
                    onRequestClose={() => setShowAddCollaboratorModal(false)}
                />
            )}
            {collaboratorToRemove && (
                <ConfirmActionModal
                    show
                    title="Remove Collaborator"
                    bodyMessage={
                        'Are you sure you want to remove ' +
                        collaboratorToRemove.name +
                        ` as a collaborator on this ${isLedger ? 'ledger' : 'service'}?`
                    }
                    action="Remove Collaborator"
                    deletingAction
                    onRequestClose={() => setCollaboratorToRemove(undefined)}
                    onSubmitAction={() =>
                        !isLedger
                            ? removeCollaboratorForService({
                                  serviceId: ledger.info.id,
                                  userId: collaboratorToRemove.userId,
                                  serviceType: serviceType,
                              })
                            : removeCollaborator(collaboratorToRemove.userId)
                    }
                />
            )}
            {showLeaveLedgerModal && (
                <ConfirmActionModal
                    show
                    title={`Leave ${isLedger ? 'Ledger' : 'Service'}`}
                    bodyMessage={`Are you sure you want to leave ${ledger.info.name}?`}
                    action={`Leave ${isLedger ? 'Ledger' : 'Service'}`}
                    deletingAction
                    infoMessage={`Leaving this ${
                        isLedger ? 'ledger' : 'service'
                    } will remove it from your shared ${isLedger ? 'ledgers' : 'services'}.`}
                    onRequestClose={() => setShowLeaveLedgerModal(false)}
                    onSubmitAction={() => leaveLedger()}
                />
            )}
        </div>
    );
};

export default Collaborators;
