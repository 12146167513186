import React, { useState } from 'react';

import { useAppActions } from '@hub-fe/app/AppContext';
import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import SettingsPage, {
    SettingsPanel,
    RightSidePanels,
    SettingsField,
} from '@hub-fe/common/SettingsPage';
import { Footer } from '@hub-fe/footer/Footer';

const LedgerExplorer: React.FC = () => (
    <div className="ledger-explorer">
        <div className="ledger-explorer-content">
            <div className="ledger-list-heading">
                <h1>Ledger Explorer</h1>
            </div>
            <JoinLedgerConfigurator />
        </div>
        <div className="footer-container">
            <Footer />
        </div>
    </div>
);

const JoinLedgerConfigurator = () => {
    const { joinLedger } = useAppActions();
    const [ledgerIdText, setLedgerIdText] = useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const modalTitle = `Joining Ledger ID: ${ledgerIdText}`;
    const modalBody =
        'Once you join this ledger, the ledger owner will have a record of your identity.';
    const onClick = () => {
        setOpenModal(true);
    };
    const onClose = () => {
        setOpenModal(false);
    };

    return (
        <SettingsPage>
            <RightSidePanels>
                <SettingsPanel>
                    <SettingsField name="Join Another User's Ledger">
                        <div className="join-ledger-configurator">
                            <p className="p2">
                                To participate on an existing ledger, the ledger owner must share
                                the Ledger ID with you. By joining someone elses ledger, a party
                                will be created on your behalf.
                            </p>
                            <div className="configurator-input row">
                                <input
                                    value={ledgerIdText}
                                    placeholder="Ledger ID"
                                    onChange={e => setLedgerIdText(e.target.value)}
                                />
                                <button disabled={ledgerIdText.length === 0} onClick={onClick}>
                                    Join
                                </button>
                            </div>
                        </div>
                    </SettingsField>
                    <ConfirmActionModal
                        action="Join"
                        show={openModal}
                        bodyMessage={modalBody}
                        title={modalTitle}
                        onRequestClose={onClose}
                        onSubmitAction={() =>
                            joinLedger(ledgerIdText, () => {
                                setLedgerIdText('');
                            })
                        }
                    />
                </SettingsPanel>
            </RightSidePanels>
        </SettingsPage>
    );
};

export default LedgerExplorer;
