import React from 'react';

import { TServiceData } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import { ParticipantRoutes } from '@hub-fe/app/participant/ParticipantRoutes';
import { ParticipantSideBar } from '@hub-fe/app/participant/ParticipantSideBar';
import { ScratchpadSideBar } from '@hub-fe/app/scratchpad/ScratchpadSideBar';
import { Footer } from '@hub-fe/footer/Footer';
import { LedgerProvider } from '@hub-fe/ledger/LedgerContext';
import { LedgerNavigation, LedgerRoutes } from '@hub-fe/ledger/LedgerDetails';
import { LedgerSideBar } from '@hub-fe/ledger/LedgerSideBar';
import { SynchronizerRoutes } from '@hub-fe/synchronizer/SynchronizerRoutes';
import { SynchronizerSideBar } from '@hub-fe/synchronizer/SynchronizerSideBar';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import { Breadcrumbs } from './Breadcrumbs';
import { ServiceNavDropdownMenu } from './ServiceNavDropdownMenu';
import { useServiceParams } from './utils/useServiceParams';

const serviceSidebars = {
    [ServiceType.PARTICIPANT]: <ParticipantSideBar />,
    [ServiceType.SCRATCHPAD]: <ScratchpadSideBar />,
    [ServiceType.SYNC_DOMAIN]: <SynchronizerSideBar />,
    [ServiceType.LEDGER]: <LedgerSideBar />,
};

const serviceRoutes = {
    [ServiceType.LEDGER]: <LedgerRoutes />,
    [ServiceType.PARTICIPANT]: <ParticipantRoutes />,
    [ServiceType.SCRATCHPAD]: <LedgerRoutes />,
    [ServiceType.SYNC_DOMAIN]: <SynchronizerRoutes />,
};

export const ServiceLayout: React.FC<{ serviceType: ServiceType }> = ({ serviceType }) => {
    const { serviceId } = useServiceParams();
    const { ledgers, participants, scratchpads, synchronizers } = useAppContext();

    const services: { [key: string]: TServiceData[] } = {
        [ServiceType.LEDGER]: ledgers,
        [ServiceType.PARTICIPANT]: participants,
        [ServiceType.SCRATCHPAD]: scratchpads,
        [ServiceType.SYNC_DOMAIN]: synchronizers,
    };
    if (!serviceId) {
        return <></>;
    }

    const service = services[serviceType].find(p => p.info.id === serviceId);

    if (!service) {
        return <></>;
    }
    return (
        <LedgerProvider
            service={service}
            ledgerId={serviceId}
            serviceId={serviceId}
            serviceType={serviceType}
        >
            <div className="ledger-details">
                {serviceType === ServiceType.LEDGER ? <LedgerNavigation /> : <Breadcrumbs />}
                {serviceType !== ServiceType.LEDGER && (
                    <ServiceNavDropdownMenu serviceType={serviceType} />
                )}
                <div className="ledger-details-body">
                    {serviceSidebars[serviceType]}
                    <div className="body-column">
                        {serviceRoutes[serviceType]}
                        <Footer />
                    </div>
                </div>
            </div>
        </LedgerProvider>
    );
};
