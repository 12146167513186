import classNames from 'classnames';
import React, { useState } from 'react';

import { artifactTypes } from '@hub-client-api';

import { useAppActions } from '@hub-fe/app/AppContext';
import DocsLink from '@hub-fe/common/DocsLink';
import { FileUploadButton } from '@hub-fe/common/FileUpload';
import { IconCheck, IconClose, IconSidebarDeployments, IconUpload } from '@hub-fe/common/Icons';
import { shortName } from '@hub-fe/common/siteInfo.variants';
import { isBotOrTrigger, itemListAsText, pluralize } from '@hub-fe/common/utils';
import { useDismissableElement } from '@hub-fe/common/utilsReact';

import { useLedgerActions, useLedgerContext } from './LedgerContext';

const QuickBuild: React.FC = () => {
    const { deployedArtifacts = [] } = useLedgerContext();
    const { checkHasUIInstance } = useLedgerActions();
    const website = checkHasUIInstance();

    const [showQuickBuild, setShowQuickBuild] = useState(false);

    const { refDismissable, refControl } = useDismissableElement<HTMLDivElement, HTMLButtonElement>(
        () => setShowQuickBuild(false)
    );

    const modelArtifactsCount = deployedArtifacts.filter(
        a => a.entityInfo.entity.tag === artifactTypes.MODEL_ARTIFACT
    ).length;

    const automationArtifactsCount = deployedArtifacts.filter(a =>
        isBotOrTrigger(a.entityInfo.entity)
    ).length;

    return (
        <div className="quick-build">
            <button
                className="console-button ghost icon-left p2 wide-width"
                ref={refControl}
                onClick={() => setShowQuickBuild(!showQuickBuild)}
            >
                <IconSidebarDeployments /> Wondering what to do next?
            </button>
            {showQuickBuild && (
                <div className="quick-build-popup" ref={refDismissable}>
                    <div className="heading row">
                        <p>The essential building blocks of a {shortName} App.</p>
                        <button className="ghost" onClick={() => setShowQuickBuild(false)}>
                            <IconClose />
                        </button>
                    </div>
                    <div className="quick-build-tile-row">
                        <QuickBuildTile
                            key="daml"
                            className="daml-model"
                            title="Daml"
                            description="Daml describes the workflows of your application. Daml Triggers are supported."
                            extOptions={['.dar']}
                            docsTopic="daml-models"
                            filesUploaded={modelArtifactsCount}
                            setShowQuickBuild={setShowQuickBuild}
                        />
                        <QuickBuildTile
                            key="automation"
                            className="automation"
                            title="Automation"
                            description="Automations trigger actions based on ledger events."
                            extOptions={['.tar', '.tar.gz', '.tgz']}
                            docsTopic="automation"
                            filesUploaded={automationArtifactsCount}
                            setShowQuickBuild={setShowQuickBuild}
                        />
                        <QuickBuildTile
                            key="ui-asset"
                            className="ui-asset"
                            title="UI Assets"
                            description="Upload your applications front-end."
                            extOptions={['.zip']}
                            docsTopic="app-ui"
                            filesUploaded={website ? 1 : 0}
                            setShowQuickBuild={setShowQuickBuild}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const QuickBuildTile = (props: {
    title: string;
    description: string;
    extOptions: string[];
    docsTopic: string;
    className: string;
    filesUploaded: number;
    setShowQuickBuild: (bool: boolean) => void;
}) => {
    const {
        title,
        description,
        extOptions,
        docsTopic,
        className,
        filesUploaded,
        setShowQuickBuild,
    } = props;
    const { ledger } = useLedgerContext();
    const { uploadArtifactFromFile } = useAppActions();
    const fileType = itemListAsText(extOptions, ', or ');

    return (
        <FileUploadButton fileType={fileType} onUploadRequest={file => doUpload(file)}>
            <div className={classNames('tile quick-build-tile ', className)}>
                <div className="tile-info">
                    <h3 className="icon-right">
                        {' '}
                        {title} <IconUpload />{' '}
                    </h3>
                    {filesUploaded === 0 ? (
                        <p className="files-uploaded p2 button ghost icon-left">
                            <IconUpload /> Upload {fileType || 'File'}
                        </p>
                    ) : (
                        <p className="files-uploaded p2 icon-left">
                            <IconCheck />
                            {filesUploaded} {pluralize(filesUploaded, 'file')} uploaded
                        </p>
                    )}
                    <p className="p2">{description}</p>
                </div>
                <DocsLink className="underline-hover" topic={docsTopic}>
                    {title} Docs
                </DocsLink>
            </div>
        </FileUploadButton>
    );

    function doUpload(file: File) {
        if (ledger) {
            uploadArtifactFromFile({ file, ledger });
        }
        setShowQuickBuild(false);
    }
};

export default QuickBuild;
