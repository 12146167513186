import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { isScratchpadData, isSynchronizerData } from '@hub-fe/app/AppContext';
import ExternalLink from '@hub-fe/common/ExternalLink';
import InformationBlock from '@hub-fe/common/InformationBlock';
import { NavLink } from '@hub-fe/common/routing';
import { images } from '@hub-fe/common/urls';
import { isLargeLedgerOrParticipant } from '@hub-fe/common/utils/isSmallLedgerOrParticipant';
import { IntegrationTile } from '@hub-fe/ledger/Integrations/IntegrationTile';
import { useLedgerActions, useLedgerContext } from '@hub-fe/ledger/LedgerContext';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

const IntegrationArcade: FunctionComponent = () => {
    const { ledgerIntegrations, serviceIntegrations, ledger, serviceType } = useLedgerContext();
    const isLedger = serviceType === ServiceType.LEDGER;
    const { toggleDatadogIntegration } = useLedgerActions();
    const [isSupportedService, setSupported] = React.useState(false);

    if (!ledger || isScratchpadData(ledger)) {
        return <></>;
    }
    React.useEffect(() => {
        if (isSynchronizerData(ledger)) {
            setSupported(true);
        } else setSupported(isLargeLedgerOrParticipant(ledger));
    }, []);

    const isEnterprise = ledger.info.hasEnterpriseOwner;
    const datadogEnabled = isSupportedService && isEnterprise;

    return (
        <div className={classNames('app-arcade', { 'vertical-layout': false })}>
            <h1>Integrations</h1>
            <div>
                <p>Extend the functionality of Daml Hub by connecting third party services.</p>
            </div>
            {!isEnterprise ||
                (!isSupportedService && (
                    <div className="datadog-warning">
                        <InformationBlock warning>
                            {isLedger ? (
                                <p className="warning">
                                    Datadog streaming is only available in High Capacity Ledgers.
                                    The ledger size can be changed in the{' '}
                                    <NavLink className="ghost" to={`../ledger-settings`}>
                                        Ledger Settings
                                    </NavLink>{' '}
                                    page.
                                </p>
                            ) : (
                                <p className="warning">
                                    Datadog streaming is only available in High Capacity services.
                                </p>
                            )}
                        </InformationBlock>
                    </div>
                ))}
            {!isEnterprise && (
                <div className="datadog-warning">
                    <InformationBlock warning>
                        <p className="warning">
                            Datadog streaming is only available to Enterprise Users.{' '}
                            <ExternalLink
                                className="ghost"
                                to={'https://www.digitalasset.com/contact-us'}
                            >
                                Contact Sales to Upgrade.
                            </ExternalLink>
                        </p>
                    </InformationBlock>
                </div>
            )}
            <section className="sample-tiles">
                <IntegrationTile
                    icon={images.ddSvg}
                    name={'Datadog Log Streaming'}
                    description={`Set up this ${
                        isLedger ? 'ledger' : 'service'
                    } to send a stream of logs to a Datadog dashboard.`}
                    navTo={'datadog'}
                    integration={
                        isLedger ? ledgerIntegrations?.datadog : serviceIntegrations?.datadog
                    }
                    handleToggle={toggleDatadogIntegration}
                    disabled={!datadogEnabled}
                    needsUpgrade={!isEnterprise}
                />
            </section>
        </div>
    );
};

export default IntegrationArcade;
