export interface IDamlTypeDefinitions {
  [typeName: string]: IDamlRecordType | IDamlVariantType;
}

export interface IDamlVariableDefinitions {
  [varName: string]: DamlType;
}

interface IDamlRecordType {
  record: {
    fields: { [field: string]: DamlType };
    typeArgs: string[];
  };
}

interface IDamlVariantType {
  variant: {
    constructors: { [con: string]: DamlType };
    typeArgs: string[];
  };
}

export interface IDamlEnumType {
  enum: {
    constructors: string[];
  };
}

export const DamlEnumType = 'Enum';
export const DamlVariantType = 'Variant';

type ComplexType = IDamlRecordType | IDamlVariantType | IDamlEnumType;

export interface ConType {
  con: string;
  args: DamlType[];
}

interface PrimType {
  prim: PrimTypeEnum;
  args: DamlType[];
}

interface VarType {
  var: string;
  args: DamlType[];
}

interface NatType {
  nat: number;
  args: DamlType[];
}

interface IInputProps<T> {
  definitions: IDamlTypeDefinitions;
  variables: IDamlVariableDefinitions;
  label: string;
  type: T;
}

export type IRecordProps = IInputProps<IDamlRecordType>;

export enum PrimTypeEnum {
  ARROW = 'Arrow',
  BOOLEAN = 'Bool',
  CONTRACT_ID = 'ContractId',
  DATE = 'Date',
  DECIMAL = 'Decimal',
  GENMAP = 'GenMap',
  INTEGER = 'Int',
  LIST = 'List',
  MAP = 'Map',
  NUMBERIC = 'Numeric',
  OPTIONAL = 'Optional',
  PARTY = 'Party',
  SCENARIO = 'Scenario',
  TEXT = 'Text',
  TIME = 'Time',
  UNIT = 'Unit',
  UPDATE = 'Update',
  RELTIME = 'RelTime',
}

export type DamlType = PrimType | ConType | VarType | NatType;

export function isRecord(c: ComplexType): c is IDamlRecordType {
  return c && (c as IDamlRecordType).record !== undefined && (c as IDamlRecordType).record !== null;
}

export function isVariant(c: ComplexType): c is IDamlVariantType {
  return (
    c && (c as IDamlVariantType).variant !== undefined && (c as IDamlVariantType).variant !== null
  );
}

export function isEnum(c: ComplexType): c is IDamlEnumType {
  return c && (c as IDamlEnumType).enum !== undefined && (c as IDamlEnumType).enum !== null;
}

export function isPrimType(c: DamlType): c is PrimType {
  return c && (c as PrimType).prim !== undefined && (c as PrimType).prim !== null;
}

export function isConType(c: DamlType): c is ConType {
  return c && (c as ConType).con !== undefined && (c as ConType).con !== null;
}

export function isVarType(c: DamlType): c is VarType {
  return c && (c as VarType).var !== undefined && (c as VarType).var !== null;
}

export function isNatType(c: DamlType): c is NatType {
  return c && (c as NatType).nat !== undefined && (c as NatType).nat !== null;
}
