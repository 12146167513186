import React, { useState } from 'react';

import { IconSuccess, IconInformation } from '@hub-fe/common/Icons';
import Modal from '@hub-fe/common/Modal';

interface IProps {
    onRequestClose: () => void;
    title: string;
    infoText?: string;
    placeholder?: string;
    validateValue: (value: string) => string | undefined;
    onSubmit: (value: string) => void;
    successText?: string | false;
    children?: React.ReactNode;
}

const WarningFlag: React.FC<{ warningText: string }> = props => (
    <div className="message-flag">
        <IconInformation />
        <p className="p2">{props.warningText}</p>
    </div>
);

const SuccessFlag: React.FC<{ successText: string }> = props => (
    <div className="message-flag">
        <IconSuccess />
        <p className="p2">{props.successText}</p>
    </div>
);

const ValueEntryModal: React.FC<IProps> = props => {
    const [value, setValue] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const validationError = props.validateValue(value);

    const handleSubmit = () => {
        if (!validationError) {
            props.onSubmit(value);
            setSubmitted(true);
            setTimeout(() => {
                setSubmitted(false);
            }, 2500);
            setValue('');
        }
    };

    let flag;

    if (validationError && !submitted) {
        flag = <WarningFlag warningText={validationError} />;
    }

    return (
        <Modal
            className="value-entry-modal"
            show
            title={props.title}
            onRequestClose={() => props.onRequestClose()}
            onSubmit={() => handleSubmit()}
        >
            {props.infoText && <p className="p2">{props.infoText}</p>}
            <div className="row">
                <p className="p2"> {props.placeholder}</p>
                <div className="value-entry">
                    <input
                        autoFocus
                        type="text"
                        value={value}
                        placeholder="Text"
                        onChange={evt => setValue(evt.target.value)}
                    />
                </div>
                {props.children}
            </div>
            <div className="actions">
                {submitted && props.successText ? (
                    <SuccessFlag successText={props.successText} />
                ) : (
                    <div className="flags">{flag}</div>
                )}
                <button onClick={() => handleSubmit()} disabled={!!validationError}>
                    Add
                </button>
            </div>
        </Modal>
    );
};

export default ValueEntryModal;
