import React from 'react';

import { SideBarLink } from './SideBarLink';
import { ILedgerSidebarLink, LinkLabels } from './config';

interface SideBarProps {
    sideBarLinks: ILedgerSidebarLink[];
    bottomInfoPanel?: React.ReactNode;
    deploymentActionNeeded?: boolean;
}

export const SideBar: React.FC<SideBarProps> = ({
    deploymentActionNeeded,
    sideBarLinks,
    bottomInfoPanel,
}) => {
    return (
        <nav className="side-bar">
            {sideBarLinks.map(l => (
                <SideBarLink
                    link={l}
                    to={l.target}
                    key={l.label}
                    actionNeeded={!!(l.label === LinkLabels.DEPLOYMENTS && deploymentActionNeeded)}
                />
            ))}
            {bottomInfoPanel}
        </nav>
    );
};
