import React from 'react';

import { useAppContext } from '@hub-fe/app/AppContext';
import { serviceTypes } from '@hub-fe/workspace/ChooseServiceType';

import en from '../../locales/en.json';
import { history } from './ControllableBrowserRouter';
import './EmptyWorkspace.scss';
import { IconDivTag, IconGlobe, IconLink } from './Icons';
import { getActiveServices } from './utils/getActiveServices';

interface TileProps {
    title: string;
    desc: string;
    to: string;
    icon?: React.ReactNode;
}
const Tile: React.FC<TileProps> = ({ icon, title, desc, to }) => {
    const onClick = () => history.push(`/console/create${to}`);
    return (
        <div className="service-tile">
            <div className="title-container">
                <div className="icon">{icon}</div>
                <h4 className="title">{title}</h4>
            </div>
            <div>
                <p>{desc}</p>
            </div>
            <div className="action-buttons">
                <button onClick={onClick} className="secondary">
                    Create
                </button>
            </div>
        </div>
    );
};
export const EmptyWorkspace: React.FC = () => {
    const { activeScratchpads, synchronizers, participants, canCreateSynchronizers } =
        useAppContext();
    const noActiveScratchpads = !activeScratchpads.length;
    const noActiveParticipants = !getActiveServices(participants).length;
    const noActiveSynchronizers = !getActiveServices(synchronizers).length;
    const [scratchpad, participant, synchronizer, networkApp] = serviceTypes;

    return (
        <div className="empty-workspace">
            <h2>Welcome to Daml Hub</h2>
            <h4>Get started by creating a new service</h4>
            <div className="tiles">
                {noActiveScratchpads && (
                    <Tile
                        icon={<IconDivTag />}
                        title={scratchpad.name}
                        desc={scratchpad.desc}
                        to={scratchpad.to}
                    />
                )}
                {noActiveParticipants && noActiveSynchronizers && (
                    <Tile
                        icon={<IconLink />}
                        title={participant.name}
                        desc={participant.desc}
                        to={participant.to}
                    />
                )}
                {canCreateSynchronizers && noActiveParticipants && noActiveSynchronizers && (
                    <>
                        <Tile
                            icon={<IconGlobe />}
                            title={synchronizer.name}
                            desc={synchronizer.desc}
                            to={synchronizer.to}
                        />
                        <Tile
                            icon={<IconGlobe />}
                            title={networkApp.name}
                            desc={en.serviceTypes.networkApp.desc}
                            to={networkApp.to}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
