import classNames from 'classnames';
import React from 'react';

const EmptyDisplayPlaceholder: React.FC<{ loading?: boolean }> = props => {
    const { loading } = props;
    return (
        <div className="empty-display-placeholder">
            {[...Array(4)].map((_, i) => (
                <div className={classNames('empty-contract-block', { blinker: loading })} key={i} />
            ))}
        </div>
    );
};

export default EmptyDisplayPlaceholder;
