import React, { useEffect } from 'react';

import { artifactTypes, IIntegrationEntityValue } from '@hub-client-api';

import ActionHeader from '@hub-fe/common/ActionHeader';
import BaseSelect from '@hub-fe/common/BaseSelect';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import ExternalLink from '@hub-fe/common/ExternalLink';
import { IconDeploy } from '@hub-fe/common/Icons';
import MenuBar from '@hub-fe/common/MenuBar';
import { NavLink, Redirect, Route, Router } from '@hub-fe/common/routing';
import { getEntityDisplayName, isBotOrTrigger, scrollToTop } from '@hub-fe/common/utils';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import {
    useEntityInfo,
    useIntegrationName,
    useLedgerActions,
    useLedgerContext,
} from '../LedgerContext';
import DeployedArtifactCode from './DeployedArtifactCode';
import DeployedArtifactSettings from './DeployedArtifactSettings';
import DeploymentInstanceTable from './DeploymentInstanceTable';
import NewDeploymentInstance from './NewDeploymentInstance';

const DeployedArtifactViewer: React.FC = () => {
    const currentLocation = window.location.href
        .substring(window.location.href.lastIndexOf('/'))
        .replace('/', '');
    const { ledgerId } = useLedgerContext();
    const { artifactHash, service } = useServiceParams();
    const { getDeployedArtifactInstancesByHash, getPublishedArtifactByHash, getArtifactByHash } =
        useLedgerActions();
    const integrationName = useIntegrationName(artifactHash);
    const ledgerArtifactInstancesCount = getDeployedArtifactInstancesByHash(
        artifactHash,
        integrationName
    ).length;
    const isPublished = !!getPublishedArtifactByHash(artifactHash, integrationName);
    const entityInfo = useEntityInfo(artifactHash, integrationName);
    const hasFiles = !!getArtifactByHash(artifactHash)?.files;
    const onPop = () => {
        history.push(`/console/${service}/${ledgerId}/deployments`);
    };
    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        window.addEventListener('popstate', onPop);
        return () => window.removeEventListener('popstate', onPop);
    }, [onPop]);

    if (!entityInfo) {
        return null;
    }

    const { entity } = entityInfo;
    const displayName = getEntityDisplayName(entity);

    const entries = [
        { label: `Instances (${ledgerArtifactInstancesCount})`, to: 'instances' },
        { label: 'Configure New Instance', to: 'configure' },
        { label: 'Settings', to: 'settings' },
    ];

    if (isBotOrTrigger(entity) && hasFiles) {
        entries.splice(2, 0, { label: 'Files', to: 'files' });
    }

    const selectOptions = entries.map(e => ({ label: e.label, value: e.to }));

    const helpLink = (entity.value as IIntegrationEntityValue).helpUrl;

    let titleButtons: JSX.Element[] = [];

    if (isPublished) {
        titleButtons = [
            ...titleButtons,
            <NavLink className="p2 deployable button ghost" key="settings" to="settings">
                <IconDeploy /> Deployable
            </NavLink>,
        ];
    }
    if (helpLink) {
        titleButtons = [
            ...titleButtons,
            <ExternalLink
                icon
                key={helpLink}
                to={helpLink}
                className="inline-link underline-hover p2"
            >
                Need some help?
            </ExternalLink>,
        ];
    }

    if (entity.tag === artifactTypes.MODEL_ARTIFACT) {
        return (
            <Router>
                <Route path="files" render={() => <DeployedArtifactCode />} />
                <Route path="files/*artifactUrl" render={() => <DeployedArtifactCode />} />
                <Route path="/" render={() => <Redirect to="files" />} />
            </Router>
        );
    }
    const baseUrl = `/console/${service}/${ledgerId}/deployments/view/${artifactHash}/`;

    return (
        <div className="deployed-artifact-viewer">
            <div className="deployed-artifact-viewer-body">
                <ActionHeader
                    title={displayName}
                    linkBackTo={`/console/${service}/${ledgerId}/deployments`}
                    fileTagEntity={entity}
                    titleButtons={titleButtons}
                />
                <MenuBar entries={entries} />
                <BaseSelect
                    isSearchable={false}
                    className="select menu-dropdown"
                    options={selectOptions}
                    value={selectOptions.find(o => o.value.endsWith(currentLocation))}
                    onChange={(val: any) => history.push(`${baseUrl}${val.value}`)}
                />
                <Router>
                    {isBotOrTrigger(entity) && (
                        <>
                            <Route path="files" render={() => <DeployedArtifactCode />} />
                            <Route
                                path="files/*artifactUrl"
                                render={() => <DeployedArtifactCode />}
                            />
                        </>
                    )}
                    <Route path="instances/*" render={() => <DeploymentInstanceTable />} />
                    <Route path="configure/*" render={() => <NewDeploymentInstance />} />
                    <Route path="settings/*" render={() => <DeployedArtifactSettings />} />
                    <Route
                        default
                        render={() =>
                            ledgerArtifactInstancesCount > 0 ? (
                                <Redirect to="instances" />
                            ) : (
                                <Redirect to="configure" />
                            )
                        }
                    />
                </Router>
            </div>
        </div>
    );
};

export default DeployedArtifactViewer;
