import React from 'react';

import { ArtifactDeploymentState, dalRestartPqsLogStream } from '@hub-client-api';

import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { SettingsTable } from '@hub-fe/common/SettingsTable';
import { TwoColLayout } from '@hub-fe/common/TwoColLayout';
import { capitalizePhrase } from '@hub-fe/common/utils';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { useLedgerActions, useLedgerContext } from '@hub-fe/ledger/LedgerContext';
import { useMessages } from '@hub-fe/messages/MessageContext';

import InformationBlock from './InformationBlock';
import LoadingButton from './LoadingButton';
import './PqsLogSettings.scss';
import { dataSourceOptions } from './SetUpPqs';
import { containsDamlModel } from './utils/containsDamlModel';

export const PqsLogSettings: React.FC = () => {
    const { serviceId } = useServiceParams();
    const { pqsStatus, pqsInstance, deployedArtifactInstances } = useLedgerContext();
    const isOn = pqsStatus?.runState === ArtifactDeploymentState.STATE_ON;
    const [modalMessage, setModalMessage] = React.useState('');
    const noDamlModel = !deployedArtifactInstances || !containsDamlModel(deployedArtifactInstances);
    const msg = useMessages();
    const { isLedgerOwner } = useLedgerContext();
    const { pausePqsInstance, resumePqsInstance, deletePqsInstance } = useLedgerActions();
    const [action, setAction] = React.useState<'pausing' | 'resuming' | undefined>();
    const inFlight =
        (action === 'pausing' && pqsStatus?.runState === ArtifactDeploymentState.STATE_ON) ||
        (action === 'resuming' && pqsStatus?.runState === ArtifactDeploymentState.STATE_OFF);
    const [showServiceActionModal, setShowServiceActionModal] = React.useState<{
        show: boolean;
        actionLabel: string;
        action?: () => void;
    }>({ show: false, actionLabel: '' });
    const onCloseModal = () => {
        setShowServiceActionModal({ show: false, actionLabel: '' });
    };
    const onRestart = async () => {
        setModalMessage('Are you sure you want to restart PQS?');
        serviceId &&
            setShowServiceActionModal({
                actionLabel: 'Restart',
                show: true,
                action: async () => {
                    try {
                        await dalRestartPqsLogStream(serviceId);
                        msg.success(
                            'Restarting PQS. Please wait a moment for the logs to refresh.'
                        );
                    } catch (e) {
                        msg.error('There was an error restarting PQS.');
                    }
                },
            });
    };
    const onPause = async () => {
        if (!isOn) {
            return;
        }
        setAction('pausing');
        setModalMessage('Are you sure you want to pause PQS?');
        serviceId &&
            setShowServiceActionModal({
                actionLabel: 'Pause',
                show: true,
                action: async () => {
                    try {
                        await pausePqsInstance(serviceId);
                    } catch {
                        setAction(undefined);
                    }
                },
            });
    };
    const onResume = async () => {
        if (isOn) {
            return;
        }
        setAction('resuming');
        if (!serviceId) {
            return;
        }
        try {
            await resumePqsInstance(serviceId);
        } catch {
            setAction(undefined);
        }
    };
    const onDelete = async () => {
        setModalMessage('Are you sure you want to delete PQS?');

        serviceId &&
            setShowServiceActionModal({
                actionLabel: 'Delete',
                show: true,
                action: async () => {
                    try {
                        await deletePqsInstance(serviceId);
                        msg.success('Deleting PQS. Please wait a moment for the logs to refresh.');
                        history.push('../../');
                    } catch (e) {
                        msg.error('There was an error deleting PQS.');
                    }
                },
            });
    };
    const displayedDataSource = dataSourceOptions.find(o => o.type === pqsInstance?.datasource);
    const note = 'Note: to change this config you must delete and create a new PQS instance.';
    const settings = [
        {
            title: 'Streaming Type',
            actionElement: null,
            desc: (
                <div>
                    <p>{note}</p>
                    <div className="config-value-wrapper">{displayedDataSource?.label || ''}</div>
                </div>
            ),
        },
        {
            title: 'Offset Setting',
            actionElement: null,
            desc: (
                <div>
                    <div></div>
                    <p>{note}</p>
                    <div className="config-value-wrapper">{pqsInstance?.ledgerStart || ''}</div>
                </div>
            ),
        },
        {
            title: 'Pause',
            desc: 'Pause this instance so data is no longer fed to PQS',
            actionElement: (
                <LoadingButton
                    loading={inFlight}
                    disabled={!isLedgerOwner || noDamlModel}
                    onClick={isOn ? onPause : onResume}
                    className="button secondary-smaller"
                >
                    {isOn ? 'Pause' : 'Resume'}
                </LoadingButton>
            ),
        },
        {
            title: 'Restart',
            desc: 'Stop and restart this PQS instance (only available if instance is running)',
            actionElement: (
                <button
                    disabled={!isLedgerOwner || !isOn || inFlight || noDamlModel}
                    onClick={onRestart}
                    className="button secondary-smaller"
                >
                    Restart
                </button>
            ),
        },
        {
            title: 'Delete',
            desc: 'Delete this PQS instance.  Once deleted, you can then create a new instance with different configuration',
            actionElement: (
                <button
                    disabled={!isLedgerOwner}
                    onClick={onDelete}
                    className="warning button secondary-smaller"
                >
                    Delete
                </button>
            ),
        },
    ];
    const col1 = (
        <>
            {noDamlModel && (
                <InformationBlock enableBackground>
                    {' '}
                    <p>
                        PQS instance will not start until the first DAR has been uploaded to your
                        ledger. Upload a DAR from the <a href="./deployments">Deployments page</a>
                    </p>
                </InformationBlock>
            )}
            <SettingsTable data={settings} />
            <ConfirmActionModal
                onSubmitAction={() =>
                    showServiceActionModal.action && showServiceActionModal.action()
                }
                title={`${capitalizePhrase(showServiceActionModal.actionLabel)} PQS`}
                action={capitalizePhrase(showServiceActionModal.actionLabel)}
                deletingAction
                bodyMessage={modalMessage}
                show={showServiceActionModal.show}
                onRequestClose={onCloseModal}
            />
        </>
    );
    return <TwoColLayout col1={col1} />;
};
