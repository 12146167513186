import classNames from 'classnames';
import React, { useState } from 'react';

import { ISiteCredential } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import LoadingButton from '@hub-fe/common/LoadingButton';
import { getTimeLeft } from '@hub-fe/common/Timing';

const SiteCredentialList: React.FC = () => {
    const { siteCredentials } = useAppContext();
    const sorted = siteCredentials.sort(
        (sc1, sc2) => new Date(sc2.validFrom).getTime() - new Date(sc1.validFrom).getTime()
    );

    return (
        <div className="site-credential-list">
            <h2>Your Credentials</h2>
            {siteCredentials.length > 0 ? (
                sorted.map(scred => <SiteCredential key={scred.credentialId} scred={scred} />)
            ) : (
                <i className="p2 row">There are no credentials.</i>
            )}
        </div>
    );
};

const SiteCredential: React.FC<{ scred: ISiteCredential }> = (props: {
    scred: ISiteCredential;
}) => {
    const { scred } = props;
    const [deleting, setDeleting] = useState(false);
    const { deleteSiteCredential } = useAppActions();

    return (
        <div className={classNames('tile site-credential-tile', { deleting })}>
            <div className="site-credential-tile-col">
                <p className="p2">{scred.credentialLabel}</p>
            </div>
            <div className="site-credential-tile-col info">
                <div className="cred-info">
                    {scred.cred && (
                        <CopyableDisplay
                            ghostButton
                            value={btoa(scred.credentialId + ':' + scred.cred)}
                        />
                    )}
                    <p className="exp-caption">{getExpiration(scred.validTo)}</p>
                </div>
                {scred.cred && (
                    <p className="exp-caption warning">
                        This credential will no longer be copyable upon page refresh.
                    </p>
                )}
            </div>
            <div className="site-credential-tile-col delete">
                <LoadingButton
                    disabled={deleting}
                    className="secondary-smaller warning"
                    loading={deleting}
                    loadingContent="Deleting"
                    onClick={() => handleDeleteCred(scred.credentialId)}
                >
                    Delete
                </LoadingButton>
            </div>
        </div>
    );

    function getExpiration(validTo?: string): string {
        if (!validTo) return 'No expiration';
        const timeLeft = getTimeLeft(validTo);
        return timeLeft ? `Expires in ${timeLeft}` : 'Expired';
    }

    function handleDeleteCred(credentialId: string): void {
        setDeleting(true);
        deleteSiteCredential(credentialId);
    }
};

export default SiteCredentialList;
