import React, { useEffect, useState } from 'react';

import {
    ArtifactDeploymentState,
    dalGetDomainLogStream,
    dalGetParticipantLogStream,
} from '@hub-client-api';

import ActionHeader from '@hub-fe/common/ActionHeader';
import InformationBlock from '@hub-fe/common/InformationBlock';
import LogViewer from '@hub-fe/common/LogViewer';
import { CreateServiceRequestServiceTypeEnum } from '@hub-fe/common/generated/clients/damlhub';
import { capitalizePhrase } from '@hub-fe/common/utils';
import { isNewService } from '@hub-fe/common/utils/isNewService';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { useMessages } from '@hub-fe/messages/MessageContext';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import { useLedgerContext } from '../LedgerContext';
import { StateIndicator } from './DeployedArtifactInstanceStateIndicator';

enum DefaultDeploymentTypeEnum {
    DOMAIN = 'domain',
    PARTICIPANT = 'participant',
}

const deploymentSupportText = {
    [CreateServiceRequestServiceTypeEnum.Synchronizer]:
        'The Synchronizer orders transactions and delivers them to the participants. Delivery is guaranteed multicast. Check the Synchronizer logs for information about the delivery and sequencing of transactions.',
    [DefaultDeploymentTypeEnum.PARTICIPANT]:
        'The participant is where parties and contracts are stored, it also handles transaction signing and validation. Check the participant logs for information related to parties, the forming of transactions and receiving transactions.',
    [DefaultDeploymentTypeEnum.DOMAIN]:
        'The domain orders transactions and delivers them to the participants. Delivery is guaranteed multicast. Check the domain logs for information about the delivery and sequencing of transactions.',
};

interface DefaultDeploymentViewerProps {
    defaultDeployment: string;
}

const DefaultDeploymentViewer: React.FC<DefaultDeploymentViewerProps> = ({ defaultDeployment }) => {
    const { ledgerId, participantStatus, domainStatus } = useLedgerContext();
    const { service } = useServiceParams();

    const msg = useMessages();
    const [logStream, setLogStream] = useState<ReadableStream>();

    const runState =
        defaultDeployment === DefaultDeploymentTypeEnum.DOMAIN ||
        defaultDeployment === ServiceType.SYNC_DOMAIN
            ? domainStatus?.runState
            : defaultDeployment === DefaultDeploymentTypeEnum.PARTICIPANT
            ? participantStatus?.runState
            : undefined;
    const isOn = runState === ArtifactDeploymentState.STATE_ON;
    const enableServiceEndpoint = service ? isNewService(service) : false;

    useEffect(() => {
        if (!ledgerId || !isOn) {
            return;
        }

        if (!logStream) {
            console.log('=== starting logs ===', { logStream });
            if (
                defaultDeployment === DefaultDeploymentTypeEnum.DOMAIN ||
                defaultDeployment === CreateServiceRequestServiceTypeEnum.Synchronizer
            ) {
                dalGetDomainLogStream(ledgerId, enableServiceEndpoint)
                    .then(stream => setLogStream(stream))
                    .catch(() =>
                        msg.error(
                            `There was an error obtaining logs for the ledger ${defaultDeployment}`
                        )
                    );
            } else if (defaultDeployment === DefaultDeploymentTypeEnum.PARTICIPANT) {
                dalGetParticipantLogStream(ledgerId, enableServiceEndpoint)
                    .then(stream => setLogStream(stream))
                    .catch(() =>
                        msg.error(
                            `There was an error obtaining logs for the ledger ${defaultDeployment}`
                        )
                    );
            }
        }
    }, [ledgerId, logStream]);
    if (!service) {
        return null;
    }
    if (!defaultDeployment) {
        return null;
    }

    const downloadFileName = (defaultDeployment || 'log') + '-' + ledgerId + '-' + '.log';

    return (
        <div className="default-deployment-viewer">
            <ActionHeader
                enableStandardBack
                title={`${capitalizePhrase(defaultDeployment)} Logs`}
                body={deploymentSupportText[defaultDeployment]}
                titleButtons={
                    runState && [
                        <StateIndicator key={runState} artifactDeploymentStates={[runState]} />,
                    ]
                }
            />
            {isOn ? (
                <LogViewer logStream={logStream} downloadFileName={downloadFileName} />
            ) : (
                <InformationBlock enableBackground>
                    <p>Logs are currently unavailable.</p>
                </InformationBlock>
            )}
        </div>
    );
};

export default DefaultDeploymentViewer;
