import React, { useState } from 'react';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import LoadingButton from '@hub-fe/common/LoadingButton';
import SuggestiveInput from '@hub-fe/common/SuggestiveInput';
import { Link } from '@hub-fe/common/routing';

export const MAX_DOMAINS_PER_USER = 5;

const SubdomainReservationPanel: React.FC<{
    ledgerId?: string;
    onRequestClose?: () => void;
    showManageDomainsLink?: boolean;
}> = ({ ledgerId, onRequestClose, showManageDomainsLink }) => {
    const { domains } = useAppContext();
    const { reserveDomain, saveDomain } = useAppActions();
    const [customDomain, setCustomDomain] = useState<string>('');
    const [saving, setSaving] = useState<boolean>(false);

    const domainLimitReached = domains.length >= MAX_DOMAINS_PER_USER;

    const disabled = !validateCustomDomain(customDomain) || domainLimitReached;
    const domainSuggestions = domains.filter(d => d.claim.Assigned === null).map(d => d.domain);

    return (
        <div className="subdomain-reservation-panel">
            {domainLimitReached ? (
                <p className="p2">
                    You’ve reached the limit of 5 custom subdomains per user. In order to reserve
                    another subdomain you must un-reserve an existing one{' '}
                    {showManageDomainsLink && (
                        <span>
                            in{' '}
                            <Link className="inline-link" to={'/console/account/subdomains'}>
                                Account Settings
                            </Link>
                        </span>
                    )}
                    .
                </p>
            ) : (
                <div className="subdomain-reserver-body">
                    <div className="custom-domain">
                        <SuggestiveInput
                            value={customDomain}
                            placeholder={'your custom subdomain...'}
                            onChange={val => setCustomDomain(val.split('.')[0])}
                            suggestions={domainSuggestions}
                            isDisabled={!ledgerId}
                        />
                        <p className="p2 suffix">.{__APP_DOMAIN__}</p>
                    </div>
                    {ledgerId ? (
                        <LoadingButton
                            className={'secondary'}
                            disabled={disabled}
                            loading={saving}
                            onClick={activateDomain}
                            loadingContent={'Saving'}
                        >
                            Save
                        </LoadingButton>
                    ) : (
                        <LoadingButton
                            className={'secondary'}
                            disabled={disabled}
                            loading={saving}
                            onClick={doReserveDomain}
                            loadingContent={'Reserving'}
                        >
                            Reserve
                        </LoadingButton>
                    )}
                </div>
            )}
        </div>
    );

    function validateCustomDomain(domain: string) {
        const isValid = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/;
        return isValid.exec(domain) !== null;
    }

    function doReserveDomain() {
        if (!customDomain) {
            return;
        }
        setSaving(true);
        reserveDomain(customDomain, undefined, onComplete);
    }

    async function activateDomain() {
        if (!ledgerId || !customDomain) {
            return;
        }
        setSaving(true);
        saveDomain(customDomain, ledgerId, onComplete);
    }

    function onComplete() {
        setCustomDomain('');
        setSaving(false);
        if (onRequestClose) {
            onRequestClose();
        }
    }
};

export default SubdomainReservationPanel;
