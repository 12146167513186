import React, { useState } from 'react';

import { dalResizeLedger, LedgerSizeEnum, RequestError, ResourceTokenType } from '@hub-client-api';

import { isLedgerData, useAppActions } from '@hub-fe/app/AppContext';
import BaseSelect, { IBaseSelectOption } from '@hub-fe/common/BaseSelect';
import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import InformationBlock from '@hub-fe/common/InformationBlock';
import LoadingButton from '@hub-fe/common/LoadingButton';
import ResourceQuotaValidationBlock, {
    isLedgerSizeValid,
} from '@hub-fe/common/ResourceQuotaValidationBlock';
import { SettingsField, SettingsPanel } from '@hub-fe/common/SettingsPage';
import { capitalizePhrase } from '@hub-fe/common/utils';
import { useMessages } from '@hub-fe/messages/MessageContext';

import { useLedgerContext } from '../LedgerContext';

const getLedgerSizeName = (size: LedgerSizeEnum): string => {
    switch (size) {
        case undefined:
            return 'Unknown';
        case LedgerSizeEnum.SMALL:
            return 'Standard';
        case LedgerSizeEnum.LARGE:
            return 'High Capacity';
        default:
            return capitalizePhrase(size.toString());
    }
};

const LedgerResizeModal: React.FC<{
    onRequestClose: () => void;
    onSubmitAction: () => void;
    newSize: LedgerSizeEnum;
}> = ({ onRequestClose, onSubmitAction, newSize }) => {
    const { ledgerIntegrations } = useLedgerContext();
    const downtimeMessage =
        'Changing ledger capacity will cause up to 10 minutes of down time on your ledger.';
    const datadogMessage =
        ledgerIntegrations?.datadog !== undefined && newSize == LedgerSizeEnum.SMALL
            ? ' This will also undeploy your currently running Datadog integration, which will need to be recreated if this ledger is resized to High Capacity.'
            : '';

    return (
        <ConfirmActionModal
            show
            onRequestClose={onRequestClose}
            title="Ledger Capacity"
            bodyMessage={downtimeMessage + datadogMessage}
            action="Change Capacity"
            onSubmitAction={onSubmitAction}
            deletingAction
        />
    );
};

const LedgerSize: React.FC = () => {
    const { ledger, isLedgerCollaborator, isLedgerPrimaryOwner } = useLedgerContext();
    if (!ledger || !isLedgerData(ledger)) {
        return null;
    }

    const { error, success } = useMessages();
    const { getQuotaTokens } = useAppActions();
    if (!ledger || !isLedgerData(ledger)) {
        return null;
    }
    const [ledgerSize, setLedgerSize] = useState<LedgerSizeEnum>(
        ledger?.info.ledgerSize || LedgerSizeEnum.SMALL
    );
    const [showResizeModal, setShowResizeModal] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const largeLedgerTokens = getQuotaTokens(ResourceTokenType.LargeLedger);
    const ledgerSizeValidationState = isLedgerSizeValid(
        ledgerSize,
        LedgerSizeEnum.LARGE,
        largeLedgerTokens
    );
    const disabled =
        ledgerSize === ledger?.info.ledgerSize ||
        ledgerSizeValidationState.overLimit ||
        isLedgerCollaborator;

    const saveLedgerSize = async () => {
        setSaving(true);
        await dalResizeLedger(ledger.info.id, ledgerSize)
            .then(async () => {
                ledger.info.ledgerSize = ledgerSize;
                success(`Successfully changed ledger size!`);
            })
            .catch((resp: RequestError) => error(`Could not change ledger size: ${resp.message}`));
        setShowResizeModal(false);
        setSaving(false);
    };

    // If the ledger size is one set by the Admin, show it - the user won't be able to change to it
    const allowedSizes = [LedgerSizeEnum.SMALL, LedgerSizeEnum.LARGE];
    if (!allowedSizes.includes(ledger.info.ledgerSize)) {
        allowedSizes.push(ledger.info.ledgerSize);
    }

    const options: IBaseSelectOption[] = allowedSizes.map((size: LedgerSizeEnum) => ({
        label: getLedgerSizeName(size),
        value: size,
        isDisabled: size !== LedgerSizeEnum.SMALL && ledgerSizeValidationState.atLimit,
    }));

    return (
        <SettingsPanel title="Ledger Capacity" className="ledger-size">
            <SettingsField>
                <div className="ledger-size-edit-panel">
                    <BaseSelect
                        className="size-selector"
                        value={options?.find(o => o.value === ledgerSize)}
                        options={options}
                        onChange={(val: any) => setLedgerSize(val.value)}
                        isDisabled={isLedgerCollaborator}
                    />
                    <LoadingButton
                        className="secondary"
                        disabled={disabled}
                        loading={saving}
                        onClick={() => setShowResizeModal(true)}
                        loadingContent="Saving"
                    >
                        Save
                    </LoadingButton>
                </div>
                {isLedgerPrimaryOwner ? (
                    <ResourceQuotaValidationBlock
                        resourceDescription="high capacity ledger"
                        validationState={ledgerSizeValidationState}
                    />
                ) : (
                    <div className="name-validation-block">
                        <InformationBlock warning>
                            <p className="caption warning">
                                Ledger capacity can only be changed by ledger owners.
                            </p>
                        </InformationBlock>
                    </div>
                )}
            </SettingsField>
            {showResizeModal && (
                <LedgerResizeModal
                    onRequestClose={() => setShowResizeModal(false)}
                    onSubmitAction={saveLedgerSize}
                    newSize={ledgerSize}
                />
            )}
        </SettingsPanel>
    );
};

export default LedgerSize;
