import React from 'react';

import { useAppContext } from '@hub-fe/app/AppContext';
import { IconAddPlus } from '@hub-fe/common/Icons';
import { ConnectButton, SavedSynchronizerCustom } from '@hub-fe/participantNode/ConnectButton';

import './ConnectToSynchronizer.scss';
import { ConnectToSynchronizerForm } from './ConnectToSynchronizerForm';
import CopyableDisplay from './CopyableDisplay';
import { ServiceTileTag } from './ServiceTileTag';
import { SplitLayout } from './SplitLayout';
import { useServiceParams } from './utils/useServiceParams';

interface SavedSynchronizerTileProps {
    name: string;
    url: string;
    isOwner?: boolean;
    serviceId: string;
}
const SavedSynchronizerTile: React.FC<SavedSynchronizerTileProps> = ({
    serviceId,
    name,
    url,
    isOwner,
}) => {
    return (
        <div className="tile saved-synchronizer-tile">
            <div>
                <div className="tile-header">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <p>{name}</p>
                        {isOwner && <ServiceTileTag tagLabel="owner" />}
                    </div>
                    <div className="margin">
                        <div className="margin secondary-smaller">
                            <ConnectButton name={name} hostName={url} serviceId={serviceId} />
                        </div>
                    </div>
                    <div></div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%s' }}>
                    <CopyableDisplay ghostButton value={url} />
                </div>
            </div>
        </div>
    );
};

const Instructions1: React.FC = () => {
    return (
        <div>
            <div>
                <p>Setup Instructions</p>
                <h3>Select a synchronizer</h3>
                <p className="p2">
                    Connect to one of your saved synchronizers. If you would like to connect to a
                    synchronizer that is not listed here click add new synchronizer.{' '}
                </p>
            </div>
            <br />
            <div>
                <p>Setup Instructions</p>
                <h3>Synchronizer owner must approve your join request </h3>
                <p className="p2">
                    {`Ask the synchronizer owner to accept your connection request.
Navigate to: Workspace > Synchronizer > Participants > Requests
Once your request is approved your node status will change from Pending Connection to Connected.

If you are connecting to your own synchronizer you will be automatically conntected. `}{' '}
                </p>
            </div>
        </div>
    );
};
const Instructions2: React.FC = () => {
    return (
        <div>
            <div>
                <h3>Get Synchronizer ID </h3>
                <p className="p2">{`Ask the synchronizer owner for the ID of the synchronizer you would like to connect to.
Navigate to: Workspace > Synchronizer > Overview`}</p>
            </div>
            <div className="instruction-section">
                <h3>Provide Synchronizer Name</h3>
                <p className="p2">
                    This name is provided by you. This name along with the the synchronizer ID will
                    be saved to your account for future use.{' '}
                </p>
            </div>
            <div>
                <h4>Post setup instructions</h4>
                <h3>Synchronizer owner must approve your join request </h3>
                <p className="p2">{`Ask the synchronizer owner to accept your connection request.
Navigate to: Workspace > Synchronizer > Participants > Requests
Once your request is approved your node status will change from Pending Connection to Connected.`}</p>
            </div>
        </div>
    );
};
interface SavedSynchronizersProps {
    onNext: () => void;
}

const SavedSynchronizers: React.FC<SavedSynchronizersProps> = ({ onNext }) => {
    const { savedSynchronizers, activeSynchronizers } = useAppContext();
    const { serviceId } = useServiceParams();

    const transformedSynchronizers: SavedSynchronizerCustom[] = activeSynchronizers.map(s => ({
        name: s.info.name,
        synchronizerUrl: s.info.hostname,
        isOwner: true,
    }));
    const displayedSavedSynchronizers: SavedSynchronizerCustom[] = [
        ...transformedSynchronizers,
        ...savedSynchronizers,
    ];

    if (!serviceId) {
        return null;
    }
    return (
        <div>
            <div className="action-container">
                <b className="text">Your saved synchronizers</b>
                <button onClick={onNext} className="connect-button button secondary-smaller">
                    <IconAddPlus />
                    Add new synchronizer
                </button>
            </div>
            {displayedSavedSynchronizers.map(s => (
                <SavedSynchronizerTile
                    serviceId={serviceId}
                    key={s.synchronizerUrl}
                    url={s.synchronizerUrl}
                    isOwner={s.isOwner}
                    name={s.name}
                />
            ))}
        </div>
    );
};
interface ConnectToSynchronizerProps {
    onCancel: () => void;
}
export const ConnectToSynchronizer: React.FC<ConnectToSynchronizerProps> = ({ onCancel }) => {
    const [step, setStep] = React.useState(0);
    const onNext = () => {
        setStep(prev => prev + 1);
    };
    const onPrev = () => {
        setStep(prev => prev - 1);
    };
    const insutrctions = [<Instructions1 key={0} />, <Instructions2 key={1} />];
    const useActions = [
        <SavedSynchronizers key={0} onNext={onNext} />,
        <ConnectToSynchronizerForm onCancel={onCancel} onPrev={onPrev} key={1} />,
    ];
    return (
        <div className="connect-to-synchronizer">
            <div className="header-container">
                <h3>Connect to a synchronizer</h3>
                <p>Connect to a saved synchronizer below or add a new connection.</p>
            </div>
            <SplitLayout>
                {insutrctions[step]}
                {useActions[step]}
            </SplitLayout>
        </div>
    );
};
