import React from 'react';

import CopyableDisplay from '@hub-fe/common/CopyableDisplay';

import { SelectServiceAndUserId } from './SelectServiceAndUserId';

interface PacSecretProps {
    pacPairB64: string;
    rootUrl: string;
    hasScope: boolean;
}
export const PacSecret: React.FC<PacSecretProps> = ({ pacPairB64, rootUrl, hasScope }) => {
    const [service, setService] = React.useState({ serviceId: '', serviceType: undefined });
    const [userId, setUserId] = React.useState<string | undefined>('');

    return (
        <div className="pacs-secret">
            <div className="token-info">
                <p className="token-title p2">Credential:</p>
                <CopyableDisplay value={pacPairB64} />
                {!hasScope && (
                    <>
                        <br />
                        <p className="token-title p2">Create account token:</p>
                        <CopyableDisplay
                            value={`curl -XPOST -d '{"scope": "site"}' \
                                -H 'Authorization: Basic ${pacPairB64}' \
                                -H 'content-type: application/json' \
                                https:${rootUrl}/api/v1/personal-access-credentials/token`}
                        />
                        <br />
                        <p className="token-title p2">Create user token:</p>
                        <SelectServiceAndUserId
                            onUserChange={val => setUserId(val?.value)}
                            serviceType={service.serviceType}
                            isDisabled={!service.serviceId}
                            serviceId={service.serviceId}
                            onServiceChange={val =>
                                setService({ serviceId: val.value, serviceType: val.serviceType })
                            }
                        />

                        <br />
                        <CopyableDisplay
                            value={`curl -XPOST -d '{"scope": "ledger:data", "ledger": {"user":"${userId}", "ledgerId": "${service.serviceId}"}}' \
            -H 'Authorization: Basic ${pacPairB64}' \
            -H 'content-type: application/json' \
            https:${rootUrl}/api/v1/personal-access-credentials/token`}
                        />
                    </>
                )}
            </div>

            <div className="token-info">
                {hasScope && (
                    <>
                        <p className="token-title p2">Usage command:</p>
                        <CopyableDisplay
                            value={`curl -XPOST -H 'Authorization: Basic ${pacPairB64}' https:${rootUrl}/api/v1/personal-access-credentials/token`}
                        />
                    </>
                )}
            </div>

            <div className="token-info-warning">
                <p className="p2">
                    Make sure to copy this secret, once you leave the page you won’t be able to copy
                    it again.
                </p>
            </div>
        </div>
    );
};
