import { navigate } from '@reach/router';
import classNames from 'classnames';
import React from 'react';

import { ILedgerIntegration } from '@hub-client-api';

import { IconRightArrow, IconTertiaryArrow } from '@hub-fe/common/Icons';
import LoadingButton from '@hub-fe/common/LoadingButton';
import { NavLink } from '@hub-fe/common/routing';
import { IntegrationToggle } from '@hub-fe/ledger/Integrations/IntegrationToggle';
import { IToggleIntegrationFn } from '@hub-fe/ledger/LedgerContext';

interface Props {
    icon: string;
    name: string;
    description: string;
    navTo: string;
    integration?: ILedgerIntegration;
    handleToggle: IToggleIntegrationFn;
    toggleDisabled?: boolean;
    disabled?: boolean;
    needsUpgrade?: boolean;
}

export const IntegrationTile: React.FC<Props> = props => {
    const {
        description,
        icon,
        name,
        navTo,
        integration,
        handleToggle,
        toggleDisabled,
        disabled,
        needsUpgrade,
    } = props;
    return (
        <div className="sample tile">
            <div className="sample-icon">
                <img src={icon} alt="Package Icon" />
            </div>
            <div className="tile-header">
                <div className="version-info">
                    {integration && (
                        <div className="integration-configure">
                            <NavLink className="p2 icon-left" to={`./${navTo}`}>
                                View Configuration
                                <IconRightArrow />
                            </NavLink>
                        </div>
                    )}
                </div>
                <div className="link-block" />
            </div>
            <div className="tile-body">
                <h4>{name}</h4>
                <p className="p2">{description}</p>
            </div>
            {integration ? (
                <div className="actions deployed">
                    <IntegrationToggle
                        integration={integration}
                        handleToggle={handleToggle}
                        disabled={toggleDisabled}
                    />
                </div>
            ) : needsUpgrade ? (
                <div className="actions deployed">
                    <p>Requires Upgrade</p>
                </div>
            ) : (
                <div>
                    <LoadingButton
                        disabled={disabled}
                        ghost
                        className={classNames({ actions: true })}
                        loading={false}
                        loadingContent="Deploying"
                        onClick={() => navigate('./integrations/' + navTo)}
                    >
                        <div className={classNames('with-arrow-button', { disabled: disabled })}>
                            <h4>Deploy</h4>
                            <IconTertiaryArrow size="small" disabled={disabled} />
                        </div>
                    </LoadingButton>
                </div>
            )}
        </div>
    );
};
