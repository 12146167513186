import React from 'react';

import './LinearProgress.scss';

interface LinearProgressProps {
    value: number;
    total: number;
}
export const LinearProgress: React.FC<LinearProgressProps> = ({ value, total }) => {
    const percentage = (value / total) * 100;
    const remaining = total - value;
    return (
        <div className="linear-progress-container">
            <div style={{ width: `${percentage}%` }} className="linear-progress-bar-fill" />

            {
                <div
                    style={{
                        width: `${100 - percentage}%`,
                        justifyContent: remaining > 5 ? 'center' : 'flex-start',
                    }}
                    className="linear-progress-unfilled"
                >
                    {remaining > 0 ? remaining.toFixed(2) : 0} GB available
                </div>
            }
        </div>
    );
};
