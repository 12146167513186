import { IParticipantData, ObjectStatus } from '@hub-client-api';

import {
  ParticipantInformation,
  ParticipantPermission,
  ParticipantTrustLevel,
} from '../generated/clients/damlhub';
import { standardizeParticipantId } from './standardizeParticipantId';

const addIsOwnedField = (args: {
  allowedParticipants: ParticipantInformation[];
  ownedParticipants: IParticipantData[];
}) => {
  const { allowedParticipants, ownedParticipants } = args;
  const ownedParticipantIds = new Set(
    ownedParticipants.map(p => standardizeParticipantId(p.info.participantId))
  );
  const withIsOwned = allowedParticipants.map(p => ({
    ...p,
    isOwned: ownedParticipantIds.has(standardizeParticipantId(p.participantId)),
  }));
  return withIsOwned;
};

export const mergeAllowedParticipants = (args: {
  allowedParticipants: ParticipantInformation[];
  ownedParticipants: IParticipantData[];
}): {
  participantId: string;
  permissionLevel: ParticipantPermission;
  trustLevel: ParticipantTrustLevel;
  isPlaceholder?: boolean;
  participantName?: string;
  status?: ObjectStatus;
}[] => {
  const { allowedParticipants, ownedParticipants } = args;
  const allowedParticipantIds = new Set(
    allowedParticipants.map(p => standardizeParticipantId(p.participantId))
  );
  const participantsNotInAllowList = ownedParticipants.filter(ownedParticipant => {
    return !allowedParticipantIds.has(ownedParticipant.info.participantId);
  });
  const withStandardizedPids = allowedParticipants.map(p => ({
    ...p,
    participantId: standardizeParticipantId(p.participantId),
    participantName: ownedParticipants.find(
      ownedParticipant =>
        ownedParticipant.info.participantId === standardizeParticipantId(p.participantId)
    )?.info.name,
    status: ownedParticipants.find(
      ownedParticipant =>
        ownedParticipant.info.participantId === standardizeParticipantId(p.participantId)
    )?.info.status.ledger,
  }));

  const participantsNotInAllowListTransformed = participantsNotInAllowList.map(
    ownedParticipant => ({
      participantId: ownedParticipant.info.participantId,
      participantName: ownedParticipant.info.name,
      permissionLevel: ParticipantPermission.Disabled,
      trustLevel: ParticipantTrustLevel.Ordinary,
      isOwned: true,
      isPlaceholder: true,
    })
  );
  const withIsOwnedField = addIsOwnedField({
    allowedParticipants: withStandardizedPids,
    ownedParticipants,
  });
  const allParticipants: {
    isOwned: boolean;
    participantId: string;
    participantName?: string;
    permissionLevel: ParticipantPermission;
    trustLevel: ParticipantTrustLevel;
    isPlaceholder?: boolean;
  }[] = [...withIsOwnedField, ...participantsNotInAllowListTransformed];
  return allParticipants;
};
