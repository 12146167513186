import React, { useEffect, useState } from 'react';

import { IDomainInfo, IProjectInfo, ObjectStatus } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import { LoadingDots } from '@hub-fe/common/Animations/Animations';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { IconArrowRightTop } from '@hub-fe/common/Icons';
import InformationBlock from '@hub-fe/common/InformationBlock';
import { ModalSingleSelect } from '@hub-fe/common/ModalSelect';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import { EMPTY_HEADER } from '@hub-fe/common/ReactTable/ReactTableUtils';
import Wizard, { WizardPage } from '@hub-fe/common/Wizard';
import { Link } from '@hub-fe/common/routing';

const SubdomainList: React.FC = () => {
    const { domains, ownedLedgers } = useAppContext();
    const [showAddToLedgerWizard, setShowAddToLedgerWizard] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState<IDomainInfo>();

    const ledgersWithCustomDomains = domains
        .map(({ claim }) => claim.Assigned?.split('.')[0])
        .filter(d => !!d);
    const allCustomDomains = domains.filter(
        ({ domain }) => !ledgersWithCustomDomains.includes(domain.split('.')[0])
    );

    const domainRows = allCustomDomains.map(info => {
        const ledger = ownedLedgers.find(l => info.claim.Assigned?.split('.')[0] === l.info.id);

        const domainStatus = info.claim.Assigned !== null ? 'Assigned' : 'Reserved';

        const ledgerUrl = `/console/ledger/${ledger?.info.id}/deployments`;

        const assignedLedgerIsAchived = ledger?.info.status.ledger === ObjectStatus.ARCHIVED;

        return {
            rowData: [
                {
                    sortKey: info.domain,
                    renderAs: <CopyableDisplay value={info.domain} />,
                },
                {
                    sortKey: domainStatus,
                    renderAs: (
                        <div className="domain-status">
                            <b className="p2">{domainStatus}</b>
                            {info.claim.Assigned !== null && ledger && (
                                <>
                                    <Link className="p2 ledger-link" to={ledgerUrl}>
                                        {ledger?.info.name} <IconArrowRightTop />
                                    </Link>
                                    <p className="p2 project-name">{ledger?.info.projectName}</p>
                                </>
                            )}
                            {assignedLedgerIsAchived && (
                                <InformationBlock>
                                    The ledger this domain is assigned to was archived.
                                </InformationBlock>
                            )}
                        </div>
                    ),
                    displayNarrowViewHeader: true,
                },
                {
                    sortKey: info.claim.Assigned === null ? 'Assign' : 'Re-Assign',
                    renderAs: <DomainActions domain={info} doAssignToLedger={doAssignToLedger} />,
                },
            ],
        };
    });

    return (
        <div className="subdomain-list">
            <h2>Your Subdomains:</h2>
            {allCustomDomains.length > 0 ? (
                <div className="domains">
                    <ReactTable
                        defaultStyle="roomy-rows"
                        tableHeaders={[{ label: 'Subdomain' }, { label: 'Status' }, EMPTY_HEADER]}
                        tableRows={domainRows}
                        emptyTableContent={
                            <p className="p2">There are no reserved domains for this account.</p>
                        }
                    />
                </div>
            ) : (
                <i className="p2 row">There are no reserved domains for this account.</i>
            )}
            {showAddToLedgerWizard && (
                <AddToLedgerWizard
                    onRequestClose={() => setShowAddToLedgerWizard(false)}
                    selectedDomain={selectedDomain}
                />
            )}
        </div>
    );

    function doAssignToLedger(domain: IDomainInfo) {
        setSelectedDomain(domain);
        setShowAddToLedgerWizard(true);
    }
};

const AddToLedgerWizard: React.FC<{
    onRequestClose(): void;
    selectedDomain?: IDomainInfo;
}> = ({ onRequestClose, selectedDomain }) => {
    const { domains, projects = [], activeLedgers } = useAppContext();
    const { saveDomain } = useAppActions();

    const [selectedLedger, setSelectedLedger] = useState<string>();
    const [selectedProject, setSelectedProject] = useState<IProjectInfo | undefined>(
        projects[0]?.info
    );
    const existingCustomDomain = selectedLedger
        ? domains.find(({ claim }) => claim.Assigned?.includes(selectedLedger))
        : undefined;
    const selectedProjectLedgers = activeLedgers.filter(
        ({ info: { projectId } }) => projectId === selectedProject?.id
    );
    const ledgerOptions = selectedProjectLedgers.filter(
        ({ info: { id } }) => !selectedDomain?.claim.Assigned?.includes(id)
    );

    return (
        <Wizard
            show
            className="add-to-ledger-wizard"
            title="Assign to Ledger"
            onRequestClose={() => onRequestClose()}
            onSubmit={() => {
                if (!selectedLedger || !selectedDomain) {
                    return;
                }
                saveDomain(selectedDomain.domain, selectedLedger);
                onRequestClose();
            }}
        >
            <WizardPage pageLabel="Project">
                <p>{`Select a project and ledger to assign to ${selectedDomain?.domain}:`}</p>
                <div className="row">
                    <h4>1. Select Project</h4>
                    {projects?.length === 0 ? (
                        <span className="p2">
                            There are no projects in your workspace. Start by{' '}
                            <Link className="inline-link" to="/console">
                                creating a project
                            </Link>
                            .
                        </span>
                    ) : (
                        <ModalSingleSelect
                            items={projects?.map(({ info }) => {
                                return { id: info.id, name: info.name };
                            })}
                            selectedItem={selectedProject?.id}
                            setSelectedItem={setNewSelectedProject}
                        />
                    )}
                </div>
            </WizardPage>
            <WizardPage pageLabel="Ledgers" disabled={!selectedProject}>
                <p>{`Select a project and ledger to assign to ${selectedDomain?.domain}:`}</p>
                <div className="row">
                    <h4>2. Select Ledger</h4>
                    {selectedProject && selectedProjectLedgers.length === 0 ? (
                        <span className="p2">
                            This project does not have any ledgers. Start by{' '}
                            <Link className="inline-link" to="/console">
                                creating a ledger
                            </Link>
                            .
                        </span>
                    ) : ledgerOptions?.length === 0 ? (
                        <span className="p2">
                            The only ledger in this project is already assigned to{' '}
                            {selectedDomain?.domain}.
                        </span>
                    ) : (
                        <div className="options-list">
                            {selectedProject && (
                                <ModalSingleSelect
                                    items={
                                        ledgerOptions?.map(l => ({
                                            id: l.info.id,
                                            name: l.info.name,
                                        })) || []
                                    }
                                    toggle
                                    selectedItem={selectedLedger}
                                    setSelectedItem={setSelectedLedger}
                                />
                            )}
                        </div>
                    )}
                    {existingCustomDomain && ledgerOptions?.length !== 0 && (
                        <InformationBlock warning>
                            This ledger's current domain, <b>{existingCustomDomain?.domain}</b> will
                            be deleted.
                        </InformationBlock>
                    )}
                </div>
            </WizardPage>
        </Wizard>
    );
    function setNewSelectedProject(id?: string) {
        const newSelectedProject = projects?.find(p => p.info.id === id)?.info;
        if (newSelectedProject) {
            setSelectedProject(newSelectedProject);
        }
    }
};

const DomainActions: React.FC<{
    domain: IDomainInfo;
    doAssignToLedger: (domain: IDomainInfo) => void;
}> = ({ domain, doAssignToLedger }) => {
    const { deleteDomain, removeDomain } = useAppActions();
    const [loadingStatus, setLoadingStatus] = useState<string | undefined>();

    useEffect(() => {
        setLoadingStatus(undefined);
    }, [domain]);

    if (loadingStatus) {
        return (
            <div className="actions loading p2">
                {loadingStatus}
                <LoadingDots />
            </div>
        );
    }
    return (
        <div className="actions">
            <button className="secondary-smaller assign" onClick={() => doAssignToLedger(domain)}>
                {domain.claim.Assigned === null ? 'Assign to Ledger' : 'Re-Assign'}
            </button>
            {domain.claim.Assigned === null ? (
                <button
                    className="secondary-smaller warning"
                    onClick={() => {
                        deleteDomain(domain, () => setLoadingStatus(undefined));
                        setLoadingStatus('Un-Reserving');
                    }}
                >
                    Un-Reserve
                </button>
            ) : (
                <button
                    className="secondary-smaller warning"
                    onClick={() => {
                        removeDomain(domain.domain, () => setLoadingStatus(undefined));
                        setLoadingStatus('Un-Assigning');
                    }}
                >
                    Un-Assign
                </button>
            )}
        </div>
    );
};

export default SubdomainList;
