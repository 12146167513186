import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { IconCircledCheck } from '@hub-fe/common/Icons';

interface IProps {
    checked: boolean;
    onClick: () => void;
    disabled?: boolean;
    children?: React.ReactNode;
}

const CheckedButton: FunctionComponent<IProps> = props => {
    const { checked, onClick, disabled } = props;

    return (
        <button
            className={classNames('secondary-smaller checked-button', { checked })}
            onClick={() => !disabled && onClick()}
            disabled={disabled}
        >
            {checked && (
                <div className="circled-check">
                    <IconCircledCheck />
                </div>
            )}
            {props.children}
        </button>
    );
};

export default CheckedButton;
