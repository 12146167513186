import React, { useState } from 'react';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import { CopyButton } from '@hub-fe/common/ClipboardButtons';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import LoadingPage from '@hub-fe/common/LoadingPage';
import SettingsPage, {
    LeftSidePanels,
    SettingsField,
    SettingsPanel,
} from '@hub-fe/common/SettingsPage';
import Toggle from '@hub-fe/common/Toggle';
import { JWT } from '@hub-fe/common/jwt-lib';
import { Link } from '@hub-fe/common/routing';

import ChangePasswordModal from './ChangePasswordModal';

const auth0ProviderPrefix = 'auth0';

const AccountRoot: React.FC<{ adminToken: JWT }> = ({ adminToken }) => {
    const { userInfo, showPrereleaseApps, isProTier } = useAppContext();
    const { toggleShowPrereleaseApps } = useAppActions();
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

    const canChangePassword = userInfo?.userId.startsWith(auth0ProviderPrefix);

    if (!userInfo) return <LoadingPage statusText="Loading Profile" />;

    return (
        <div className="account-profile">
            <SettingsPage title={<h2>Profile</h2>}>
                <LeftSidePanels>
                    <SettingsPanel>
                        <SettingsField name="Name" value={userInfo?.userName} />
                        <SettingsField name="Email" value={userInfo?.userEmail} />
                        <SettingsField name="User ID">
                            {isProTier && (
                                <p className="caption collaborator-info">
                                    Provide this to ledger owners to become a collaborator on their
                                    ledger
                                </p>
                            )}
                            <CopyableDisplay value={userInfo?.userId} />
                        </SettingsField>
                        <SettingsField name="Authentication">
                            <div className="copy-jwt">
                                <CopyButton
                                    disabled={!adminToken}
                                    content={adminToken}
                                    label="Copy Account JWT"
                                />
                            </div>
                        </SettingsField>
                        <SettingsField name="Privacy and Settings">
                            <div className="settings-links">
                                <Link className="p2 inline-link" to="/terms">
                                    View Terms of Use
                                </Link>
                                {canChangePassword && (
                                    <div
                                        className="p2 inline-link"
                                        onClick={() => setShowChangePasswordModal(true)}
                                    >
                                        Change Password
                                    </div>
                                )}
                            </div>
                        </SettingsField>
                        <SettingsField name="Enable Pre-Release Sample Apps and Integrations">
                            <p className="p2">
                                This setting will enable Pre-release Sample Apps and Integrations to
                                appear in the Workspace and Integration Browser. Pre-release
                                versions may not be stable.
                            </p>
                            <div className="row">
                                <Toggle
                                    on={showPrereleaseApps}
                                    onChanged={toggleShowPrereleaseApps}
                                    onLabel="Enabled"
                                    offLabel="Disabled"
                                    onColor="green"
                                />
                            </div>
                        </SettingsField>
                        {userInfo?.isIntegrationAuthor && (
                            <SettingsField
                                name="Integration Permissions"
                                value="This account is allowed to upload integrations."
                            />
                        )}
                    </SettingsPanel>
                </LeftSidePanels>
            </SettingsPage>
            {showChangePasswordModal && (
                <ChangePasswordModal
                    email={userInfo?.userEmail}
                    onRequestClose={() => setShowChangePasswordModal(false)}
                />
            )}
        </div>
    );
};

export default AccountRoot;
