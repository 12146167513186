import React from 'react';

import { SynchronizerUrlInput } from '@hub-fe/SynchronizerUrlInput/SynchronizerUrlInput';

import { IconLeftArrow } from './Icons';

interface ConnectToSynchronizerFormProps {
    onPrev: () => void;
    onCancel: () => void;
}
export const ConnectToSynchronizerForm: React.FC<ConnectToSynchronizerFormProps> = ({
    onPrev,
    onCancel,
}) => {
    return (
        <div>
            <div
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onClick={onPrev}
            >
                <IconLeftArrow />
                <p className="p2" style={{ marginLeft: '4px' }}>
                    Back to saved connections
                </p>
            </div>

            <SynchronizerUrlInput onCancel={onCancel} />
        </div>
    );
};
