import React from 'react';

import ActionHeader from '@hub-fe/common/ActionHeader';
import { EMPTY_HEADER } from '@hub-fe/common/ReactTable/ReactTableUtils';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import { useLedgerContext } from '../LedgerContext';
import ArtifactTable from './ArtifactTable';

const deploymentsSettingsTableHeaders = [
    { label: 'File Name' },
    { label: 'Upload Date' },
    { label: 'Artifact Hash' },
    { label: 'Deployment Permissions' },
    EMPTY_HEADER,
];

const DeploymentsSettings: React.FC = () => {
    const { ledgerId, deployedArtifacts, isLedgerOwner } = useLedgerContext();
    const { service } = useServiceParams();
    const isLedger = service === ServiceType.LEDGER;
    const servieHeaders = deploymentsSettingsTableHeaders.filter(
        header => header.label !== 'Deployment Permissions'
    );
    const displayedHeader = isLedger ? deploymentsSettingsTableHeaders : servieHeaders;
    return (
        <div className="deployments-settings">
            <ActionHeader
                title="Deployments Settings"
                linkBackTo={`/console/${service}/${ledgerId}/deployments`}
            />
            <ArtifactTable
                deployedArtifacts={deployedArtifacts || []}
                tableHeaders={displayedHeader}
                linkedRows={false}
                linkedFileNames
                loading={!deployedArtifacts}
                defaultAllExpanded
                enableSortByDate
                canChangeDeploymentPermissions={isLedgerOwner}
                emptyTableContent={<p className="p2">This {service} has no deployments.</p>}
            />
        </div>
    );
};

export default DeploymentsSettings;
