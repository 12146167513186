import React from 'react';

import { useAppContext } from '@hub-fe/app/AppContext';
import { useLedgerActions, useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import BaseSelect from './BaseSelect';
import './ManageDisabledParticipants.scss';
import Modal from './Modal';
import { ParticipantPermission } from './generated/clients/damlhub';
import { mergeAllowedParticipants } from './utils/mergeAllowedParticipants';

interface ManageDisabledParticipantsProps {
    serviceId: string;
    show: boolean;
    onRequestClose: () => void;
}
interface Selected {
    [key: string]: ParticipantPermission;
}
const permissionLevels: { label: ParticipantPermission; value: ParticipantPermission }[] = [
    {
        label: 'Submission',
        value: 'Submission',
    },
    {
        label: 'Observation',
        value: 'Observation',
    },
    {
        label: 'Disabled',
        value: 'Disabled',
    },
];
export const ManageDisabledParticipants: React.FC<ManageDisabledParticipantsProps> = ({
    show,
    onRequestClose,
    serviceId,
}) => {
    const { activeParticipants: ownedParticipants } = useAppContext();
    const { allowedParticipants } = useLedgerContext();
    const { setParticipantPermission } = useLedgerActions();

    const all = mergeAllowedParticipants({
        ownedParticipants,
        allowedParticipants: allowedParticipants || [],
    });
    const [checked, setChecked] = React.useState({} as { [key: string]: boolean });
    const [selected, setSelected] = React.useState({} as Selected);
    const toggleCheck = (participantId: string) => {
        if (checked[participantId]) {
            const updated = { ...checked };
            delete updated[participantId];
            setChecked(updated);
        } else {
            setChecked(prev => ({ ...prev, [participantId]: true }));
        }
    };
    const onChange = (val: any, participantId: string) => {
        setSelected(prev => ({ ...prev, [participantId]: val.value }));
    };
    const disabledParticipants = all.filter(p => p.permissionLevel === 'Disabled');
    const onSave = async () => {
        try {
            const participants = Object.keys(checked);
            await Promise.all(
                participants.map(async p => {
                    setParticipantPermission(serviceId, p, selected[p]);
                })
            );
            onRequestClose();
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <Modal
            title="Manage Disabled Participants"
            className="confirm-action-modal"
            show={show}
            onRequestClose={onRequestClose}
        >
            <div className="manage-disabled-participants">
                <p>
                    Select participants you would like to re-enable and provide their permission
                    level.{' '}
                </p>
                {disabledParticipants.length > 0 && (
                    <div className="header">
                        <div>
                            <div className="checkbox"></div>
                        </div>
                        <div>Participant ID</div>
                        <div style={{ marginLeft: '50%' }}>Permission</div>
                    </div>
                )}
                {disabledParticipants.length === 0 && (
                    <p className="no-disabled p">No disabled participants</p>
                )}
                {disabledParticipants.map(disabledP => (
                    <div className="participant-row" key={disabledP.participantId}>
                        <input
                            onChange={() => toggleCheck(disabledP.participantId)}
                            className="checkbox"
                            type="checkbox"
                        />
                        <input value={disabledP.participantId} className="id" />
                        <div>
                            <BaseSelect
                                isSearchable={false}
                                value={{
                                    label: selected[disabledP.participantId] || 'Disabled',
                                    value: selected[disabledP.participantId],
                                }}
                                name={disabledP.participantId}
                                options={permissionLevels}
                                onChange={val => onChange(val, disabledP.participantId)}
                            />
                        </div>
                    </div>
                ))}
                <div className="actions">
                    <button className="secondary cancel" onClick={onRequestClose}>
                        Cancel
                    </button>
                    <button
                        disabled={disabledParticipants.length === 0}
                        onClick={onSave}
                        className="primary"
                    >
                        Save
                    </button>
                </div>
            </div>
        </Modal>
    );
};
