import React, { useState } from 'react';

import { IDomainInfo } from '@hub-client-api';

import SubdomainReservationPanel, {
    MAX_DOMAINS_PER_USER,
} from '@hub-fe/account/SubdomainReservationPanel';
import { useAppContext } from '@hub-fe/app/AppContext';
import ExternalLink from '@hub-fe/common/ExternalLink';
import { IconEdit } from '@hub-fe/common/Icons';
import { SettingsField } from '@hub-fe/common/SettingsPage';
import Toggle from '@hub-fe/common/Toggle';
import { useDismissableElement } from '@hub-fe/common/utilsReact';

import { useLedgerActions, useLedgerContext } from '../LedgerContext';

interface LedgerDomainsProps {
    service?: string;
    disableCustomWebDomain?: boolean;
}
const LedgerDomains: React.FC<LedgerDomainsProps> = ({ disableCustomWebDomain, service }) => {
    const { isProTier, domains } = useAppContext();
    const { ledgerId, isLedgerPrimaryOwner, subdomains } = useLedgerContext();
    const { toggleDomain } = useLedgerActions();
    const customDomain = subdomains?.custom;

    const [editDefaultDomain, setEditDefaultDomain] = useState<boolean>(!customDomain);
    const { refDismissable } = useDismissableElement<HTMLDivElement, HTMLDivElement>(() =>
        setEditDefaultDomain(!customDomain?.domain)
    );
    const canEditSubdomains = isProTier && isLedgerPrimaryOwner && !disableCustomWebDomain;
    const domainLimitReached = domains.length >= MAX_DOMAINS_PER_USER;
    const CustomDomain = () =>
        !disableCustomWebDomain && customDomain ? (
            <SettingsField name={`Custom ${service || 'Ledger'} Domain`}>
                <Subdomain url={customDomain.domain} onEdit={() => setEditDefaultDomain(true)} />
            </SettingsField>
        ) : (
            <></>
        );

    return (
        <>
            {canEditSubdomains ? (
                editDefaultDomain || !customDomain ? (
                    <SettingsField name="Custom Ledger Domain">
                        {domainLimitReached && <CustomDomain />}
                        <div ref={refDismissable}>
                            <SubdomainReservationPanel
                                ledgerId={ledgerId}
                                onRequestClose={() => setEditDefaultDomain(false)}
                                showManageDomainsLink
                            />
                        </div>
                    </SettingsField>
                ) : (
                    isProTier && <CustomDomain />
                )
            ) : (
                customDomain && <CustomDomain />
            )}
            {subdomains?.default && (
                <SettingsField name={`Default ${service || 'Ledger'} Domain`}>
                    <Subdomain
                        disableToggle={!!service}
                        on={!!subdomains?.default?.claim?.Assigned}
                        onToggle={() => doToggleDomain(subdomains?.default)}
                        url={subdomains?.default.domain}
                    />
                </SettingsField>
            )}
        </>
    );

    function doToggleDomain(domain?: IDomainInfo) {
        if (domain) {
            toggleDomain(domain);
        }
    }
};

const Subdomain = (props: {
    url: string;
    on?: boolean;
    onToggle?: () => void;
    onEdit?: () => void;
    disableToggle?: boolean;
}) => {
    const { url, on, onToggle, onEdit, disableToggle } = props;
    const { isLedgerPrimaryOwner } = useLedgerContext();
    const subdomainUrl = `https://${url}`;

    return (
        <div className="field-data in-line subdomain">
            {url ? (
                <ExternalLink className="p2 external-link" to={subdomainUrl} icon>
                    <p className="p2 subdomain-url">{subdomainUrl}</p>
                </ExternalLink>
            ) : (
                <div className="p2 external-link">
                    <div className="p2 subdomain-url">your-custom-domain</div>
                </div>
            )}

            {onEdit && isLedgerPrimaryOwner && (
                <button className="ghost" onClick={onEdit}>
                    <IconEdit />
                </button>
            )}
            {!disableToggle && onToggle && isLedgerPrimaryOwner && (
                <Toggle
                    on={!!on}
                    onChanged={onToggle}
                    onLabel="Enabled"
                    offLabel="Disabled"
                    onColor="green"
                    togglingLabel={on ? 'Disabling' : 'Enabling'}
                />
            )}
        </div>
    );
};

export default LedgerDomains;
