import React from 'react';

import { IconWarning } from './Icons';
import './InputErrorMessage.scss';

interface ErrorMessageProps {
    errorMessage: string;
}
export const InputErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage }) => {
    return (
        <div className="input-error-message-container">
            <IconWarning />
            {errorMessage}
        </div>
    );
};
