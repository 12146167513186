import React from 'react';

import PartyDropdown, { formatSelectionData } from '@hub-fe/common/PartyDropdown';

import { useLedgerContext } from '../../LedgerContext';

const ContractTemplatePartyDropdown: React.FC<{
    onChange: (val?: any) => void;
    selectedParty?: string;
}> = ({ onChange, selectedParty }) => {
    const { ledger, ownerParties = [], userParties = [] } = useLedgerContext();

    const groupedPartyOptions = [
        {
            label: 'Your Parties',
            options: formatSelectionData(ownerParties),
        },
        {
            label: 'End Users',
            options: formatSelectionData(userParties),
        },
    ];

    return (
        <PartyDropdown
            groupedPartyOptions={groupedPartyOptions}
            isClearable
            isCreatable
            onChange={val => onChange(val?.value)}
            placeholder="Select a Party..."
            selectedParty={selectedParty}
            ledgerStatus={ledger?.info.status.ledger}
        />
    );
};

export default ContractTemplatePartyDropdown;
