import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { ILedgerData, ObjectStatus } from '@hub-client-api';

import { getLedgerDomainClaim, useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import { InfiniteLoadingWheel } from '@hub-fe/common/Animations/Animations';
import {
    IconRightArrow,
    IconSidebarGlobe,
    IconResume,
    IconWarning,
    IconCanton,
    IconSidebarUser,
} from '@hub-fe/common/Icons';
import LedgerStatusDisplay from '@hub-fe/common/LedgerStatusDisplay';
import { NavLink } from '@hub-fe/common/routing';
import { pluralize } from '@hub-fe/common/utils';
import { useDragDrop } from '@hub-fe/common/utils/useDragDrop';

import { checkStaleLedger } from './ProjectSelector';

const ledgerTransientStates = [ObjectStatus.DELETING, ObjectStatus.DEPLOYING];

const ProjectLedger: React.FC<{ ledger: ILedgerData }> = ({ ledger }) => {
    const ledgerStatus = ledger.objectStatus || ledger.info.status.ledger;
    const ledgerAccessDisabled =
        ledger.objectStatus === ObjectStatus.DELETING || ledgerStatus === ObjectStatus.PAUSED;
    const { dragProps, dragCount } = useDragDrop({
        ledger,
        serviceAccessDisabled: ledgerAccessDisabled,
    });

    if (ledger.objectStatus === ObjectStatus.LOADING || !ledger) {
        return <LoadingLedger name={ledger.info.name} />;
    }

    const linkProps = {
        draggable: false,
    };

    let target;

    if (!ledgerAccessDisabled) {
        target = `/console/ledger/${ledger.info.id}`;
    }

    return (
        <div
            className={classNames(
                'project-ledger ledger-tile',
                {
                    'drag-over': dragCount > 0,
                },
                {
                    disabled: ledgerAccessDisabled,
                }
            )}
            {...dragProps}
        >
            {target ? (
                /* NavLink requires a link destination, which is not normally the
                 * case for Anchors. To retain NavLink's ability to navigate
                 * without a full reload and still use anchor-based CSS styling in
                 * the cases where there is no link target, we have to select
                 * between NavLink and ordinary anchors.
                 */
                <NavLink to={target} {...linkProps}>
                    <LedgerBody ledger={ledger} isDisabled={ledgerAccessDisabled} />
                </NavLink>
            ) : (
                <a {...linkProps}>
                    <LedgerBody ledger={ledger} isDisabled={ledgerAccessDisabled} />
                </a>
            )}
        </div>
    );
};

const LedgerBody: React.FC<{ ledger: ILedgerData; isDisabled: boolean }> = ({
    isDisabled,
    ledger,
}) => {
    const { resumeLedger, getLedgerCustomSubdomain, updateLedgerStatus } = useAppActions();
    const { isProTier } = useAppContext();
    const [count, setCount] = useState<number | undefined>(ledger.info.artifactCount);

    const customDomain = getLedgerCustomSubdomain(ledger.info.id);
    const domainLink = customDomain ? customDomain.domain : getLedgerDomainClaim(ledger.info.id);

    const ledgerStatus = ledger.objectStatus || ledger.info.status.ledger;

    const hasTransientState = ledgerTransientStates.includes(ledgerStatus);
    const isStale = checkStaleLedger(ledger.info) && !isProTier;

    useEffect(() => {
        const newCount = ledger.info.artifactCount;
        if ((newCount || 0) > (count || 0)) {
            updateLedgerStatus(ledger);
        }
        setCount(newCount);
    }, [ledger.info.artifactCount]);

    let target;

    if (!isDisabled) {
        target = `/console/ledger/${ledger.info.id}`;
    }

    const artifactCount = (
        <div className="artifact-count">
            {count !== undefined && (
                <p className="p2 ">
                    {count} {pluralize(count, 'file')} deployed
                </p>
            )}
        </div>
    );

    const transitionState = (
        <div className="transition-state">
            <InfiniteLoadingWheel />
            <p className="p2">{ledger.objectStatus} </p>
        </div>
    );

    const ledgerId = <p className="p2">ID: {ledger.info.id}</p>;

    const resumeButton = (
        <button
            className="ghost resume icon-left"
            onClick={e => {
                resumeLedger(ledger);
                e.preventDefault();
            }}
        >
            <IconResume /> Resume
        </button>
    );

    const staleLedgerWarning = isStale && (
        <div className="stale-ledger-warning icon-left p2">
            <IconWarning />
            Ledgers are deleted after 14 days of inactivity.{' '}
            {
                <span
                    className="inline-link"
                    onClick={e => {
                        resumeLedger(ledger);
                        e.preventDefault();
                    }}
                >
                    Resume now
                </span>
            }
        </div>
    );

    const collaboratorCount = ledger.info.secondaryOwners.length || 0;

    return (
        <div
            className={classNames('ledger-content', {
                starting: ledgerStatus === ObjectStatus.STARTING,
            })}
        >
            <div className="header">
                <h4>{ledger.info.name}</h4>
                {ledgerStatus === ObjectStatus.PAUSED ? resumeButton : target && <IconRightArrow />}
            </div>
            {staleLedgerWarning}
            <div className="detail-grid">
                <div className="ledger-detail">{ledgerId}</div>
                <div className="ledger-detail artifacts">{artifactCount}</div>
                {customDomain && (
                    <div className="ledger-detail domain icon-left">
                        <div className="domain-link">
                            <IconSidebarGlobe />
                            <p className="p2">{domainLink}</p>
                        </div>
                    </div>
                )}
                {isProTier && (
                    <div className="ledger-detail p2 collaborators icon-left">
                        <IconSidebarUser />
                        {collaboratorCount} {pluralize(collaboratorCount, 'collaborator')}
                    </div>
                )}
                <div className="ledger-detail state">
                    {hasTransientState ? (
                        transitionState
                    ) : (
                        <LedgerStatusDisplay status={ledgerStatus} includeStartingWarning />
                    )}
                </div>
            </div>
            <div className="detail-grid">
                <div className="ledger-detail">
                    <IconCanton />
                </div>
            </div>
        </div>
    );
};

const LoadingLedger = (props: { name: string }) => (
    <div className="project-ledger ledger-tile disabled loading">
        <div className="ledger-content">
            <div className="header">
                <h4>{props.name}</h4>
            </div>
            <div className="ledger-detail loading-transition-state icon-left">
                <InfiniteLoadingWheel />
                <p className="p2">Creating Ledger...</p>
            </div>
        </div>
    </div>
);

export default ProjectLedger;
