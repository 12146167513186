import React from 'react';

import { FileUploadButton } from './FileUpload';
import { NoDeploymentsIcon } from './Icons';
import LoadingButton from './LoadingButton';
import './NoDeploymentsTile.scss';

interface NoDeploymentsTileProps {
    doUpload: (file: File) => void;
    onDeploy: () => void;
}
export const NoDeploymentsTile: React.FC<NoDeploymentsTileProps> = ({ onDeploy, doUpload }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const onClick = () => {
        setIsLoading(true);
        onDeploy();
    };
    return (
        <div className="no-deployments tile">
            <NoDeploymentsIcon />
            <p className="header">You have no deployments</p>
            <p className="p2">Upload your own files or deploy a sample app to get started.</p>
            <div className="button-container">
                <LoadingButton
                    loadingContent="Deploying"
                    loading={isLoading}
                    onClick={onClick}
                    className="deploy primary"
                >
                    Deploy Sample App
                </LoadingButton>
                <FileUploadButton
                    onUploadRequest={file => doUpload(file)}
                    className="secondary button secondary"
                >
                    Upload Files
                </FileUploadButton>
            </div>
        </div>
    );
};
