import classNames from 'classnames';
import React from 'react';

import { useAppContext } from '@hub-fe/app/AppContext';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import Expander from './Expander';
import './ServiceNavDropdownMenu.scss';
import { sidebarlinks } from './Sidebar/config';
import { NavLink } from './routing';
import { getLedgerSidebarTabFormUrl } from './routing/RoutingContext';
import { useServiceParams } from './utils/useServiceParams';

interface ServiceNavNarrowProps {
    serviceType: ServiceType;
}
export const ServiceNavDropdownMenu: React.FC<ServiceNavNarrowProps> = ({ serviceType }) => {
    const [open, setOpen] = React.useState(false);
    const getSidebarLink = sidebarlinks[serviceType];
    const { service } = useServiceParams();
    const { isProTier } = useAppContext();
    const { isLedgerOwner } = useLedgerContext();
    const { serviceId } = useServiceParams();
    if (!serviceId) {
        return null;
    }
    const toggleOpen = () => {
        setOpen(!open);
    };
    const segment = getLedgerSidebarTabFormUrl(serviceId);

    const menuLinks = getSidebarLink({ isLedgerOwner, isProTier });
    const currentPage = menuLinks.find(p => p.target.includes(segment));
    getSidebarLink;
    return (
        <div className="service-nav-narrow">
            <div
                className={classNames('icon-right icon-left page-menu', {
                    expanded: open,
                })}
                onClick={toggleOpen}
            >
                {currentPage?.icon}
                {currentPage?.label}
                <Expander expanded={open} />
            </div>
            <div className="control-menu" onClick={toggleOpen}>
                {open &&
                    menuLinks.map(({ label, target, icon }) => (
                        <NavLink
                            className="control-menu-item"
                            key={label}
                            to={`/console/${service}/${serviceId}/${target}`}
                        >
                            <h3 className="icon-left">
                                {icon}
                                {label}
                            </h3>
                        </NavLink>
                    ))}
            </div>
        </div>
    );
};
