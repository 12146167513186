import { Link } from '@reach/router';
import React, { useState, useEffect } from 'react';

import { isScratchpadData, isSynchronizerData } from '@hub-fe/app/AppContext';
import DocsLinkConsole from '@hub-fe/common/DocsLinkConsole';
import { IconLeftArrow } from '@hub-fe/common/Icons';
import InformationBlock from '@hub-fe/common/InformationBlock';
import LoadingButton from '@hub-fe/common/LoadingButton';
import { isLargeLedgerOrParticipant } from '@hub-fe/common/utils/isSmallLedgerOrParticipant';
import { IntegrationToggle } from '@hub-fe/ledger/Integrations/IntegrationToggle';
import { useLedgerActions, useLedgerContext } from '@hub-fe/ledger/LedgerContext';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

const DatadogIntegration: React.FC = () => {
    const { ledgerIntegrations, ledger, serviceType, serviceIntegrations } = useLedgerContext();
    const { toggleDatadogIntegration, createDatadogIntegration, deleteDatadogIntegration } =
        useLedgerActions();
    const [apiKey, setApiKey] = useState('');
    const isLedger = serviceType === ServiceType.LEDGER;
    const integration = isLedger ? ledgerIntegrations : serviceIntegrations;
    const [isSupportedService, setSupported] = React.useState(false);
    const [obfuscatedKey, setObfuscatedKey] = useState(integration?.datadog?.apiKey || '');
    const [saving, setSaving] = useState<boolean>(false);
    const displayedService = isLedger ? 'ledger' : 'service';
    const datadog = integration?.datadog;

    useEffect(() => {
        if (!ledger || isScratchpadData(ledger)) {
            return;
        }
        if (datadog) {
            setObfuscatedKey(datadog.apiKey);
        }
        if (isSynchronizerData(ledger)) {
            setSupported(true);
        } else setSupported(isLargeLedgerOrParticipant(ledger));
    }, [datadog, ledger]);

    if (!ledger || isScratchpadData(ledger)) {
        return <></>;
    }

    const isEnterprise = !!ledger.info.hasEnterpriseOwner;

    const updateLogstream = async () => {
        setSaving(true);
        if (!datadog) {
            createDatadogIntegration(apiKey, () => {
                setSaving(false);
                setApiKey('');
            });
        } else {
            deleteDatadogIntegration(() => setSaving(false));
        }
    };
    const enabled = isSupportedService && isEnterprise;

    const buttonText = datadog ? 'Delete' : 'Submit';

    const highCapacityWarning = `${displayedService} must be High Capacity`;
    const enterpriseWarning = `${displayedService} owner must be an Enterprise user`;
    const warningText = `The ${
        isEnterprise ? highCapacityWarning : enterpriseWarning
    } to enable Datadog streaming.`;

    return (
        <div className="datadog-settings">
            <Link className="active p2 icon-left backwards-link" to={'../'}>
                <IconLeftArrow />
                Back
            </Link>
            <div className="integration-info">
                <h1 className="title">Datadog Monitoring</h1>
                <p>Set up this {displayedService} to send data to a Datadog dashboard.</p>
            </div>
            <div className="key-info">
                <h4>Datadog API Key</h4>
                <p>You can create one in your Datadog organizational settings.</p>{' '}
                <p>
                    For more information,{' '}
                    <DocsLinkConsole topic="datadog-logging-integration">
                        visit the Daml Hub docs
                    </DocsLinkConsole>
                    .
                </p>
            </div>
            <div className="settings">
                <div className="api-key">
                    {!datadog ? (
                        <>
                            <input
                                placeholder={'API Key'}
                                value={apiKey}
                                onChange={e => setApiKey(e.target.value)}
                                disabled={saving}
                            />
                        </>
                    ) : (
                        <>
                            <input
                                placeholder={'API Key'}
                                value={obfuscatedKey}
                                disabled={!enabled}
                            />
                            <LoadingButton
                                onClick={updateLogstream}
                                loading={saving}
                                className="secondary warning"
                            >
                                {buttonText}
                            </LoadingButton>
                        </>
                    )}
                </div>
                <div className="actions">
                    {datadog ? (
                        <div>
                            <IntegrationToggle
                                integration={datadog}
                                handleToggle={toggleDatadogIntegration}
                                disabled={!enabled}
                            />
                        </div>
                    ) : (
                        <LoadingButton
                            onClick={updateLogstream}
                            loading={saving}
                            disabled={!enabled}
                        >
                            {buttonText}
                        </LoadingButton>
                    )}
                </div>
                {!enabled && (
                    <div>
                        <InformationBlock warning>
                            <p className="caption warning">{warningText}</p>
                        </InformationBlock>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DatadogIntegration;
