import React from 'react';
import { ActionMeta, components, OptionProps, SingleValue, SingleValueProps } from 'react-select';

import { ObjectStatus } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import BaseSelect from '@hub-fe/common/BaseSelect';
import { MinimalUserDetails } from '@hub-fe/common/generated/clients/damlhub';
import { isNewService } from '@hub-fe/common/utils/isNewService';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import './PersonalAccessCredentials.scss';

interface SelectServiceAndUserIdProps {
    onServiceChange: ((newValue: any, actionMeta: ActionMeta<any>) => void) | undefined;
    onUserChange:
        | ((
              newValue: SingleValue<{
                  label: string;
                  value: string;
              }>,
              actionMeta: ActionMeta<{
                  label: string;
                  value: string;
              }>
          ) => void)
        | undefined;
    isDisabled: boolean;
    serviceType?: ServiceType;
    serviceId?: string;
}
export const SelectServiceAndUserId: React.FC<SelectServiceAndUserIdProps> = ({
    isDisabled,
    serviceId,
    serviceType,
    onUserChange,
    onServiceChange,
}) => {
    const { activeLedgers, damlHubClient, activeParticipants, activeScratchpads } = useAppContext();
    const [serviceUsers, setServiceUsers] = React.useState<MinimalUserDetails[]>([]);
    React.useEffect(() => {
        const fetchServiceUsers = async () => {
            try {
                if (serviceType && isNewService(serviceType) && serviceId) {
                    const users = await damlHubClient.userManagementApi.listUsersOfParticipant({
                        serviceId: serviceId,
                    });
                    setServiceUsers(users.result || []);
                } else {
                    if (serviceId) {
                        const users = await damlHubClient.userManagementApi.listUsers({
                            ledgerId: serviceId,
                        });
                        setServiceUsers(users.result || []);
                    }
                }
            } catch (e) {
                console.log(e);
                setServiceUsers([]);
            }
        };
        fetchServiceUsers();
    }, [serviceId, serviceType, damlHubClient.userManagementApi]);
    const participantGroup = {
        label: 'Participants',
        options: activeParticipants
            .filter(p => p.objectStatus === ObjectStatus.RUNNING)
            .map(p => ({
                label: p.info.name,
                value: p.info.id,
                serviceType: ServiceType.PARTICIPANT,
            })),
    };
    const ledgersGroup = {
        label: 'Ledgers',
        options: activeLedgers
            .filter(p => p.objectStatus === ObjectStatus.RUNNING)
            .map(p => ({
                label: p.info.name,
                value: p.info.id,
                serviceType: ServiceType.LEDGER,
            })),
    };
    const scratchpadGroup = {
        label: 'Scratchpads',
        options: activeScratchpads
            .filter(p => p.objectStatus === ObjectStatus.RUNNING)
            .map(p => ({
                label: p.info.name,
                value: p.info.id,
                serviceType: ServiceType.SCRATCHPAD,
            })),
    };
    const allServices = [ledgersGroup, participantGroup, scratchpadGroup];
    const LedgerOption = (props: OptionProps<any, boolean>): JSX.Element => (
        <components.Option {...props}>
            <p className="p2">{props.data.label}</p>
            <p className="caption"> ID: {props.data.value}</p>
        </components.Option>
    );
    const SingleValue = (props: SingleValueProps<any>): JSX.Element => (
        <components.SingleValue {...props} className="ledger-option">
            <p className="p2">{props.data.label}</p>
            <p className="caption"> ID: {props.data.value}</p>
        </components.SingleValue>
    );
    const formatGroupLabel = (data: any) => (
        <div className="content-only-label">
            <h4>{data.label}</h4>
        </div>
    );
    return (
        <>
            <div className="field">
                <h4>
                    Select a Service <span className="required-field-asterisk">*</span>
                </h4>
                <BaseSelect
                    options={allServices}
                    formatGroupLabel={formatGroupLabel}
                    components={{ Option: LedgerOption, SingleValue }}
                    onChange={onServiceChange}
                />
            </div>
            <div className="field">
                <h4>
                    User ID <span className="required-field-asterisk">*</span>
                </h4>
                <BaseSelect
                    isDisabled={isDisabled}
                    isClearable
                    options={serviceUsers.map(u => ({
                        label: u.userId,
                        value: u.userId,
                    }))}
                    onChange={onUserChange}
                />
            </div>
        </>
    );
};
