import React from 'react';

import BaseSelect from '@hub-fe/common/BaseSelect';
import { useLedgerActions } from '@hub-fe/ledger/LedgerContext';

import { ParticipantPermission } from './generated/clients/damlhub';

const permissionLevels: { label: ParticipantPermission; value: ParticipantPermission }[] = [
    {
        label: 'Submission',
        value: 'Submission',
    },
    {
        label: 'Observation',
        value: 'Observation',
    },
];

interface PermissionLevelsDropdownProps {
    value: ParticipantPermission;
    participantId: string;
    serviceId: string;
}
export const PermissionLevelsDropdown: React.FC<PermissionLevelsDropdownProps> = ({
    participantId,
    value,
    serviceId,
}) => {
    const [permissionLevel, setPermissionLevel] = React.useState<
        undefined | ParticipantPermission
    >();
    React.useEffect(() => {
        setPermissionLevel(value);
    }, [value]);

    const { setParticipantPermission } = useLedgerActions();
    const onChange = React.useCallback(
        async (e: any) => {
            if (!serviceId) {
                return;
            }
            try {
                await setParticipantPermission(serviceId, participantId, e.value);
                setPermissionLevel(e.value);
            } catch {
                setPermissionLevel(value);
            }
        },
        [serviceId, participantId, setParticipantPermission, setPermissionLevel, value]
    );
    const permissionValue = { label: permissionLevel, value: permissionLevel };
    return (
        <BaseSelect
            isDisabled={!serviceId}
            onChange={onChange}
            isSearchable={false}
            isOptionDisabled={e => {
                return e.value === (permissionLevel || value);
            }}
            value={permissionValue}
            options={permissionLevels}
        />
    );
};
