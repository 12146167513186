import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';

import Modal, { IModalProps, ModalControls } from './Modal';

interface IWizardPageProps {
    pageLabel: string;
    disabled?: boolean;
    className?: string;
    children?: React.ReactNode;
}

export const WizardPage: FunctionComponent<IWizardPageProps> = props => {
    return <div className={classNames('wizard-page', props.className)}>{props.children}</div>;
};

interface IProps {
    children: Array<React.ReactElement<IWizardPageProps>>;
    onSubmit: () => void;
    actionDetails?: string;
}

const Wizard: FunctionComponent<IProps & IModalProps> = ({
    children,
    onSubmit,
    actionDetails,
    ...props
}) => {
    const [page, setPage] = useState(0);

    const modalProps = {
        ...props,
        onSubmit: () => handleSubmit(),
    };

    const activeChildren = children.filter(c => c !== null);

    return (
        <Modal {...modalProps}>
            <div className="wizard">
                <div className="wizard-body">{activeChildren[page]}</div>
                <ModalControls>
                    {actionDetails && onLastPage() && <p className="p2">{actionDetails}</p>}
                    {allowPrevPage() && (
                        <button
                            className="secondary"
                            id="prev-page"
                            type="button"
                            onClick={() => prevPage()}
                        >
                            Back
                        </button>
                    )}
                    {onLastPage() ? (
                        <button
                            id="next-page"
                            type="button"
                            disabled={isAdvanceDisabled()}
                            onClick={() => onSubmit()}
                        >
                            Submit
                        </button>
                    ) : (
                        <button
                            id="next-page"
                            type="button"
                            disabled={isAdvanceDisabled()}
                            onClick={() => nextPage()}
                        >
                            Next
                        </button>
                    )}
                </ModalControls>
            </div>
        </Modal>
    );

    function handleSubmit() {
        if (onLastPage()) {
            onSubmit();
            setPage(0);
        } else {
            nextPage();
        }
    }

    function onLastPage(): boolean {
        return page === activeChildren.length - 1;
    }

    function isAdvanceDisabled(): boolean {
        const childPage = activeChildren[page];

        return !!childPage.props.disabled;
    }

    function allowPage(page: number): boolean {
        if (page < 0 || page >= activeChildren.length) {
            return false;
        }

        return true;
    }

    function allowPrevPage() {
        return allowPage(page - 1);
    }

    function prevPage() {
        if (allowPrevPage()) {
            setPage(page - 1);
        }
    }

    function allowNextPage() {
        return allowPage(page + 1);
    }

    function nextPage() {
        if (allowNextPage()) {
            setPage(page + 1);
        }
    }
};

export default Wizard;
