import React from 'react';

import { artifactTypes } from '@hub-client-api';

import ActionHeader from '@hub-fe/common/ActionHeader';
import DownloadButton from '@hub-fe/common/DownloadButton';
import { getEntityName } from '@hub-fe/common/utils';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import { useEntityInfo, useLedgerActions, useLedgerContext } from '../LedgerContext';
import DeploymentFileBrowser from './DeployedArtifactFileBrowser';
import DeployedArtifactFileContent from './DeployedArtifactFileContent';

const DeployedArtifactCode: React.FC = () => {
    const { artifactHash, service } = useServiceParams();
    const { ledger } = useLedgerContext();
    const { getArtifactByHash, downloadArtifactData } = useLedgerActions();
    const entityInfo = useEntityInfo(artifactHash);
    const files = getArtifactByHash(artifactHash)?.files;
    const isModel = entityInfo?.entity.tag === artifactTypes.MODEL_ARTIFACT;

    if (!entityInfo || (!files && !isModel)) {
        return null;
    }

    const fileName = getEntityName(entityInfo.entity);

    function handleDownload() {
        if (entityInfo) {
            downloadArtifactData(fileName, entityInfo.artifactHash);
        }
    }

    return (
        <div className="deployed-artifact-code">
            {isModel ? (
                <ActionHeader
                    title={fileName}
                    linkBackTo={`/console/${service}/${ledger?.info.id}/deployments`}
                    fileTagEntity={entityInfo.entity}
                    titleButtons={[<DownloadButton key="download" onClick={handleDownload} />]}
                />
            ) : (
                <h2 className="row"> Code </h2>
            )}
            <div className="deployed-artifact-code-body">
                <DeploymentFileBrowser />
                <DeployedArtifactFileContent />
            </div>
        </div>
    );
};

export default DeployedArtifactCode;
