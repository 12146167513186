import React from 'react';

import { artifactTypes } from '@hub-client-api';

import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { toDateTimeFormatDisplay } from '@hub-fe/common/Timing';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import {
    useEntityInfo,
    useIntegrationName,
    useLedgerActions,
    useLedgerContext,
} from '../LedgerContext';
import DeployedArtifactPermissionSelector from './DeployedArtifactPermissionSelector';

const DeployedArtifactSettings: React.FC = () => {
    const { artifactHash } = useServiceParams();
    const { getDeployedArtifactCreatedTime, getPublishedArtifactByHash } = useLedgerActions();
    const integrationName = useIntegrationName(artifactHash);
    const entityInfo = useEntityInfo(artifactHash, integrationName);
    const createdAt = getDeployedArtifactCreatedTime(artifactHash);
    const publishedDeployedArtifact = getPublishedArtifactByHash(artifactHash, integrationName);
    const { isLedgerOwner, ledger, serviceType } = useLedgerContext();

    if (!entityInfo) {
        return null;
    }

    const isAutomation = entityInfo.entity.tag === artifactTypes.AUTOMATION_ARTIFACT;
    const artifactDate = isLedgerOwner ? createdAt : publishedDeployedArtifact?.createdAt;
    const isLedger = serviceType === ServiceType.LEDGER;
    return (
        <div className="deployed-artifact-settings">
            <h2 className="row">Settings</h2>
            <div className="settings-tiles">
                <div className="settings-tile ">
                    <div className="deployment-info">
                        <CopyableDisplay label="Artifact Hash" value={entityInfo.artifactHash} />
                    </div>
                    {artifactDate && (
                        <div className="deployment-info">
                            <h4>Date Added:</h4>
                            <p className="p2">{formatArtifactDate(artifactDate)}</p>
                        </div>
                    )}
                    {!isLedgerOwner && (
                        <div className="deployment-info">
                            <h4>Deployment Owner:</h4>
                            <p className="p2">{publishedDeployedArtifact?.owner}</p>
                        </div>
                    )}
                </div>
                {isLedger && isAutomation && isLedgerOwner && ledger && (
                    <div className="settings-tile">
                        <DeployedArtifactPermissionSelector
                            isPublished={!!publishedDeployedArtifact}
                            deployedArtifact={entityInfo}
                            includeHeading
                            ledgerName={ledger?.info.name}
                            artifactHash={artifactHash}
                        />
                    </div>
                )}
            </div>
        </div>
    );

    function formatArtifactDate(deployedDate: string) {
        const date = new Date(deployedDate);
        return toDateTimeFormatDisplay(date);
    }
};

export default DeployedArtifactSettings;
