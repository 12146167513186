import React from 'react';

import { filterPartyOptions } from '@hub-fe/common/PartyDropdown';

import { ContractSearchPanel } from '../ContractSearchPanel';
import { useLedgerContext } from '../LedgerContext';
import { ContractSearchResult } from './ContractSearchResult';

interface SearchWrapperProps {
    displaySearchRes: boolean;
    toggle: () => void;
}
export const SearchWrapper: React.FC<SearchWrapperProps> = ({ toggle, displaySearchRes }) => {
    const { ownerParties = [], isLedgerOwner, defaultParties } = useLedgerContext();
    const parties = filterPartyOptions(ownerParties, defaultParties, false, !isLedgerOwner);

    return (
        <>
            <ContractSearchPanel
                showSearchRes={displaySearchRes}
                onSearchClick={toggle}
                parties={parties}
            />
            {displaySearchRes && <ContractSearchResult />}
        </>
    );
};
