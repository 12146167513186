import React from 'react';

import './ServiceTileTag.scss';

interface ServiceTileTagProps {
    tagLabel: string;
}
export const ServiceTileTag: React.FC<ServiceTileTagProps> = ({ tagLabel }) => {
    return (
        <div className="service-tile-tag">
            <p>{tagLabel}</p>
        </div>
    );
};
