import React from 'react';

import './SettingsTable.scss';

interface SettingsTableProps {
    data: { title: string; desc: string | React.ReactNode; actionElement: React.ReactNode }[];
}
export const SettingsTable: React.FC<SettingsTableProps> = ({ data }) => {
    const rows = data.map(row => (
        <div key={row.title} className="row">
            <div className="header">
                <h4>{row.title}</h4>
                <div className="action">{row.actionElement}</div>
            </div>
            <div className="desc">
                <p>{row.desc}</p>
            </div>
        </div>
    ));
    return <div className="settings-table">{rows}</div>;
};
