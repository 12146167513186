import React, { useState, useEffect } from 'react';
import { components, OptionProps, MultiValueRemoveProps, GroupBase } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { ILedgerData, IParticipantData, IScratchpadData } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import { IBaseSelectOption } from '@hub-fe/common/BaseSelect';
import { IconAddPlus, IconClose } from '@hub-fe/common/Icons';
import InformationBlock from '@hub-fe/common/InformationBlock';
import Modal, { ModalControls } from '@hub-fe/common/Modal';
import { ModalSingleSelect } from '@hub-fe/common/ModalSelect';
import Wizard, { WizardPage } from '@hub-fe/common/Wizard';
import { isNewService } from '@hub-fe/common/utils/isNewService';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import { ServiceType } from './ChooseServiceType';

interface IMultiLedgerActionModalProps {
    onRequestClose: () => void;
    ledgerId?: string;
    projectId?: string;
}

const ShareLedgerActionModal: React.FC<IMultiLedgerActionModalProps> = props => {
    const { onRequestClose, ledgerId, projectId } = props;
    const [selectedLedgerId, setSelectedLedgerId] = useState<string>();
    const [userIds, setUserIds] = useState<Set<IBaseSelectOption>>();
    const [isDropdownOpen, setDropdown] = React.useState(false);
    const toggleDropdown = () => {
        setDropdown(!isDropdownOpen);
    };
    const { activeLedgers, participants, scratchpads } = useAppContext();
    const { shareLedger, addCollaboratorForService } = useAppActions();
    const { service } = useServiceParams();
    useEffect(() => {
        if (ledgerId) {
            setSelectedLedgerId(ledgerId);
        }
    }, [ledgerId]);
    if (!service) {
        return null;
    }
    const services = {
        [ServiceType.LEDGER]: activeLedgers,
        [ServiceType.SCRATCHPAD]: scratchpads,
        [ServiceType.PARTICIPANT]: participants,
    };
    const ledger = services[service].find(
        (l: IParticipantData | IScratchpadData | ILedgerData) => l.info.id === selectedLedgerId
    );

    const onSubmit = async () => {
        if (selectedLedgerId && userIds) {
            if (isNewService(service)) {
                userIds.forEach(async user => {
                    await addCollaboratorForService({
                        serviceId: selectedLedgerId,
                        userId: user.value,
                        serviceType:
                            service === ServiceType.PARTICIPANT
                                ? ServiceType.PARTICIPANT
                                : ServiceType.SCRATCHPAD,
                    });
                });
            } else {
                shareLedger(
                    selectedLedgerId,
                    Array.from(userIds).map(({ value }) => value)
                );
            }
        }

        onRequestClose();
    };

    const modalProps = {
        onSubmit,
        className: 'share-ledger-action-modal',
        title: isNewService(service) ? `Share a Service` : `Share a Ledger`,
        show: true,
        onRequestClose,
        disableOnEnter: isDropdownOpen,
    };

    if (ledgerId && ledger) {
        return (
            <Modal {...modalProps}>
                <SelectUserIdsPage
                    ledgerName={ledger.info.name}
                    setUserIds={newUserIds => setUserIds(new Set(newUserIds))}
                    userIds={userIds}
                    ledger={ledger}
                    toggleDropdown={toggleDropdown}
                />
                <ModalControls>
                    <button disabled={userIds?.size === 0} onClick={onSubmit}>
                        Add Collaborators
                    </button>
                </ModalControls>
            </Modal>
        );
    }

    return (
        <Wizard {...modalProps}>
            <SelectLedgerPage
                selectedLedgerId={selectedLedgerId}
                setSelectedLedgerId={setSelectedLedgerId}
                projectId={projectId}
            />
            <WizardPage
                className="add-collaborator-page"
                pageLabel=""
                disabled={!userIds || userIds.size === 0}
            >
                <SelectUserIdsPage
                    ledgerName={ledger?.info.name || 'this ledger'}
                    setUserIds={setUserIds}
                    userIds={userIds}
                    ledger={ledger}
                />
            </WizardPage>
        </Wizard>
    );
};

const SelectLedgerPage: React.FC<{
    selectedLedgerId?: string;
    setSelectedLedgerId: (ledgerId?: string) => void;
    projectId?: string;
}> = ({ selectedLedgerId, setSelectedLedgerId, projectId }) => {
    const { activeLedgers } = useAppContext();

    const ledgerOptions = projectId
        ? activeLedgers
              .filter(l => l.info.projectId === projectId)
              .map(l => ({ id: l.info.id, name: l.info.name }))
        : activeLedgers.map(l => ({ id: l.info.id, name: l.info.name }));

    return (
        <WizardPage pageLabel="" disabled={!selectedLedgerId}>
            <p className="row">Select a ledger you would like to share with collaborators</p>
            <ModalSingleSelect
                items={ledgerOptions}
                selectedItem={selectedLedgerId}
                setSelectedItem={setSelectedLedgerId}
            />
        </WizardPage>
    );
};

enum VerificationStatusEnum {
    INVALID = 'invalid',
    VALID = 'valid',
}

export const SelectUserIdsPage: React.FC<{
    ledgerName: string;
    setUserIds: (ids?: Set<IBaseSelectOption>) => void;
    userIds?: Set<IBaseSelectOption>;
    toggleDropdown?: () => void;
    ledger?: ILedgerData | IParticipantData | IScratchpadData;
}> = ({ ledgerName, setUserIds, userIds, ledger, toggleDropdown }) => {
    const { ledgers, userInfo } = useAppContext();

    const { verifyUser } = useAppActions();
    const [latestUnverifiedID, setLatestUnverifiedID] = useState<{
        id: string;
        status: VerificationStatusEnum;
    }>();

    const allCollaborators = ledgers.flatMap(l => l.info.secondaryOwners);

    const Option = (props: OptionProps<any, boolean>): JSX.Element => {
        return (
            <components.Option {...props} className="collaborator-option">
                {props.data.__isNew__ ? (
                    <p className="caption collaborator-id icon-left add-collaborator">
                        <IconAddPlus /> Add ID:
                        <div className="text-background "> {props.data.value}</div>
                    </p>
                ) : (
                    <>
                        <p className="label p2">{props.data.label}</p>
                        <p className="p2 id">ID: </p>
                        <p className="caption collaborator-id text-background ellipsis">
                            {props.data.value}
                        </p>
                    </>
                )}
            </components.Option>
        );
    };

    const MultiValueRemove = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
        props: MultiValueRemoveProps<Option, IsMulti, Group>
    ) => {
        return (
            <components.MultiValueRemove {...props}>
                <IconClose />
            </components.MultiValueRemove>
        );
    };

    let options = allCollaborators.reduce((acc: IBaseSelectOption[], c) => {
        const option = {
            label: c.name,
            value: c.userId,
        };

        const alreadyInResult = acc.find(a => a.value === option.value);
        const alreadyCollaboratorOnLedger = ledger?.info.secondaryOwners.find(
            ({ userId }) => userId === c.userId
        );
        const isSelf = userInfo?.userId === c.userId;

        if (alreadyInResult || alreadyCollaboratorOnLedger || isSelf) {
            return acc;
        }

        return [...acc, option];
    }, []);

    options = options.sort((a, b) => (a.label < b.label ? -1 : 1));

    return (
        <div className="add-collaborator-page">
            <p className="row p2">
                Add one or more collaborators to <b>{ledgerName}</b> by providing their User ID or
                use the dropdown to select users you have collaborated with before.
            </p>
            <div className="collaborator-input">
                <div className="row">
                    <p>Collaborator User ID(s)</p>
                    <p className="caption"> User ID can be found in Account Settings.</p>
                </div>

                <CreatableSelect
                    className="creatable-selector focused react-select"
                    isClearable
                    value={Array.from(userIds || [])}
                    onChange={collaborators => handleNewCollaborators(new Set(collaborators))}
                    isMulti
                    onMenuOpen={toggleDropdown}
                    onMenuClose={toggleDropdown}
                    options={options}
                    placeholder="Select or input a User ID..."
                    components={{
                        Option,
                        MultiValueRemove,
                    }}
                />

                {latestUnverifiedID && (
                    <InformationBlock warning>
                        User with ID <b>{latestUnverifiedID?.id} </b> does not exist.
                    </InformationBlock>
                )}
            </div>
        </div>
    );

    async function handleNewCollaborators(collaborators: Set<IBaseSelectOption>) {
        setLatestUnverifiedID(undefined);
        const updatedCollaborators = new Set(collaborators);
        await Promise.all(
            Array.from(collaborators).map(async c => {
                const user = await verifyUser(c.value);
                const status = user ? VerificationStatusEnum.VALID : VerificationStatusEnum.INVALID;
                if (status === VerificationStatusEnum.INVALID) {
                    setLatestUnverifiedID({ id: c.value, status });
                    updatedCollaborators.delete(c);
                }
            })
        );

        setUserIds(updatedCollaborators);
    }
};

export default ShareLedgerActionModal;
