import React from 'react';

import { ILedgerData } from '@hub-client-api';

import { CopyButton } from '@hub-fe/common/ClipboardButtons';
import ExternalLink from '@hub-fe/common/ExternalLink';
import LedgerStatusDisplay from '@hub-fe/common/LedgerStatusDisplay';
import ToolTip from '@hub-fe/common/ToolTip';
import { start_and_end } from '@hub-fe/common/utils';

import { useLedgerContext } from './LedgerContext';

const LedgerSideBarInfo: React.FC<{ ledger: ILedgerData }> = ({ ledger }) => {
    const { eventCount, subdomains } = useLedgerContext();

    const domainLink = `https://${subdomains?.custom?.domain || subdomains?.default?.domain}`;

    return (
        <div className="ledger-side-bar-info">
            <div className="side-bar-info">
                <LedgerStatusDisplay status={ledger.objectStatus || ledger.info.status.ledger} />
            </div>
            <p className="p2 side-bar-info">
                Ledger ID: {ledger?.info.id} <CopyButton ghost content={ledger?.info.id} />
            </p>
            <div className="side-bar-info">
                <p className="p2">Ledger Events: {eventCount} </p>
                <ToolTip
                    caption={
                        'Ledger Events are the incremental state changes that occur in your application as your users take actions. These events are polled every hour.'
                    }
                />
            </div>

            <p className="p2 side-bar-info subdomain-link">
                Subdomain:{' '}
                <ExternalLink icon to={domainLink}>
                    <span> {start_and_end(domainLink.replace(`https://`, ''))}</span>
                </ExternalLink>
            </p>
        </div>
    );
};

export default LedgerSideBarInfo;
