import React, { useMemo } from 'react';

import {
    IAutomationConfigValueInfo,
    IDeployedArtifactInstanceInfo,
    ObjectStatus,
} from '@hub-client-api';

import { LoadingDots } from '@hub-fe/common/Animations/Animations';
import Banner from '@hub-fe/common/Banner';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { IconBulb, IconSidebarUser } from '@hub-fe/common/Icons';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import { IReactTableRow, EMPTY_HEADER } from '@hub-fe/common/ReactTable/ReactTableUtils';
import { NavLink, Route, Router } from '@hub-fe/common/routing';
import { getDeployedArtifactFileName } from '@hub-fe/common/utils';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import {
    AUTOMATION_USER_FIELD,
    useIntegrationName,
    useLedgerActions,
    useLedgerContext,
} from '../LedgerContext';
import DeployedArtifactInstanceStateIndicator, {
    getStatusLabel,
} from './DeployedArtifactInstanceStateIndicator';
import DeploymentInstanceDetails from './DeploymentInstanceDetails';
import DeploymentInstanceTableActions from './DeploymentInstanceTableActions';

function getDeploymentInstanceParty(instance: IDeployedArtifactInstanceInfo) {
    const automationConfig = instance?.config?.value as IAutomationConfigValueInfo;
    if (automationConfig?.runAs == 'user') {
        return automationConfig.configMap[AUTOMATION_USER_FIELD];
    } else {
        return automationConfig?.runAs;
    }
}

const DeploymentInstanceTableBody: React.FC = () => {
    const {
        ledgerId,
        isLedgerOwner,
        deployedArtifactInstances,
        ledgerIntegrations,
        ownerParties,
        userParties,
        ledger,
    } = useLedgerContext();
    const ledgerIsStarting = ledger?.info.status.ledger === ObjectStatus.STARTING;

    const { deploymentId, artifactHash, service } = useServiceParams();
    const { getDeployedArtifactInstancesByHash, getDeployedArtifactStates } = useLedgerActions();

    const integrationName = useIntegrationName(artifactHash);

    const instances = useMemo(() => {
        const ownedInstances = getDeployedArtifactInstancesByHash(artifactHash, integrationName);
        return isLedgerOwner ? ownedInstances : deployedArtifactInstances || [];
    }, [deployedArtifactInstances, artifactHash, integrationName]);

    const isTrigger = (instances[0]?.config.value as IAutomationConfigValueInfo)?.name;

    const tableHeaders = [
        { label: 'Instance ID' },
        { label: isLedgerOwner ? (isTrigger ? 'Trigger Label' : 'Label') : 'File Name' },
        integrationName ? EMPTY_HEADER : { label: 'Running As' },
        { label: 'Status' },
    ];

    const tableRows = useMemo(
        () => instances.map(instance => createInsanceRow(instance)),
        [instances, ownerParties, userParties]
    );

    const emptyTableContent = isLedgerOwner ? (
        <p className="p2">
            There are no instances of this deployment yet. Click{' '}
            <NavLink to="../configure" className="inline-link">
                here
            </NavLink>{' '}
            to configure a new instance.
        </p>
    ) : (
        <p className="p2">You have not deployed bots or integrations to this ledger.</p>
    );

    const selectedActionsRenderer = (selectedRows: number[] = []) => {
        const newSelectedInstances = tableRows.reduce(
            (acc: IDeployedArtifactInstanceInfo[], row, i) => {
                if (selectedRows.includes(i)) {
                    const instance = deployedArtifactInstances?.find(
                        ({ id }) => id === row.rowData[0].sortKey
                    );
                    if (instance) {
                        return [...acc, instance];
                    }
                }
                return acc;
            },
            []
        );
        return <DeploymentInstanceTableActions instances={newSelectedInstances} />;
    };
    const allParties = [...(userParties || []), ...(ownerParties || [])];
    const hasParties = !!allParties.length;
    const tableIsLoading = !deployedArtifactInstances || (isLedgerOwner && !hasParties);
    const instanceTable = useMemo(
        () => (
            <ReactTable
                tableHeaders={tableHeaders}
                tableRows={tableRows}
                isSelectable
                loading={tableIsLoading}
                selectedActionsRenderer={selectedActionsRenderer}
                emptyTableContent={emptyTableContent}
                expanderLabel={{ expanded: 'Hide Details', closed: 'View Details' }}
                expanderPosition="last"
                onlyOneExpanded
                defaultExpandedRow={tableRows.find(row => row.rowData[0].sortKey === deploymentId)}
            />
        ),
        [tableHeaders, tableRows, deployedArtifactInstances]
    );
    return (
        <div className="deployment-instance-table">
            {isLedgerOwner ? (
                <h2 className="row">{!isLedgerOwner && 'Your'} Instances</h2>
            ) : (
                <h4>{!isLedgerOwner && 'Your'} Instances</h4>
            )}
            {instances.length > 0 && isLedgerOwner && (
                <p className="row p2">
                    Expand an instance of this deployment to view its logs and status. Select one or
                    more to <b>turn on</b>, <b>turn off</b> or <b>un-deploy</b>.
                </p>
            )}
            {!ledgerIntegrations?.datadog && (
                <Banner
                    bannerName="logging-info1"
                    dismissible
                    className="datadog-tip-banner"
                    header="Daml Hub Tip"
                    headerIcon={<IconBulb />}
                    message={
                        <p className="p2">
                            For finer-grained logs consider setting up a{' '}
                            <NavLink
                                className="ghost"
                                to={`/console/${service}/${ledgerId}/integrations`}
                            >
                                Datadog Integration
                            </NavLink>
                            .
                        </p>
                    }
                />
            )}
            {instanceTable}
        </div>
    );

    function getPartyDisplayName(instanceParty: string): string {
        if (isLedgerOwner) {
            const parties = [...(userParties || []), ...(ownerParties || [])];
            if (!integrationName) {
                return (
                    parties.find(({ identifier }) => identifier === instanceParty)?.displayName ||
                    instanceParty
                );
            }
            return '';
        }
        return 'You';
    }

    function createInsanceRow(instance: IDeployedArtifactInstanceInfo): IReactTableRow {
        const urlStart = `/console/${service}/${ledgerId}/deployments`;
        const urlEnd = `${integrationName ? `/name/${integrationName}` : ''}/instances`;
        const baseUrl = isLedgerOwner
            ? `${urlStart}/view/${artifactHash}${urlEnd}`
            : `${urlStart}/view/${instance.entityInfo.artifactHash}${urlEnd}`;

        const instanceParty = getDeploymentInstanceParty(instance);
        const runningAsUserParty = !!userParties?.find(p => p.identifier === instanceParty);
        const canViewLogs = isLedgerOwner ? !runningAsUserParty : true;
        const expanded = instance.id === deploymentId;
        const rowLink = `${baseUrl}/${instance.id}/${canViewLogs ? 'logs' : 'status'}`;
        return {
            rowData: tableHeaders.map(cellType => {
                switch (cellType.label) {
                    case 'Instance ID':
                        return {
                            sortKey: instance.id,
                            renderAs: <CopyableDisplay value={instance.id} />,
                        };
                    case 'Trigger Label':
                        const triggerName = instance.instanceLabel;
                        return {
                            sortKey: triggerName,
                            displayNarrowViewHeader: true,
                        };
                    case 'Label':
                        return {
                            sortKey: instance.instanceLabel,
                            displayNarrowViewHeader: true,
                        };
                    case 'File Name':
                        const fileName = getDeployedArtifactFileName(
                            instance.entityInfo.entity,
                            true
                        );
                        return {
                            sortKey: fileName,
                            displayNarrowViewHeader: true,
                        };
                    case '':
                        return {
                            sortKey: '',
                        };
                    case 'Running As':
                        const instancePartyName = getPartyDisplayName(instanceParty);
                        return {
                            sortKey: instancePartyName,
                            renderAs:
                                !instancePartyName && ledgerIsStarting ? (
                                    <div className="loading-parties">
                                        <p className="p2">Loading Parties</p>
                                        <LoadingDots />
                                    </div>
                                ) : runningAsUserParty ? (
                                    <UserPartyItem label={instancePartyName} />
                                ) : undefined,
                            displayNarrowViewHeader: true,
                        };
                    case 'Status':
                        const artifactDeploymentStates = getDeployedArtifactStates(instances);
                        const label = getStatusLabel(artifactDeploymentStates);

                        return {
                            sortKey: label,
                            renderAs: (
                                <DeployedArtifactInstanceStateIndicator instances={[instance]} />
                            ),
                        };
                    default:
                        return { sortKey: '' };
                }
            }),
            url: expanded ? (isLedgerOwner ? baseUrl : urlStart) : rowLink,
            subRows: [
                {
                    rowData: [],
                    renderRowAs: (
                        <DeploymentInstanceDetails canViewLogs={canViewLogs} instance={instance} />
                    ),
                },
            ],
        };
    }
};

const UserPartyItem = (props: { label: string }) => (
    <div className="item-name icon-left">
        <IconSidebarUser /> <p className="p2">{props.label}</p>
    </div>
);

const DeploymentInstanceTable: React.FC = () => {
    return (
        <Router>
            <Route path=":deploymentId/*" render={() => <DeploymentInstanceTableBody />} />
            <Route default render={() => <DeploymentInstanceTableBody />} />
        </Router>
    );
};

export default DeploymentInstanceTable;
