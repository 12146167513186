import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import { getActiveServices } from '@hub-fe/common/utils/getActiveServices';
import { getConnectionsGraph } from '@hub-fe/common/utils/getConnectionsGraph';
import { NoConnectionTile } from '@hub-fe/synchronizer/NoConnectionTile';
import { NoParticipantsTile } from '@hub-fe/synchronizer/NoParticipantsTile';

import './ConnectionsTab.scss';
import { ParticipantNodeTile } from './ParticipantNodeTile';
import { SynchronizerTile } from './SynchronizerTile';

export const ConnectionsTab: React.FC = () => {
    const { synchronizers, participants, tempParticipants, tempSynchronizers } = useAppContext();
    const activeSynchronizers = getActiveServices(synchronizers);
    const activeParticipants = getActiveServices(participants);
    const graph = getConnectionsGraph({
        synchronizers: [...activeSynchronizers, ...Object.values(tempSynchronizers || [])],
        participants: [...activeParticipants, ...Object.values(tempParticipants || [])],
    });

    const renderGrid = () => {
        const renderedElements = [];

        for (const domainId in graph.internal) {
            const domain = [...synchronizers, ...Object.values(tempSynchronizers || [])].find(
                synchronizer => domainId === synchronizer.info.id
            );
            renderedElements.push(
                <div key={domain?.info.id} className="grid-row">
                    <div className="synchronizers-col">
                        {domain ? (
                            <SynchronizerTile
                                url={domain.info.hostname}
                                key={domain.info.id}
                                name={domain.info.name}
                                isProd={domain.info.production}
                                id={domain.info.id}
                                status={
                                    domain.objectStatus ||
                                    domain.info.status.ledger ||
                                    ObjectStatus.STARTING
                                }
                            />
                        ) : (
                            <NoConnectionTile />
                        )}
                    </div>
                    <div className="participant-col">
                        {graph.internal[domainId].length < 1 && <NoParticipantsTile />}
                        {graph.internal[domainId].map(p => (
                            <ParticipantNodeTile
                                key={p.info.id}
                                name={p.info.name}
                                isProd={p.info.production}
                                serviceId={p.info.id}
                                participantId={p.info.participantId}
                                status={p.objectStatus || p.info.status.ledger}
                                capacity={p.info.participantSize}
                                isSharedService={!!p.info.secondaryOwners.length}
                            />
                        ))}
                    </div>
                </div>
            );
        }
        for (const hostUrl in graph.external) {
            renderedElements.push(
                <div key={hostUrl} className="grid-row">
                    <div className="synchronizers-col">
                        <SynchronizerTile
                            name={hostUrl}
                            id={hostUrl}
                            url={hostUrl}
                            status={ObjectStatus.INACTIVE}
                            isExternalDomain
                        />
                    </div>
                    <div className="participant-col">
                        {graph.external[hostUrl].length < 1 && <NoParticipantsTile />}
                        {graph.external[hostUrl].map(p => (
                            <ParticipantNodeTile
                                key={p.info.id}
                                name={p.info.name}
                                participantId={p.info.participantId}
                                serviceId={p.info.id}
                                isProd={p.info.production}
                                status={p.objectStatus || p.info.status.ledger}
                                capacity={p.info.participantSize}
                                isSharedService={!!p.info.secondaryOwners.length}
                            />
                        ))}
                    </div>
                </div>
            );
        }
        if (graph.noConnection.length > 0) {
            renderedElements.push(
                <div key={'noElement'} className="grid-row">
                    <div className="synchronizers-col">
                        <NoConnectionTile />
                    </div>
                    <div className="participant-col">
                        {graph.noConnection.map(p => (
                            <ParticipantNodeTile
                                key={p.info.id}
                                name={p.info.name}
                                participantId={p.info.participantId}
                                serviceId={p.info.id}
                                isProd={p.info.production}
                                status={p.objectStatus || p.info.status.ledger}
                                capacity={p.info.participantSize}
                                isSharedService={!!p.info.secondaryOwners.length}
                            />
                        ))}
                    </div>
                </div>
            );
        }

        return renderedElements;
    };

    return (
        <>
            <div className="connections-table">
                <div className="grid-row">
                    <div className="synchronizers-col">
                        <div className="col-header">
                            <h2>Synchronizers</h2>
                        </div>
                    </div>
                    <div className="participant-col">
                        <div className="col-header">
                            <h2>Participants</h2>
                        </div>
                    </div>
                </div>
                {renderGrid()}
            </div>
        </>
    );
};
