import { isEqual } from 'lodash';
import { useEffect, useReducer, useState } from 'react';

import {
  dalOpenLedgerTransactionStream,
  IContractInfo,
  ILedgerEvent,
  ILedgerTransactionInfo,
  isArchivedEvent,
  isCreatedEvent,
  ITemplateInfo,
  ITransactionStreamCloseFn,
} from '@hub-client-api';

import { Logger } from '@hub-fe/common/Log';

const LOG = Logger('contract-stream');

export type TimedContractInfo = IContractInfo & {
  seenAt?: number;
};

export interface ContractStreamState {
  loading: boolean;
  contracts: TimedContractInfo[];
  offset: string;
  lastTransaction?: ILedgerTransactionInfo;
  templateIds: ITemplateInfo[];
}

type ContractStreamStateByParty = {
  [party: string]: ContractStreamState;
};

function getPartyState(
  statesByParty: ContractStreamStateByParty,
  templateIds: ITemplateInfo[],
  party?: string
): ContractStreamState {
  const candidateStreamState = party && statesByParty[party];

  /* The state for a given party is contingent on the templates used
   * for the query. The easiest way to see this is to consider the
   * case when a query for a party is broadened to include more
   * templates. In this case, the query has to be restarted from the
   * beginning to capture the contracts of the new templates added
   * might have been created before the current offset for the stream.
   */
  if (candidateStreamState && isEqual(candidateStreamState.templateIds, templateIds)) {
    return candidateStreamState;
  }

  return {
    loading: true,
    contracts: [],
    offset: '',
    templateIds,
  };
}

const updatePartyState = (
  partyState: ContractStreamState,
  event: ILedgerEvent
): ContractStreamState => {
  if (isCreatedEvent(event)) {
    if (partyState.contracts.find(c => c.contractId === event.created.contractId)) {
      return partyState;
    } else {
      const newContract = {
        ...event.created,
        seenAt: partyState.offset === '' ? undefined : Date.now(),
      };

      return {
        ...partyState,
        contracts: [newContract, ...partyState.contracts],
      };
    }
  } else if (isArchivedEvent(event)) {
    return {
      ...partyState,
      contracts: partyState.contracts.filter(
        contract => contract.contractId !== event.archived.contractId
      ),
    };
  }

  return partyState;
};

const queryReducer = (
  statesByParty: ContractStreamStateByParty,
  transaction: ILedgerTransactionInfo
): ContractStreamStateByParty => {
  const { party, events, offset, templateIds } = transaction;

  const partyStream = { ...getPartyState(statesByParty, templateIds, party) };

  /* Heartbeat transaction messages - messages with no events -
   * should be ignored unless they are the event that signals the
   * stream has fully loaded. Empty transaction that do not meet
   * that category are thus handled by returning the same statesByParty
   * map, which causes Redux to consider that nothing needs to be updated.
   *
   * The initial startup message is determined by the presence of an offset
   * in the transaction message and the stream for the party being in a
   * loading state.
   */
  if (events?.length === 0 && !(offset && partyStream.loading)) {
    return statesByParty;
  }

  if (offset) {
    partyStream.offset = offset;
    partyStream.loading = false;
  }

  if (transaction?.events?.length > 0) {
    partyStream.lastTransaction = transaction;
  }

  return {
    ...statesByParty,
    [party]: (events || []).reduce(updatePartyState, partyStream),
  };
};

function useContractStream(
  ledgerId?: string,
  party?: string,
  templates?: ITemplateInfo[],
  disable?: boolean
): ContractStreamState {
  const [statesByParty, updateWithTransaction] = useReducer(queryReducer, {});
  const [cachedState, setCachedState] = useState<ContractStreamStateByParty>();
  useEffect(() => {
    if (disable) {
      return;
    }
    if (ledgerId) {
      let cache: { ledgerId?: string; state?: ContractStreamStateByParty } = {};

      Object.entries(statesByParty).forEach(([party, state]) => {
        cache = {
          ledgerId,
          state: {
            [party]: state,
          },
        };
      });
      setCachedState(cache.state);
    }
  }, [ledgerId, statesByParty, disable]);

  useEffect(() => {
    if (disable) {
      return;
    }
    let closer: ITransactionStreamCloseFn | undefined;

    if (ledgerId && party && templates?.length) {
      LOG.debug(`Open stream, n=${templates?.length}`);
      closer = dalOpenLedgerTransactionStream(
        ledgerId,
        party,
        templates,
        undefined,
        updateWithTransaction
      );
    }

    return () => {
      if (closer) {
        LOG.debug(`Close stream, n=${templates?.length}`);
        closer();
      }
    };
  }, [ledgerId, party, templates, disable]);

  const result = Object.assign(
    {},
    getPartyState(statesByParty, templates || [], party),
    cachedState && party ? cachedState[party] : {}
  );

  return result;
}

export default useContractStream;
