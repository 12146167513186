import { FieldProps, ErrorSchema } from '@rjsf/utils';
import { JSONSchema7 as JsonSchema } from 'json-schema';
import React, { useState, useEffect } from 'react';

import BaseSelect from '@hub-fe/common/BaseSelect';
import CheckedButton from '@hub-fe/common/CheckedButton';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import ExternalLink from '@hub-fe/common/ExternalLink';
import { PrimTypeEnum, DamlEnumType } from '@hub-fe/common/TemplateFieldsInterfaces';
import { appendTimeToDate, toDateFormatted, toDateTimeFormatted } from '@hub-fe/common/Timing';
import { support, docs } from '@hub-fe/common/urls';

import ContractTemplatePartyDropdown from './ContractTemplatePartyDropdown';
import { checkIsUnsupportedType, UNSUPPORTED_TYPE } from './ContractTemplateSchema';

/*
 * BooleanField:
 * Bool = true or false
 */
export const BooleanField: React.FC<FieldProps> = ({ formData, onChange }) => (
    <div>
        <CheckedButton checked={formData === true} onClick={() => onChange(true)}>
            True
        </CheckedButton>
        <CheckedButton checked={formData === false} onClick={() => onChange(false)}>
            False
        </CheckedButton>
    </div>
);

/*
 * NumberField:
 * Int       = Signed 64-bit integers
 * Decimal   = Fixed-point number with 28 digits before and 10 digits after the decimal point (a synonym for Numeric 10)
 * Numeric n = Fixed-point number with 28 digits before and up to 38 digits after the decimal point. The parameter n controls
 *             the number of digits after the decimal point.
 */
export const NumberField: React.FC<FieldProps> = ({ formData, onChange, schema }) => (
    <input
        type="number"
        step="any"
        value={formData || ''}
        placeholder={schema.description}
        pattern={schema.pattern}
        onChange={({ currentTarget: { value } }) => onChange(value)}
    />
);

/*
 * StringField:
 * Date        = Any date between 0001-01-01 and 9999-12-31 (using a year-month-day format).
 * Time        = A valid date and absolute time in UTC format.
 * Text        = String of characters
 * RelTime     = Difference between two times; created using days, hours, minutes, seconds, miliseconds and microseconds
 * ContractId  = An ID for a contract created from a template a
 * Party       = A party to a contract that exists on the ledger
 * Enum        = A list of text items to choose from
 * Unsupported = A a type that that this UI does not recognize and therefore does not (yet) support
 */
export const StringField: React.FC<FieldProps> = ({ onChange, formData, schema }) => {
    if (checkIsUnsupportedType(schema)) {
        return <UnsupportedField schema={schema} onChange={onChange} formData={formData} />;
    }

    const damlType = schema.description;

    switch (damlType) {
        case DamlEnumType:
            const options = schema.enum?.map(k => ({
                value: k,
                label: k,
            }));
            return (
                <BaseSelect
                    onChange={val => onChange(val?.value)}
                    options={options}
                    placeholder="Select a variant..."
                    value={formData ? { value: formData, label: formData } : undefined}
                />
            );
        case PrimTypeEnum.PARTY:
            return <ContractTemplatePartyDropdown onChange={onChange} selectedParty={formData} />;
        case PrimTypeEnum.DATE:
            return (
                <BasicTextInput
                    type="date"
                    formData={formData}
                    onChange={val => onChange(toDateFormatted(new Date(val)))}
                    placeholder={damlType}
                />
            );
        case PrimTypeEnum.TIME:
            return <DateTimeInput handleChange={onChange} />;
        default:
            return (
                <BasicTextInput formData={formData} onChange={onChange} placeholder={damlType} />
            );
    }
};

const UnsupportedField: React.FC<{
    schema: JsonSchema;
    onChange: (e: any, es?: ErrorSchema | undefined) => any;
    formData: any;
}> = ({ schema, onChange, formData }) => (
    <div className="unsupported-type">
        {schema.description && (
            <div className="type-details">
                <p className="caption unsupported"> Unsupported Daml Type:</p>
                <CopyableDisplay
                    value={schema.description.split(`${UNSUPPORTED_TYPE}:`)[1]}
                    isCode
                />
            </div>
        )}
        <p className="caption">
            This Daml type is not yet supported by the Daml Hub UI. To request support, features or
            report bugs, post on the
            <ExternalLink className="inline-link" to={support}>
                Daml Forum
            </ExternalLink>
            . To view a list of supported Daml Types, visit the{' '}
            <ExternalLink className="inline-link" to={docs + `/quickstart#live-data`}>
                docs
            </ExternalLink>
            .
        </p>
        <p className="caption">
            If you would like to proceed, fill in the type's value using the text field below and
            accepted syntax.
        </p>
        <BasicTextInput
            type="text"
            formData={formData}
            onChange={newData => onChange(newData)}
            placeholder={UNSUPPORTED_TYPE}
        />
    </div>
);

const DateTimeInput: React.FC<{
    handleChange: (dateTime: string) => void;
}> = ({ handleChange }) => {
    const [time, setTime] = useState<string>();
    const [date, setDate] = useState<string>();

    useEffect(() => {
        if (date && time) {
            const datetime = appendTimeToDate(new Date(date), time);
            handleChange(toDateTimeFormatted(datetime));
        }
    }, [date, time]);

    return (
        <div className="date-time">
            <BasicTextInput type="date" formData={date} onChange={setDate} />
            <p className="p2 at">at</p>
            <BasicTextInput type="time" formData={time} onChange={setTime} />
        </div>
    );
};

const BasicTextInput: React.FC<{
    formData?: string;
    placeholder?: string;
    onChange: (val: string) => void;
    type?: 'text' | 'date' | 'time';
}> = ({ formData, placeholder, onChange, type = 'text' }) => (
    <input
        type={type}
        value={formData || ''}
        placeholder={placeholder}
        onChange={({ currentTarget: { value } }) => onChange(value)}
    />
);
