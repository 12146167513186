import React, { useState } from 'react';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import { DeployFileWizardNew } from '@hub-fe/common/DeployFileWizardNew';
import { itemListAsText } from '@hub-fe/common/utils';

import DeployFileWizard from './DeployFileWizard';
import RenameFileModal from './RenameFileModal';

type ICollectionModalOpen = 'rename' | 'delete' | 'deploy' | undefined;

const CollectionFileMenu: React.FC<{
    fileNames: string[];
    allSelected: boolean;
    onFileMenuActionComplete: () => void;
    numberSelected: number;
}> = props => {
    const [modalOpen, setModalOpen] = useState(undefined as ICollectionModalOpen);
    const { deleteWorkspaceFile, downloadWorkspaceFile } = useAppActions();
    const { isConnectUiEnabled } = useAppContext();

    const { fileNames, numberSelected } = props;
    const atLeastOne = fileNames.length >= 1;
    const multiple = fileNames.length > 1;

    function completeFileMenuAction(actionCompleted: boolean) {
        setModalOpen(undefined);

        if (actionCompleted) {
            props.onFileMenuActionComplete();
        }
    }

    function handleDownload() {
        fileNames.forEach(fileName => downloadWorkspaceFile(fileName));
        completeFileMenuAction(true);
    }

    function handleDeleteFiles() {
        fileNames.forEach(fileName => deleteWorkspaceFile(fileName));
        completeFileMenuAction(true);
    }

    if (!atLeastOne) {
        return null;
    }

    return (
        <>
            <div className="collection-file-menu">
                <p className="p2">{numberSelected} selected</p>
                <div className="actions">
                    {!multiple && (
                        <button
                            className="secondary-smaller  p2"
                            onClick={() => setModalOpen('rename')}
                        >
                            Rename
                        </button>
                    )}
                    <button className="secondary-smaller p2" onClick={() => setModalOpen('deploy')}>
                        Deploy to...
                    </button>
                    <button className="secondary-smaller  p2" onClick={() => handleDownload()}>
                        Download
                    </button>
                    <button
                        className="secondary-smaller  p2"
                        onClick={() => setModalOpen('delete')}
                    >
                        Delete
                    </button>
                </div>
            </div>
            {modalOpen === 'rename' && (
                <RenameFileModal
                    originalFileName={fileNames[0]}
                    onRequestClose={renameRequested => completeFileMenuAction(renameRequested)}
                />
            )}
            <ConfirmActionModal
                show={modalOpen === 'delete'}
                title={`Delete File${multiple ? 's' : ''}`}
                bodyMessage={'Are you sure you want to delete ' + itemListAsText(fileNames) + '?'}
                action={`Delete File${multiple ? 's' : ''}`}
                infoMessage={
                    multiple
                        ? 'Deleting these files will remove them from your Collection, but they will remain deployed within projects if they have already been deployed.'
                        : 'Deleting this file will remove it from your Collection, but it will remain deployed within projects if it has already been deployed.'
                }
                deletingAction
                onRequestClose={() => completeFileMenuAction(false)}
                onSubmitAction={() => handleDeleteFiles()}
            />
            {modalOpen === 'deploy' &&
                (isConnectUiEnabled ? (
                    <DeployFileWizardNew
                        fileNames={fileNames}
                        onRequestClose={deployRequested => completeFileMenuAction(deployRequested)}
                    />
                ) : (
                    <DeployFileWizard
                        fileNames={fileNames}
                        onRequestClose={deployRequested => completeFileMenuAction(deployRequested)}
                    />
                ))}
        </>
    );
};

export default CollectionFileMenu;
