import { useLocation } from '@reach/router';
import React, { useEffect, useMemo } from 'react';

import { artifactTypes, automationTypes, IArtifactFileInfo } from '@hub-client-api';

import BaseSelect from '@hub-fe/common/BaseSelect';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { IconRightArrow } from '@hub-fe/common/Icons';
import { NavLink } from '@hub-fe/common/routing';
import { getEntityType, scrollToTop } from '@hub-fe/common/utils';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import { useEntityInfo, useLedgerActions } from '../LedgerContext';

const DeployedArtifactFileBrowser: React.FC = () => {
    const { artifactHash } = useServiceParams();
    const { getArtifactByHash } = useLedgerActions();
    const entityInfo = useEntityInfo(artifactHash);
    const location = useLocation();
    const files = getArtifactByHash(artifactHash)?.files;
    const type = entityInfo ? getEntityType(entityInfo.entity) : undefined;

    const firstFilePath = useMemo(() => {
        let file;

        if (type === automationTypes.BOT_ARTIFACT) file = files?.find(m => m.kind === 'python');

        if (type === automationTypes.TRIGGER_ARTIFACT || type === artifactTypes.MODEL_ARTIFACT)
            file = files?.find(m => m.kind === 'file');

        return file ? file.package_name + '/' + file.file_path : '';
    }, [files, type]);

    useEffect(() => {
        scrollToTop();
        const isOnArtifactUrl = window.location.pathname.split('/').slice(8).length > 0;

        if (firstFilePath && !isOnArtifactUrl) {
            history.push(firstFilePath);
        }
    }, [firstFilePath]);

    if (!files || !entityInfo) {
        return null;
    }

    const selectOptions = files
        .filter(f => f.kind === 'python' || f.kind === 'file')
        .map(f => {
            return { label: f.name, value: findFileUrl(f) };
        });

    return (
        <>
            <div className="deployed-artifact-file-browser">
                <h4> Files </h4>
                <div className="file-browser">{files.map(entry => makeFileItems(entry))}</div>
            </div>
            <h4 className="select-label"> Files </h4>
            <BaseSelect
                isSearchable={false}
                value={selectOptions.find(o => o.value === location.pathname)}
                className="select artifact-file-select"
                options={selectOptions}
                onChange={(val: any) => history.push(val.value)}
            />
        </>
    );

    function makeFileItems(file: IArtifactFileInfo) {
        const key = file.file_path + '-' + file.kind + '-' + file.name + '-' + file.package_name;
        if (file.kind !== 'python' && file.kind !== 'file') {
            return;
        }

        return (
            <NavLink
                key={key}
                to={findFileUrl(file)}
                className="field-name"
                activeClassName="active"
            >
                {file.name}
                <IconRightArrow />
            </NavLink>
        );
    }

    function findFileUrl(file: IArtifactFileInfo) {
        const base = location.pathname.split('/').slice(0, 8).join('/');
        return `${base}/${file.package_name}/${file.file_path}`;
    }
};

export default DeployedArtifactFileBrowser;
