import classNames from 'classnames';
import React, { useState } from 'react';

import { IProjectData, ObjectStatus } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import { InfiniteLoadingWheel } from '@hub-fe/common/Animations/Animations';
import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { IconAddPlus, IconCog, IconEdit } from '@hub-fe/common/Icons';
import PopupMenu, { PopupMenuEntry } from '@hub-fe/common/PopupMenu';
import RenameModal from '@hub-fe/common/RenameModal';
import { NavLink } from '@hub-fe/common/routing';
import { partitionArray } from '@hub-fe/common/utils';

import MultiLedgerActionModal from './MultiLedgerActionModal';
import ProjectLedger from './ProjectLedger';
import ShareLedgerActionModal from './ShareLedgerActionModal';

export type IProjectModalAction =
    | 'delete-project'
    | 'delete-ledgers'
    | 'pause-ledgers'
    | 'move-ledgers'
    | 'resume-ledgers'
    | 'rename-project'
    | 'share-ledger'
    | undefined;

const multiLedgerActions: IProjectModalAction[] = [
    'delete-ledgers',
    'pause-ledgers',
    'resume-ledgers',
    'move-ledgers',
];

const Project: React.FC<{ project: IProjectData }> = props => {
    const [modalOpen, setModalOpen] = useState(undefined as IProjectModalAction);
    const { project } = props;
    const { activeLedgers, projects = [], isProTier } = useAppContext();
    const ledgers = activeLedgers.filter(l => l.info.projectId === props.project.info.id);
    const { deleteProject, renameProject } = useAppActions();
    const deleting = project.objectStatus === ObjectStatus.DELETING;
    const [paused, running] = partitionArray(
        l =>
            l.info.status.ledger === ObjectStatus.PAUSED ||
            l.info.status.ledger === ObjectStatus.PAUSING,
        ledgers.filter(li => li.info.status.ledger !== ObjectStatus.SUSPENDED)
    );

    const newLedgerGhost = (
        <NavLink
            className="button ghost icon-left"
            to={`/console/projects/create-ledger/${project.info.id}`}
        >
            <IconAddPlus /> New Ledger
        </NavLink>
    );

    function handleDeleteProject() {
        deleteProject(project);
        setModalOpen(undefined);
    }

    if (project.objectStatus === ObjectStatus.LOADING) {
        return (
            <LoadingProjectTile
                className="creating"
                content={`Creating Project: ${project.info.name}...`}
            />
        );
    }

    if (!ledgers) {
        return <LoadingProjectTile content={`Loading ${project.info.name}...`} />;
    }

    if (deleting) {
        return <LoadingProjectTile content={`Deleting: ${project.info.name}`} />;
    }

    return (
        <div className="project tile" id={project.info.name}>
            <div className="project-header">
                <h3 className="project-name">
                    {project.info.name}
                    <button
                        className="rename-project ghost"
                        onClick={() => setModalOpen('rename-project')}
                    >
                        <IconEdit />
                    </button>
                </h3>
                <button
                    className="button secondary-smaller icon-left add-ledger-button"
                    onClick={() =>
                        history.push(`/console/projects/create-ledger/${project.info.id}`)
                    }
                >
                    <IconAddPlus />
                    <div className="button-label">Add Ledger</div>
                </button>
                <PopupMenu
                    title={
                        <button className="ghost icon-left">
                            <IconCog />
                            Options
                        </button>
                    }
                >
                    <PopupMenuEntry
                        label="Create a new ledger"
                        onClick={() =>
                            history.push(`/console/projects/create-ledger/${project.info.id}`)
                        }
                    />
                    {ledgers.length > 0 && (
                        <PopupMenuEntry
                            label="Delete ledgers"
                            onClick={() => setModalOpen('delete-ledgers')}
                        />
                    )}
                    {ledgers.length > 0 && (
                        <PopupMenuEntry
                            label="Move ledgers"
                            onClick={() => setModalOpen('move-ledgers')}
                        />
                    )}
                    {running.length > 0 && (
                        <PopupMenuEntry
                            label="Pause ledgers"
                            onClick={() => setModalOpen('pause-ledgers')}
                        />
                    )}
                    {paused.length > 0 && (
                        <PopupMenuEntry
                            label="Resume ledgers"
                            onClick={() => setModalOpen('resume-ledgers')}
                        />
                    )}
                    {isProTier && (
                        <PopupMenuEntry
                            label="Share Ledger"
                            onClick={() => setModalOpen('share-ledger')}
                        />
                    )}
                    <PopupMenuEntry
                        label="Delete project"
                        onClick={() => setModalOpen('delete-project')}
                    />
                </PopupMenu>
            </div>
            <div className="project-ledgers">
                {ledgers.length === 0 ? (
                    <div className="empty-project">
                        <p className="p2">This project is empty.</p>
                        {newLedgerGhost}
                    </div>
                ) : (
                    <>
                        {ledgers.length > 0 && (
                            <div className="tile-grid">
                                {ledgers.map(l => (
                                    <ProjectLedger key={l.info.id} ledger={l} />
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
            {modalOpen === 'share-ledger' && (
                <ShareLedgerActionModal
                    onRequestClose={() => setModalOpen(undefined)}
                    projectId={project.info.id}
                />
            )}
            {multiLedgerActions.includes(modalOpen) && (
                <MultiLedgerActionModal
                    project={project.info}
                    ledgers={getMultiLedgerModalLedgerOptions()}
                    action={modalOpen}
                    onRequestClose={() => setModalOpen(undefined)}
                />
            )}
            {modalOpen === 'delete-project' && (
                <ConfirmActionModal
                    show
                    title="Delete Project"
                    bodyMessage={'Are you sure you want to delete ' + project.info.name + '?'}
                    action="Delete Project"
                    infoMessage="Deleting this project will remove it from your Workspace. All ledgers
                    within this project will also be deleted. This action cannot be undone."
                    deletingAction
                    onRequestClose={() => setModalOpen(undefined)}
                    onSubmitAction={() => handleDeleteProject()}
                />
            )}
            {modalOpen === 'rename-project' && (
                <RenameModal
                    originalName={project.info.name}
                    onSubmit={newName => renameProject(newName, project.info.id)}
                    onRequestClose={() => setModalOpen(undefined)}
                    existingNames={projects.map(p => p.info.name)}
                    title={'Rename Project'}
                    noSpaces
                />
            )}
        </div>
    );

    function getMultiLedgerModalLedgerOptions() {
        if (modalOpen === 'resume-ledgers') {
            return paused;
        } else if (modalOpen === 'pause-ledgers') {
            return running;
        }
        return ledgers;
    }
};

const LoadingProjectTile = (props: { content: string; className?: string }) => (
    <div className={classNames('loading-projects-tile', props.className)}>
        <InfiniteLoadingWheel />
        <p>{props.content}</p>
    </div>
);

export default Project;
