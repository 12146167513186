import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import { InfiniteLoadingWheel } from '@hub-fe/common/Animations/Animations';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { IconSmallCircle } from '@hub-fe/common/Icons';
import {
    disableClickThrough,
    serviceTransientStates,
    synchronizerStatusIndicator,
} from '@hub-fe/common/displayedStatusesConfig';

import './SynchronizerTile.scss';

interface SynchronizerTileProps {
    name: string;
    id: string;
    status: ObjectStatus;
    url: string;
    isExternalDomain?: boolean;
    isProd?: boolean;
}

export const SynchronizerTile: React.FC<SynchronizerTileProps> = props => {
    const { isProd, name, id, status, isExternalDomain, url } = props;
    const target = `/console/synchronizer/${id}/participants`;

    const disable = disableClickThrough(status);
    const hasTransientState = serviceTransientStates.includes(status);
    const tile = (
        <div>
            <div className="header">
                {isExternalDomain ? <h3>External Domain</h3> : <h4>{name}</h4>}
            </div>
            <p className="p2 url">Synchronizer URL:</p>
            <div
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <CopyableDisplay ghostButton value={url} />
            </div>
            {!isExternalDomain && (
                <div className="other-info">
                    <div className="color-indicator">
                        {hasTransientState ? (
                            <div>
                                <InfiniteLoadingWheel />
                            </div>
                        ) : (
                            <IconSmallCircle
                                color={synchronizerStatusIndicator[status]?.color || 'disabled'}
                            />
                        )}
                    </div>
                    <p className="p2">{synchronizerStatusIndicator[status]?.label || ''}</p>
                    {isProd && (
                        <>
                            <div className="info-divider">|</div>
                            <p className="p2" style={{ fontWeight: 'bold' }}>
                                Production
                            </p>
                        </>
                    )}
                </div>
            )}
        </div>
    );
    if (isExternalDomain) {
        return <div className="synchronizer-tile external">{tile}</div>;
    }
    return (
        <div
            className="synchronizer-tile"
            onClick={() => {
                !disable && history.push(target);
            }}
        >
            {tile}
        </div>
    );
};
