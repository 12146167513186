import React from 'react';

import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { useLedgerActions } from '@hub-fe/ledger/LedgerContext';
import { useMessages } from '@hub-fe/messages/MessageContext';

import { IconRadio } from './Icons';
import LoadingButton from './LoadingButton';
import './SetUpPqs.scss';
import { PqsDataSource, PqsLedgerStart } from './generated/clients/damlhub';
import { useServiceParams } from './utils/useServiceParams';

const ledgerStartOptions: { type: PqsLedgerStart; desc?: string; label: string }[] = [
    {
        type: 'Oldest',
        desc: 'Use the oldest available (unpruned) offset on the ledger or resume where it left off.',
        label: 'Start from oldest contract available',
    },
    {
        type: 'Latest',
        desc: 'Streaming starts at the latest known ledger end offset. On first start, this will result in PQS calling `ActiveContractService` to get a state snapshot, which it will load into the `_creates` table. It will then start streaming creates, archives, and (optionally) exercises from the offset of that `ActiveContractService`. When you restart PQS, it will start from the point it last left off.',
        label: 'Start from current time',
    },
    {
        type: 'Genesis',
        desc: 'Use the original offset of the ledger. This causes PQS to try to start from offset `0`. It allows you to load historic creates, archives, or (optionally) exercises from a ledger that already has data on it. If you try to restart on an already populated database in this mode, PQS may rewrite data.',
        label: 'Start from genesis',
    },
];
export const dataSourceOptions: { type: PqsDataSource; label: string; desc?: string }[] = [
    {
        type: 'TransactionStream',
        label: 'Transaction Stream',
        desc: "Read the ledger's filtered transaction stream for a set of parties. Includes creates, archives, and interface views, but excludes transient contracts and exercises. Transient contracts are contracts that were both created and archived in the same transaction.",
    },
    {
        type: 'TransactionTreeStream',
        desc: "Read the ledger's transaction tree stream for a set of parties. Includes creates, exercises, and transient contracts, but excludes interface views.",
        label: 'Transaction Tree Stream',
    },
];

interface SetUpPqsProps {
    serviceId: string;
}
export const SetUpPqs: React.FC<SetUpPqsProps> = ({ serviceId }) => {
    const { createPqsInstance } = useLedgerActions();
    const [selectedTransactionStreamType, setSelectedStreamType] = React.useState(
        dataSourceOptions[1].type
    );
    const params = useServiceParams();

    const [selectedLedgerStartOption, setSelectedLedgerStartOption] = React.useState(
        ledgerStartOptions[0].type
    );
    const { success } = useMessages();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const onSubmit = async () => {
        setLoading(true);
        try {
            await createPqsInstance(serviceId, {
                datasource: selectedTransactionStreamType,
                ledgerStart: selectedLedgerStartOption,
            });
            setLoading(false);
            success('Creating new PQS Instance, please wait a moment.');
            history.replace(`/console/${params.service}/${serviceId}/overview/pqs/logs`);
        } catch {
            setLoading(false);
        }
    };
    const onCancel = () => {
        history.goBack();
    };
    return (
        <div>
            <div className="setup-section">
                <h1>Set Up PQS</h1>
                <p className="sub-heading">Create new instance</p>
                <h4>Select Streaming Type</h4>
                <p className="desc">
                    Select the streaming type for PQS to use to read events from the participant
                </p>

                {dataSourceOptions.map(option => {
                    return (
                        <div
                            className="radio-option"
                            onClick={() => setSelectedStreamType(option.type)}
                            key={option.label}
                        >
                            <div className="radio">
                                <IconRadio filled={selectedTransactionStreamType === option.type} />
                            </div>
                            <div className="text-area">
                                <p>{option.label}</p>
                                <p className="p2">{option.desc}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div>
                <h4>Select Offset</h4>
                <p className="desc">
                    Select the prescribed ledger offset you would like PQS to read from
                </p>
                {ledgerStartOptions.map(option => {
                    return (
                        <div
                            className="radio-option"
                            onClick={() => setSelectedLedgerStartOption(option.type)}
                            key={option.type}
                        >
                            <div className="radio">
                                <IconRadio filled={selectedLedgerStartOption === option.type} />
                            </div>
                            <div className="text-area">
                                <p>{option.label}</p>
                                <p className="p2">{option.desc}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="button-container">
                <button onClick={onCancel} className="secondary cancel-button">
                    Cancel
                </button>
                <LoadingButton onClick={onSubmit} loading={isLoading} className="primary">
                    Submit
                </LoadingButton>
            </div>
        </div>
    );
};
