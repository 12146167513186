import React from 'react';

import ToolTip from '@hub-fe/common/ToolTip';

import SubdomainList from './SubdomainList';
import SubdomainReservationPanel from './SubdomainReservationPanel';

const AccountSubdomains: React.FC = () => {
    return (
        <div className="account-subdomains">
            <h2>Subdomains</h2>
            <div className="subdomain-reserver">
                <div className="title">
                    <h4>Reserve a Subdomain:</h4>
                    <ToolTip
                        caption={
                            "Custom subdomains must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character."
                        }
                    />
                </div>
                <SubdomainReservationPanel />
            </div>
            <SubdomainList />
        </div>
    );
};

export default AccountSubdomains;
