import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { IconCalendar } from './Icons';
import {
    appendTimeToDate,
    isValidDate,
    toDateFormatted,
    toDateTimeFormatted,
    toTimeFormatted,
} from './Timing';

interface IDateSelectorProps {
    showTimeInput?: boolean;
    required?: boolean;
    returnDateObject?: boolean;
    handleSelect: (val: any) => void;
}

const DateSelector: React.FC<IDateSelectorProps> = ({
    required,
    showTimeInput,
    returnDateObject,
    handleSelect,
}) => {
    const [startDate, setStartDate] = useState<Date | undefined>();

    const handleDateChange = (date: Date) => {
        if (date === null && !required) {
            // Clear the data
            handleSelect({ value: null });
        }
        if (!isValidDate(date)) return;
        setStartDate(date);
        if (returnDateObject) {
            handleSelect(date);
        } else {
            const value = showTimeInput ? toDateTimeFormatted(date) : toDateFormatted(date);
            handleSelect({ value });
        }
    };

    const handleChangeRaw = (value: string) => {
        const datetime = new Date(value);

        if (!isValidDate(datetime)) {
            return;
        }

        handleTime(toTimeFormatted(datetime));
        return datetime;
    };

    const handleTime = (time: string) => {
        if (startDate) {
            const datetime = appendTimeToDate(startDate, time);
            setStartDate(datetime);
            if (returnDateObject) {
                handleSelect(datetime);
            } else {
                handleSelect({ value: toDateTimeFormatted(datetime) });
            }
        }
    };

    return (
        <div className="date-selector">
            <DatePicker
                selected={startDate}
                showTimeInput={showTimeInput}
                dateFormat={showTimeInput ? 'yyyy-MM-dd h:mm:ss.SSS aa' : 'yyyy-MM-dd'}
                customInput={<CustomDateInput />}
                placeholderText="Select a date..."
                customTimeInput={
                    startDate && (
                        <CustomTimeInput
                            handleTime={handleTime}
                            time={toTimeFormatted(startDate)}
                        />
                    )
                }
                onChange={(val: Date) => handleDateChange(val)}
                onChangeRaw={event => handleChangeRaw(event.target.value)}
                showPopperArrow={false}
                isClearable={!required}
            />
        </div>
    );
};

interface ICustomDateInputProps {
    value?: string;
    onClick?: (e: any) => void;
    onChange?: (e: any) => void;
    onBlur?: (e: any) => void;
    onFocus?: (e: any) => void;
    onKeyDown?: (e: any) => void;
}

const CustomDateInput = (props: ICustomDateInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const onIconClick = (e: any) => {
        if (inputRef && inputRef.current && props.onClick) {
            inputRef.current.focus();
            props.onClick(e);
        }
    };

    return (
        <div className="custom-date-input">
            <input
                placeholder={'Select a date...'}
                ref={inputRef}
                value={props.value}
                onClick={props.onClick}
                onChange={props.onChange}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                onKeyDown={props.onKeyDown}
                className={classNames({ focus: !props.value || props.value === '' })}
            />
            <span className="custom-date-input-calendar-icon" onClick={onIconClick}>
                <IconCalendar />
            </span>
        </div>
    );
};

interface ICustomTimeInputProps {
    time: string;
    handleTime: (e: string) => void;
    onBlur?: (e: any) => void;
    onFocus?: (e: any) => void;
    onKeyDown?: (e: any) => void;
}

const CustomTimeInput = (props: ICustomTimeInputProps) => (
    <input
        required
        className="custom-time-input"
        type="time"
        step="0.001"
        value={props.time}
        onChange={e => props.handleTime(e.target.value)}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onKeyDown={props.onKeyDown}
    />
);

export default DateSelector;
