import React from 'react';

import { IconAlert, IconRightArrow } from '../Icons';
import { NavLink } from '../routing';
import { ILedgerSidebarLink } from './config';

interface SideBarLinkProps {
    link: ILedgerSidebarLink;
    actionNeeded: boolean;
    to: string;
}
export const SideBarLink: React.FC<SideBarLinkProps> = props => {
    const { link, actionNeeded, to } = props;
    return (
        <NavLink key={link.target} to={to} activeClassName="active" className="side-bar-link">
            {link.icon} {link.label}
            {actionNeeded && <IconAlert />}
            <IconRightArrow />
        </NavLink>
    );
};
