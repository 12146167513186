import React from 'react';

import { StateIndicator } from '@hub-fe/ledger/Deployments/DeployedArtifactInstanceStateIndicator';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import ActionHeader from './ActionHeader';
import MenuBar from './MenuBar';
import './Pqs.scss';
import { PqsLogSettings } from './PqsLogSettings';
import { PqsLogViewer } from './PqsLogViewer';
import { Route, Router } from './routing';

const tabs = [
    {
        label: 'Logs',
        to: 'logs',
    },
    { label: 'Settings', to: 'settings' },
];
export const Pqs: React.FC = () => {
    const { pqsStatus } = useLedgerContext();
    const runState = pqsStatus?.runState;
    return (
        <div className="pqs">
            <ActionHeader
                linkBackTo="../"
                titleButtons={
                    runState && [
                        <StateIndicator key={runState} artifactDeploymentStates={[runState]} />,
                    ]
                }
                title={
                    <div className="pqs-title">
                        <h1 style={{ marginRight: 0 }}>PQS</h1>
                    </div>
                }
            />
            <MenuBar entries={tabs} />
            <Router>
                <Route path="settings" render={() => <PqsLogSettings />} />
                <Route exact default path="logs" render={() => <PqsLogViewer />} />
            </Router>
        </div>
    );
};
