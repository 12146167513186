import React, { useMemo, useState } from 'react';

import BaseSelect from '@hub-fe/common/BaseSelect';
import DateSelector from '@hub-fe/common/DateSelector';
import { IconClose } from '@hub-fe/common/Icons';

const CustomExpirationValue = 'custom';

type ExpirationValue = Date | undefined | typeof CustomExpirationValue;

const ExpirationDateDropdown: React.FC<{
    onSetExpiration: (expiration?: Date) => void;
    expiration?: Date;
}> = ({ onSetExpiration, expiration }) => {
    const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);

    const getDate = (days: number) => {
        const today = new Date();
        const newDate = new Date();
        newDate.setDate(today.getDate() + days);
        return newDate;
    };

    const dayIntervals = [7, 30, 60, 90];

    const options: { label: string; value: ExpirationValue }[] = useMemo(
        () => [
            ...dayIntervals.map(num => ({ label: `${num} days`, value: getDate(num) })),
            { label: 'Custom...', value: CustomExpirationValue },
        ],
        []
    );

    return (
        <div className="expiration-date-select">
            {showCustomDatePicker ? (
                <>
                    <DateSelector
                        showTimeInput
                        returnDateObject
                        required
                        handleSelect={date => onSetExpiration(date)}
                    />
                    <button
                        className="ghost close-button"
                        onClick={() => {
                            onSetExpiration();
                            setShowCustomDatePicker(false);
                        }}
                    >
                        <IconClose />
                    </button>
                </>
            ) : (
                <BaseSelect
                    aria-label="Expiration Date"
                    placeholder="Expiration Date"
                    value={options.find(o => o.value?.toString() == expiration?.toString())}
                    options={options}
                    onChange={val => {
                        if (!val || val.value === undefined) {
                            onSetExpiration(undefined);
                            setShowCustomDatePicker(false);
                        } else if (val.value === CustomExpirationValue) {
                            setShowCustomDatePicker(true);
                            onSetExpiration(undefined);
                        } else {
                            onSetExpiration(val.value);
                            setShowCustomDatePicker(false);
                        }
                    }}
                />
            )}
        </div>
    );
};

export default ExpirationDateDropdown;
