import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { components } from 'react-select';

import { useAppContext } from '@hub-fe/app/AppContext';
import { NavLink } from '@hub-fe/common/routing';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import ExternalLink from './ExternalLink';
import { IconCheck, IconSidebarDeployments, IconTertiaryArrow } from './Icons';
import LoadingButton from './LoadingButton';
import SmallSelect from './SmallSelect';
import { dalArcadeIconURL, IArcadeAppInfo } from './server';

export type IDeployAppFn = (arcadeApp: IArcadeAppInfo) => void;

enum TileState {
    AVAILABLE = 'available',
    LOADING = 'loading',
    DEPLOYED = 'deployed',
}

const ArcadeAppTile: FunctionComponent<{
    appVersionInfos: IArcadeAppInfo[];
    deployArcadeApp: IDeployAppFn;
    deployedArtifactHashes: string[];
    emptyWorkspace?: boolean;
    verticalLayout?: boolean;
    selected?: boolean;
}> = props => {
    const {
        appVersionInfos,
        deployArcadeApp,
        deployedArtifactHashes,
        emptyWorkspace,
        verticalLayout,
        selected,
    } = props;
    const { ledgerId } = useLedgerContext();
    const { showPrereleaseApps } = useAppContext();
    const latest = appVersionInfos.find(({ catalog }) => !catalog.experimental)?.catalog.version;
    const [version, setVersion] = useState(latest);
    const app = appVersionInfos.find(avi => avi.catalog.version === version);
    const integrationIsDeployed =
        app && !!deployedArtifactHashes.find(da => da === app.artifactHash);
    const [state, setState] = useState<TileState>(
        integrationIsDeployed ? TileState.DEPLOYED : TileState.AVAILABLE
    );

    useEffect(() => {
        if (state === TileState.LOADING) {
            if (ledgerId && integrationIsDeployed) {
                setState(TileState.DEPLOYED);
            }
        }
    }, [state, integrationIsDeployed, ledgerId]);

    const startDeploy = () => {
        if (app) {
            deployArcadeApp(app);
            if (ledgerId) {
                setState(TileState.LOADING);
            }
        }
    };

    if (!app) {
        return null;
    }

    const disabled = state !== TileState.AVAILABLE;

    let versionDisplay = null;

    if (showPrereleaseApps) {
        if (appVersionInfos.length < 2) {
            versionDisplay = <span className="alt-caption">v{app.catalog.version}</span>;
        } else {
            const options = appVersionInfos.map(({ catalog: { version } }) => ({
                label: version,
                value: version,
            }));
            const Option = (props: any) => (
                <components.Option
                    {...props}
                    className={classNames({
                        current: props.data.value === latest,
                        rc: appVersionInfos.find(
                            ({ catalog: { version } }) => version === props.data.version
                        )?.catalog.experimental,
                    })}
                >
                    {props.data.value === latest && <IconCheck />}
                    {props.data.label}
                </components.Option>
            );

            versionDisplay = (
                <SmallSelect
                    width="100px"
                    options={options}
                    value={options.find(o => o.value === version)}
                    onChange={(val: any) => setVersion(val.value)}
                    components={{ Option }}
                />
            );
        }
    }

    let iconDisplay = null;

    if (app.iconArtifactHash) {
        iconDisplay = <img src={dalArcadeIconURL(app.iconArtifactHash)} alt="Package Icon" />;
    }

    let appActivateControl = null;

    if (integrationIsDeployed && ledgerId && !verticalLayout) {
        appActivateControl = (
            <div className="actions deployed">
                <p className="p2">Deployed</p>
                <NavLink className="p2 icon-left" to={`/console/ledger/${ledgerId}/deployments`}>
                    <IconSidebarDeployments />
                    View deployment
                </NavLink>
            </div>
        );
    } else {
        appActivateControl = (
            <LoadingButton
                disabled={disabled}
                ghost={!emptyWorkspace}
                className={classNames({ actions: !emptyWorkspace })}
                loading={state === TileState.LOADING}
                loadingContent="Deploying"
                onClick={startDeploy}
            >
                {emptyWorkspace ? (
                    'Deploy'
                ) : (
                    <div className="with-arrow-button">
                        <h4>Deploy</h4>
                        <IconTertiaryArrow size="small" />
                    </div>
                )}
            </LoadingButton>
        );
    }

    const { catalog } = app;

    const linkBlock = (
        <div className="link-block">
            {catalog.demoUrl && (
                <ExternalLink className="inline-link underline-hover p2" to={catalog.demoUrl} icon>
                    Live Example
                </ExternalLink>
            )}
            {catalog.sourceUrl && (
                <ExternalLink
                    className="inline-link underline-hover p2"
                    to={catalog.sourceUrl}
                    icon
                >
                    More Info
                </ExternalLink>
            )}
        </div>
    );

    const sampleIcon = <div className="sample-icon">{iconDisplay}</div>;
    const versionInfo = <div className="version-info">{versionDisplay}</div>;

    if (verticalLayout) {
        return (
            <div
                className={classNames(
                    'sample tile',
                    { 'vertical-layout': verticalLayout },
                    { selected }
                )}
            >
                <div
                    className={classNames('vertical-layout-body', { 'body-selected': selected })}
                    onClick={() => verticalLayout && startDeploy()}
                >
                    <div className="vertical-layout-header">
                        <h4>{catalog.shortDescription}</h4>
                    </div>

                    <div className="tile-body">
                        {sampleIcon}
                        <p className="p2">{catalog.description}</p>
                    </div>
                </div>

                {(catalog.sourceUrl || catalog.demoUrl || versionInfo) && (
                    <div className="tile-header">
                        {linkBlock}
                        {versionInfo}
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="sample tile">
            {sampleIcon}
            <div className="tile-header">
                {versionInfo} {linkBlock}
            </div>
            <div className="tile-body">
                <h4>{catalog.shortDescription}</h4>
                <p className="p2">{catalog.description}</p>
            </div>
            {appActivateControl}
        </div>
    );
};

export default ArcadeAppTile;
