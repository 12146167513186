import React from 'react';

import { IContractInfo } from '@hub-client-api';

import CopyableDisplay from '@hub-fe/common/CopyableDisplay';

import TemplateIdDisplay from '../TemplateIdDisplay';

const ContractInfoHeader: React.FC<{
    contract: IContractInfo;
    archived: boolean;
}> = ({ contract, archived }) => {
    return (
        <div className="contract-info-header">
            <h2>
                <TemplateIdDisplay templateId={contract.templateId} />{' '}
                {archived && <i className="p2">Archived</i>}
            </h2>
            <CopyableDisplay label="Contract Id" value={contract.contractId} />
        </div>
    );
};

export default ContractInfoHeader;
