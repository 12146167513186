import React from 'react';

import { isLedgerData, useAppContext } from '@hub-fe/app/AppContext';
import { validateEntity } from '@hub-fe/common/artifacts';

import { SideBar } from '../common/Sidebar/SideBar';
import { getLedgerSideBarLinks } from '../common/Sidebar/config';
import { useLedgerActions, useLedgerContext } from './LedgerContext';
import LedgerSideBarInfo from './LedgerSideBarInfo';

export const LedgerSideBar: React.FC = () => {
    const { isProTier } = useAppContext();
    const { isDeployedArtifactConfigured } = useLedgerActions();

    const { deployedArtifacts, ledger, isLedgerOwner } = useLedgerContext();
    if (!ledger || !isLedgerData(ledger)) {
        return <></>;
    }

    const sideBarLinks = getLedgerSideBarLinks({ isProTier, isLedgerOwner });
    const deploymentActionNeeded = !!deployedArtifacts?.find(
        d =>
            !isDeployedArtifactConfigured(d) &&
            validateEntity(d.entityInfo.entity, ledger.info.backend).length === 0
    );

    return (
        <SideBar
            bottomInfoPanel={<LedgerSideBarInfo ledger={ledger} />}
            deploymentActionNeeded={deploymentActionNeeded}
            sideBarLinks={sideBarLinks}
        />
    );
};
