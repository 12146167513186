import React from 'react';

import { ILedgerData, IParticipantData, IScratchpadData } from '@hub-client-api';

import { useAppActions } from '@hub-fe/app/AppContext';

export const useDragDrop = ({
    serviceAccessDisabled,
    ledger,
}: {
    serviceAccessDisabled: boolean;
    ledger: IParticipantData | ILedgerData | IScratchpadData;
}): {
    dragProps: {
        onDrop: (evt: any) => Promise<void>;
        onDragEnter: () => void;
        onDragLeave: () => void;
    };
    dragCount: number;
} => {
    const { deployWorkspaceFileToLedger } = useAppActions();
    const [dragCount, setDragCount] = React.useState(0);
    const handleDrag = (count: number) => {
        if (!serviceAccessDisabled) {
            setDragCount(count);
        }
    };
    async function deployWorkspaceFile(fileName: string) {
        setDragCount(dragCount - 1);

        // prevent from dragging and dropping directly from file explorer
        if (!fileName) return;

        deployWorkspaceFileToLedger({ ledger, fileNames: [fileName] });
    }
    const dragProps = {
        onDrop: (evt: any) => deployWorkspaceFile(evt.dataTransfer.getData('text')),
        onDragEnter: () => handleDrag(dragCount + 1),
        onDragLeave: () => handleDrag(dragCount - 1),
    };
    return { dragProps, dragCount };
};
