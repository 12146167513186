import React, { useState } from 'react';

import { ILedgerData } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import { ModalMultiSelect, ModalSingleSelect } from '@hub-fe/common/ModalSelect';
import Wizard, { WizardPage } from '@hub-fe/common/Wizard';

const DeployFileWizard: React.FC<{
    fileNames?: string[];
    appName?: string;
    onRequestClose: (deployRequested: boolean) => void;
}> = ({ fileNames, onRequestClose, appName }) => {
    const { projects, activeLedgers } = useAppContext();
    const { deployWorkspaceFileToLedger } = useAppActions();

    const projectsSelect = projects?.map(({ info }) => ({
        id: info.id,
        name: info.name,
    }));

    const [selectedProject, setSelectedProject] = useState(projects ? projects[0].info : undefined);
    const selectedProjectLedgers = activeLedgers.filter(
        l => l.info.projectId === selectedProject?.id
    );

    const [selectedLedgers, setSelectedLedgers] = useState<ILedgerData[]>([
        selectedProjectLedgers[0],
    ]);
    const [deployListLength, setDeployListLength] = useState(5);

    const submitDeployment = () => {
        onRequestClose(true);
        selectedLedgers.forEach(ledger =>
            deployWorkspaceFileToLedger({ ledger, fileNames: fileNames || [] })
        );
    };

    const ledgerOptions = selectedProjectLedgers.map(({ info }) => {
        return { id: info.id, name: info.name };
    });

    let deployList: string[] = [];

    if (fileNames) {
        deployList = fileNames;
    } else if (appName) {
        deployList = [appName];
    }

    const selectedLedgerIds = new Set(selectedLedgers.map(l => l.info.id));

    return (
        <Wizard
            show
            className="deploy-file-wizard"
            title={`Deploy ${appName ? appName : ''} to...`}
            onRequestClose={() => onRequestClose(false)}
            onSubmit={submitDeployment}
        >
            <WizardPage pageLabel="Project">
                <p className="options-list">
                    Choose a project to deploy {deployList.slice(0, deployListLength).join(', ')}
                    {deployListLength < deployList.length && (
                        <>
                            {', '}
                            <button
                                className="secondary-smaller p2"
                                onClick={() => setDeployListLength(deployListLength + 5)}
                            >
                                ...view more
                            </button>
                        </>
                    )}
                    :
                </p>

                <div className="row selector-field" id="project-dropdown">
                    <ModalSingleSelect
                        items={projectsSelect}
                        selectedItem={selectedProject?.id}
                        setSelectedItem={handleSetSelectedProject}
                    />
                </div>
            </WizardPage>
            <WizardPage pageLabel="Ledgers">
                <p>{`Choose ledgers to deploy ${deployList}:`}</p>
                <ModalMultiSelect
                    items={selectedProject && ledgerOptions}
                    selectedItems={selectedLedgerIds}
                    setSelectedItems={(ids: Set<String>) =>
                        setSelectedLedgers(activeLedgers.filter(l => ids.has(l.info.id)))
                    }
                />
            </WizardPage>
        </Wizard>
    );

    function handleSetSelectedProject(projectId?: string) {
        const newProject = projects?.find(p => p.info.id === projectId)?.info;
        if (newProject) {
            setSelectedProject(newProject);
        }
    }
};

export default DeployFileWizard;
