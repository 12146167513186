import React, { useState } from 'react';

import LoadingButton from '@hub-fe/common/LoadingButton';
import Modal from '@hub-fe/common/Modal';

const AUTH0_DB_CONNECTION = 'dabl-user-pass-auth';

const ChangePasswordModal: React.FC<{ email: string; onRequestClose: () => void }> = props => (
    <Modal
        show
        className="change-password-modal"
        title="Change Password"
        onRequestClose={() => props.onRequestClose()}
    >
        <ChangePasswordControl email={props.email} onRequestClose={props.onRequestClose} />
    </Modal>
);

const ChangePasswordControl = (props: { email: string; onRequestClose: () => void }) => {
    const { email } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [passwordResetConfirmed, setPasswordResetConfirmed] = useState(false);

    return (
        <div>
            {passwordResetConfirmed ? (
                <p>Thank you! We've just sent you an email with the link to reset your password.</p>
            ) : (
                <form className="forms" onSubmit={resetPasswordRequest}>
                    <p>
                        {' '}
                        An email with a link to change your password will be sent to <b>{email}</b>.
                    </p>
                    {error && <div className="error">{error}</div>}
                    <div className="action-bar right">
                        <button
                            type="button"
                            className="secondary"
                            onClick={() => props.onRequestClose()}
                        >
                            Cancel
                        </button>
                        <LoadingButton
                            submit
                            loading={loading}
                            loadingContent="Sending"
                            className="primary"
                        >
                            Send Email
                        </LoadingButton>
                    </div>
                </form>
            )}
        </div>
    );

    async function resetPasswordRequest(event: React.FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault();

        setLoading(true);
        const data = {
            email,
            client_id: __AUTH0_CLIENT_ID__,
            connection: AUTH0_DB_CONNECTION,
        };

        const response = await fetch(`https://${__OAUTH_DOMAIN__}/dbconnections/change_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).catch(err => {
            setError(err);
        });

        if (response && response.status === 200) {
            setPasswordResetConfirmed(true);
        } else {
            setError('Something went wrong. Please try again later!');
        }

        setLoading(false);
    }
};

export default ChangePasswordModal;
