import React from 'react';

import { history } from '@hub-fe/common/ControllableBrowserRouter';

import './SetupTile.scss';

export const SetupTile: React.FC = () => {
    const onClick = () => {
        history.push('pqs/setup');
    };
    return (
        <div className="setup-tile">
            <div className="panel">
                <h3>Set up PQS</h3>
                <h4>Create new instance</h4>
                <p>Set up a Participant Query Store (PQS) instance for your participant node.</p>
                <button onClick={onClick}>Set up PQS</button>
            </div>
        </div>
    );
};
