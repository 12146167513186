import React from 'react';

import { IconInformation } from '@hub-fe/common/Icons';

const LedgerCollaboratorWarning: React.FC<{}> = () => (
    <div className="collaborator-warning icon-left">
        <IconInformation />
        <p className="p2">You are a collaborator on this ledger.</p>
    </div>
);

export default LedgerCollaboratorWarning;
