import React from 'react';

import { ClipboardButton } from './ClipboardButtons';
import { CopyActionsEnum } from './utils/formatCopyToken';

export const CopyActions: React.FC<{
    customLabel?: string;
    disableLabel?: boolean;
    handleCopy: (format: string) => void;
}> = ({ customLabel, handleCopy, disableLabel }) => (
    <div className="party-info copy-actions">
        {!disableLabel && <p className="copy-lable p2">{customLabel || 'Copy:'}</p>}
        {Object.values(CopyActionsEnum).map((a, i) => (
            <div className="action" key={i}>
                {i !== 0 && <p className="seporator">|</p>}
                <ClipboardButton label={a} ghost onClick={async () => handleCopy(a)} />
            </div>
        ))}
    </div>
);
