import React from 'react';

import { ISynchronizerData } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import { ManageDisabledParticipants } from '@hub-fe/common/ManageDisabledParticipants';
import { SettingsTable } from '@hub-fe/common/SettingsTable';
import { Storage } from '@hub-fe/common/Storage';
import Toggle from '@hub-fe/common/Toggle';
import { TwoColLayout } from '@hub-fe/common/TwoColLayout';
import { routeToConnections } from '@hub-fe/common/routing/RoutingContext';
import { capitalizePhrase } from '@hub-fe/common/utils';
import { mergeAllowedParticipants } from '@hub-fe/common/utils/mergeAllowedParticipants';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import en from '../../locales/en.json';
import '../common/SettingsTable.scss';

export const SynchronizerSettings: React.FC = () => {
    const { deleteSynchronizer, enableOpenAccess, updateServiceProductionTag } = useAppActions();
    const { serviceId } = useServiceParams();
    const [isDisabledParticipantsOpen, setIsDisabledParticipantsOpen] = React.useState(false);
    const onOpen = () => {
        setIsDisabledParticipantsOpen(true);
    };
    const { activeParticipants: ownedParticipants } = useAppContext();
    const { allowedParticipants } = useLedgerContext();
    const all = mergeAllowedParticipants({
        ownedParticipants,
        allowedParticipants: allowedParticipants || [],
    });
    const disabledParticipants = all.filter(
        participants => participants.permissionLevel === 'Disabled'
    );
    const onClose = () => {
        setIsDisabledParticipantsOpen(false);
    };
    const [showServiceActionModal, setShowServiceActionModal] = React.useState<{
        show: boolean;
        actionLabel: string;
        action?: (arg: { info: { id: string } }) => void;
    }>({ show: false, actionLabel: '' });
    const onCloseModal = () => {
        setShowServiceActionModal({ show: false, actionLabel: '' });
    };
    const { synchronizers } = useAppContext();
    const synchronizer = synchronizers.find(sd => sd.info.id === serviceId);
    const [isLoading, setLoading] = React.useState(false);
    const isProd = !!synchronizer?.info?.production;

    const onDelete = async () => {
        serviceId &&
            setShowServiceActionModal({
                show: true,
                actionLabel: 'delete',
                action: async () => {
                    setLoading(true);
                    synchronizer && (await deleteSynchronizer(synchronizer));
                    setLoading(false);
                    routeToConnections();
                },
            });
    };

    const onEnableOpenAccess = () => {
        if (serviceId) {
            enableOpenAccess({ serviceId });
        }
    };
    const toggleProd = async () => {
        if (!serviceId) {
            return;
        }
        await updateServiceProductionTag({
            serviceId: serviceId,
            enableProduction: !isProd,
            serviceType: ServiceType.SYNC_DOMAIN,
        });
    };

    const settings = [
        {
            title: 'Tag Service as Production',
            desc: en.serviceSettings.tagServiceAsProductionDesc,
            actionElement: (
                <Toggle
                    on={isProd}
                    onLabel="Enabled"
                    offLabel="Off"
                    onColor="green"
                    onChanged={toggleProd}
                    labelPosition="left"
                    togglingLabel={false ? 'Disabling' : 'Enabling'}
                />
            ),
        },
        {
            title: 'Allow Open Access',
            desc: en.serviceSettings.synchronizer.allowOpenAccess,
            actionElement: (
                <button
                    disabled={!!synchronizer?.info.openTopology}
                    onClick={onEnableOpenAccess}
                    className="button secondary-smaller"
                >
                    {synchronizer?.info.openTopology ? 'Enabled' : 'Enable'}
                </button>
            ),
        },
        {
            title: 'Disabled Participants',
            desc: en.serviceSettings.synchronizer.deleteDesc,
            actionElement: (
                <button
                    disabled={disabledParticipants.length === 0}
                    onClick={onOpen}
                    className="button secondary-smaller"
                >
                    Manage Disabled Participants
                </button>
            ),
        },
        {
            title: 'Delete',
            desc: en.serviceSettings.synchronizer.deleteDesc,
            actionElement: (
                <button onClick={onDelete} className="button secondary-smaller warning">
                    {isLoading ? 'Deleting' : 'Delete'}
                </button>
            ),
        },
    ];
    const col1 = (
        <>
            <h1>Settings</h1>
            <SettingsTable data={settings} />
            {synchronizer && <Storage disks={synchronizer?.info.status.disks} />}

            <ConfirmActionModal
                onSubmitAction={() =>
                    showServiceActionModal.action &&
                    showServiceActionModal.action({
                        info: { id: serviceId },
                    } as ISynchronizerData)
                }
                title={`${capitalizePhrase(showServiceActionModal.actionLabel)} Synchronizer`}
                action={capitalizePhrase(showServiceActionModal.actionLabel)}
                deletingAction
                bodyMessage={`Are you sure you want to ${showServiceActionModal.actionLabel} this Synchronizer?`}
                show={showServiceActionModal.show}
                onRequestClose={onCloseModal}
            ></ConfirmActionModal>
            <ConfirmActionModal
                onSubmitAction={() =>
                    showServiceActionModal.action &&
                    showServiceActionModal.action({
                        info: { id: serviceId },
                    } as ISynchronizerData)
                }
                title={'Manage Disabled Participants'}
                action={'Save'}
                deletingAction={false}
                bodyMessage={`Are you sure you want to ${showServiceActionModal.actionLabel} this Synchronizer?`}
                show={isDisabledParticipantsOpen}
                onRequestClose={onClose}
            ></ConfirmActionModal>
            {serviceId && isDisabledParticipantsOpen && (
                <ManageDisabledParticipants
                    show={isDisabledParticipantsOpen}
                    onRequestClose={onClose}
                    serviceId={serviceId}
                />
            )}
        </>
    );
    return <TwoColLayout col1={col1} />;
};
