import * as React from 'react';

import App from '@hub-fe/app/App';
import IsBrowser from '@hub-fe/common/IsBrowser';
import apiTokens, {
    ADMIN_LEDGER,
    IDablJwtClaims,
    IDecodedDablJwt,
    SITE_PARTY,
} from '@hub-fe/common/apiTokens';
import { decode } from '@hub-fe/common/jwt-lib';
import RoutingProvider from '@hub-fe/common/routing/RoutingContext';
import { login } from '@hub-fe/common/urls';
import { DamlHubHelmet } from '@hub-fe/landing/nav';

import { isLoggedIn } from '../service/auth';

const Console: React.FC = () => {
    const ensureConsoleToken = async () => {
        const siteJwt = await apiTokens.getToken(ADMIN_LEDGER, SITE_PARTY);
        const { claims } = decode<IDecodedDablJwt>(siteJwt);
        const { hubClaims } = claims as IDablJwtClaims;

        // clear apiToken if location is /console and the site JWT is still an admin token
        if (hubClaims.adminUI) {
            apiTokens.clear();
        }
    };

    React.useEffect(() => {
        if (!isLoggedIn()) {
            window.location.assign(`https://${login}`);
        }
        ensureConsoleToken();
    }, []);

    return (
        <IsBrowser>
            <DamlHubHelmet />
            <RoutingProvider>
                <App />
            </RoutingProvider>
        </IsBrowser>
    );
};

// Needed for Gatsby
// ts-unused-exports:disable-next-line
export default Console;
