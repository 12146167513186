import React from 'react';

import { IArcadeAppInfo } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import AppArcade from '@hub-fe/common/AppArcade';

const SampleApps: React.FC<{
    emptyWorkspace: boolean;
    onDeploy: (app: IArcadeAppInfo) => void;
    verticalLayout?: boolean;
    selectedApp?: IArcadeAppInfo;
}> = ({ emptyWorkspace, onDeploy, verticalLayout, selectedApp }) => {
    const { sampleApps } = useAppContext();
    return (
        <AppArcade
            loadingTileCount={3}
            sectionTitle={
                emptyWorkspace ? (
                    <h2>Or get started by deploying a sample app:</h2>
                ) : verticalLayout ? (
                    <p>
                        Select a Sample App{selectedApp && ':'}
                        {selectedApp && <b> {selectedApp.catalog.shortDescription}</b>}
                    </p>
                ) : (
                    <h2>Sample Apps</h2>
                )
            }
            verticalLayout={verticalLayout}
            arcadeApps={sampleApps}
            deployArcadeApp={onDeploy}
            emptyWorkspace={emptyWorkspace}
            selectedApp={selectedApp}
        />
    );
};

export default SampleApps;
