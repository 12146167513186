import React, { useEffect, useRef, useState } from 'react';

import { dalGetChoicesByTemplate, IChoice, IContractInfo } from '@hub-client-api';

import BackwardsLink from '@hub-fe/common/BackwardsLink';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { isNewService } from '@hub-fe/common/utils/isNewService';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import { useLedgerActions, useLedgerContext } from '../LedgerContext';
import TemplateIdDisplay from '../TemplateIdDisplay';
import ContractTemplateForm from './ContractTemplateForm/ContractTemplateForm';
import { useIsHighEventCount } from './utils';

const ChoiceExerciseInfoHeader = (props: { contract: IContractInfo; selectedParty: string }) => {
    const { contract, selectedParty } = props;
    const { userParties = [], ownerParties = [] } = useLedgerContext();
    const submittedAs =
        [...userParties, ...ownerParties].find(p => p.identifier === selectedParty)?.displayName ||
        selectedParty;
    return (
        <div className="exercise-info-header">
            <div className="row">
                <label className="p2 bold">Template:</label>
                <TemplateIdDisplay templateId={contract.templateId} />
            </div>
            <div className="row">
                <CopyableDisplay label="Contract Id" value={contract.contractId} />
            </div>
            <div className="row">
                <label className="p2 bold">Submitted as: &nbsp; </label>
                {submittedAs}
            </div>
        </div>
    );
};

const ChoiceTemplate: React.FC = () => {
    const { selectedContract, choice: selectedChoice, service } = useServiceParams();
    if (!service) {
        return null;
    }
    const enableServiceEndpoint = isNewService(service);
    const { ledgerId, selectedParty } = useLedgerContext();
    const { getDetailsByContractId, submitCommandExercise } = useLedgerActions();
    const enableHighCountView = useIsHighEventCount();

    const [currentContract, setCurrentContract] = useState<IContractInfo>();
    const [currentChoice, setCurrentChoice] = useState<IChoice>();

    const mounted = useRef(false);
    const handleSubmit = async (value: any, onError: () => void) => {
        if (!selectedParty || !currentChoice || !currentContract) {
            resetChoiceState();
            return;
        }

        const command = {
            contractId: currentContract.contractId,
            templateId: currentContract.templateId,
            choice: currentChoice.name,
            argument: value,
        };

        await submitCommandExercise(command, onSuccess, onError, selectedParty);
    };

    useEffect(() => {
        if (!mounted.current) {
            updateComponent();
            mounted.current = true;
        } else {
            if (!currentChoice) {
                updateComponent();
            }
        }
    });

    if (!currentContract || !currentChoice || !selectedParty || !ledgerId) {
        return null;
    }
    return (
        <div className="choice-template">
            <div className="choice-template-body">
                <BackwardsLink />
                <h1>Exercise Choice: {currentChoice.name}</h1>
                <ChoiceExerciseInfoHeader
                    contract={currentContract}
                    selectedParty={selectedParty}
                />
                <ContractTemplateForm
                    definitions={currentChoice.definitions}
                    label={currentContract.templateId}
                    onSubmit={handleSubmit}
                    type={currentChoice.type}
                />
            </div>
        </div>
    );

    async function updateComponent() {
        if (!selectedChoice || !selectedContract) {
            return;
        }
        const choiceState = await getInitialState(selectedChoice, selectedContract);
        setCurrentChoice(choiceState?.currentChoice);
        setCurrentContract(choiceState?.currentContract);
    }

    function resetChoiceState() {
        setCurrentChoice(undefined);
        setCurrentContract(undefined);
    }

    function onSuccess() {
        if (enableHighCountView) {
            history.goBack();
            return;
        }
        const rootUrl = `/console/${service}/` + ledgerId + '/live-data/' + selectedContract;
        history.push(rootUrl + '/?party=' + selectedParty);
    }

    async function getInitialState(choiceName: string, selectedContract: string) {
        if (!selectedParty) {
            return;
        }
        const currentContract = await getDetailsByContractId(decodeURIComponent(selectedContract));
        const choices = await dalGetChoicesByTemplate(
            ledgerId,
            currentContract.templateId,
            selectedParty,
            enableServiceEndpoint
        );
        const matchingChoices = choices.filter((choice: IChoice) => choice.name === choiceName);
        if (matchingChoices.length !== 1) {
            throw new Error('no current choice selected');
        }
        const currentChoice = matchingChoices[0];
        return {
            value: {},
            currentContract,
            currentChoice,
        };
    }
};

export default ChoiceTemplate;
