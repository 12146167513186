import classNames from 'classnames';
import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import { IconPause, IconPlay } from '@hub-fe/common/Icons';
import { filterPartyOptions } from '@hub-fe/common/PartyDropdown';
import { NavLink } from '@hub-fe/common/routing';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import { useLedgerContext } from '../LedgerContext';
import './ActiveContracts.scss';
import ActiveContractsSearch, {
    applyContractStreamFilter,
    useContractFilterQuery,
} from './ActiveContractsSearch';
import ContractContents from './ContractContents';
import ContractSelector from './ContractSelector';
import PartySelector, { PartySelectorDropdown } from './PartySelector';
import useContractStream, { ContractStreamState } from './useContractStream';
import { useIsHighEventCount } from './utils';

export const ActiveContractsComponent: React.FC = () => {
    const {
        ledger,
        selectedParty,
        ownerParties = [],
        isLedgerOwner,
        defaultParties,
        templates: allTemplates = [],
    } = useLedgerContext();
    const isHighEventCount = useIsHighEventCount();

    const { selectedContract, service } = useServiceParams();
    const selectedContractId = selectedContract && decodeURIComponent(selectedContract);

    const { contractFilter, filterQueryString } = useContractFilterQuery();

    const { contractTemplates: queryTemplates } = useContractFilterQuery();

    const templates = queryTemplates.length > 0 ? queryTemplates : allTemplates;

    const currentStreamState = useContractStream(ledger?.info.id, selectedParty, templates);

    const [pauseSnapshot, setPauseSnapshot] = React.useState<ContractStreamState>();

    const archivedContracts =
        pauseSnapshot?.contracts?.filter(
            psc => !currentStreamState.contracts.find(cc => psc.contractId === cc.contractId)
        ) || [];

    function setPaused(paused: boolean) {
        setPauseSnapshot(paused ? currentStreamState : undefined);
    }
    const contractStreamState = pauseSnapshot || currentStreamState;
    if (!ledger) {
        return null;
    }

    const initializing =
        ledger.info.status.ledger === ObjectStatus.STARTING ||
        ledger.info.status.ledger === ObjectStatus.RESUMING;

    const readOnly = ledger.info.status.ledger === ObjectStatus.PAUSED;

    const filteredStreamState = applyContractStreamFilter(contractFilter, contractStreamState);

    const currentContract = filteredStreamState.contracts.find(
        c => c.contractId === selectedContractId
    );

    const createLink =
        `/console/${service}/${ledger.info.id}/live-data/create` +
        (filterQueryString ? `?${filterQueryString}` : '');

    const searchEmpty = contractFilter.length > 0 && filteredStreamState.contracts.length === 0;
    const parties = filterPartyOptions(ownerParties, defaultParties, false, !isLedgerOwner, true);
    const contractView = (
        <div
            className={classNames(
                'contract-view',
                isHighEventCount ? 'high-event-count' : 'standard-event-count',
                { paused: !!pauseSnapshot }
            )}
        >
            {!isHighEventCount && (
                <>
                    <h4 className="column-heading">Parties</h4>
                    <PartySelector initializing={initializing} parties={parties} />
                </>
            )}
            <h4 className="column-heading">
                <span className="column-title">
                    Contracts
                    {contractStreamState.loading
                        ? null
                        : ` (${filteredStreamState?.contracts?.length})`}
                </span>
            </h4>

            <ContractSelector
                contractStreamState={filteredStreamState}
                searchEmpty={searchEmpty}
                archivedContracts={archivedContracts}
            />
            {!!pauseSnapshot && (
                <div className="paused-banner p2">
                    The contracts stream is paused.
                    <button className="resume" onClick={() => setPaused(false)}>
                        <p className="p2 inline-link"> Resume Now</p>
                    </button>
                </div>
            )}
            <h4 className="column-heading">
                <span className="column-title">Details</span>{' '}
                {!initializing && !readOnly && (
                    <button
                        className="paused secondary-smaller icon-left"
                        onClick={() => setPaused(!pauseSnapshot)}
                    >
                        {pauseSnapshot ? (
                            <>
                                <IconPlay />
                                <p className="p2">Resume Contracts</p>
                            </>
                        ) : (
                            <>
                                <IconPause />
                                <p className="p2">Pause Contracts</p>
                            </>
                        )}
                    </button>
                )}
            </h4>
            <ContractContents
                isHighEventCount={isHighEventCount}
                contract={currentContract}
                loading={contractStreamState.loading}
                readOnly={readOnly}
                archived={
                    !!archivedContracts.find(c => c.contractId === currentContract?.contractId)
                }
            />
        </div>
    );
    if (isHighEventCount) {
        return <div>{contractView}</div>;
    }
    return (
        <div className="active-contracts">
            <div className="live-data-title">
                <h1>Live Data</h1>
                {!initializing && !contractStreamState.loading && <ActiveContractsSearch />}
                {selectedParty && ledger?.info.id && !initializing && !readOnly && (
                    <NavLink className="button" to={createLink}>
                        Add Contract
                    </NavLink>
                )}
            </div>
            <PartySelectorDropdown parties={parties} />
            {contractView}
        </div>
    );
};
