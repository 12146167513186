import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { IProjectData, LedgerSizeEnum, ResourceTokenType } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import { IBaseSelectOption } from '@hub-fe/common/BaseSelect';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { FileUploadButton, FileUploadDropzone } from '@hub-fe/common/FileUpload';
import { IconClose, IconRadio } from '@hub-fe/common/Icons';
import MultiStepActionPage, {
    MultiStepActionSubPage,
    ISummaryInfo,
} from '@hub-fe/common/MultiStepActionPage';
import { NameValidationBlock, INameValidationState, isNameValid } from '@hub-fe/common/RenameModal';
import ResourceQuotaValidationBlock, {
    IResourceQuotaValidationState,
    isLedgerSizeValid,
} from '@hub-fe/common/ResourceQuotaValidationBlock';
import { ArtifactFileTag, determineFileTag } from '@hub-fe/common/Tag';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { Footer } from '@hub-fe/footer/Footer';

import { SelectUserIdsPage } from './ShareLedgerActionModal';

const CreateNewProjectPage: React.FC<{}> = () => {
    const { projectId } = useServiceParams();
    const { createProject, createLedger } = useAppActions();
    const { activeLedgers, projects, isProTier } = useAppContext();
    const { getQuotaTokens } = useAppActions();
    const [projectName, setProjectName] = useState('');
    const [ledgerName, setLedgerName] = useState('');
    const [artifacts, setArtifacts] = useState<File[]>([]);
    const [collaboratorIds, setCollaboratorIds] = useState<Set<IBaseSelectOption> | undefined>();
    const [ledgerSize, setLedgerSize] = useState<LedgerSizeEnum>(LedgerSizeEnum.SMALL);

    const project = projects?.find(p => p.info.id === projectId);
    const names = activeLedgers.map(({ info: { name } }) => name);

    const validateValue = useCallback(
        (ledgerName: string) => isNameValid(ledgerName, names),
        [names]
    );

    const largeLedgerTokens = getQuotaTokens(ResourceTokenType.LargeLedger);
    const ledgerSizeValidationState = isLedgerSizeValid(
        ledgerSize,
        LedgerSizeEnum.LARGE,
        largeLedgerTokens
    );

    const ledgerNameValidationState = validateValue(ledgerName);
    const projectNameValidationState = isNameValid(projectName, []);

    const onSubmit = useCallback(() => {
        const pname = projectName.trim();
        const lname = ledgerName.trim();
        const collaborators = collaboratorIds
            ? Array.from(collaboratorIds || []).map(({ value }) => value)
            : undefined;

        if (project && !!lname) {
            createLedger(project.info, lname, undefined, collaborators, artifacts, ledgerSize);
        } else if (!!pname && !!lname) {
            createProject(pname, lname, undefined, collaborators, artifacts, ledgerSize);
        }

        history.push('/console');
    }, [createProject, projectName, ledgerName]);

    let summary: ISummaryInfo[] = [];

    if (projectName) {
        summary = [...summary, { label: 'Project', value: projectName, pageIndex: 0 }];
    }

    if (ledgerName) {
        summary = [...summary, { label: 'Ledger', value: ledgerName, pageIndex: 0 }];
    }

    if (artifacts.length > 0) {
        summary = [
            ...summary,
            {
                label: 'Artifacts',
                value: (
                    <div className="artifact-summary">
                        {artifacts.map((file, i) => (
                            <UploadedFile key={i} file={file} />
                        ))}
                    </div>
                ),
                pageIndex: 1,
                className: 'artifacts',
            },
        ];
    }

    return (
        <div className="wrapper">
            <MultiStepActionPage
                className="create-new-project-page"
                title={
                    project ? `${project.info.name}: Create a New Ledger` : `Create a New Project`
                }
                subtile={`Add a ${
                    project ? 'ledger to your project.' : 'project to your workspace.'
                }`}
                onSubmit={onSubmit}
                submitButtonLabel="Launch Ledger"
                submitActionDetails="It will take two to five minutes for your ledger to be fully deployed."
                summary={summary}
            >
                <MultiStepActionSubPage
                    trackerLabel="Ledger Details"
                    pageTitle="Provide Ledger Details"
                    disabled={
                        (!project && !projectNameValidationState.isValid) ||
                        !ledgerNameValidationState.isValid ||
                        ledgerSizeValidationState.overLimit
                    }
                >
                    <LedgerDetails
                        project={project}
                        projectName={projectName}
                        ledgerName={ledgerName}
                        ledgerSize={ledgerSize}
                        setLedgerSize={setLedgerSize}
                        ledgerNameValidationState={ledgerNameValidationState}
                        projectNameValidationState={projectNameValidationState}
                        ledgerSizeValidationState={ledgerSizeValidationState}
                        setProjectName={setProjectName}
                        setLedgerName={setLedgerName}
                    />
                    {project && (
                        <div>
                            <div className={'multi-step-action-page-sub-page'}>
                                <h2>Upload Artifacts</h2>
                            </div>
                            <UploadArtifacts artifacts={artifacts} setArtifacts={setArtifacts} />
                        </div>
                    )}
                </MultiStepActionSubPage>
                {!project ? (
                    <MultiStepActionSubPage
                        trackerLabel="Select Artifacts"
                        pageTitle="Upload Artifacts"
                        disabled={!ledgerName}
                    >
                        <UploadArtifacts artifacts={artifacts} setArtifacts={setArtifacts} />
                    </MultiStepActionSubPage>
                ) : null}
                {isProTier ? (
                    <MultiStepActionSubPage
                        trackerLabel="Add Collaborators"
                        pageTitle="Share this ledger with other Daml Hub users."
                        disabled={!ledgerName}
                    >
                        <SelectUserIdsPage
                            ledgerName={ledgerName}
                            setUserIds={setCollaboratorIds}
                            userIds={collaboratorIds}
                        />
                    </MultiStepActionSubPage>
                ) : null}
            </MultiStepActionPage>
            <div className="footer-container">
                <Footer />
            </div>
        </div>
    );
};

const UploadArtifacts: React.FC<{
    artifacts: File[];
    setArtifacts: (artifacts: File[]) => void;
}> = ({ artifacts, setArtifacts }) => {
    const addArtifact = (file: File) => setArtifacts([...artifacts, file]);
    const removeArtifact = (file: File) => setArtifacts(artifacts.filter(f => f !== file));

    return (
        <>
            <p className="caption sdk-info">You can skip this step and add files later.</p>
            <FileUploadDropzone
                className={classNames('file-drop-zone field', {
                    empty: artifacts.length === 0,
                })}
                onUploadRequest={addArtifact}
            >
                {Array.from(artifacts).map((file, i) => (
                    <UploadedFile key={i} file={file} onFileRemove={removeArtifact} />
                ))}
                <FileUploadButton onUploadRequest={addArtifact} ghostButton />
            </FileUploadDropzone>
        </>
    );
};

const LedgerDetails: React.FC<{
    project?: IProjectData;
    projectName?: string;
    ledgerName?: string;
    ledgerNameValidationState: INameValidationState;
    projectNameValidationState: INameValidationState;
    ledgerSizeValidationState: IResourceQuotaValidationState;
    ledgerSize: LedgerSizeEnum;
    setProjectName: (name: string) => void;
    setLedgerName: (name: string) => void;
    setLedgerSize: (type: LedgerSizeEnum) => void;
}> = ({
    project,
    projectName,
    ledgerName,
    ledgerSize,
    setLedgerSize,
    ledgerNameValidationState,
    projectNameValidationState,
    ledgerSizeValidationState,
    setProjectName,
    setLedgerName,
}) => {
    const { isPrimaryEnterprise } = useAppContext();

    return (
        <>
            <>
                {!project && (
                    <>
                        <div className="field">
                            <p>Project Name</p>
                            <input
                                type="text"
                                autoFocus={!project}
                                value={projectName}
                                onChange={e => setProjectName(e.target.value)}
                            />
                            <NameValidationBlock validationState={projectNameValidationState} />
                        </div>
                    </>
                )}
                <div className="field">
                    <p>Ledger Name</p>
                    <input
                        type="text"
                        autoFocus={!!project}
                        value={ledgerName}
                        onChange={e => setLedgerName(e.target.value.trim())}
                    />
                    <NameValidationBlock validationState={ledgerNameValidationState} uniqueName />
                </div>
                {isPrimaryEnterprise && (
                    <div className="field">
                        <p>Ledger Size</p>
                        <div className="ledger-size-options">
                            <div
                                className={classNames('ledger-size-option', {
                                    selected: ledgerSize === LedgerSizeEnum.SMALL,
                                })}
                                onClick={() => setLedgerSize(LedgerSizeEnum.SMALL)}
                            >
                                <IconRadio filled={ledgerSize === LedgerSizeEnum.SMALL} />
                                <p>Standard</p>
                                <div className="default-tag">Default</div>
                            </div>
                            <div
                                className={classNames('ledger-size-option', {
                                    selected: ledgerSize === LedgerSizeEnum.LARGE,
                                    disabled: ledgerSizeValidationState.atLimit,
                                })}
                                onClick={() => setLedgerSize(LedgerSizeEnum.LARGE)}
                            >
                                <IconRadio
                                    filled={ledgerSize === LedgerSizeEnum.LARGE}
                                    disabled={ledgerSizeValidationState.atLimit}
                                />
                                <p>High Capacity</p>
                            </div>
                        </div>
                        <ResourceQuotaValidationBlock
                            validationState={ledgerSizeValidationState}
                            resourceDescription="high capacity ledger"
                        />
                    </div>
                )}
            </>
        </>
    );
};

const UploadedFile: React.FC<{
    file: File;
    onFileRemove?: (file: File) => void;
}> = ({ file, onFileRemove }) => {
    const fileTag = determineFileTag(file.name);
    return (
        <div className="uploaded-file">
            <div className="p2">
                <b>{file.name}</b>
                {fileTag && <ArtifactFileTag tag={fileTag} />}
            </div>
            {onFileRemove && (
                <button className="uploaded-file-close ghost" onClick={() => onFileRemove(file)}>
                    <IconClose />
                </button>
            )}
        </div>
    );
};

export default CreateNewProjectPage;
