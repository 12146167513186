import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import { history } from '@hub-fe/common/ControllableBrowserRouter';
import InformationBlock from '@hub-fe/common/InformationBlock';
import { CreateInstanceRequestPqsPostgresUserEnum } from '@hub-fe/common/generated/clients/damlhub';
import { NavLink } from '@hub-fe/common/routing';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import {
    useEntityInfo,
    useIntegrationName,
    useLedgerActions,
    useLedgerContext,
} from '../LedgerContext';
import DeploymentInstanceConfigurator from './DeploymentInstanceConfigurator';

const NewDeploymentInstance: React.FC = () => {
    const { artifactHash } = useServiceParams();
    const integrationName = useIntegrationName(artifactHash);
    const { getDeployedArtifactInstancesByHash, getPublishedArtifactByHash } = useLedgerActions();
    const ledgerArtifactInstancesCount = getDeployedArtifactInstancesByHash(
        artifactHash,
        integrationName
    ).length;
    const { isLedgerOwner, ledger } = useLedgerContext();
    const publishedDeployedArtifact = getPublishedArtifactByHash(artifactHash, integrationName);
    const status = ledger?.info.status.ledger;

    const isParticipant = ledger?.serviceType === ServiceType.PARTICIPANT;
    const isLedger = ledger?.serviceType === ServiceType.LEDGER;

    const entityInfo = useEntityInfo(artifactHash, integrationName);
    const participantMessage =
        'Participant needs to be running and connected to a synchronizer to configure new instance.';
    const nonParticipantMessage = `${
        isLedger ? 'Ledger' : 'Service'
    } needs to be running to configure new instance.`;
    const message = isParticipant ? participantMessage : nonParticipantMessage;

    if (!entityInfo) {
        return null;
    }

    return (
        <div className="new-deployment-instance">
            <h2 className="row">Configure New Instance</h2>
            {status !== ObjectStatus.RUNNING && (
                <InformationBlock enableBackground>
                    <p className="p2">{message}</p>
                </InformationBlock>
            )}
            {ledgerArtifactInstancesCount > 0 && !isLedgerOwner ? (
                <p className="p2">
                    The owner of this ledger only allows one running instance of this deployment.
                    Create a new deployment by
                    <NavLink to={'./deployments'}> un-deploying </NavLink>
                    the existing one.
                </p>
            ) : (
                <DeploymentInstanceConfigurator
                    artifact={entityInfo}
                    initialFieldValues={{
                        pqsPostgresUser: CreateInstanceRequestPqsPostgresUserEnum.Pqsreader,
                    }}
                    publishedDeployedArtifact={publishedDeployedArtifact}
                    onComplete={() => history.push('.')}
                />
            )}
        </div>
    );
};

export default NewDeploymentInstance;
