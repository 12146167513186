import React, { useState } from 'react';

import { IParticipantData, IScratchpadData } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import { ModalMultiSelect } from '@hub-fe/common/ModalSelect';

import Modal, { ModalControls } from './Modal';

export const DeployFileWizardNew: React.FC<{
    fileNames?: string[];
    appName?: string;
    onRequestClose: (deployRequested: boolean) => void;
}> = ({ fileNames, onRequestClose, appName }) => {
    const { activeParticipants, activeScratchpads } = useAppContext();
    const { deployWorkspaceFileToLedger } = useAppActions();
    const allServices = [...activeParticipants, ...activeScratchpads];
    const allServicesTransformedForModal = allServices.map(service => ({
        id: service.info.id,
        serviceType: service.serviceType,
        name: service.info.name,
    }));
    const [selectedServices, setSelectedServices] = useState<
        (IParticipantData | IScratchpadData)[]
    >([allServices[0]]);

    const submitDeployment = () => {
        onRequestClose(true);
        selectedServices.forEach(service =>
            deployWorkspaceFileToLedger({ ledger: service, fileNames: fileNames || [] })
        );
    };
    let deployList: string[] = [];

    if (fileNames) {
        deployList = fileNames;
    } else if (appName) {
        deployList = [appName];
    }
    const [deployListLength, setDeployListLength] = React.useState(5);

    const selectedServiceIds = new Set(selectedServices.map(l => l.info.id));

    return (
        <Modal
            className="deploy-file-wizard"
            title={`Deploy ${appName ? appName : ''} to...`}
            show
            onSubmit={submitDeployment}
            onRequestClose={() => onRequestClose(false)}
        >
            <div className="wizard">
                <div className="wizard-body">
                    <div className="wizard-page">
                        <p className="options-list">
                            Choose a project to deploy{' '}
                            {deployList.slice(0, deployListLength).join(', ')}
                            {deployListLength < deployList.length && (
                                <>
                                    {', '}
                                    <button
                                        className="secondary-smaller p2"
                                        onClick={() => setDeployListLength(deployListLength + 5)}
                                    >
                                        ...view more
                                    </button>
                                </>
                            )}
                        </p>
                        <ModalMultiSelect
                            items={allServicesTransformedForModal}
                            selectedItems={selectedServiceIds}
                            setSelectedItems={(ids: Set<String>) =>
                                setSelectedServices(allServices.filter(l => ids.has(l.info.id)))
                            }
                        />
                        <ModalControls>
                            <button type="button" onClick={submitDeployment}>
                                Submit
                            </button>
                        </ModalControls>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
