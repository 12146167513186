import React from 'react';

import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { Footer } from '@hub-fe/footer/Footer';
import Collections from '@hub-fe/workspace/Collections';

import MenuBar from './MenuBar';
import './WorkspaceNewLayout.scss';
import { WorkspaceQuotaWarnings } from './WorkspaceQuotaWarnings';

export const defaultServicePath = '/console/create/scratchpad';
export enum WorkspacePageEnum {
    SCRATCHPAD = 'scratchpad',
    NODES = 'nodes',
}
const Header: React.FC = () => {
    return (
        <div className="workspace-title">
            <h1>Workspace</h1>
            <button className="button" onClick={() => history.push(defaultServicePath)}>
                Create New
            </button>
        </div>
    );
};
const Tabs: React.FC = () => (
    <div className="menu-bar">
        <MenuBar
            entries={[
                { label: 'Nodes', to: WorkspacePageEnum.NODES },
                { label: 'Scratchpad', to: WorkspacePageEnum.SCRATCHPAD },
            ]}
        />
    </div>
);

interface WorkSpaceNewLayoutProps {
    enableEmptyWorkspace?: boolean;
    children?: React.ReactNode;
}
export const WorkSpaceNewLayout: React.FC<WorkSpaceNewLayoutProps> = ({
    children,
    enableEmptyWorkspace,
}) => {
    return (
        <div className="workspace-new">
            <WorkspaceQuotaWarnings />
            <Header />
            {!enableEmptyWorkspace && <Tabs />}
            <div className="main">
                {!enableEmptyWorkspace && (
                    <div className="left-sidebar">
                        <Collections />
                    </div>
                )}
                <div className="main-column">
                    {children}
                    <Footer />
                </div>
            </div>
        </div>
    );
};
