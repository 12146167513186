import { useNavigate } from '@reach/router';
import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';
import { ConnectButton } from '@hub-fe/participantNode/ConnectButton';

import './ConnectOwnParticipant.scss';
import { IconSmallCircle } from './Icons';
import { participantStatusIndicator } from './displayedStatusesConfig';
import { getDisabledOwnedParticipants } from './utils/getDisabledOwnedParticiapnts';

interface ConnectOwnparticipantProps {
    synchronizerName: string;
    synchronizerUrl: string;
}
export const ConnectOwnParticipant: React.FC<ConnectOwnparticipantProps> = ({
    synchronizerName,
    synchronizerUrl,
}) => {
    const { activeParticipants: ownedParticipants } = useAppContext();
    const { allowedParticipants } = useLedgerContext();
    const disabledOwnedParticipants = getDisabledOwnedParticipants({
        ownedParticipants,
        allowedParticipants,
    });

    const nav = useNavigate();
    const goToParticipant = (participantServiceId: string) => {
        nav(`/console/participant/${participantServiceId}`);
    };
    if (disabledOwnedParticipants.length === 0) {
        return null;
    }

    return (
        <div className="connect-own-participant">
            <div>
                <h4>Want to connect your own Participant?</h4>
            </div>
            <div className="desc">
                <p>
                    Connect your participant nodes that do not have a connection to this
                    synchronizer.
                </p>
            </div>
            <div>
                {disabledOwnedParticipants.map(p => {
                    const isConnecting = p.info.status.ledger === ObjectStatus.CONNECTING;
                    return (
                        <div className="participant tile" key={p.info.id}>
                            <div onClick={() => goToParticipant(p.info.id)} className="name">
                                {p.info.name}
                            </div>
                            <div>
                                <input readOnly value={p.info.participantId} />
                            </div>

                            <div className="status">
                                <IconSmallCircle
                                    color={
                                        participantStatusIndicator[p.info.status.ledger]?.color ||
                                        'disabled'
                                    }
                                />
                                <>{participantStatusIndicator[p.info.status.ledger]?.label}</>
                            </div>

                            <div className="connect">
                                <ConnectButton
                                    isConnecting={isConnecting}
                                    disabled={p.info.status.ledger === ObjectStatus.STARTING}
                                    serviceId={p.info.id}
                                    name={synchronizerName}
                                    hostName={synchronizerUrl}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
