import classNames from 'classnames';
import copy from 'clipboard-copy';
import React, { useState } from 'react';
import { useCallback } from 'react';

import { ObjectStatus, ILedgerParty } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import BackwardsLink from '@hub-fe/common/BackwardsLink';
import { IBaseSelectOption } from '@hub-fe/common/BaseSelect';
import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import { CopyActions } from '@hub-fe/common/CopyActions';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { IconAddPlus, IconClose } from '@hub-fe/common/Icons';
import PartyDropdown, {
    filterPartyOptions,
    formatSelectionLedgerPartyData,
} from '@hub-fe/common/PartyDropdown';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import { IReactTableRow, EMPTY_HEADER } from '@hub-fe/common/ReactTable/ReactTableUtils';
import { useInterval } from '@hub-fe/common/Timing';
import { UpdatePrimaryParty } from '@hub-fe/common/UpdatePrimaryParty';
import {
    RightsResponse,
    PartyRightsTypeEnum,
    MinimalUserDetails,
} from '@hub-fe/common/generated/clients/damlhub';
import { NavLink, Route, Router } from '@hub-fe/common/routing';
import { formatCopyToken } from '@hub-fe/common/utils/formatCopyToken';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { useMessages } from '@hub-fe/messages/MessageContext';

import AddIdentityModal, { IdentityTypeEnum } from '../ActiveContracts/AddIdentityModal';
import {
    useLedgerActions,
    useLedgerContext,
    ILedgerUserDetails,
    getSelectedParty,
} from '../LedgerContext';

const Users: React.FC<{}> = () => {
    const {
        isLedgerOwner,
        ledger,
        users = [],
        subdomains,
        ledgerId,
        ownerParties,
    } = useLedgerContext();

    const { userInfo } = useAppContext();
    const { service } = useServiceParams();
    const selectedParty =
        ownerParties && getSelectedParty(ownerParties, undefined, undefined, userInfo);
    !isLedgerOwner &&
        userInfo &&
        users.push({ userId: userInfo.userId, primaryParty: selectedParty });
    const { userId: selectedUserId } = useServiceParams();
    const { mintUserToken } = useLedgerActions();
    const { error } = useMessages();
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const userPageUrl = `/console/${service}/${ledger?.info.id}/identities/users`;
    const selectedUser = users.find(user => user.userId === selectedUserId);
    const userRows: IReactTableRow[] = users.map(user => {
        const isSelectedUser = selectedUser?.userId === user.userId;

        return {
            rowData: [
                {
                    sortKey: user.userId,
                    renderAs: (
                        <p className={classNames('p2', { 'user-selected': isSelectedUser })}>
                            {user.userId}
                        </p>
                    ),
                },
                {
                    sortKey: 'Copy',
                    renderAs: <CopyActions handleCopy={format => handleCopy(format, user)} />,
                },
            ],
            url: isSelectedUser ? userPageUrl : user.userId,
        };
    });

    const ledgerIsRunning = ledger?.info.status.ledger === ObjectStatus.RUNNING;
    const headerButtons =
        ledgerIsRunning && isLedgerOwner
            ? [
                  <button
                      className="secondary-smaller icon-left add-user-button"
                      onClick={() => setShowAddUserModal(true)}
                      key="add-user-button"
                  >
                      <IconAddPlus /> Add User
                  </button>,
              ]
            : [];

    return (
        <div
            className={classNames('users', {
                'user-details-open': !!selectedUser,
            })}
        >
            {users.length > 0 ? (
                <div className="identities-table">
                    <div className="narrow-mock-table-header">{headerButtons}</div>

                    <ReactTable
                        tableHeaders={[{ label: 'User' }, EMPTY_HEADER]}
                        tableRows={userRows}
                        headerButtons={headerButtons}
                        isSortable
                        defaultPageSize={10}
                        disablePagination={false}
                        tabOpened={!!selectedUser}
                    />
                    {!!selectedUser && <div className="whitespace-element" />}
                </div>
            ) : (
                <div className="no-users">
                    <p className="p2"> No users have been created on this ledger.</p>
                    {isLedgerOwner && (
                        <button
                            className="secondary-smaller icon-left add-user-button"
                            onClick={() => setShowAddUserModal(true)}
                            key="add-user-button"
                        >
                            <IconAddPlus /> Add User
                        </button>
                    )}
                </div>
            )}
            <Router>
                {selectedUser && (
                    <Route
                        path={`:userId`}
                        render={() => (
                            <UserDetails
                                userDetails={selectedUser}
                                headerButtons={headerButtons}
                                userPageUrl={userPageUrl}
                                handleCopy={handleCopy}
                            />
                        )}
                    />
                )}
                <Route default render={() => <></>}></Route>
            </Router>
            {showAddUserModal && (
                <AddIdentityModal
                    onRequestClose={() => setShowAddUserModal(false)}
                    identityType={IdentityTypeEnum.USER}
                />
            )}
        </div>
    );

    async function handleCopy(format: string, user?: MinimalUserDetails) {
        if (!subdomains?.default || !user?.userId) {
            error('There was an error copying this token.');
            return;
        }

        const token = await mintUserToken(user?.userId);
        if (token) {
            const content = formatCopyToken(
                format,
                ledgerId,
                subdomains?.default.domain,
                token,
                user?.primaryParty || '',
                true
            );

            if (content) {
                await copy(content);
            } else {
                error('There was an error copying this token.');
            }
        }
    }
};

enum UserRightsAsEnum {
    ACT_AS = 'Act As',
    READ_AS = 'Read As',
}

const UserDetails: React.FC<{
    userDetails: MinimalUserDetails;
    headerButtons: JSX.Element[];
    userPageUrl: string;
    handleCopy: (format: string, user: MinimalUserDetails) => void;
}> = ({ userDetails, headerButtons, userPageUrl, handleCopy }) => {
    const { isLedgerOwner } = useLedgerContext();
    const { userInfo } = useAppContext();
    const isReadOnlyUser =
        userDetails.userId.includes('public') || userDetails.userId.includes('useradmin');
    const showDeleteButton =
        userInfo?.userId !== userDetails.userId && !isReadOnlyUser && isLedgerOwner;
    const { deleteUser, grantUserRights, revokeUserRights, listUserRights, updateUsers } =
        useLedgerActions();
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const modalBody = 'Are you sure you want to delete this user?';
    const modalTitle = 'Deleting User';
    const onClose = () => {
        setOpenModal(false);
    };

    const [openEdit, setEdit] = React.useState(false);

    const onToggleOpen = () => {
        setEdit(true);
    };
    const onToggleClose = () => {
        setEdit(false);
    };
    const [showAssociateParty, setShowAssociateParty] = useState<UserRightsAsEnum | undefined>();
    const [
        user = { userId: userDetails.userId, primaryParty: userDetails.primaryParty, rights: [] },
        setUser,
    ] = useState<ILedgerUserDetails>();
    // this is to refresh (via polling) only the selected User rights
    // as opposed to refreshing all Users' rights globally in LedgerContext,
    // which would be too expensive
    const setUserRights = async () => {
        const user = await listUserRights(userDetails);
        setUser(user);
    };
    useInterval(
        'refresh selected user rights',
        useCallback(() => {
            const refresh = async () => {
                const user = await listUserRights(userDetails);
                setUser(user);
            };
            refresh();
        }, [userDetails]),
        5000
    );

    const onRequestClose = () => setShowAssociateParty(undefined);
    const { userId, primaryParty, rights } = user;
    const readAsParties = rights.filter(({ type }) => type === PartyRightsTypeEnum.CanReadAs);
    const actAsParties = rights.filter(({ type }) => type === PartyRightsTypeEnum.CanActAs);

    return (
        <div className="user-details">
            <div className="mock-table-header">
                <BackwardsLink url={userPageUrl} />
                {headerButtons}
            </div>
            <div className="user-details-content">
                <div className="user-details-heading">
                    <div className="user-details-name">
                        <h2>{userId}</h2>
                    </div>
                    <NavLink className="close button ghost" to={userPageUrl}>
                        <IconClose />
                    </NavLink>
                </div>
                {!openEdit ? (
                    <div className="edit-container">
                        <div className="user-details-sub-heading">
                            <p className="p2 primary-party"> Primary Party:</p>
                            {primaryParty && <CopyableDisplay value={primaryParty} />}
                            <button onClick={onToggleOpen} className="edit secondary-smaller">
                                {!primaryParty ? 'Add' : 'Edit'}
                            </button>
                        </div>
                    </div>
                ) : (
                    <UpdatePrimaryParty
                        userId={userId}
                        toggleOpen={onToggleClose}
                        currPrimaryParty={primaryParty || ''}
                    />
                )}

                <CopyActions
                    handleCopy={format => handleCopy(format, user)}
                    customLabel="Copy User Token:"
                />
                {showDeleteButton && (
                    <div className="delete-container">
                        <button
                            className="secondary-smaller warning delete-user"
                            onClick={() => setOpenModal(true)}
                        >
                            Delete User
                        </button>
                    </div>
                )}

                <div className="divider"></div>
                <h3>Rights:</h3>
                <div className="user-rights">
                    <h4>{UserRightsAsEnum.ACT_AS}</h4>
                    <p className="caption user-rights-description">
                        This user can act as the following associated parties:
                    </p>
                    {isLedgerOwner && (
                        <AssociatePartyTile
                            show={showAssociateParty === UserRightsAsEnum.ACT_AS}
                            onRequestOpen={() => setShowAssociateParty(UserRightsAsEnum.ACT_AS)}
                            onRequestClose={onRequestClose}
                            associateAs={UserRightsAsEnum.ACT_AS}
                            onAssociateParty={async party => {
                                await grantUserRights({
                                    userId,
                                    parties: [party],
                                    rightsType: PartyRightsTypeEnum.CanActAs,
                                });
                                setUserRights();
                            }}
                        />
                    )}
                    <AssociatedParties
                        associatedParties={actAsParties}
                        onRemoveAssociatedParty={async party => {
                            await revokeUserRights({ userId, party });
                            setUserRights();
                        }}
                    />
                </div>
                <div className="user-rights">
                    <h4>{UserRightsAsEnum.READ_AS}</h4>
                    <p className="caption user-rights-description">
                        This user can read as the following associated parties:
                    </p>
                    {isLedgerOwner && (
                        <AssociatePartyTile
                            show={showAssociateParty === UserRightsAsEnum.READ_AS}
                            onRequestOpen={() => setShowAssociateParty(UserRightsAsEnum.READ_AS)}
                            onRequestClose={onRequestClose}
                            associateAs={UserRightsAsEnum.READ_AS}
                            onAssociateParty={async party => {
                                await grantUserRights({
                                    userId,
                                    parties: [party],
                                    rightsType: PartyRightsTypeEnum.CanReadAs,
                                });
                                setUserRights();
                            }}
                        />
                    )}
                    <AssociatedParties
                        associatedParties={readAsParties}
                        onRemoveAssociatedParty={async party => {
                            await revokeUserRights({ userId, party });
                            setUserRights();
                        }}
                    />
                </div>
            </div>
            <ConfirmActionModal
                deletingAction
                action="Delete User"
                show={openModal}
                bodyMessage={modalBody}
                title={modalTitle}
                onRequestClose={onClose}
                onSubmitAction={async () => {
                    await deleteUser({ userId });
                    setUserRights();
                    setOpenModal(false);
                    updateUsers();
                }}
            />
        </div>
    );
};

const AssociatedParties: React.FC<{
    associatedParties: RightsResponse[];
    onRemoveAssociatedParty: (party: RightsResponse) => void;
}> = ({ associatedParties, onRemoveAssociatedParty }) => {
    const { userParties = [], ownerParties = [], isLedgerOwner } = useLedgerContext();

    if (associatedParties.length === 0) {
        return <></>;
    }

    return (
        <div className="associated-party-list">
            {associatedParties.map(
                (party, i) =>
                    party.party && (
                        <div className="associated-party p2" key={i}>
                            Party: {getPartyName(party.party)}
                            {isLedgerOwner && (
                                <button
                                    className="ghost"
                                    onClick={() => {
                                        onRemoveAssociatedParty(party);
                                    }}
                                >
                                    <IconClose />
                                </button>
                            )}
                        </div>
                    )
            )}
        </div>
    );

    function getPartyName(partyId: string): string {
        const partyInfo = [...userParties, ...ownerParties].find(p => p.identifier === partyId);
        return partyInfo?.displayName || partyId;
    }
};

const AssociatePartyTile: React.FC<{
    show: boolean;
    associateAs: UserRightsAsEnum;
    onRequestClose: () => void;
    onRequestOpen: () => void;
    onAssociateParty: (party: ILedgerParty) => void;
}> = ({ onRequestClose, onRequestOpen, show, associateAs, onAssociateParty }) => {
    const [selectedParty, setSelectedParty] = useState<ILedgerParty>();
    const {
        ledger,
        isLedgerOwner,
        ownerParties = [],
        defaultParties,
        userParties,
    } = useLedgerContext();

    const otherParties = userParties || [];
    const allParties = [...ownerParties, ...otherParties];
    const parties = filterPartyOptions(
        allParties,
        defaultParties,
        !isLedgerOwner,
        !isLedgerOwner,
        true
    );
    if (!show) {
        return (
            <button className="ghost icon-left" onClick={onRequestOpen}>
                <IconAddPlus /> Associate Party
            </button>
        );
    }

    return (
        <div className="associate-party-tile">
            <div className="associate-party-tile-heading">
                <h4> Associate {associateAs} Party</h4>
                <button className="ghost" onClick={onRequestClose}>
                    <IconClose />
                </button>
            </div>
            <div className="party-control">
                <PartyDropdown
                    selectedParty={selectedParty?.displayName}
                    onChange={(p: IBaseSelectOption<ILedgerParty>) => setSelectedParty(p.value)}
                    partyOptions={formatSelectionLedgerPartyData(parties)}
                    ledgerStatus={ledger?.info.status.ledger}
                />
                <button
                    disabled={!selectedParty}
                    onClick={() => {
                        if (selectedParty) {
                            onAssociateParty(selectedParty);
                            onRequestClose();
                        }
                    }}
                >
                    Add
                </button>
            </div>
        </div>
    );
};

export default Users;
