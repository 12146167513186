import React from 'react';

import { SideBar } from '@hub-fe/common/Sidebar/SideBar';
import { getParticipantSideBarLinks } from '@hub-fe/common/Sidebar/config';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import { useAppContext } from '../AppContext';

export const ParticipantSideBar: React.FC = () => {
    const { isProTier } = useAppContext();

    const { isLedgerOwner } = useLedgerContext();
    const sideBarLinks = getParticipantSideBarLinks({
        isProTier,
        isLedgerOwner,
    });

    return <SideBar sideBarLinks={sideBarLinks} />;
};
