import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';

import { history } from '@hub-fe/common/ControllableBrowserRouter';

import { IconClose, CircledNumber } from './Icons';

interface IMultiStepActionPageProps {
    trackerLabel: string;
    pageTitle: string;
    disabled?: boolean;
    className?: string;
    children?: React.ReactNode;
}

export const MultiStepActionSubPage: FunctionComponent<IMultiStepActionPageProps> = props => (
    <div className={classNames('multi-step-action-page-sub-page', props.className)}>
        <h2>{props.pageTitle}</h2>
        {props.children}
    </div>
);

export interface ISummaryInfo {
    label: string;
    value: string | JSX.Element;
    pageIndex: number;
    className?: string;
}

interface IProps {
    onSubmit: () => void;
    className: string;
    title: string;
    subtile: string;
    submitActionDetails?: string;
    children: Array<React.ReactElement<IMultiStepActionPageProps> | null>;
    summary?: ISummaryInfo[];
    submitButtonLabel: string;
}

const MultiStepActionPage: FunctionComponent<IProps> = ({
    onSubmit,
    title,
    subtile,
    className,
    submitActionDetails,
    children,
    submitButtonLabel,
    summary = [],
}) => {
    const [page, setPage] = useState(0);

    const activeChildren = children.filter(c => c !== null);
    const currentStep = activeChildren[page];

    const checkIsDisabled = (isDisabled?: boolean): boolean =>
        isDisabled || !!activeChildren.find((s, i) => s?.props.disabled && i < page);
    const currentSummary = summary.filter(s => s.pageIndex < page);

    return (
        <div className={classNames('multi-step-action-page', className)}>
            <div className="multi-step-action-page-body">
                <div className="title-row">
                    <h1>{title}</h1>
                    <button className="close-button ghost" onClick={() => history.goBack()}>
                        <IconClose />
                    </button>
                </div>
                <p className="p2 sub-tile">{subtile}</p>
                <div className="step-tracker">
                    {activeChildren.length >= 2 &&
                        activeChildren.map(
                            (child, i) =>
                                child && (
                                    <MultiStepActionPageStep
                                        key={i}
                                        visited={i < page}
                                        disabled={checkIsDisabled(child.props.disabled)}
                                        isCurrent={
                                            currentStep?.props.trackerLabel ===
                                            child.props.trackerLabel
                                        }
                                        index={i}
                                        onClick={() => setPage(i)}
                                        includeSeparator={i !== activeChildren.length - 1}
                                        label={child?.props.trackerLabel}
                                    />
                                )
                        )}
                </div>
                {currentSummary.length > 0 && (
                    <div className="multi-step-action-page-summary">
                        {currentSummary.map((s, i) => (
                            <div className={classNames('page-summary', s.className)} key={i}>
                                <h4 className="p2">{s.label}</h4>
                                <p className="p2">{s.value}</p>
                            </div>
                        ))}
                    </div>
                )}
                <div className="multi-step-action-page-body">{activeChildren[page]}</div>

                <div className="multi-step-action-page-controls action-bar">
                    {allowPage(page - 1) && (
                        <button
                            className="secondary"
                            id="prev-page"
                            type="button"
                            onClick={() => prevPage()}
                        >
                            Go Back
                        </button>
                    )}
                    {onLastPage() ? (
                        <button
                            id="next-page"
                            type="button"
                            disabled={isAdvanceDisabled()}
                            onClick={() => handleSubmit()}
                        >
                            {submitButtonLabel}
                        </button>
                    ) : (
                        <button
                            id="next-page"
                            type="button"
                            disabled={isAdvanceDisabled()}
                            onClick={() => nextPage()}
                        >
                            Continue
                        </button>
                    )}
                    {submitActionDetails && onLastPage() && (
                        <p className="p2">{submitActionDetails}</p>
                    )}
                </div>
            </div>
        </div>
    );

    function handleSubmit() {
        if (onLastPage()) {
            onSubmit();
            setPage(0);
        } else {
            nextPage();
        }
    }

    function onLastPage(): boolean {
        return page === activeChildren.length - 1;
    }

    function isAdvanceDisabled(): boolean {
        const childPage = activeChildren[page];
        return !!childPage?.props.disabled;
    }

    function allowPage(page: number): boolean {
        return page >= 0 && page < activeChildren.length;
    }

    function prevPage() {
        if (allowPage(page - 1)) {
            setPage(page - 1);
        }
    }

    function nextPage() {
        if (!onLastPage()) {
            setPage(page + 1);
        }
    }
};

const MultiStepActionPageStep: React.FC<{
    disabled: boolean;
    visited: boolean;
    onClick: () => void;
    isCurrent: boolean;
    index: number;
    includeSeparator: boolean;
    label: string;
}> = ({ visited, disabled, onClick, isCurrent, index, includeSeparator, label }) => (
    <>
        <button
            onClick={() => !disabled && onClick()}
            className={classNames(
                'multi-step-action-page-step',
                {
                    current: isCurrent,
                },
                { disabled },
                { visited }
            )}
        >
            <CircledNumber number={index + 1} isActive={isCurrent} />
            <h4 className="p2">{label}</h4>
        </button>
        {includeSeparator && <h4 className={classNames('separator', { visited })}>{'>'}</h4>}
    </>
);

export default MultiStepActionPage;
