import Form from '@rjsf/core';
import { ArrayFieldTemplateProps, ObjectFieldTemplateProps, FieldTemplateProps } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import classNames from 'classnames';
import { JSONSchema7 as JsonSchema } from 'json-schema';
import React, { useEffect, useState } from 'react';

import { IconAddPlus, IconClose } from '@hub-fe/common/Icons';
import LoadingButton from '@hub-fe/common/LoadingButton';
import LoadingPage from '@hub-fe/common/LoadingPage';
import {
    PrimTypeEnum,
    IDamlTypeDefinitions,
    DamlType,
} from '@hub-fe/common/TemplateFieldsInterfaces';
import { formatTemplateName } from '@hub-fe/common/utils';

import { BooleanField, NumberField, StringField } from './ContractTemplateFields';
import {
    GEN_MAP_VALUE,
    GEN_MAP_KEY,
    DEFAULT_OBJECT,
    createSchemaObject,
    updateMapEntries,
    TEXT_MAP_KEY,
    TEXT_MAP_VALUE,
    MAP_PAIR,
    JsonSchemaTypeEnum,
    checkIsSet,
    checkIsUnsupportedType,
    formatFieldName,
} from './ContractTemplateSchema';

// Potential Improvements (TODO):
// 1. add payload viewer with LoadableReactJson and editable values
// 2. add multiselect dropdowns and creatable select to prim-type list and sets
// 3. add modals for creating deeply nested components
// 5. Ensure responsive view

const ContractTemplateForm: React.FC<{
    definitions: IDamlTypeDefinitions;
    label: string;
    onSubmit: (payload: object, onError: () => void) => void;
    type: { [field: string]: DamlType };
}> = ({ onSubmit, definitions, label, type }) => {
    const [schema, setSchema] = useState<JsonSchema>();
    const [formData, setFormData] = useState<object>({});
    const [isSubmitting, setSubmitting] = React.useState(false);

    const handleSubmitForm = async () => {
        try {
            setSubmitting(true);
            if (!hasFields) {
                await onSubmit({}, () => setSubmitting(false));
            } else {
                const payload = { ...formData };
                updateMapEntries(payload);
                await onSubmit(payload, () => setSubmitting(false));
            }
        } catch (e) {
            alert(e);
            setSubmitting(false);
        }
    };
    useEffect(() => {
        const formattedTemplateName = formatTemplateName(label);
        const schema = createSchemaObject(
            formattedTemplateName,
            type,
            formattedTemplateName,
            definitions,
            DEFAULT_OBJECT,
            [label]
        );
        setSchema(schema);
    }, [type, definitions, label]);

    const hasFields = Object.keys(type).length > 0;

    if (!schema) {
        return (
            <div className="contract-template-form">
                <LoadingPage statusText="Loading template..." />;
            </div>
        );
    }

    if (!hasFields) {
        return (
            <div className="contract-template-form">
                <LoadingButton
                    loading={isSubmitting}
                    className="submit-button"
                    onClick={() => handleSubmitForm()}
                >
                    Submit
                </LoadingButton>
            </div>
        );
    }

    return (
        <div className="contract-template-form">
            <div className="p2 form-settings">
                <div className="required-field-info">
                    <span className="required-field-asterisk">*</span>
                    <p className="p2">Indicates Required field</p>
                </div>
            </div>
            <div className="template-form">
                <Form
                    schema={schema}
                    omitExtraData
                    noHtml5Validate
                    fields={{
                        BooleanField,
                        NumberField: NumberField,
                        IntegerField: NumberField,
                        StringField,
                    }}
                    formData={formData}
                    showErrorList={false}
                    onChange={({ formData }) => setFormData(formData)}
                    templates={{
                        FieldTemplate: CustomFieldTemplate,
                        ObjectFieldTemplate,
                        ArrayFieldTemplate,
                    }}
                    validator={validator}
                >
                    <LoadingButton
                        loading={isSubmitting}
                        onClick={handleSubmitForm}
                        className="submit-button"
                    >
                        Submit
                    </LoadingButton>
                </Form>
            </div>
        </div>
    );
};

const CustomFieldTemplate: React.FC<FieldTemplateProps> = ({
    id,
    children,
    classNames: fieldClassNames,
    label,
    required,
    schema,
    rawErrors,
}) => {
    const formattedLabel = id === 'root' ? label : formatFieldName(label);

    return (
        <div
            className={classNames(fieldClassNames, {
                'unsupported-type': checkIsUnsupportedType(schema),
            })}
        >
            <label
                className={classNames('field-label', {
                    'has-error': rawErrors && rawErrors.length > 0,
                })}
            >
                {schema.type === JsonSchemaTypeEnum.OBJECT ? (
                    <h4>{formattedLabel}</h4>
                ) : (
                    <p>{formattedLabel}</p>
                )}
                {required && <span className="required-field-asterisk">*</span>}
            </label>
            {children}
        </div>
    );
};

const ObjectFieldTemplate: React.FC<ObjectFieldTemplateProps> = ({
    title,
    description,
    properties,
    schema,
    idSchema,
}) => {
    const isSet = checkIsSet(schema);
    const isRoot = idSchema.$id === 'root';

    if (description === PrimTypeEnum.UNIT) {
        return null;
    }

    if (title === MAP_PAIR) {
        return (
            <>
                {properties.map(({ name, content }, i) => {
                    const label = isSet
                        ? undefined
                        : name === GEN_MAP_KEY || name === TEXT_MAP_KEY
                        ? 'Key'
                        : name === GEN_MAP_VALUE || name === TEXT_MAP_VALUE
                        ? 'Value'
                        : name;

                    if ((name === GEN_MAP_VALUE || name === TEXT_MAP_VALUE) && isSet) {
                        return null;
                    }

                    return (
                        <div className="map-item" key={i}>
                            {label}
                            {content}
                        </div>
                    );
                })}
            </>
        );
    }

    return (
        <fieldset className={classNames({ 'nested-fieldset': !isRoot })}>
            {properties.map(element => element.content)}
        </fieldset>
    );
};

const ArrayFieldTemplate: React.FC<ArrayFieldTemplateProps> = ({
    canAdd,
    className,
    items,
    onAddClick,
    schema,
}) => {
    return (
        <div className={className}>
            {items.map((element, i) => (
                <div
                    className={classNames('array-item', {
                        'map-pair': (schema.items as JsonSchema).title === MAP_PAIR,
                    })}
                    key={i}
                >
                    {element.children}
                    <button className="ghost" onClick={element.onDropIndexClick(element.index)}>
                        <IconClose />
                    </button>
                </div>
            ))}
            {canAdd && (
                <button
                    className="secondary-smaller add-item-button icon-right"
                    onClick={onAddClick}
                >
                    Add Item <IconAddPlus />
                </button>
            )}
        </div>
    );
};

export default ContractTemplateForm;
