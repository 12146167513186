import React, { useMemo, useState } from 'react';

import { ILedgerData, ObjectStatus } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import ExternalLink from '@hub-fe/common/ExternalLink';
import { IconCheckbox } from '@hub-fe/common/Icons';
import { IconCanton } from '@hub-fe/common/Icons';
import LedgerStatusDisplay from '@hub-fe/common/LedgerStatusDisplay';
import LoadingButton from '@hub-fe/common/LoadingButton';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import { IReactTableRow, EMPTY_HEADER } from '@hub-fe/common/ReactTable/ReactTableUtils';
import { toDateFormatted } from '@hub-fe/common/Timing';

const LedgerHistory: React.FC = () => {
    const { ownedLedgers } = useAppContext();
    const [showArchivedLedgers, setShowArchivedLedgers] = useState<boolean>(false);

    const ledgersToView = useMemo(
        () =>
            showArchivedLedgers
                ? ownedLedgers
                : ownedLedgers.filter(l => l.info.status.ledger !== ObjectStatus.ARCHIVED),
        [ownedLedgers, showArchivedLedgers]
    );

    const ledgerRows: IReactTableRow[] = ledgersToView?.map(ledger => {
        const archived = ledger.info.status.ledger === ObjectStatus.ARCHIVED;

        return {
            rowData: [
                {
                    sortKey: ledger.info.name,
                    renderAs: archived ? undefined : (
                        <ExternalLink
                            className="p2"
                            to={`/console/ledger/${ledger.info.id}/deployments`}
                            icon
                        >
                            {ledger.info.name}
                        </ExternalLink>
                    ),
                },
                {
                    sortKey: 'canton',
                    renderAs: <IconCanton />,
                },
                {
                    sortKey: ledger.info.id,
                    renderAs: <CopyableDisplay value={ledger.info.id} />,
                    displayNarrowViewHeader: true,
                },
                {
                    sortKey: new Date(ledger.info.createdAt),
                    renderAs: (
                        <p className="p2">{toDateFormatted(new Date(ledger.info.createdAt))}</p>
                    ),
                    displayNarrowViewHeader: true,
                },
                {
                    sortKey: ledger.info.status.ledger,
                    renderAs: <LedgerStatusDisplay status={ledger.info.status.ledger} />,
                    displayNarrowViewHeader: true,
                },
                {
                    sortKey: ledger.info.eventCount,
                    displayNarrowViewHeader: true,
                },
                {
                    sortKey:
                        ledger.info.status.ledger === ObjectStatus.STARTING ||
                        ledger.info.status.ledger === ObjectStatus.RUNNING
                            ? 'Pause'
                            : ledger.info.status.ledger === ObjectStatus.PAUSED
                            ? 'Resume'
                            : '',
                    renderAs: <LedgerActions ledger={ledger} />,
                },
            ],
        };
    });

    const hasArchived = ownedLedgers.find(l => l.info.status.ledger === ObjectStatus.ARCHIVED);

    return (
        <div className="ledger-history">
            <div className="title">
                <h2>Ledger History</h2>
                {hasArchived && (
                    <div
                        className="checkbox-col p2"
                        onClick={() => setShowArchivedLedgers(!showArchivedLedgers)}
                    >
                        <IconCheckbox checked={showArchivedLedgers} /> Show Deleted Ledgers
                    </div>
                )}
            </div>
            <ReactTable
                tableRows={ledgerRows}
                tableHeaders={[
                    { label: 'Ledger' },
                    { label: '' },
                    { label: 'Ledger ID' },
                    { label: 'Date Created', sortType: 'datetime' },
                    { label: 'Status' },
                    { label: 'Events' },
                    EMPTY_HEADER,
                ]}
                defaultStyle="roomy-rows"
                isSortable
                emptyTableContent={
                    <div className="p2">
                        There are no ledgers to display. You can create projects and ledgers from{' '}
                        <a href={'/'}> the workspace</a>.
                    </div>
                }
            />
        </div>
    );
};

const LedgerActions = (props: { ledger: ILedgerData }) => {
    const { ledger } = props;
    const { resumeLedger, pauseLedger } = useAppActions();

    if (
        ledger.info.status.ledger === ObjectStatus.ARCHIVED ||
        ledger.info.status.ledger === ObjectStatus.TERMINATING
    ) {
        return null;
    }
    switch (ledger.info.status.ledger) {
        case ObjectStatus.STARTING:
        case ObjectStatus.RUNNING:
            return (
                <LoadingButton
                    className="secondary-smaller"
                    loading={ledger.objectStatus === ObjectStatus.PAUSING}
                    loadingContent="Pausing"
                    onClick={() => pauseLedger(ledger)}
                >
                    Pause
                </LoadingButton>
            );
        case ObjectStatus.PAUSED:
            return (
                <LoadingButton
                    className="secondary-smaller warning"
                    loading={ledger.objectStatus === ObjectStatus.RESUMING}
                    loadingContent="Resuming"
                    onClick={() => resumeLedger(ledger)}
                >
                    Resume
                </LoadingButton>
            );
        default:
            return null;
    }
};

export default LedgerHistory;
