import classNames from 'classnames';
import React from 'react';

import { IconCheckbox, IconRadio } from '@hub-fe/common/Icons';
import { NBSP } from '@hub-fe/common/utils';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import { ServiceTileTag } from './ServiceTileTag';
import { CreateServiceRequestServiceTypeEnum } from './generated/clients/damlhub';

type IModalSelectionState = Set<string>;

export interface IModalSelectInfo {
    id: string;
    name: string;
    serviceType?: ServiceType | CreateServiceRequestServiceTypeEnum | 'ledger';
}

export const ModalMultiSelect: React.FC<{
    items?: IModalSelectInfo[];
    selectedItems: IModalSelectionState;
    setSelectedItems: (selectedItems: IModalSelectionState) => void;
    displayDisabledText?: (item: IModalSelectInfo) => string | undefined;
}> = ({ items, selectedItems, setSelectedItems, displayDisabledText }) => {
    const toggleLedgerSelected = (item: IModalSelectInfo) => {
        if (displayDisabledText && !!displayDisabledText(item)) {
            return;
        }
        const newSet = new Set(selectedItems);
        newSet.has(item.id) ? newSet.delete(item.id) : newSet.add(item.id);
        setSelectedItems(newSet);
    };

    return (
        <div className="modal-select row">
            {items &&
                items.map(item => (
                    <div
                        onClick={() => toggleLedgerSelected(item)}
                        className="selection-row row"
                        key={item.id}
                    >
                        <div className="selection">
                            <IconCheckbox
                                checked={selectedItems.has(item.id)}
                                disabled={displayDisabledText ? !!displayDisabledText(item) : false}
                            />
                            <div
                                className={classNames('tile', {
                                    checked: selectedItems.has(item.id),
                                    disabled: displayDisabledText && !!displayDisabledText(item),
                                })}
                            >
                                <p>{item.name}</p>
                                <div className="item-id">
                                    ID:{NBSP}
                                    {item.id}
                                </div>
                                {item.serviceType && <ServiceTileTag tagLabel={item.serviceType} />}
                            </div>
                        </div>
                        {displayDisabledText && (
                            <p className="disabled-warning caption">{displayDisabledText(item)}</p>
                        )}
                    </div>
                ))}
        </div>
    );
};

export const ModalSingleSelect: React.FC<{
    items?: IModalSelectInfo[];
    selectedItem?: string;
    setSelectedItem: (selectedItem?: string) => void;
    toggle?: boolean;
}> = ({ items, selectedItem, setSelectedItem, toggle }) => {
    const toggleLedgerSelected = (id: string) => {
        if (selectedItem && toggle) {
            setSelectedItem(undefined);
        } else {
            setSelectedItem(id);
        }
    };

    const compareSelectedItem = (id: string) => {
        return selectedItem === id;
    };

    return (
        <ModalSelectBody
            items={items}
            toggleLedgerSelected={toggleLedgerSelected}
            compareSelectedItem={compareSelectedItem}
            isSingleSelect
        />
    );
};

const ModalSelectBody = (props: {
    items?: IModalSelectInfo[];
    toggleLedgerSelected: (itemId: string) => void;
    compareSelectedItem: (itemId: string) => boolean;
    isSingleSelect?: boolean;
}) => {
    return (
        <div className="modal-select row">
            {props.items &&
                props.items.map(item => (
                    <div
                        onClick={() => props.toggleLedgerSelected(item.id)}
                        className="selection-row row"
                        key={item.id}
                    >
                        <div className="selection">
                            {props.isSingleSelect ? (
                                <IconRadio filled={props.compareSelectedItem(item.id)} />
                            ) : (
                                <IconCheckbox checked={props.compareSelectedItem(item.id)} />
                            )}
                            <div
                                className={classNames('tile', {
                                    checked: props.compareSelectedItem(item.id),
                                })}
                            >
                                <p>{item.name}</p>
                                <div className="item-id">
                                    ID:{NBSP}
                                    {item.id}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};
