import classNames from 'classnames';
import React from 'react';

import { IDeployedArtifactInfo, ObjectStatus } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import ActionHeader from '@hub-fe/common/ActionHeader';
import { LoadingDots } from '@hub-fe/common/Animations/Animations';
import { FileUploadButton } from '@hub-fe/common/FileUpload';
import { IconControls } from '@hub-fe/common/Icons';
import InformationBlock from '@hub-fe/common/InformationBlock';
import { NoDeploymentsTile } from '@hub-fe/common/NoDeploymentsTile';
import ReactTable from '@hub-fe/common/ReactTable/ReactTable';
import { TwoColLayout } from '@hub-fe/common/TwoColLayout';
import { validateEntity } from '@hub-fe/common/artifacts';
import { NavLink, Route, Router } from '@hub-fe/common/routing';
import { partitionArray, scrollToTop } from '@hub-fe/common/utils';
import { isNewService } from '@hub-fe/common/utils/isNewService';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import PublishedArtifactsArcade from '@hub-fe/explorer/PublishedArtifactsArcade';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import en from '../../../locales/en.json';
import { useLedgerActions, useLedgerContext } from '../LedgerContext';
import ActionNeededTile from './ActionNeededTile';
import ArtifactTable from './ArtifactTable';
import DefaultDeploymentViewer from './DefaultDeploymentViewer';
import { StateIndicator } from './DeployedArtifactInstanceStateIndicator';
import DeployedArtifactViewer from './DeployedArtifactViewer';
import DeploymentInstanceTable from './DeploymentInstanceTable';
import DeploymentsSettings from './DeploymentsSettings';
import InlineDeploymentInstanceConfig from './InlineDeploymentInstanceConfig';

const deployedArtifactsTableHeaders = [
    { label: 'File Name' },
    { label: 'Upload Date' },
    { label: 'Status' },
    { label: 'Deployment Count' },
];

const DeployedArtifactsBody = () => {
    const { isLedgerOwner, deployedArtifacts, deployedArtifactInstances, ledger } =
        useLedgerContext();
    const { isDeployedArtifactConfigured } = useLedgerActions();
    const { uploadArtifactFromFile } = useAppActions();
    const { service } = useServiceParams();
    if (!service) {
        return null;
    }
    const disableDefaultDeployments = service === ServiceType.PARTICIPANT;
    const [configuredArtifacts, unconfiguredArtifacts] = partitionArray(
        d => isDeployedArtifactConfigured(d),
        deployedArtifacts
    );
    const allInvalid = unconfiguredArtifacts.every(
        d => ledger && validateEntity(d.entityInfo.entity, ledger.info.backend).length > 0
    );
    function doUpload(file: File) {
        if (ledger) {
            uploadArtifactFromFile({ file, ledger });
        }

        scrollToTop();
    }

    const titleButtons: JSX.Element[] = [];
    if (isLedgerOwner) {
        titleButtons.push(
            <FileUploadButton
                key="fileuploadbutton"
                onUploadRequest={file => doUpload(file)}
                ghostButton
            />
        );
    }
    if (deployedArtifacts && deployedArtifacts.length > 0) {
        titleButtons.push(
            <NavLink
                key="deploymentssettings"
                className="button secondary-smaller icon-left"
                to="settings"
            >
                <IconControls /> Deployments Settings
            </NavLink>
        );
    }

    return (
        <div className={classNames('deployed-artifacts', { isLedgerOwner: 'is-ledger-owner' })}>
            <ActionHeader title="Deployments" titleButtons={titleButtons} />
            {deployedArtifacts && deployedArtifactInstances && unconfiguredArtifacts.length > 0 && (
                <ActionNeededTile>
                    {unconfiguredArtifacts?.map(a => (
                        <InlineDeploymentInstanceConfig
                            key={a.entityInfo.artifactHash}
                            artifact={a.entityInfo}
                        />
                    ))}
                </ActionNeededTile>
            )}
            {isLedgerOwner ? (
                <>
                    <UserDeployments
                        unconfiguredArtifacts={unconfiguredArtifacts}
                        configuredArtifacts={configuredArtifacts}
                        doUpload={doUpload}
                    />

                    {unconfiguredArtifacts.length > 0 &&
                        configuredArtifacts.length > 0 &&
                        !allInvalid && (
                            <ReviewActionNeededText withTile={configuredArtifacts.length === 0} />
                        )}
                    {!disableDefaultDeployments && <DefaultDeployments />}
                </>
            ) : (
                <>
                    <PublishedArtifactsArcade />
                    <DeploymentInstanceTable />
                </>
            )}
        </div>
    );
};

interface DeploymentDetailsProps {
    deploymentName: string;
    supportingText: string;
}

const DeploymentDetails: React.FC<DeploymentDetailsProps> = ({
    deploymentName,
    supportingText,
}) => {
    return (
        <div>
            <div>
                <div className="deployment-name-header">{deploymentName}</div>
            </div>
            <div className="supporting-text">{supportingText}</div>
        </div>
    );
};

const DefaultDeployments = () => {
    const { domainStatus, participantStatus, serviceType } = useLedgerContext();
    if (!serviceType) {
        return null;
    }
    if (isNewService(serviceType)) {
        return null;
    }

    const defaultDeploymentRows = [
        {
            rowData: [
                {
                    sortKey: 'Participant',
                    renderAs: (
                        <DeploymentDetails
                            deploymentName="Participant"
                            supportingText={en.defaultDeployments.participantSupportText}
                        />
                    ),
                    displayNarrowViewHeader: false,
                },
                {
                    sortKey: participantStatus?.runState,
                    renderAs: participantStatus?.runState ? (
                        <StateIndicator artifactDeploymentStates={[participantStatus.runState]} />
                    ) : (
                        <p className="p2 loading-status">
                            Waiting for status <LoadingDots />
                        </p>
                    ),
                    displayNarrowViewHeader: true,
                },
            ],
            url: participantStatus?.runState && 'participant',
        },
        {
            rowData: [
                {
                    sortKey: 'Domain',
                    displayNarrowViewHeader: false,
                    renderAs: (
                        <DeploymentDetails
                            deploymentName={'Domain'}
                            supportingText={en.defaultDeployments.domainSupportText}
                        />
                    ),
                },
                {
                    sortKey: domainStatus?.runState,
                    renderAs: domainStatus?.runState ? (
                        <StateIndicator artifactDeploymentStates={[domainStatus.runState]} />
                    ) : (
                        <p className="p2 loading-status">
                            Waiting for status <LoadingDots />
                        </p>
                    ),
                    displayNarrowViewHeader: true,
                },
            ],
            url: domainStatus?.runState && 'domain',
        },
    ];

    return (
        <div className="default-deployments">
            <h4>Default Deployments</h4>
            <ReactTable
                customArrowLabel="View Logs"
                tableHeaders={[{ label: 'Deployment Name' }, { label: 'Status' }]}
                tableRows={defaultDeploymentRows}
                loading={!domainStatus || !participantStatus}
            />
        </div>
    );
};

const UserDeployments: React.FC<{
    configuredArtifacts: IDeployedArtifactInfo[];
    unconfiguredArtifacts: IDeployedArtifactInfo[];
    doUpload: (file: File) => void;
}> = ({ configuredArtifacts, unconfiguredArtifacts, doUpload }) => {
    const { walletSampleApp, damlHubClient } = useAppContext();
    const { serviceType } = useLedgerContext();
    const { ledger } = useLedgerContext();
    const isPaused =
        ledger?.info.status.ledger === ObjectStatus.PAUSED ||
        ledger?.info.status.ledger === ObjectStatus.PAUSING;
    const { deployArtifactToLedger } = useAppActions();
    const { deployedArtifacts, deployedArtifactInstances } = useLedgerContext();
    let body =
        deployedArtifacts === undefined ? null : (
            <>
                <h4>Your Deployments</h4>
                {isPaused && (
                    <InformationBlock enableBackground>
                        Service is paused, resume service in Settings to access deployment details.
                    </InformationBlock>
                )}
                <ArtifactTable
                    tableHeaders={deployedArtifactsTableHeaders}
                    linkedRows
                    linkedFileNames={false}
                    deployedArtifacts={configuredArtifacts}
                    loading={!deployedArtifacts || !deployedArtifactInstances}
                    defaultAllExpanded
                    enableSortByDate
                />
            </>
        );
    const deployWalletSampleApp = () => {
        if (!ledger || !walletSampleApp) {
            return;
        }
        if (serviceType === ServiceType.LEDGER) {
            deployArtifactToLedger(ledger, { app: walletSampleApp[0] });
        } else {
            damlHubClient.artifactsApi.deployArtifactToService({
                serviceId: ledger.info.id,
                createArtifactRequest: { artifactHash: walletSampleApp[0].artifactHash },
            });
        }
    };
    if (deployedArtifacts && deployedArtifactInstances && configuredArtifacts.length === 0) {
        if (unconfiguredArtifacts.length > 0) {
            body = <ReviewActionNeededText withTile={configuredArtifacts.length === 0} />;
        } else {
            // Temporarily disabling wallet sample app UI, hence adding additional truthy conditions for participant and scratchpad
            body =
                serviceType === ServiceType.LEDGER ||
                serviceType === ServiceType.PARTICIPANT ||
                serviceType === ServiceType.SCRATCHPAD ? (
                    <p className="p2 no-deployments tile">
                        {`This ledger  has no deployments, `}
                        <FileUploadButton
                            className="inline-link"
                            onUploadRequest={file => doUpload(file)}
                        >
                            upload a file
                        </FileUploadButton>{' '}
                        to get started.
                    </p>
                ) : (
                    <TwoColLayout
                        col1={
                            <NoDeploymentsTile
                                onDeploy={deployWalletSampleApp}
                                doUpload={doUpload}
                            />
                        }
                    />
                );
        }
    }
    return <div className="user-deployments">{body}</div>;
};

const ReviewActionNeededText: React.FC<{ withTile: boolean }> = ({ withTile }) => (
    <p className={classNames('p2 no-deployments ', { tile: withTile })}>
        You have uploaded files that have not been configured and deployed. Please review the{' '}
        <span className="inline-link" onClick={() => scrollToTop()}>
            Action Needed
        </span>
        items.
    </p>
);

const DeployedArtifacts: React.FC = () => {
    const { isLedgerOwner } = useLedgerContext();
    return (
        <Router>
            <Route
                path="view/:artifactHash/name/:integrationName/*"
                render={() =>
                    isLedgerOwner ? <DeployedArtifactViewer /> : <DeployedArtifactsBody />
                }
            />
            <Route
                path="view/:artifactHash/*"
                render={() =>
                    isLedgerOwner ? <DeployedArtifactViewer /> : <DeployedArtifactsBody />
                }
            />
            <Route path="settings/*" render={() => <DeploymentsSettings />} />
            <Route
                path="participant"
                render={() => <DefaultDeploymentViewer defaultDeployment="participant" />}
            />
            <Route
                path="domain"
                render={() => <DefaultDeploymentViewer defaultDeployment="domain" />}
            />
            <Route
                path="synchronizer"
                render={() => <DefaultDeploymentViewer defaultDeployment="synchronizer" />}
            />
            <Route path="/" render={() => <DeployedArtifactsBody />} />
        </Router>
    );
};
export default DeployedArtifacts;
