import React from 'react';

import { ILedgerParty } from '@hub-client-api';

import { useLedgerActions, useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import { IBaseSelectOption } from './BaseSelect';
import LoadingButton from './LoadingButton';
import Modal from './Modal';
import PartyDropdown, { filterPartyOptions, formatSelectionLedgerPartyData } from './PartyDropdown';
import { NameValidationBlock, isNameValid } from './RenameModal';
import { PartyRights, PartyRightsTypeEnum } from './generated/clients/damlhub';

interface AddUserModalProps {
    onRequestClose: () => void;
}

const AddRightsTypeToPartiesList = (
    identifiers: string[],
    rightsType: PartyRightsTypeEnum
): PartyRights[] => {
    return identifiers.map(i => {
        return { party: i, type: rightsType };
    });
};

export const AddUserModal: React.FC<AddUserModalProps> = ({ onRequestClose }) => {
    const [userId, setUserId] = React.useState('');
    const [primaryParty, setPrimaryParty] = React.useState<ILedgerParty>();
    const [actAsParties, setActAsParties] = React.useState<PartyRights[]>([] as PartyRights[]);
    const [readAsParties, setReadAsParties] = React.useState<PartyRights[]>([] as PartyRights[]);
    const [isLoading, setLoading] = React.useState(false);
    const { createUser, updateUsers } = useLedgerActions();

    const transformToListWithRights = (
        p: IBaseSelectOption<ILedgerParty>[],
        rightsType: PartyRightsTypeEnum
    ) => {
        const identifiers = p.map(p => p.value.identifier);
        const transformed = AddRightsTypeToPartiesList(identifiers, rightsType);
        rightsType === PartyRightsTypeEnum.CanActAs
            ? setActAsParties(transformed)
            : setReadAsParties(transformed);
    };

    const onAdd = async () => {
        if (!userId) {
            return;
        }
        setLoading(true);
        await createUser({
            userId: userId,
            primaryParty: primaryParty?.identifier,
            rights: [...actAsParties, ...readAsParties],
        });
        updateUsers();
        setLoading(false);
        onRequestClose();
    };
    const {
        ledger,
        isLedgerOwner,
        ownerParties = [],
        defaultParties,
        userParties,
        users,
    } = useLedgerContext();
    const userIds = users?.map(({ userId }) => userId) || [];
    const otherParties = userParties || [];
    const allParties = [...ownerParties, ...otherParties];
    const parties = filterPartyOptions(
        allParties,
        defaultParties,
        !isLedgerOwner,
        !isLedgerOwner,
        true
    );

    const userIdValidationState = isNameValid(userId, userIds, { disableLengthRequirement: true });
    const { isUnique, syntaxValid } = userIdValidationState;
    const disableAdd = !syntaxValid || !isUnique;
    return (
        <Modal
            onSubmit={onAdd}
            onRequestClose={onRequestClose}
            className="value-entry-modal"
            show
            title={'Add User'}
        >
            <div className="row">
                <p>Enter User ID</p>
                <input
                    style={{ width: '100%' }}
                    className="value-entry"
                    autoFocus
                    type="text"
                    value={userId}
                    placeholder="User ID"
                    onChange={evt => setUserId(evt.target.value.trim())}
                />
                <NameValidationBlock
                    uniqueName
                    hideLengthWarning
                    validationState={userIdValidationState}
                />
            </div>
            <div className="row">
                <p>Select primary party</p>
                <PartyDropdown
                    selectedParty={primaryParty?.displayName}
                    onChange={(p: IBaseSelectOption<ILedgerParty>) => setPrimaryParty(p.value)}
                    partyOptions={formatSelectionLedgerPartyData(parties)}
                    ledgerStatus={ledger?.info.status.ledger}
                />
            </div>
            <div className="row">
                <p>Act as</p>
                <p className="caption user-rights-description">
                    This user can act as the following associated parties:
                </p>
                <PartyDropdown
                    onChange={(p: IBaseSelectOption<ILedgerParty>[]) => {
                        transformToListWithRights(p, PartyRightsTypeEnum.CanActAs);
                    }}
                    partyOptions={formatSelectionLedgerPartyData(parties)}
                    ledgerStatus={ledger?.info.status.ledger}
                    isMultiple
                />
            </div>
            <div className="row">
                <p>Read as</p>
                <p className="caption user-rights-description">
                    This user can read as the following associated parties:
                </p>
                <PartyDropdown
                    onChange={(p: IBaseSelectOption<ILedgerParty>[]) => {
                        transformToListWithRights(p, PartyRightsTypeEnum.CanReadAs);
                    }}
                    partyOptions={formatSelectionLedgerPartyData(parties)}
                    ledgerStatus={ledger?.info.status.ledger}
                    isMultiple
                />
            </div>

            <div className="actions" style={{ justifyContent: 'flex-end' }}>
                <LoadingButton disabled={disableAdd} loading={isLoading} onClick={onAdd}>
                    Add
                </LoadingButton>
            </div>
        </Modal>
    );
};
