import React from 'react';

import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import { DetailsTable } from '@hub-fe/common/DetailsTable';
import {
    IconBell,
    IconDateCreated,
    IconRocket,
    IconSidebarDeployments,
    IconSidebarUser,
} from '@hub-fe/common/Icons';
import InformationBlock from '@hub-fe/common/InformationBlock';
import { MonitorDeploymentTile } from '@hub-fe/common/MonitorDeploymentTile';
import { Pqs } from '@hub-fe/common/Pqs';
import { SetUpPqs } from '@hub-fe/common/SetUpPqs';
import { SetupTile } from '@hub-fe/common/SetupTile';
import { TwoColLayout } from '@hub-fe/common/TwoColLayout';
import { Route, Router } from '@hub-fe/common/routing';
import { containsDamlModel } from '@hub-fe/common/utils/containsDamlModel';
import DefaultDeploymentViewer from '@hub-fe/ledger/Deployments/DefaultDeploymentViewer';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import en from '../../../locales/en.json';
import { isScratchpadData } from '../AppContext';
import './ScratchpadOverview.scss';

export const ScratchpadOverview: React.FC = () => {
    const {
        deployedArtifactInstances,
        ledger,
        participantStatus,
        domainStatus,
        pqsStatus,
        pqsInstance,
        eventCount,
    } = useLedgerContext();

    const hasDamlModel = deployedArtifactInstances
        ? containsDamlModel(deployedArtifactInstances)
        : false;
    if (!ledger || !isScratchpadData(ledger)) {
        return null;
    }
    const date = new Date(ledger.info.createdAt);
    const formattedDate = date.toLocaleDateString('en-US');
    const scratchpadDetails = [
        {
            icon: <IconSidebarUser />,
            label: 'Name',
            renderAs: <p className="value p2">{ledger.info.name || ''}</p>,
        },
        {
            icon: <IconSidebarDeployments />,
            label: 'Service ID',
            renderAs: (
                <div className="value">
                    <CopyableDisplay value={ledger.info.id} />
                </div>
            ),
        },
        {
            icon: <IconBell filled={false} />,
            label: 'Event Count',
            renderAs: <p className="value p2">{eventCount || 0}</p>,
        },
        {
            icon: <IconDateCreated />,
            label: 'Date Created',
            renderAs: <p className="value p2">{formattedDate}</p>,
        },
        {
            icon: <IconRocket />,
            label: 'Canton Version',
            renderAs: <p className="value p2">{ledger.info.sdkVersion}</p>,
        },
        {
            icon: <IconRocket />,
            label: 'Canton Protocol Version',
            renderAs: <p className="value p2">{ledger.info.cantonProtocol}</p>,
        },
        {
            icon: <IconRocket />,
            label: 'PQS Version',
            renderAs: <p className="value p2">{ledger.info.pqsVersion || 'unknown'}</p>,
        },
    ];
    const col1 = (
        <div className="scratchpad-details">
            {!pqsInstance && <SetupTile />}

            <h2>Scratchpad Details</h2>
            <DetailsTable data={scratchpadDetails} />
            <h2>Monitoring</h2>
            <div className="monitor-tile">
                <MonitorDeploymentTile
                    desc={en.defaultDeployments.participantSupportText}
                    status={participantStatus}
                    label="Participant"
                    to="participant"
                    caption="View Logs"
                />
            </div>
            <div className="monitor-tile">
                <MonitorDeploymentTile
                    desc={en.defaultDeployments.synchronizerSupportText}
                    status={domainStatus}
                    label="Synchronizer"
                    to="synchronizer"
                    caption="View Logs"
                />
            </div>
            {pqsInstance && (
                <>
                    <div className="monitor-tile">
                        <MonitorDeploymentTile
                            desc={'View PQS settings and logs.'}
                            status={pqsStatus}
                            label={<div>PQS</div>}
                            to="pqs/logs"
                            caption="View Details"
                        >
                            {!hasDamlModel && (
                                <InformationBlock enableBackground fullWidth>
                                    <div style={{ width: '100%' }}>
                                        <p>PQS instance will not start until a dar is deployed.</p>
                                        <a href="./deployments">
                                            <p style={{ textDecoration: 'underline' }}>
                                                Go to Deployments
                                            </p>
                                        </a>
                                    </div>
                                </InformationBlock>
                            )}
                        </MonitorDeploymentTile>
                    </div>
                </>
            )}
        </div>
    );
    const Details = () => (
        <>
            <h1>Overview</h1>
            <TwoColLayout col1={col1} />
        </>
    );
    return (
        <Router>
            <Route default path="/" render={() => <Details />} />
            <Route
                path="participant"
                render={() => <DefaultDeploymentViewer defaultDeployment="participant" />}
            />
            <Route
                path="synchronizer"
                render={() => <DefaultDeploymentViewer defaultDeployment="synchronizer" />}
            />
            <Route exact path="pqs/setup" render={() => <SetUpPqs serviceId={ledger.info.id} />} />

            <Route path="pqs/*" render={() => <Pqs />} />
        </Router>
    );
};
