import React from 'react';

import { Redirect, Route, Router } from '@hub-fe/common/routing';
import IntegrationBrowser from '@hub-fe/ledger/Integrations/IntegrationBrowser';

import { Participants } from './Participants';
import { SynchronizerOverview } from './SynchronizerOverview';
import { SynchronizerSettings } from './SynchronizerSettings';

export const SynchronizerRoutes: React.FC = () => {
    return (
        <Router>
            <Route path="participants/*" render={() => <Participants />} />
            <Route path="overview/*" render={() => <SynchronizerOverview />} />
            <Route path="settings/*" render={() => <SynchronizerSettings />} />
            {<Route path="integrations/*" render={() => <IntegrationBrowser />} />}

            <Route path="/" render={() => <Redirect to="participants" />} />
        </Router>
    );
};
