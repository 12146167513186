import React from 'react';

import { IPublishedDeployedArtifactInfo } from '@hub-client-api';

import ActionNeededTile from '@hub-fe/ledger/Deployments/ActionNeededTile';
import InlineDeploymentInstanceConfig from '@hub-fe/ledger/Deployments/InlineDeploymentInstanceConfig';
import {
    formatAsIEntityInfo,
    useLedgerActions,
    useLedgerContext,
} from '@hub-fe/ledger/LedgerContext';

const PublishedArtifactTile = (props: { artifact: IPublishedDeployedArtifactInfo }) => {
    const { artifact } = props;
    const { getDeployedArtifactInstancesByHash } = useLedgerActions();
    const count = getDeployedArtifactInstancesByHash(artifact.artifactHash).length;

    if (count > 0) {
        return null;
    }

    return (
        <InlineDeploymentInstanceConfig
            artifact={formatAsIEntityInfo(artifact)}
            publishedDeployedArtifact={artifact}
        />
    );
};

const PublishedArtifactsArcade: React.FC = () => {
    const { publishedDeployedArtifacts } = useLedgerContext();
    const { getDeployedArtifactInstancesByHash } = useLedgerActions();

    const allConfigured = publishedDeployedArtifacts?.every(a => {
        const count = getDeployedArtifactInstancesByHash(a.artifactHash).length;
        if (count > 0) {
            return true;
        }
        return false;
    });

    if (allConfigured || allConfigured === undefined) {
        return null;
    }

    return (
        <ActionNeededTile>
            {publishedDeployedArtifacts?.map(p => (
                <PublishedArtifactTile key={p.artifactHash} artifact={p} />
            ))}
        </ActionNeededTile>
    );
};

export default PublishedArtifactsArcade;
