import React from 'react';

import { useAppActions } from '@hub-fe/app/AppContext';
import ConfigurationWizard, { ConfigWizardSteps } from '@hub-fe/common/ConfigurationWizard';
import ExternalLink from '@hub-fe/common/ExternalLink';
import { NavLink } from '@hub-fe/common/routing';
import * as urls from '@hub-fe/common/urls';

import SiteCredentialList from './SiteCredentialList';

const SiteCredentials: React.FC = () => {
    const { createSiteCredential } = useAppActions();

    return (
        <div className="site-credentials">
            <h2>Site Credentials (Deprecated)</h2>
            <p className="subheading">
                (Note: Site Credentials are deprecated. Please use{' '}
                <NavLink className="inline-link" to="/console/account/personal-access-credentials">
                    Personal Access Credentials
                </NavLink>{' '}
                instead)
            </p>
            <p className="subheading">
                Generate a long-lived site-credential which can be exchanged for an account JWT.
                Read more in our{' '}
                <ExternalLink
                    className="inline-link"
                    icon
                    to={urls.docs + '/quickstart/console#site-credentials-deprecated'}
                >
                    docs
                </ExternalLink>
                .
            </p>
            <ConfigurationWizard
                label={'Site Credential'}
                steps={[ConfigWizardSteps.LABEL, ConfigWizardSteps.EXPIRATION]}
                onSubmit={handleNewSiteCredential}
            />
            <SiteCredentialList />
        </div>
    );

    function handleNewSiteCredential(configMap: Map<ConfigWizardSteps, any>, onFinish: () => void) {
        const label = configMap.get(ConfigWizardSteps.LABEL);
        const expiration = configMap.get(ConfigWizardSteps.EXPIRATION);
        createSiteCredential(label, expiration, onFinish);
    }
};

export default SiteCredentials;
