import { useLocation } from '@reach/router';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';

import { IContractInfo, ObjectStatus } from '@hub-client-api';

import { LoadingDots } from '@hub-fe/common/Animations/Animations';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { IconNewItemIndicator, IconRightArrow } from '@hub-fe/common/Icons';
import { useCurrentTime } from '@hub-fe/common/Timing';
import { NavLink } from '@hub-fe/common/routing';
import { NBSP, parseTemplateName } from '@hub-fe/common/utils';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import { useLedgerContext } from '../LedgerContext';
import './ContractSelector.scss';
import EmptyDisplayPlaceholder from './EmptyDisplayPlaceholder';
import { ContractStreamState, TimedContractInfo } from './useContractStream';
import { useIsHighEventCount } from './utils';

const MAX_CONTRACTS = 200;

const NEW_CONTRACT_INDICATION_TIME = 3000;

interface IContractItemProps {
    seenAt?: number;
    archived: boolean;
    contractId: string;
    templateId: string;
}

const ContractItem = React.memo((props: IContractItemProps) => {
    const { templateId, seenAt, archived, contractId } = props;

    const { selectedContract, service } = useServiceParams();

    const { ledger } = useLedgerContext();

    const refItem = useRef<HTMLAnchorElement>(null);

    const search = useLocation().search;
    const currentTime = useCurrentTime();

    const selected = !!(selectedContract && decodeURIComponent(selectedContract) === contractId);

    const contractIdUrlFragment = encodeURIComponent(contractId);

    useEffect(() => {
        if (selected) {
            refItem.current?.scrollIntoView(false);
        }
    }, [selected]);

    const newContract = !!seenAt && currentTime - seenAt < NEW_CONTRACT_INDICATION_TIME;

    const parsedTemplate = parseTemplateName(templateId);

    return (
        <NavLink
            key={contractId}
            className={classNames('contract-item', { selected, archived })}
            to={
                `/console/${service}/` +
                ledger?.info.id +
                '/live-data/' +
                contractIdUrlFragment +
                search
            }
            ref={refItem}
        >
            {newContract && <IconNewItemIndicator />}
            {archived && <IconNewItemIndicator disabled />}
            <div className="item-info">
                <div className="package-name">{parsedTemplate.packageName}</div>
                <div className="template-name">{parsedTemplate.templateName}</div>
                <div className="contract-id">
                    ID:{NBSP}
                    {contractId}
                </div>
            </div>
            {selected && <IconRightArrow />}
        </NavLink>
    );
});

interface IContractSelectorProps {
    contractStreamState: ContractStreamState;
    searchEmpty: boolean;
    archivedContracts: IContractInfo[];
}

const ContractSelector: React.FC<IContractSelectorProps> = props => {
    const { contractStreamState, searchEmpty, archivedContracts } = props;
    const { ledger, serviceType } = useLedgerContext();
    if (!serviceType) {
        return null;
    }
    const { selectedContract, service } = useServiceParams();
    const isLedger = serviceType === ServiceType.LEDGER;
    const search = useLocation().search;

    const initializingStatus =
        ledger?.info.status.ledger === ObjectStatus.CONNECTING ||
        ledger?.info.status.ledger === ObjectStatus.STARTING ||
        ledger?.info.status.ledger === ObjectStatus.RESUMING
            ? ledger.info.status.ledger
            : undefined;

    let contractsElided = 0;

    const { loading, contracts } = contractStreamState;

    useEffect(() => {
        if (loading || !selectedContract || !ledger) {
            return;
        }

        if (contracts.length === 0) {
            history.push(`/console/${service}/${ledger.info.id}/live-data/${search}`);
        }

        const selectedContractMissing =
            contracts.length > 0 &&
            !contracts.some(c => c.contractId === decodeURIComponent(selectedContract));

        if (selectedContractMissing) {
            const cid = encodeURIComponent(contracts[0].contractId);
            history.push(`/console/${service}/${ledger.info.id}/live-data/${cid}${search}`);
        }
    }, [loading, contracts, selectedContract, ledger, search]);
    const enableHighEventCount = useIsHighEventCount();

    if (!contracts || contracts.length === 0 || initializingStatus) {
        let emptyViewExplanation = <>There is no data for this party on this ledger... yet</>;

        if (initializingStatus) {
            const ledgerExplanation = `This ledger is still ${initializingStatus.toLocaleLowerCase()}`;
            const participantExplanation = `This service is still ${initializingStatus.toLocaleLowerCase()}`;
            const displayedExplanation = isLedger ? ledgerExplanation : participantExplanation;
            emptyViewExplanation = (
                <>
                    {displayedExplanation}
                    <LoadingDots />
                </>
            );
        } else if (loading) {
            emptyViewExplanation = (
                <>
                    Loading ledger data
                    <LoadingDots />
                </>
            );
        } else if (searchEmpty) {
            emptyViewExplanation = <>There is no data for this party that matches your search.</>;
        }

        return (
            <div
                className={classNames(
                    'contract-selector empty-state',
                    enableHighEventCount
                        ? 'contract-selector-high-event-count'
                        : 'contract-selector-standard-event-count'
                )}
            >
                <p>{emptyViewExplanation}</p>
                <EmptyDisplayPlaceholder loading={loading} />
            </div>
        );
    }

    let displayContracts = contracts;
    if (contracts.length > MAX_CONTRACTS) {
        contractsElided = contracts.length - MAX_CONTRACTS;
        displayContracts = contracts.slice(0, MAX_CONTRACTS);
    }
    const Row = ({
        index,
        style,
        data,
    }: {
        index: number;
        style: React.CSSProperties;
        data: TimedContractInfo[];
    }) => {
        const row = data[index];
        return (
            <div style={style}>
                <ContractItem
                    archived={!!archivedContracts.find(c => c.contractId === row.contractId)}
                    key={row.contractId}
                    contractId={row.contractId}
                    templateId={row.templateId}
                    seenAt={row.seenAt}
                />
            </div>
        );
    };
    return (
        <div
            className={classNames(
                'contract-selector',
                enableHighEventCount
                    ? 'contract-selector-high-event-count'
                    : 'contract-selector-standard-event-count'
            )}
        >
            <List
                height={570} // Height of the list container
                itemCount={displayContracts.length}
                itemSize={119}
                width={enableHighEventCount ? 458 : 352.09} // Width of the list container
                itemData={displayContracts}
            >
                {Row}
            </List>
            {contractsElided > 0 && (
                <div className="contract-item elided-placeholder">{contractsElided} more...</div>
            )}
        </div>
    );
};

export default ContractSelector;
