import React from 'react';

import { quotaTokensForType, ResourceTokenType } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import Banner, { BannerWarningType } from '@hub-fe/common/Banner';
import ExternalLink from '@hub-fe/common/ExternalLink';
import InformationBlock from '@hub-fe/common/InformationBlock';
import LoadingPage from '@hub-fe/common/LoadingPage';
import MenuBar from '@hub-fe/common/MenuBar';
import ToolTip from '@hub-fe/common/ToolTip';
import { Route, Router } from '@hub-fe/common/routing';

import './AppTile.scss';
import Collections from './Collections';
import ProjectLedger from './ProjectLedger';
import { ProjectSelector } from './ProjectSelector';

enum WorkspacePageEnum {
    PROJECTS = 'projects',
    SHARED_LEDGERS = 'shared-ledgers',
    SCRATCHPAD = 'scratchpad',
    NODES = 'nodes',
}

const Workspace: React.FC = () => {
    const { ledgers, userInfo, isProTier, projects, maxLedgers, quotas } = useAppContext();
    const emptyWorkspace = projects?.length === 0;
    const ledgerTokens = quotaTokensForType(quotas, ResourceTokenType.LedgerResource);
    if (!projects || !maxLedgers || !ledgers) {
        return <LoadingPage statusText="Loading Workspace" />;
    }

    const atLedgerLimit = ledgerTokens.tokens.length == 0;
    const showPauseLedgerWarning =
        userInfo?.tier === 'DABL_FREE' &&
        !!projects &&
        projects?.length > 0 &&
        !window.location.href.endsWith(WorkspacePageEnum.SHARED_LEDGERS);

    return (
        <div className="workspace">
            {atLedgerLimit && (
                <Banner
                    bannerName="upgrade"
                    dismissible
                    warningType={BannerWarningType.WARNING}
                    className="upgrade-warning-banner"
                    header="Ledger Usage Warning"
                    message={
                        <p className="p2">
                            You have reached your allotment of {ledgerTokens.spentTokens.length}{' '}
                            ledgers for your account. You will no longer be able to create ledgers
                            until you delete an existing ledger.
                        </p>
                    }
                />
            )}

            <div className="workspace-body">
                <Collections />
                <div className="workspace-content">
                    <div className="workspace-title">
                        {!emptyWorkspace && <h1>Workspace</h1>}
                        {showPauseLedgerWarning && <LedgerPauseWarning />}
                    </div>
                    {isProTier && (
                        <MenuBar
                            entries={[
                                {
                                    label: 'Your Projects',
                                    to: WorkspacePageEnum.PROJECTS,
                                },
                                {
                                    label: 'Shared Ledgers',
                                    to: WorkspacePageEnum.SHARED_LEDGERS,
                                },
                            ]}
                        />
                    )}

                    <div>
                        {isProTier ? (
                            <Router>
                                <Route
                                    default
                                    path={WorkspacePageEnum.PROJECTS}
                                    render={() => <ProjectSelector />}
                                />

                                <Route
                                    path={WorkspacePageEnum.SHARED_LEDGERS}
                                    render={() => <SharedLedgers />}
                                />
                            </Router>
                        ) : (
                            <ProjectSelector />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const SharedLedgers = () => {
    const { sharedLedgers } = useAppContext();
    return (
        <div className="workspace-shared-ledgers">
            <h2>Shared Ledgers</h2>
            {sharedLedgers.length === 0 ? (
                <div className="p2">No ledgers have been shared with you.</div>
            ) : (
                <section className="shared-ledger-list">
                    {sharedLedgers.map(l => (
                        <ProjectLedger key={l.info.id} ledger={l} />
                    ))}
                </section>
            )}
        </div>
    );
};

const ledgerWarning = 'Ledgers are paused after 24 hours of inactivity.';

const LedgerPauseWarning = () => {
    const upgradeButton = (
        <>
            {ledgerWarning}
            <ExternalLink className="inline-link" to="https://www.digitalasset.com/contact-us">
                Contact Sales to Upgrade
            </ExternalLink>
        </>
    );

    return (
        <>
            <InformationBlock>{upgradeButton}</InformationBlock>
            <ToolTip direction="left" caption={upgradeButton} />
        </>
    );
};

export default Workspace;
