import React, { useState } from 'react';

import { IDeployedArtifactInstanceInfo } from '@hub-client-api';

import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import { autoPluralize } from '@hub-fe/common/utils';

import { useLedgerActions, useLedgerContext } from '../LedgerContext';

const DeploymentInstanceTableActions: React.FC<{
    instances: IDeployedArtifactInstanceInfo[];
}> = props => {
    const { instances } = props;
    const { deployedArtifactInstances, isLedgerOwner } = useLedgerContext();
    const { deleteDeployedArtifactInstance, updateDeployedArtifactInstance } = useLedgerActions();
    const instanceCount = instances.length;
    const [showUndeployInstanceModal, setShowUndeployInstanceModal] = useState(false);

    const allOn = instances.every(({ id }) => getDeploymentInstanceById(id)?.enabled);
    const allPaused = instances.every(({ id }) => !getDeploymentInstanceById(id)?.enabled);

    return (
        <div>
            <div className="row deployment-instance-table-actions">
                <p className="p2">
                    {instanceCount} {autoPluralize(instanceCount, 'deployment')} selected:
                </p>
                {isLedgerOwner && (
                    <>
                        <button
                            disabled={!allOn}
                            className="p2 secondary"
                            onClick={() => doSetEnabled(false)}
                        >
                            Turn Off
                        </button>
                        <button
                            disabled={!allPaused}
                            className="p2 secondary"
                            onClick={() => doSetEnabled(true)}
                        >
                            Turn On
                        </button>
                    </>
                )}
                <button
                    className="p2 secondary warning"
                    onClick={() => setShowUndeployInstanceModal(true)}
                >
                    Un-Deploy
                </button>
            </div>

            <ConfirmActionModal
                show={showUndeployInstanceModal}
                title={`Un-Deploy ${autoPluralize(instanceCount, 'Instance')}`}
                bodyMessage={`Are you sure you want to un-deploy the selected ${autoPluralize(
                    instanceCount,
                    'instance'
                )} of this deployment?`}
                action={`Un-Deploy ${autoPluralize(instanceCount, 'Instance')}`}
                deletingAction
                onRequestClose={() => setShowUndeployInstanceModal(false)}
                onSubmitAction={() => doUnDeploy()}
            />
        </div>
    );

    function doSetEnabled(setTo: boolean) {
        instances.forEach(i => updateDeployedArtifactInstance({ instance: i, enabled: setTo }));
    }

    function doUnDeploy() {
        instances.forEach(({ id }) => {
            const instance = getDeploymentInstanceById(id);
            if (instance) {
                deleteDeployedArtifactInstance(instance);
            }
        });
        setShowUndeployInstanceModal(false);
    }

    function getDeploymentInstanceById(id: string) {
        return deployedArtifactInstances?.find(i => i.id === id);
    }
};

export default DeploymentInstanceTableActions;
