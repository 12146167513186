import { artifactTypes, IDeployedArtifactInstanceInfo } from '@hub-client-api';

export const containsDamlModel = (
  deployedArtifactInstances: IDeployedArtifactInstanceInfo[]
): boolean => {
  return (
    deployedArtifactInstances.findIndex(
      e => e.entityInfo.entity.tag === artifactTypes.MODEL_ARTIFACT
    ) > -1
  );
};
