import { IParticipantData, ISynchronizerData } from '@hub-client-api';

interface ConnectionsGraph {
  external: { [key: string]: IParticipantData[] };
  internal: { [key: string]: IParticipantData[] };
  noConnection: IParticipantData[];
}
export const getConnectionsGraph = (arg: {
  synchronizers: ISynchronizerData[];
  participants: IParticipantData[];
}): ConnectionsGraph => {
  const { synchronizers, participants } = arg;
  const internalSynchronizerUrlToId = {};

  const internal: { [key: string]: IParticipantData[] } = {};
  const external: { [key: string]: IParticipantData[] } = {};
  const noConnection: IParticipantData[] = [];
  const graph = { internal, external, noConnection };

  synchronizers.forEach(synchronizer => {
    internal[synchronizer.info.id] = [];
    internalSynchronizerUrlToId[`${synchronizer.info.hostname}`] = synchronizer.info.id;
  });

  participants.forEach(participant => {
    const hasConnection = !!participant.info.connection;

    if (hasConnection) {
      const domainUrl = participant.info?.connection?.synchronizerUrl;

      if (domainUrl) {
        if (internalSynchronizerUrlToId[domainUrl]) {
          const connectedSynchronizerId = internalSynchronizerUrlToId[domainUrl];
          if (internal[connectedSynchronizerId]) {
            internal[connectedSynchronizerId].push(participant);
          } else {
            internal[connectedSynchronizerId] = [participant];
          }
        } else {
          external[domainUrl] = [...(external[domainUrl] || []), participant];
        }
      }
    } else {
      noConnection.push(participant);
    }
  });
  graph['internal'] = internal;
  graph['external'] = external;
  graph['noConnection'] = noConnection;
  return graph;
};
