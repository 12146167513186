import React, { useState } from 'react';

import { ILedgerData, IProjectInfo } from '@hub-client-api';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import ConfirmActionModal from '@hub-fe/common/ConfirmActionModal';
import Modal, { ModalControls } from '@hub-fe/common/Modal';
import { ModalMultiSelect, ModalSingleSelect, IModalSelectInfo } from '@hub-fe/common/ModalSelect';
import Wizard, { WizardPage } from '@hub-fe/common/Wizard';
import { itemListAsText, capitalizePhrase } from '@hub-fe/common/utils';

import { IProjectModalAction } from './Project';

interface IMultiLedgerActionModalProps {
    project: IProjectInfo;
    ledgers: ILedgerData[];
    action: IProjectModalAction;
    onRequestClose: () => void;
}

const MultiLedgerActionModal: React.FC<IMultiLedgerActionModalProps> = props => {
    const { ledgers, project, onRequestClose, action } = props;
    const { projects = [], ownedLedgers } = useAppContext();
    const { deleteLedger, pauseLedger, resumeLedger, moveLedger } = useAppActions();
    const [selectedLedgers, setSelectedLedgers] = useState(new Set<string>());
    const [selectedProjectId, setSelectedProjectId] = useState<string>();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const handleRequestClose = () => {
        setSelectedLedgers(new Set());
        setShowConfirmationModal(false);
        onRequestClose();
    };

    const handleSubmitSelection = () => {
        if (action === 'delete-ledgers') {
            setShowConfirmationModal(true);
        } else {
            handleConfirmation();
        }
    };

    const handleConfirmation = () => {
        ledgerList.forEach(ledger => doAction(ledger));
        handleRequestClose();
    };

    function doAction(ledger: ILedgerData) {
        switch (action) {
            case 'delete-ledgers':
                return deleteLedger(ledger);
            case 'pause-ledgers':
                return pauseLedger(ledger);
            case 'resume-ledgers':
                return resumeLedger(ledger);
            case 'move-ledgers':
                return moveLedger(selectedProjectId || '', ledger.info.id);
        }
    }

    const actionVerb = capitalizePhrase(action?.split('-')[0] || '');
    const ledgerList = ledgers.filter(({ info }) => selectedLedgers.has(info.id));
    const names = ledgerList.map(ledger => ledger.info.name);
    const numSelected = names.length;
    const allSelected = ledgers.length === numSelected;
    const maybePlural = numSelected > 1 ? 's' : '';
    const actionAndTitle = `${actionVerb} Ledger${maybePlural}`;
    const namesStr = itemListAsText(names);
    const ledgerOptions = ledgers.map(l => ({ id: l.info.id, name: l.info.name }));

    const modalProps = {
        onSubmit: handleSubmitSelection,
        className: 'multi-ledger-action-modal',
        title: `${actionVerb} Ledgers`,
        show: !showConfirmationModal,
        onRequestClose: handleRequestClose,
    };

    if (action === 'move-ledgers') {
        const projectItems = projects
            .filter(p => p.info.id !== project.id)
            .map(p => ({ id: p.info.id, name: p.info.name }));

        const ledgersInSelectedProject = ownedLedgers.filter(
            l => l.info.projectId === selectedProjectId
        );

        const checkRepeatName = (item: IModalSelectInfo) => {
            if (ledgersInSelectedProject.find(l => l.info.name === item.name)) {
                return `There is already a ledger in ${selectedProjectName} named ${item.name}. You must rename ${item.name} before moving it.`;
            }
            return undefined;
        };

        const selectedProjectName = projectItems.find(p => p.id === selectedProjectId)?.name;
        return (
            <Wizard {...modalProps}>
                <WizardPage pageLabel="" disabled={!selectedProjectId}>
                    <p className="row">Select a project to move ledgers to:</p>
                    <ModalSingleSelect
                        items={projectItems}
                        selectedItem={selectedProjectId}
                        setSelectedItem={setSelectedProjectId}
                    />
                </WizardPage>
                <WizardPage pageLabel={''} disabled={!selectedLedgers}>
                    <p className="row">{`Choose ledgers in ${
                        project.name
                    } to ${actionVerb.toLowerCase()} to ${selectedProjectName}:`}</p>
                    <ModalMultiSelect
                        items={project && ledgerOptions}
                        selectedItems={selectedLedgers}
                        setSelectedItems={setSelectedLedgers}
                        displayDisabledText={checkRepeatName}
                    />
                </WizardPage>
            </Wizard>
        );
    }

    return (
        <>
            <Modal {...modalProps}>
                <p className="row">{`Choose ledgers in ${
                    project.name
                } to ${actionVerb.toLowerCase()}:`}</p>
                <ModalMultiSelect
                    items={project && ledgerOptions}
                    selectedItems={selectedLedgers}
                    setSelectedItems={setSelectedLedgers}
                />
                <ModalControls>
                    <button
                        className="secondary warning"
                        disabled={numSelected === 0}
                        onClick={() => handleSubmitSelection()}
                    >
                        {actionVerb}
                    </button>
                </ModalControls>
            </Modal>
            <ConfirmActionModal
                show={showConfirmationModal}
                onRequestClose={handleRequestClose}
                title={actionAndTitle}
                bodyMessage={`Are you sure you want to ${actionVerb.toLowerCase()} ledger${maybePlural} ${namesStr}?`}
                infoMessage={
                    allSelected
                        ? `You are ${actionVerb.toLowerCase()}ing ${
                              numSelected > 1 ? 'all ledgers' : 'the last ledger'
                          } in this project.`
                        : undefined
                }
                action={actionAndTitle}
                onSubmitAction={() => handleConfirmation()}
                deletingAction
            />
        </>
    );
};

export default MultiLedgerActionModal;
