import React, { useState } from 'react';

import { ObjectStatus } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import CopyableDisplay from '@hub-fe/common/CopyableDisplay';
import DocsLink from '@hub-fe/common/DocsLink';
import DownloadButton from '@hub-fe/common/DownloadButton';
import { IconEdit } from '@hub-fe/common/Icons';
import LedgerCollaboratorWarning from '@hub-fe/common/LedgerCollaboratorWarning';
import RenameModal from '@hub-fe/common/RenameModal';
import SettingsPage, {
    LeftSidePanels,
    RightSidePanels,
    SettingsField,
    SettingsPanel,
} from '@hub-fe/common/SettingsPage';
import { shortName } from '@hub-fe/common/siteInfo.variants';
import {
    downloadFile,
    IParticipants,
    participantsToLedgerParties,
    toParticipantsJson,
} from '@hub-fe/common/utils';

import { useLedgerActions, useLedgerContext } from '../LedgerContext';
import LedgerActions from './LedgerActions';
import LedgerDomains from './LedgerDomains';
import LedgerSize from './LedgerSize';

const LedgerSettings: React.FC = () => {
    const { isLedgerOwner, isLedgerCollaborator, ledger, ledgerId, subdomains } =
        useLedgerContext();
    const { activeLedgers } = useAppContext();
    const { renameLedger } = useLedgerActions();
    const customDomain = subdomains?.custom;
    const [showRenameLedgerModal, setShowRenameLedgerModal] = useState(false);

    if (!ledger) {
        return null;
    }

    const date = new Date(ledger.info.createdAt);

    return (
        <SettingsPage title={<h1>Ledger Settings</h1>}>
            <LeftSidePanels>
                <SettingsPanel title="General">
                    {isLedgerCollaborator && <LedgerCollaboratorWarning />}
                    <SettingsField name="Ledger Name">
                        <div className="field-data ">
                            {ledger.info.name}
                            {isLedgerOwner && (
                                <button
                                    className="ghost rename-ledger"
                                    onClick={() => setShowRenameLedgerModal(true)}
                                >
                                    <IconEdit />
                                </button>
                            )}
                        </div>
                    </SettingsField>
                    <SettingsField name="Ledger ID">
                        <div className="field-data">
                            <CopyableDisplay value={ledgerId} />
                        </div>
                    </SettingsField>
                    <SettingsField name="Date Created" value={date.toLocaleDateString('en-US')} />
                    <SettingsField name="Canton Release Version" value={ledger.info.sdkVersion} />
                </SettingsPanel>
                <SettingsPanel title="Subdomains">
                    <LedgerDomains />
                </SettingsPanel>
            </LeftSidePanels>
            <RightSidePanels>
                {isLedgerOwner && (
                    <>
                        <RunDamlScript />
                        {ledger.info.hasEnterpriseOwner && <LedgerSize />}
                        <SettingsPanel title="API">
                            <SettingsField name="Daml Hub API">
                                <div className="field-data">
                                    <CopyableDisplay value={subdomains?.default?.domain || ''} />
                                </div>
                            </SettingsField>
                            {customDomain?.domain && (
                                <SettingsField name={'Custom Subdomain API'}>
                                    <div className="field-data in-line">
                                        <CopyableDisplay value={customDomain.domain} />
                                    </div>
                                </SettingsField>
                            )}
                        </SettingsPanel>
                        <LedgerActions />
                    </>
                )}
            </RightSidePanels>
            {showRenameLedgerModal && (
                <RenameModal
                    originalName={ledger.info.name}
                    onSubmit={newName => renameLedger(newName, ledger.info.id)}
                    onRequestClose={() => setShowRenameLedgerModal(false)}
                    existingNames={activeLedgers.map(l => l.info.name)}
                    uniqueName
                    noSpaces
                    title="Rename Ledger"
                />
            )}
        </SettingsPage>
    );
};

const RunDamlScript = () => {
    const { ledger, defaultParties } = useLedgerContext();

    if (!ledger) {
        return null;
    }

    async function downloadParticipantsJson() {
        if (!ledger || !defaultParties?.publicParty) return;

        const participantsJson: IParticipants = await toParticipantsJson(ledger.info.id);

        const jsonContent =
            'data:text/json;charset=utf-8,' + JSON.stringify(participantsJson || {}, null, 4);
        const encodedUri = encodeURI(jsonContent);
        downloadFile(encodedUri, 'participants.json');
    }

    async function downloadLedgerPartiesJson() {
        if (!ledger || !defaultParties?.publicParty) return;

        const participantsJson: IParticipants = await toParticipantsJson(ledger.info.id);

        const ledgerParties = participantsJson ? participantsToLedgerParties(participantsJson) : {};

        const jsonContent =
            'data:text/json;charset=utf-8,' + JSON.stringify(ledgerParties, null, 4);
        const encodedUri = encodeURI(jsonContent);
        downloadFile(encodedUri, 'ledger-parties.json');
    }

    return (
        <SettingsPanel
            title="Initialize your ledger with Daml Script"
            subtitle={
                <p className="p2">
                    For more information on how to bootstrap your ledger with Daml Script,{' '}
                    <DocsLink topic="ledger-initialization-with-daml-script">
                        visit the {shortName} docs
                    </DocsLink>
                    .
                </p>
            }
        >
            <SettingsField name="Participant Config">
                <div className="field-data">
                    <div className="download-display">
                        <p className="text-background code">participants.json</p>
                        <DownloadButton
                            onClick={() => downloadParticipantsJson()}
                            iconOnly
                            disabled={
                                ledger.info.status.ledger !== ObjectStatus.RUNNING ||
                                !defaultParties?.publicParty
                            }
                        />
                    </div>
                </div>
            </SettingsField>
            <SettingsField name="Ledger Parties">
                <div className="field-data">
                    <div className="download-display">
                        <p className="text-background code">ledger-parties.json</p>
                        <DownloadButton
                            onClick={() => downloadLedgerPartiesJson()}
                            iconOnly
                            disabled={
                                ledger.info.status.ledger !== ObjectStatus.RUNNING ||
                                !defaultParties?.publicParty
                            }
                        />
                    </div>
                </div>
            </SettingsField>
        </SettingsPanel>
    );
};

export default LedgerSettings;
