import React from 'react';

import { Pqs } from '@hub-fe/common/Pqs';
import { SetUpPqs } from '@hub-fe/common/SetUpPqs';
import { Route, Router } from '@hub-fe/common/routing';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import DefaultDeploymentViewer from '@hub-fe/ledger/Deployments/DefaultDeploymentViewer';

import { ParticipantOverview } from './ParticipantOverview';

export const ParticipantOverviewRoutes: React.FC = () => {
    const { serviceId } = useServiceParams();
    return (
        <Router>
            <Route
                path="participant"
                render={() => <DefaultDeploymentViewer defaultDeployment="participant" />}
            />
            <Route exact path="pqs/setup" render={() => <SetUpPqs serviceId={serviceId || ''} />} />

            <Route exact path="pqs/*" render={() => <Pqs />} />
            <Route default exact path="/" render={() => <ParticipantOverview />} />
        </Router>
    );
};
