import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import { isSynchronizerData, useAppContext } from '@hub-fe/app/AppContext';
import { ConnectOwnParticipant } from '@hub-fe/common/ConnectOwnParticipant';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import InformationBlock from '@hub-fe/common/InformationBlock';
import MenuBar from '@hub-fe/common/MenuBar';
import { ParticipantInformation } from '@hub-fe/common/generated/clients/damlhub';
import { mergeAllowedParticipants } from '@hub-fe/common/utils/mergeAllowedParticipants';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import './ParticipantsManagement.scss';
import { ParticipantsTable } from './ParticipantsTable';

interface AllowProps {
    participants?: ParticipantInformation[];
}
const Allow: React.FC<AllowProps> = () => {
    const onAdd = () => history.push('add-participant');
    const { activeParticipants: ownedParticipants } = useAppContext();
    const { allowedParticipants } = useLedgerContext();
    const all = mergeAllowedParticipants({
        ownedParticipants,
        allowedParticipants: allowedParticipants || [],
    });
    const enabledParticipants = all.filter(
        participants => participants.permissionLevel !== 'Disabled'
    );
    return (
        <div>
            <div className="form">
                <div className="input-button-container">
                    <button onClick={onAdd} className="button secondary">
                        Add Participant
                    </button>
                </div>
            </div>
            <ParticipantsTable data={enabledParticipants || []} />
        </div>
    );
};

export const ParticipantsManagement: React.FC = () => {
    const { ledger } = useLedgerContext();
    if (!ledger) {
        return null;
    }
    const isLive = ledger?.info.status.ledger === ObjectStatus.RUNNING;

    return (
        <div className="allow-list">
            {!isLive ? (
                <InformationBlock enableBackground>
                    Adding Participants will be available once the Synchronizer is live.
                </InformationBlock>
            ) : (
                <>
                    <MenuBar entries={[{ to: '', label: 'Allow List' }]} />
                    <Allow />
                    {isSynchronizerData(ledger) && (
                        <ConnectOwnParticipant
                            synchronizerName={ledger.info.name}
                            synchronizerUrl={ledger.info.hostname}
                        />
                    )}
                </>
            )}
        </div>
    );
};
