import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { dalGetChoicesByTemplate, IChoice, IContractInfo } from '@hub-client-api';

import { NavLink } from '@hub-fe/common/routing';
import { isNewService } from '@hub-fe/common/utils/isNewService';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import { useLedgerContext } from '../LedgerContext';

interface IProps {
    contract: IContractInfo;
    disabled: boolean;
}

const ContractChoices: React.FC<IProps> = props => {
    const [choices, setChoices] = useState([] as IChoice[]);
    const { ledgerId, selectedParty } = useLedgerContext();
    const { contract, disabled } = props;
    const { service } = useServiceParams();

    const enableServiceEndpoint = service ? isNewService(service) : false;
    useEffect(() => {
        if (selectedParty) {
            dalGetChoicesByTemplate(
                ledgerId,
                contract.templateId,
                selectedParty,
                enableServiceEndpoint
            ).then(choices => setChoices(choices));
        }
    }, [ledgerId, contract.templateId, selectedParty, enableServiceEndpoint]);

    if (choices.length === 0) {
        return null;
    }

    const handleClick = (e: any) => {
        if (disabled) e.preventDefault();
    };

    return (
        <div className={classNames('contract-choice-block row', { archived: disabled })}>
            <h4>Choices</h4>
            <div className="action-bar wrap">
                {choices.map(choice => (
                    <NavLink
                        key={choice.name}
                        className={classNames(
                            'button secondary-smaller',
                            {
                                dark: choice.name === 'Archive' && !disabled,
                            },
                            { disabled }
                        )}
                        onClick={event => handleClick(event)}
                        activeClassName="active"
                        to={encodeURIComponent(choice.name) + '/?party=' + selectedParty}
                    >
                        {choice.name}
                    </NavLink>
                ))}
            </div>
        </div>
    );
};

export default ContractChoices;
