import React from 'react';

import { Redirect, Route, Router } from '@hub-fe/common/routing';
import ActiveContracts from '@hub-fe/ledger/ActiveContracts/ActiveContracts';
import ActiveContractsHighEventCount from '@hub-fe/ledger/ActiveContracts/ActiveContractsHighEventCount';
import { useIsHighEventCount } from '@hub-fe/ledger/ActiveContracts/utils';
import Collaborators from '@hub-fe/ledger/Collaborators';
import DeployedArtifacts from '@hub-fe/ledger/Deployments/DeployedArtifacts';
import Identities from '@hub-fe/ledger/Identities/Identities';
import IntegrationBrowser from '@hub-fe/ledger/Integrations/IntegrationBrowser';
import { ParticipantOverviewRoutes } from '@hub-fe/participantNode/ParticipantOverviewRoutes';

import { useAppContext } from '../AppContext';
import { ParticipantSettings } from './ParticipantSettings';

export const ParticipantRoutes: React.FC = () => {
    const { isProTier } = useAppContext();
    const isHighEventCount = useIsHighEventCount();

    return (
        <Router>
            <Route path="overview/*" render={() => <ParticipantOverviewRoutes />} />
            <Route path="deployments/*" render={() => <DeployedArtifacts />} />
            <Route path="identities/*" render={() => <Identities />} />
            <Route
                path="live-data/*"
                render={() =>
                    isHighEventCount ? <ActiveContractsHighEventCount /> : <ActiveContracts />
                }
            />
            <Route path="settings/*" render={() => <ParticipantSettings />} />
            {isProTier && <Route path="collaborators" render={() => <Collaborators />} />}
            {<Route path="integrations/*" render={() => <IntegrationBrowser />} />}
            <Route path="/" render={() => <Redirect to="overview" />} />
        </Router>
    );
};
