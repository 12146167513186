import classNames from 'classnames';
import React from 'react';

import { ILedgerData, ILedgerInfo, ObjectStatus } from '@hub-client-api';

import { deploySampleApp, useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import Banner, { BannerWarningType } from '@hub-fe/common/Banner';
import { useScrollToElement } from '@hub-fe/common/ScrollContext';
import { getDayDiff } from '@hub-fe/common/Timing';
import { Link, NavLink } from '@hub-fe/common/routing';
import { Footer } from '@hub-fe/footer/Footer';

import LearningResources from './LearningResources';
import Project from './Project';
import SampleApps from './SampleApps';

const INACTIVE_LEDGER_DAYS_BANNER_THRESHOLD = 10;
const INACTIVE_LEDGER_DAYS_DELETE_THRESHOLD = 14;

export const checkStaleLedger = (ledger: ILedgerInfo): boolean => {
    if (!ledger.lastStateChange) {
        return false;
    }

    const dayDiff = getDayDiff(new Date(), new Date(ledger.lastStateChange));
    const isPaused = ledger.status.ledger === ObjectStatus.PAUSED;

    return dayDiff >= INACTIVE_LEDGER_DAYS_BANNER_THRESHOLD && isPaused;
};
const getStaleLedgers = (ledgers: ILedgerData[]): ILedgerData[] =>
    ledgers.filter(({ info }) => checkStaleLedger(info));

const useFindNewElementId = (): string | undefined => {
    const { projects = [], activeLedgers } = useAppContext();

    const [scrollToProject, setScrollToProject] = React.useState<string>();

    React.useEffect(() => {
        const loadingProject = projects?.find(
            ({ objectStatus }) => objectStatus === ObjectStatus.LOADING
        )?.info.name;
        if (loadingProject) {
            setScrollToProject(loadingProject);
        } else {
            const loadingLedgerProject = activeLedgers
                .filter(l => l.objectStatus === ObjectStatus.LOADING)
                .sort((a, b) => {
                    if (a.createdTime && b.createdTime) {
                        return a.createdTime < b.createdTime ? 1 : -1;
                    }
                    return 0;
                })[0]?.info.projectName;
            if (loadingLedgerProject) {
                setScrollToProject(loadingLedgerProject);
            }
        }
    }, [projects, activeLedgers]);

    return scrollToProject;
};

export const ProjectSelector: React.FC = () => {
    const { projects, activeLedgers, isProTier } = useAppContext();
    const { createProject } = useAppActions();

    const emptyWorkspace = projects?.length === 0;

    const showInactiveLedgersBanner = getStaleLedgers(activeLedgers).length > 0;

    const scrollToProject = useFindNewElementId();

    useScrollToElement(scrollToProject);

    return (
        <div className="workspace-project-selector">
            {emptyWorkspace ? (
                <h2 className="welcome-header"> Welcome to Daml Hub! </h2>
            ) : (
                <>
                    <div className="project-selector-header">
                        <h2>Projects</h2>
                        <NavLink
                            className={classNames('button', { disabled: !projects })}
                            to={'/console/projects/create-project'}
                        >
                            Create Project
                        </NavLink>
                    </div>
                    {showInactiveLedgersBanner && !isProTier && (
                        <Banner
                            bannerName="ledger-deletion-warning"
                            dismissible
                            className="ledger-deletion-warning-banner"
                            warningType={BannerWarningType.WARNING}
                            header={`Ledger Deletion Warning`}
                            message={
                                <p className="p2">
                                    You have some inactive ledgers.{' '}
                                    <b>
                                        All ledgers inactive for{' '}
                                        {INACTIVE_LEDGER_DAYS_DELETE_THRESHOLD} days or more will be
                                        deleted.
                                    </b>{' '}
                                    Please resume your ledgers or
                                    <br />
                                    <span>
                                        <Link className="ghost" to="/console/upgrade">
                                            upgrade your account
                                        </Link>
                                    </span>
                                </p>
                            }
                        />
                    )}
                    <section className="project-list">
                        {projects?.map(p => (
                            <div id={p.info.name} key={p.info.id}>
                                <Project project={p} />
                            </div>
                        ))}
                    </section>
                </>
            )}
            {emptyWorkspace && (
                <section className="empty-workspace">
                    <h2>
                        Get started with creating an app from scratch, uploading your own files:
                    </h2>
                    <NavLink className="button secondary" to={'/console/projects/create-project'}>
                        New Project
                    </NavLink>
                </section>
            )}
            <SampleApps
                emptyWorkspace={emptyWorkspace}
                onDeploy={app => deploySampleApp(app, activeLedgers, createProject)}
            />
            <LearningResources />
            <Footer />
        </div>
    );
};
