import React from 'react';

import { IResourceType, LedgerSizeEnum } from '@hub-client-api';

import InformationBlock from '@hub-fe/common/InformationBlock';
import { pluralize } from '@hub-fe/common/utils';

export function isLedgerSizeValid(
    proposedSize: LedgerSizeEnum,
    limitedSize: LedgerSizeEnum,
    tokens: IResourceType
): IResourceQuotaValidationState {
    const ledgersAllowed = tokens.tokens.length + tokens.spentTokens.length;
    const ledgersRemaining = ledgersAllowed - tokens.spentTokens.length;
    const atLimit = ledgersRemaining === 0;
    const overLimit = proposedSize === limitedSize && ledgersRemaining - 1 < 0;
    return {
        overLimit,
        atLimit,
        resourcesAllowed: ledgersAllowed,
        resourcesRemaining: ledgersRemaining,
    };
}

export interface IResourceQuotaValidationState {
    overLimit: boolean;
    atLimit: boolean;
    resourcesAllowed: number;
    resourcesRemaining: number;
}

const ResourceQuotaValidationBlock: React.FC<{
    validationState: IResourceQuotaValidationState;
    resourceDescription: string;
}> = ({ validationState, resourceDescription }) => {
    const { overLimit, resourcesAllowed, resourcesRemaining } = validationState;

    return (
        <div className="name-validation-block">
            <InformationBlock warning={overLimit}>
                <p className="caption warning">
                    Your account is limited to {resourcesAllowed}{' '}
                    {pluralize(resourcesAllowed, resourceDescription)}.{' '}
                    {resourcesRemaining < 1 ? (
                        <>You have none available.</>
                    ) : (
                        <>
                            You have {resourcesRemaining}{' '}
                            {pluralize(resourcesRemaining, resourceDescription)} available.
                        </>
                    )}
                </p>
            </InformationBlock>
        </div>
    );
};

export default ResourceQuotaValidationBlock;
