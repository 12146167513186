import React, { useState } from 'react';

import { InfiniteLoadingWheel } from '@hub-fe/common/Animations/Animations';
import BackwardsLink from '@hub-fe/common/BackwardsLink';
import LoadingButton from '@hub-fe/common/LoadingButton';
import apiTokens, { ADMIN_LEDGER, SITE_PARTY } from '@hub-fe/common/apiTokens';
import domains from '@hub-fe/common/domains';
import { routeToWorkspace } from '@hub-fe/common/routing/RoutingContext';
import { shortName } from '@hub-fe/common/siteInfo.variants';
import TermsAndConditions from '@hub-fe/common/terms/TermsAndConditions';

import { LoginStage, useAppContext } from './AppContext';

const AcceptDABLTerms = () => {
    const [isSubmitting, setSubmitting] = useState(false);

    async function acceptTermsOfService() {
        setSubmitting(true);

        try {
            const cachedSiteJwt = apiTokens.getCachedToken(ADMIN_LEDGER, SITE_PARTY);

            if (!cachedSiteJwt) {
                throw new Error('expected cached site JWT');
            }

            const method = 'POST';
            const headers = new Headers();

            headers.append('Accept', 'application/json');
            headers.append('Authorization', `Bearer ${cachedSiteJwt}`);

            const options: RequestInit = { method, headers };
            const tosAcceptResponse = await fetch(
                `//${domains.hub}/api/console/auth/tos-acceptance`,
                options
            );
            const tosToken = await tosAcceptResponse.json();
            apiTokens.set(ADMIN_LEDGER, SITE_PARTY, tosToken.accessToken);
            routeToWorkspace();
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div className="accept-controls default">
            <LoadingButton
                id="project-dabl-accept"
                loading={isSubmitting}
                loadingContent={`I accept the ${shortName} terms and conditions`}
                onClick={acceptTermsOfService}
            >
                I accept the {shortName} terms and conditions
            </LoadingButton>
            {isSubmitting && <InfiniteLoadingWheel />}
        </div>
    );
};

const TermsPage: React.FC = () => {
    const { loginInfo } = useAppContext();
    const acceptedTermsOfService =
        loginInfo.stage === LoginStage.LOGGED_IN && loginInfo.claims?.hubClaims.tosAccepted;

    const acceptButton = !acceptedTermsOfService ? <AcceptDABLTerms /> : null;

    return (
        <div className="terms-page">
            {acceptedTermsOfService && <BackwardsLink url="/console/account" />}
            <h1>Terms And Conditions</h1>

            <div className="terms-legal-text">
                <TermsAndConditions mustAcceptTerms={!acceptedTermsOfService} />
                {acceptButton}
            </div>
        </div>
    );
};

export default TermsPage;
