import React, { useEffect, useState } from 'react';

import { dalGetHighlightedArtifact } from '@hub-client-api';

import LoadingPage from '@hub-fe/common/LoadingPage';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import { useLedgerActions } from '../LedgerContext';

const DeployedArtifactFileContent: React.FC = () => {
    const [artifactHtml, setArtifactHtml] = useState('');
    const { artifactHash, artifactUrl, service } = useServiceParams();

    const { getArtifactByHash } = useLedgerActions();
    const artifact = getArtifactByHash(artifactHash);

    useEffect(() => {
        if (!artifact || !artifactUrl) {
            return;
        }

        dalGetHighlightedArtifact(artifact, artifactUrl).then(resp => setArtifactHtml(resp));
    }, [artifactUrl, artifact]);

    if (!service || !artifact || !artifactUrl) {
        return null;
    }

    return (
        <div className="code-content">
            <h4> Code </h4>
            <div className="code-wrapper">
                <div className="code">
                    <div className="file-path">{artifactUrl}</div>
                    {artifactHtml ? (
                        <div
                            className="source-text"
                            dangerouslySetInnerHTML={{ __html: artifactHtml }}
                        />
                    ) : (
                        <LoadingPage />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeployedArtifactFileContent;
