import React from 'react';

import { IconAlert, IconExpander } from '@hub-fe/common/Icons';

import { useLedgerActions, useLedgerContext } from '../LedgerContext';

const ActionNeededTile: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { isLedgerOwner, actionNeededExpanded } = useLedgerContext();
    const { toggleActionNeededExpanded } = useLedgerActions();

    return (
        <div className="action-needed-tile row">
            <div className="title icon-left">
                {isLedgerOwner && <IconAlert />}
                {isLedgerOwner ? <h3>Action Needed</h3> : <h3>Published Deployments</h3>}
                <button className="ghost no-hover icon-left" onClick={toggleActionNeededExpanded}>
                    <IconExpander expanded={actionNeededExpanded} />
                    {actionNeededExpanded ? 'Collapse' : 'Expand'}
                </button>
            </div>
            {actionNeededExpanded && (
                <>
                    {isLedgerOwner ? (
                        <>
                            <p className="p2">
                                You have files that have been added to this ledger but are not
                                running yet.
                            </p>
                            <p className="p2">
                                Please review the actions needed to continue with their deployment.
                            </p>
                        </>
                    ) : (
                        <p className="p2">
                            Bots and integrations made deployable by the ledger’s owner.
                        </p>
                    )}
                    <table>
                        <thead>
                            <tr>
                                <th align="left">
                                    <p className="p2 bold">File Name</p>
                                </th>
                                <th align="left">
                                    <p className="p2 bold">Actions</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>{children}</tbody>
                    </table>
                </>
            )}
        </div>
    );
};

export default ActionNeededTile;
