import React from 'react';
import Select from 'react-select';

import { IconChevronDown } from '@hub-fe/common/Icons';

const SmallSelect: React.FC<any> = props => (
    <Select
        className="small-select"
        classNamePrefix="small-select"
        {...props}
        components={{ ...props.components, DropdownIndicator: () => <IconChevronDown /> }}
    />
);

export default SmallSelect;
