import React from 'react';

import {
    IconSidebarACS,
    IconSidebarDeployments,
    IconSidebarSettings,
    IconSidebarUser,
    IconSidebarWebhook,
    IconMultiParty,
    IconInformation,
} from '@hub-fe/common/Icons';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

export enum LinkLabels {
    DEPLOYMENTS = 'Deployments',
    LIVE_DATA = 'Live Data',
    INTEGRATIONS = 'Integrations',
    IDENTITIES = 'Identities',
    LEDGER_SETTINGS = 'Ledger Settings',
    COLLABORATORS = 'Collaborators',
    APP_SETTINGS = 'Settings',
    OVERVIEW = 'Overview',
    PARTICIPANTS = 'Participants',
}
enum LinkTargets {
    DEPLOYMENTS = 'deployments',
    LIVE_DATA = 'live-data',
    BROWSE_INTEGRATIONS = 'integrations',
    IDENTITIES = 'identities',
    LEDGER_SETTINGS = 'ledger-settings',
    COLLABORATORS = 'collaborators',
    CONNECTION = 'connection',
    APP_SETTINGS = 'settings',
    OVERVIEW = 'overview',
    PARTICIPANTS = 'participants',
}
export interface ILedgerSidebarLink {
    ownerOnly: boolean;
    label: LinkLabels;
    icon: JSX.Element;
    target: LinkTargets;
    to?: string;
}

const LEDGER_SIDEBAR_LINKS: ILedgerSidebarLink[] = [
    {
        ownerOnly: false,
        label: LinkLabels.DEPLOYMENTS,
        icon: <IconSidebarDeployments />,
        target: LinkTargets.DEPLOYMENTS,
    },
    {
        ownerOnly: false,
        label: LinkLabels.LIVE_DATA,
        icon: <IconSidebarACS />,
        target: LinkTargets.LIVE_DATA,
    },
    {
        ownerOnly: false,
        label: LinkLabels.IDENTITIES,
        icon: <IconSidebarUser />,
        target: LinkTargets.IDENTITIES,
    },
    {
        ownerOnly: true,
        label: LinkLabels.COLLABORATORS,
        icon: <IconMultiParty />,
        target: LinkTargets.COLLABORATORS,
    },
    {
        ownerOnly: false,
        label: LinkLabels.LEDGER_SETTINGS,
        icon: <IconSidebarSettings />,
        target: LinkTargets.LEDGER_SETTINGS,
    },
    {
        ownerOnly: true,
        label: LinkLabels.INTEGRATIONS,
        icon: <IconSidebarWebhook />,
        target: LinkTargets.BROWSE_INTEGRATIONS,
    },
];
const SCRATCHPAD_SIDEBAR_LINKS: ILedgerSidebarLink[] = [
    {
        ownerOnly: false,
        label: LinkLabels.OVERVIEW,
        icon: <IconInformation />,
        target: LinkTargets.OVERVIEW,
    },
    {
        ownerOnly: false,
        label: LinkLabels.DEPLOYMENTS,
        icon: <IconSidebarDeployments />,
        target: LinkTargets.DEPLOYMENTS,
    },
    {
        ownerOnly: false,
        label: LinkLabels.LIVE_DATA,
        icon: <IconSidebarACS />,
        target: LinkTargets.LIVE_DATA,
    },
    {
        ownerOnly: false,
        label: LinkLabels.IDENTITIES,
        icon: <IconSidebarUser />,
        target: LinkTargets.IDENTITIES,
    },
    {
        ownerOnly: true,
        label: LinkLabels.COLLABORATORS,
        icon: <IconMultiParty />,
        target: LinkTargets.COLLABORATORS,
    },
    {
        ownerOnly: false,
        label: LinkLabels.APP_SETTINGS,
        icon: <IconSidebarSettings />,
        target: LinkTargets.APP_SETTINGS,
    },
];
const PARTICIPANT_SIDEBAR_LINKS: ILedgerSidebarLink[] = [
    {
        ownerOnly: false,
        label: LinkLabels.OVERVIEW,
        icon: <IconInformation />,
        target: LinkTargets.OVERVIEW,
    },
    {
        ownerOnly: false,
        label: LinkLabels.DEPLOYMENTS,
        icon: <IconSidebarDeployments />,
        target: LinkTargets.DEPLOYMENTS,
    },
    {
        ownerOnly: false,
        label: LinkLabels.LIVE_DATA,
        icon: <IconSidebarACS />,
        target: LinkTargets.LIVE_DATA,
    },
    {
        ownerOnly: false,
        label: LinkLabels.IDENTITIES,
        icon: <IconSidebarUser />,
        target: LinkTargets.IDENTITIES,
    },
    {
        ownerOnly: true,
        label: LinkLabels.COLLABORATORS,
        icon: <IconMultiParty />,
        target: LinkTargets.COLLABORATORS,
    },
    {
        ownerOnly: true,
        label: LinkLabels.INTEGRATIONS,
        icon: <IconSidebarWebhook />,
        target: LinkTargets.BROWSE_INTEGRATIONS,
    },
    {
        ownerOnly: false,
        label: LinkLabels.APP_SETTINGS,
        icon: <IconSidebarSettings />,
        target: LinkTargets.APP_SETTINGS,
    },
];
export const SYNCHRONIZER_SIDEBAR_LINKS: ILedgerSidebarLink[] = [
    {
        ownerOnly: false,
        label: LinkLabels.PARTICIPANTS,
        icon: <IconSidebarWebhook />,
        target: LinkTargets.PARTICIPANTS,
    },
    {
        ownerOnly: false,
        label: LinkLabels.OVERVIEW,
        icon: <IconInformation />,
        target: LinkTargets.OVERVIEW,
    },
    {
        ownerOnly: true,
        label: LinkLabels.INTEGRATIONS,
        icon: <IconSidebarWebhook />,
        target: LinkTargets.BROWSE_INTEGRATIONS,
    },
    {
        ownerOnly: false,
        label: LinkLabels.APP_SETTINGS,
        icon: <IconSidebarSettings />,
        target: LinkTargets.APP_SETTINGS,
    },
];

export const getParticipantSideBarLinks = ({
    isLedgerOwner,
    isProTier,
}: {
    isLedgerOwner: boolean;
    isProTier?: boolean;
}): ILedgerSidebarLink[] => {
    let links = isLedgerOwner
        ? PARTICIPANT_SIDEBAR_LINKS
        : PARTICIPANT_SIDEBAR_LINKS.filter(p => !p.ownerOnly);
    if (!isProTier) {
        links = links.filter(l => l.label !== LinkLabels.COLLABORATORS);
    }
    return links;
};
const getSynchronizerSideBarLinks = ({
    isLedgerOwner,
    isProTier,
}: {
    isLedgerOwner: boolean;
    isProTier?: boolean;
}): ILedgerSidebarLink[] => {
    let links = isLedgerOwner
        ? SYNCHRONIZER_SIDEBAR_LINKS
        : SYNCHRONIZER_SIDEBAR_LINKS.filter(p => !p.ownerOnly);
    if (!isProTier) {
        links = links.filter(l => l.label !== LinkLabels.COLLABORATORS);
    }
    return links;
};
export const getScratchpadSideBarLinks = ({
    isLedgerOwner,
    isProTier,
}: {
    isLedgerOwner: boolean;
    isProTier?: boolean;
}): ILedgerSidebarLink[] => {
    let links = isLedgerOwner
        ? SCRATCHPAD_SIDEBAR_LINKS
        : SCRATCHPAD_SIDEBAR_LINKS.filter(p => !p.ownerOnly);
    if (!isProTier) {
        links = links.filter(l => l.label !== LinkLabels.COLLABORATORS);
    }
    return links;
};

export const getLedgerSideBarLinks = ({
    isLedgerOwner,
    isProTier,
}: {
    isLedgerOwner: boolean;
    isProTier?: boolean;
}): ILedgerSidebarLink[] => {
    let links = isLedgerOwner
        ? LEDGER_SIDEBAR_LINKS
        : LEDGER_SIDEBAR_LINKS.filter(p => !p.ownerOnly);
    if (!isProTier) {
        links = links.filter(l => l.label !== LinkLabels.COLLABORATORS);
    }
    return links;
};
export const sidebarlinks = {
    [ServiceType.LEDGER]: getLedgerSideBarLinks,
    [ServiceType.PARTICIPANT]: getParticipantSideBarLinks,
    [ServiceType.SYNC_DOMAIN]: getSynchronizerSideBarLinks,
    [ServiceType.SCRATCHPAD]: getScratchpadSideBarLinks,
};
