import Loadable from '@loadable/component';
import className from 'classnames';
import React, { useEffect, useState } from 'react';

import { IContractInfo } from '@hub-client-api';

import ContractChoices from './ContractChoices';
import ContractInfoHeader from './ContractInfoHeader';
import EmptyDisplayPlaceholder from './EmptyDisplayPlaceholder';
import { TimedContractInfo } from './useContractStream';

const MAX_PAYLOAD_SIZE = 4000000; // 4MB
const PRELOAD_PAYLOAD_SIZE = 1000000; // 1MB

const LoadableReactJson = Loadable(() => import('react-json-view'));

const ContractDetails = (props: { contract: TimedContractInfo; archived: boolean }) => {
    // `seenAt` is technically unused, but the aim is to exclude it from
    // the `contract` object, so the linter is silenced here
    const { seenAt, ...contract } = props.contract; // eslint-disable-line
    const { archived } = props;

    return (
        <div className={className('contract-details row', { archived })}>
            <h4>Contract Data</h4>
            <LoadableContractData contract={contract} />
        </div>
    );
};

const LoadableContractData: React.FC<{ contract: any }> = ({ contract }) => {
    const [displayContractData, setDisplayContractData] = useState(false);

    const { size } = new Blob([JSON.stringify(contract.payload)]);

    useEffect(() => {
        if (size < PRELOAD_PAYLOAD_SIZE) {
            setDisplayContractData(true);
        }
    }, [size]);

    if (size > MAX_PAYLOAD_SIZE) {
        return <p className="p2">This contract payload is too large to be displayed.</p>;
    }

    if (size > PRELOAD_PAYLOAD_SIZE && !displayContractData) {
        return (
            <div className="load-contract-data">
                <p className="p2">This contract payload is pretty large.</p>
                <button onClick={() => setDisplayContractData(true)} className="secondary-smaller">
                    Load Contract Data
                </button>
            </div>
        );
    }

    return <LoadableReactJson src={contract} style={{ wordBreak: 'break-all' }} />;
};

interface IExternalProps {
    contractId?: string;
}

interface IProps extends IExternalProps {
    contract?: IContractInfo;
    archived: boolean;
    readOnly: boolean;
    loading: boolean;
    isHighEventCount?: boolean;
}

const ContractContents: React.FC<IProps> = React.memo(props => {
    const { contract, loading, archived, readOnly, isHighEventCount } = props;
    let contents;

    if (contract && typeof window !== 'undefined') {
        contents = (
            <>
                <ContractInfoHeader contract={contract} archived={archived} />
                <ContractChoices contract={contract} disabled={archived || readOnly} />
                <ContractDetails contract={contract} archived={archived} />
            </>
        );
    } else {
        contents = <EmptyDisplayPlaceholder loading={loading} />;
    }

    return (
        <div
            className={className(
                'contract-contents',
                { archived, 'empty-state': !contract },
                isHighEventCount
                    ? 'contract-contents-high-event-count'
                    : 'contract-contents-standard-event-count'
            )}
        >
            {contents}
        </div>
    );
});

export default ContractContents;
