import React from 'react';

import './SplitLayout.scss';

interface SplitLayoutProps {
    children: React.ReactNode[];
}
export const SplitLayout: React.FC<SplitLayoutProps> = ({ children }) => {
    return (
        <div className="layout">
            <div className="scrollable left-section">
                {/* Left Scrollable Section */}
                {/* Add content here */}
                {children[0]}
            </div>
            <div className="divider"></div>
            <div className="scrollable right-section">
                {/* Right Scrollable Section */}
                {/* Add content here */}
                {children[1]}
            </div>
        </div>
    );
};
