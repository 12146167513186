import React, { FunctionComponent, useCallback, useRef, useState } from 'react';

import { useAppActions } from '@hub-fe/app/AppContext';
import InformationBlock from '@hub-fe/common/InformationBlock';
import Modal, { ModalControls } from '@hub-fe/common/Modal';
import { isFileNameValid } from '@hub-fe/common/utils';

interface IProps {
    originalFileName: string;
    onRequestClose: (renameRequested: boolean) => void;
}

const RenameFileModal: FunctionComponent<IProps> = props => {
    const { originalFileName, onRequestClose } = props;
    const { renameWorkspaceFile } = useAppActions();
    const [fileName, setFileName] = useState(originalFileName);
    const refInitialized = useRef<boolean>(false);

    const refInput = useCallback(
        (node: HTMLInputElement) => {
            if (!node || refInitialized.current) {
                return;
            }

            refInitialized.current = true;

            const extIndex = fileName.lastIndexOf('.');

            node.setSelectionRange(0, extIndex === -1 ? fileName.length : extIndex);
        },
        [fileName]
    );

    const validFileName = isFileNameValid(fileName.trim());

    function handleRenameFile() {
        renameWorkspaceFile(fileName, originalFileName);
        onRequestClose(true);
    }

    return (
        <Modal
            onSubmit={() => handleRenameFile()}
            className="confirm-action-modal"
            title="Rename file"
            show
            onRequestClose={() => onRequestClose(false)}
        >
            <p>New Name</p>
            <input
                type="text"
                value={fileName}
                placeholder="New file name"
                onChange={e => setFileName(e.target.value)}
                ref={refInput}
                autoFocus
            />
            <div className="row">
                <InformationBlock>No special characters allowed.</InformationBlock>
            </div>
            <ModalControls>
                <button disabled={!validFileName} onClick={() => handleRenameFile()}>
                    Submit
                </button>
            </ModalControls>
        </Modal>
    );
};

export default RenameFileModal;
