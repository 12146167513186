import React from 'react';

import { useAppActions } from '@hub-fe/app/AppContext';
import { InputErrorMessage } from '@hub-fe/common/InputErrorMessage';
import LoadingButton from '@hub-fe/common/LoadingButton';

import './SaveSynchronizerInputForm.scss';

interface SaveSynchronizerInputFormProps {
    existingNames: string[];
    onClose: () => void;
}

export const SaveSynchronizerInputForm: React.FC<SaveSynchronizerInputFormProps> = props => {
    const { existingNames, onClose } = props;
    const { saveSynchronizer } = useAppActions();
    const [name, setName] = React.useState('');
    const hasDupeName = existingNames.find(existingName => existingName === name);
    const [url, setUrl] = React.useState('');
    const [hasNameError, setNameError] = React.useState(false);

    const isSaveButtonDisabled = !name || !url;
    const [isLoading, setLoading] = React.useState(false);
    const onSave = async () => {
        if (!url || !name) {
            return;
        }
        if (hasDupeName) {
            setNameError(true);
            return;
        }
        setLoading(true);
        await saveSynchronizer({ name, synchronizerUrl: url });
        setLoading(false);
        onClose();
    };
    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (hasNameError) {
            setNameError(false);
        }
        setName(e.target.value);
    };
    const onUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value);
    };
    const onCancel = () => {
        onClose();
        setName('');
        setUrl('');
    };

    return (
        <div className="save-synchronizer-form">
            <div className="input-container">
                Synchronizer name
                <input onChange={onNameChange} />
                {hasNameError && <InputErrorMessage errorMessage={'Duplicate name'} />}
            </div>
            <div className="input-container">
                <p className="bold">Synchronizer Url</p>
                <input onChange={onUrlChange} />
            </div>
            <div className="button-container">
                <button onClick={onCancel} className="secondary spacing">
                    Cancel
                </button>
                <LoadingButton
                    onClick={onSave}
                    disabled={isSaveButtonDisabled}
                    className="primary"
                    loading={isLoading}
                >
                    Save
                </LoadingButton>
            </div>
        </div>
    );
};
