import React, { useState } from 'react';

import { ILedgerIntegration } from '@hub-client-api';

import Toggle from '@hub-fe/common/Toggle';
import { IToggleIntegrationFn } from '@hub-fe/ledger/LedgerContext';

export const IntegrationToggle: React.FC<{
    integration: ILedgerIntegration;
    handleToggle: IToggleIntegrationFn;
    disabled?: boolean;
}> = ({ integration, handleToggle, disabled }) => {
    const [toggling, setToggling] = useState<boolean>(false);

    const toggleLogstream = async () => {
        if (integration) {
            setToggling(true);
            handleToggle(!integration.enabled, () => setToggling(false));
        }
    };

    return (
        <Toggle
            on={integration.enabled}
            onColor="green"
            toggling={toggling}
            togglingLabel="Setting"
            onChanged={toggleLogstream}
            disabled={disabled}
            onLabel="Enabled"
            offLabel="Disabled"
        />
    );
};
