import classNames from 'classnames';
import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import Banner, { BannerWarningType } from '@hub-fe/common/Banner';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { Route, Router } from '@hub-fe/common/routing';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';

import { useLedgerContext } from '../LedgerContext';
import { useContractFilterQuery } from './ActiveContractsSearch';
import ChoiceTemplate from './ChoiceTemplate';
import CreateContracts from './CreateContracts';
import { SearchWrapper } from './SearchWrapper';

const ActiveContractsHighEventCount: React.FC = () => {
    const { selectedParty, ledger } = useLedgerContext();
    const { service } = useServiceParams();
    if (!ledger) {
        return null;
    }
    const [showSearchRes, setShowSearchRes] = React.useState(false);
    const { filterQueryString } = useContractFilterQuery();

    const displaySearchResComponent = showSearchRes && filterQueryString;
    const toggle = () => {
        setShowSearchRes(!showSearchRes);
    };

    const initializing =
        ledger.info.status.ledger === ObjectStatus.STARTING ||
        ledger.info.status.ledger === ObjectStatus.RESUMING;
    const readOnly = ledger.info.status.ledger === ObjectStatus.PAUSED;
    const createLink =
        `/console/${service}/${ledger.info.id}/live-data/create` +
        (filterQueryString ? `?${filterQueryString}` : '');
    return (
        <div className={classNames('active-contracts')}>
            <div className="live-data-title">
                <h1>Live Data</h1>
                {selectedParty && ledger?.info.id && !initializing && !readOnly && (
                    <button
                        onClick={() => {
                            history.push(createLink);
                        }}
                    >
                        Add Contract
                    </button>
                )}
            </div>
            <div className="banner-container">
                <Banner
                    bannerName="liveData"
                    dismissible
                    warningType={BannerWarningType.CAUTION}
                    className="upgrade-warning-banner"
                    header="Alert"
                    message={
                        <>
                            <p className="p2">
                                Your ledger has too many contracts to display them all at once.
                                Select a party and template to query the ledger contracts.
                            </p>
                            <p className="p2">
                                Note: if you select a party and template which has a lof of
                                contracts, it may still slow down the performance of your ledger and
                                it may take a long time for the contracts to appear.
                            </p>
                        </>
                    }
                />
            </div>

            <Router>
                <Route path="create/*" render={() => <CreateContracts />} />
                <Route path="create/:currentTemplate" render={() => <CreateContracts />} />
                <Route path=":selectedContract/:choice" render={() => <ChoiceTemplate />} />
                <Route
                    path=":selectedContract"
                    render={() => (
                        <SearchWrapper
                            toggle={toggle}
                            displaySearchRes={!!displaySearchResComponent}
                        />
                    )}
                />
                <Route
                    path="/"
                    render={() => (
                        <SearchWrapper
                            toggle={toggle}
                            displaySearchRes={!!displaySearchResComponent}
                        />
                    )}
                />
            </Router>
        </div>
    );
};

export default ActiveContractsHighEventCount;
