import { JSONSchema7 as JsonSchema } from 'json-schema';

import {
    ConType,
    DamlEnumType,
    DamlType,
    DamlVariantType,
    IDamlEnumType,
    IDamlTypeDefinitions,
    IDamlVariableDefinitions,
    isConType,
    isEnum,
    isNatType,
    isPrimType,
    isRecord,
    isVariant,
    isVarType,
    PrimTypeEnum,
} from '@hub-fe/common/TemplateFieldsInterfaces';
import { toDateFormatted } from '@hub-fe/common/Timing';

// TODO: These min/max ranges may need to be enforced in a different way since JavaScript's number precision
//  does not allow us to natively handle the full range of integer values that Daml supports
/* eslint-disable */
const DAML_INTEGER_RANGE_MAX = 9223372036854775807;
/* eslint-enable */
const DAML_INTEGER_RANGE_MIN = -9223372036854775808;
const DAML_NUMBERIC_MAX_DIGITS_BEFORE_DECIMAL = 28;
const DEFAULT_ARRAY: any[] = [];
const DEFAULT_BOOLEAN = false;
const DEFAULT_DATE = toDateFormatted(new Date());
const INTEGER_REGEX = `-?[0-9]{0,10}`;
const NUMERIC_REGEX = (digitsAfterDecimal: number) =>
    `(?!^0*$)(?!^0*\.0*$)^\d{1,${DAML_NUMBERIC_MAX_DIGITS_BEFORE_DECIMAL}}(\.\d{1,${digitsAfterDecimal}})?$`; // eslint-disable-line no-useless-escape
const TEXT_MAP_ID = 'TEXT_MAP_ID';
export const DEFAULT_OBJECT = {};
export const GEN_MAP_KEY = 'GEN_MAP_KEY';
export const GEN_MAP_VALUE = 'GEN_MAP_VALUE';
export const MAP_PAIR = 'Map Pair';
export const TEXT_MAP_KEY = 'TEXT_MAP_KEY';
export const TEXT_MAP_VALUE = 'TEXT_MAP_VALUE';
export const UNSUPPORTED_TYPE = 'Unsupported Type';
const VARIANT_TAG_ENUM_KEY = 'VARIANT_TAG_ENUM_KEY';

export enum JsonSchemaTypeEnum {
    STRING = 'string',
    ARRAY = 'array',
    BOOLEAN = 'boolean',
    NUMBER = 'number',
    INTEGER = 'integer',
    OBJECT = 'object',
}

// Schema based on type definitions found in https://docs.daml.com/daml/intro/3_Data.html
function determineSchema(
    damlType: DamlType,
    fieldName: string,
    definitions: IDamlTypeDefinitions,
    variables: IDamlVariableDefinitions,
    parentTypes: string[]
): JsonSchema {
    if (isPrimType(damlType)) {
        return determinePrimTypeSchema(
            fieldName,
            damlType.prim,
            damlType.args,
            definitions,
            variables,
            parentTypes
        );
    }

    if (isConType(damlType)) {
        return determineConType(damlType, fieldName, definitions, variables, parentTypes);
    }
    if (isVarType(damlType)) {
        if (damlType === variables[damlType.var]) {
            // Recursive var type
            return unsupportedType(JSON.stringify(damlType));
        }
        return determineSchema(
            variables[damlType.var],
            fieldName,
            definitions,
            variables,
            parentTypes
        );
    }
    if (isNatType(damlType)) {
        // a bare Nat type is an error; we should always process them in the context of a primitive Numeric
        return unsupportedType(JSON.stringify(damlType));
    }

    return unsupportedType(JSON.stringify(damlType) || '');
}

export function createSchemaObject(
    damlType: string,
    fields: { [field: string]: DamlType },
    fieldName: string,
    definitions: IDamlTypeDefinitions,
    variables: IDamlVariableDefinitions,
    parentTypes: string[]
): JsonSchema {
    const schemaProperties = {};
    let requiredFields: string[] = [];

    Object.keys(fields).forEach(n => {
        let name = n;
        const damlType = fields[name];
        // See updateMapEntries for use of TEXT_MAP_ID
        if (isPrimType(damlType) && damlType.prim === PrimTypeEnum.MAP) {
            if (damlType.prim === PrimTypeEnum.MAP) {
                name = name + TEXT_MAP_ID;
            }
        }

        let fieldSchema = determineSchema(damlType, name, definitions, variables, parentTypes);

        if (checkIsOptional(damlType)) {
            fieldSchema = { ...fieldSchema, default: undefined };
        } else {
            requiredFields = [...requiredFields, name];
        }

        schemaProperties[name] = fieldSchema;
    });

    return {
        title: fieldName,
        description: damlType,
        properties: schemaProperties,
        required: requiredFields,
        type: JsonSchemaTypeEnum.OBJECT,
        default: DEFAULT_OBJECT,
    };
}

const determinePrimTypeSchema = (
    fieldName: string,
    primType: PrimTypeEnum,
    args: DamlType[],
    definitions: IDamlTypeDefinitions,
    variables: IDamlVariableDefinitions,
    parentTypes: string[]
): JsonSchema => {
    const baseSchema = {
        description: primType,
        title: fieldName,
        default: undefined,
    };
    switch (primType) {
        case PrimTypeEnum.INTEGER:
            return {
                ...baseSchema,
                type: JsonSchemaTypeEnum.INTEGER,
                pattern: INTEGER_REGEX,
                minimum: DAML_INTEGER_RANGE_MIN,
                maximum: DAML_INTEGER_RANGE_MAX,
            };
        case PrimTypeEnum.DECIMAL:
        case PrimTypeEnum.NUMBERIC:
            return {
                ...baseSchema,
                type: JsonSchemaTypeEnum.NUMBER,
                minimum: DAML_INTEGER_RANGE_MIN,
                maximum: DAML_INTEGER_RANGE_MAX,
                pattern: NUMERIC_REGEX(isNatType(args[0]) ? args[0].nat : 10),
            };
        case PrimTypeEnum.DATE:
            return {
                ...baseSchema,
                default: DEFAULT_DATE,
                type: JsonSchemaTypeEnum.STRING,
            };
        case PrimTypeEnum.TIME:
            return {
                ...baseSchema,
                type: JsonSchemaTypeEnum.STRING,
            };
        case PrimTypeEnum.BOOLEAN:
            return {
                ...baseSchema,
                default: DEFAULT_BOOLEAN,
                type: JsonSchemaTypeEnum.BOOLEAN,
            };
        case PrimTypeEnum.UNIT:
            return {
                ...baseSchema,
                default: DEFAULT_OBJECT,
                type: JsonSchemaTypeEnum.OBJECT,
            };
        case PrimTypeEnum.LIST:
            return {
                ...baseSchema,
                additionalItems: true,
                default: DEFAULT_ARRAY,
                items: determineSchema(args[0], fieldName, definitions, variables, parentTypes),
                type: JsonSchemaTypeEnum.ARRAY,
            };
        case PrimTypeEnum.OPTIONAL:
            return determineSchema(args[0], fieldName, definitions, variables, parentTypes);
        case PrimTypeEnum.MAP:
        case PrimTypeEnum.GENMAP:
            return determineMapTypeSchema(
                fieldName,
                primType,
                args,
                definitions,
                variables,
                parentTypes
            );
        case PrimTypeEnum.TEXT:
        case PrimTypeEnum.CONTRACT_ID:
        case PrimTypeEnum.PARTY:
        case PrimTypeEnum.RELTIME:
            return {
                ...baseSchema,
                type: JsonSchemaTypeEnum.STRING,
            };
        case PrimTypeEnum.SCENARIO:
        case PrimTypeEnum.ARROW:
        case PrimTypeEnum.UPDATE:
        default:
            return unsupportedType(JSON.stringify(primType));
    }
};

const getNewConTypeArgs = (
    type: ConType,
    definitions: IDamlTypeDefinitions,
    variables: IDamlVariableDefinitions
) => {
    const newType = definitions[type.con];
    const varTypes: string[] = isRecord(newType)
        ? newType.record.typeArgs
        : isVariant(newType)
        ? newType.variant.typeArgs
        : [];

    const newVariables = { ...variables };
    varTypes.forEach((v, i) => {
        newVariables[v] = type.args[i];
    });
    return { newType, newVariables };
};

const getVariantSchema = (
    variantName: string,
    damlType: DamlType,
    definitions: IDamlTypeDefinitions,
    variables: IDamlVariableDefinitions,
    parentTypes: string[]
) => {
    const body = {};
    body[VARIANT_TAG_ENUM_KEY] = {
        enum: [variantName],
    };
    body[variantName] = determineSchema(damlType, variantName, definitions, variables, parentTypes);

    return {
        properties: body,
        required: [variantName],
    };
};

const determineConType = (
    type: ConType,
    fieldName: string,
    definitions: IDamlTypeDefinitions,
    variables: IDamlVariableDefinitions,
    parentTypes: string[]
): JsonSchema => {
    const { newType, newVariables } = getNewConTypeArgs(type, definitions, variables);

    // check for recursive types
    if (parentTypes.includes(type.con)) {
        return unsupportedType(JSON.stringify(type));
    }

    const newParentTypes = [...parentTypes, type.con];

    if (isRecord(newType)) {
        return createSchemaObject(
            fieldName,
            newType.record.fields,
            fieldName,
            definitions,
            newVariables,
            newParentTypes
        );
    }

    if (isVariant(newType)) {
        const constructorKeys = Object.keys(newType.variant.constructors);
        return {
            title: fieldName,
            description: DamlVariantType,
            type: JsonSchemaTypeEnum.OBJECT,
            properties: {
                VARIANT_TAG_ENUM_KEY: {
                    title: fieldName,
                    description: DamlEnumType,
                    type: JsonSchemaTypeEnum.STRING,
                    enum: constructorKeys,
                    default: constructorKeys[0],
                },
            },
            required: [VARIANT_TAG_ENUM_KEY],
            dependencies: {
                VARIANT_TAG_ENUM_KEY: {
                    oneOf: constructorKeys.map(variantName =>
                        getVariantSchema(
                            variantName,
                            newType.variant.constructors[variantName],
                            definitions,
                            newVariables,
                            newParentTypes
                        )
                    ),
                },
            },
        };
    }

    if (isEnum(newType)) {
        return {
            title: fieldName,
            description: DamlEnumType,
            type: JsonSchemaTypeEnum.STRING,
            enum: (newType as IDamlEnumType).enum.constructors,
        };
    }

    return unsupportedType(JSON.stringify(newType) || '');
};

const determineMapTypeSchema = (
    fieldName: string,
    primType: PrimTypeEnum,
    args: DamlType[],
    definitions: IDamlTypeDefinitions,
    variables: IDamlVariableDefinitions,
    parentTypes: string[]
): JsonSchema => {
    let isSet = false;
    let properties = {};

    switch (primType) {
        case PrimTypeEnum.MAP:
            properties = {
                TEXT_MAP_KEY: {
                    title: 'Key',
                    description: PrimTypeEnum.TEXT,
                    type: JsonSchemaTypeEnum.STRING,
                },
                TEXT_MAP_VALUE: determineSchema(
                    args[0],
                    formatMultiTypeFieldName(args[0], args, variables, definitions),
                    definitions,
                    variables,
                    parentTypes
                ),
            };
            break;
        case PrimTypeEnum.GENMAP:
            let keyType = args[0];

            // If key is a Var Type k, this GenMap is a Set
            const primVariable = Object.keys(variables).find(v => isPrimType(variables[v]));
            if (isVarType(keyType) && primVariable) {
                keyType = variables[primVariable];
                isSet = true;
            }

            properties = {
                GEN_MAP_KEY: determineSchema(
                    keyType,
                    formatMultiTypeFieldName(keyType, args, variables, definitions),
                    definitions,
                    variables,
                    parentTypes
                ),
                GEN_MAP_VALUE: determineSchema(
                    args[1],
                    formatMultiTypeFieldName(args[1], args, variables, definitions),
                    definitions,
                    variables,
                    parentTypes
                ),
            };
            break;
    }

    return {
        title: fieldName,
        description: primType,
        type: JsonSchemaTypeEnum.ARRAY,
        default: DEFAULT_ARRAY,
        items: {
            title: MAP_PAIR,
            description: primType,
            type: JsonSchemaTypeEnum.OBJECT,
            default: DEFAULT_OBJECT,
            properties,
            additionalItems: false,
        },
        uniqueItems: isSet,
        additionalItems: true,
    };
};

const formatMultiTypeFieldName = (
    damlType: DamlType,
    args: DamlType[],
    variables: IDamlVariableDefinitions,
    definitions: IDamlTypeDefinitions
): string => {
    if (checkIsOptional(damlType)) {
        return formatMultiTypeFieldName(args[0], args, variables, definitions);
    }

    if (isPrimType(damlType)) {
        return damlType.prim;
    } else if (isConType(damlType)) {
        const { newType } = getNewConTypeArgs(damlType, definitions, variables);
        if (isVariant(newType)) {
            return DamlVariantType;
        } else if (isEnum(newType)) {
            return DamlEnumType;
        }
    } else if (isVarType(damlType)) {
        if (variables[damlType.var].args[0]) {
            return formatMultiTypeFieldName(
                variables[damlType.var].args[0],
                args,
                variables,
                definitions
            );
        }
    }
    return formatDescription(JSON.stringify(damlType));
};

const unsupportedType = (type: string): JsonSchema => ({
    type: JsonSchemaTypeEnum.STRING,
    description: `${UNSUPPORTED_TYPE}: ${JSON.stringify(type)}`,
});

const checkIsOptional = (damlType: DamlType) =>
    isPrimType(damlType) && damlType.prim === PrimTypeEnum.OPTIONAL;

const formatDescription = (description: string): string =>
    description.includes(':') ? description.slice(description.indexOf(':') + 1) : description;

export const formatFieldName = (fieldName: string): string => {
    const name = formatDescription(fieldName.replace(TEXT_MAP_ID, ''));
    const toUpper = name.charAt(0).toUpperCase() + name.slice(1);
    return toUpper.replace(/([A-Z])/g, ' $1').trim();
};

export const checkIsUnsupportedType = (schema: JsonSchema): boolean =>
    !!schema.description?.startsWith(UNSUPPORTED_TYPE);

export const checkIsSet = (items: JsonSchema): boolean | undefined =>
    items?.properties &&
    items.properties[GEN_MAP_VALUE] &&
    (items.properties[GEN_MAP_VALUE] as JsonSchema).description === PrimTypeEnum.UNIT;

/* GenMaps and Maps have schema that render them as arrays of objects
   with "key" and "value" fields. The resulting formData has to be altered slightly
   to align with the type structure the server expects.
   This function does the following map transformations:

   GenMap Pair : [...{ GEN_MAP_KEY: kₙ, GEN_MAP_VALUE: vₙ }]    => [...[ kₙ, vₙ ]]
   Map Pair    : [...{ TEXT_MAP_KEY: kₙ, TEXT_MAP_VALUE: vₙ }]  => {...{ kₙ: vₙ }}

   Variant types have a schema that uses "oneOf", which renders a single select and
   corresponding value. The resulting formData has to be altered slightly
   to align with the type structure the server expects.
   This function does the following variant transformations:

   Variant : { VARIANT_TAG_ENUM_KEY: k, k: v } => {tag: k, value: v}
*/
export function updateMapEntries(payload: object): void {
    Object.keys(payload).forEach(fieldName => {
        const current = payload[fieldName];
        if (current && typeof current === 'object') {
            if (Array.isArray(current) && fieldName.endsWith(TEXT_MAP_ID)) {
                const newFieldName = fieldName.replace(TEXT_MAP_ID, '');
                payload[newFieldName] = updateTextMapObject(current);
                delete payload[fieldName];
                return updateMapEntries(payload[newFieldName] || {});
            } else if (current[GEN_MAP_KEY] !== undefined) {
                payload[fieldName] = updateGenMapEntry(payload[fieldName]);
            } else if (current[TEXT_MAP_KEY] !== undefined) {
                payload[fieldName] = updateTextMapEntry(payload[fieldName]);
            } else if (current[VARIANT_TAG_ENUM_KEY] !== undefined) {
                payload[fieldName] = updateVariantEntry(payload[fieldName]);
            }
            return updateMapEntries(payload[fieldName] || {});
        }
    });
}

const updateVariantEntry = (current: object) => {
    const variant = {};
    Object.keys(current).map(i => {
        if (i === VARIANT_TAG_ENUM_KEY) {
            const tag = current[i];
            variant['tag'] = current[i];
            variant['value'] = current[tag];
        }
    });
    return variant;
};

const updateTextMapEntry = (current: object) => {
    const entry = {};
    entry[current[TEXT_MAP_KEY]] = current[TEXT_MAP_VALUE];
    return entry;
};

const updateGenMapEntry = (current: object) => [current[GEN_MAP_KEY], current[GEN_MAP_VALUE]];

const updateTextMapObject = (current: object[]) => {
    current = current.filter(e => Object.keys(e).length !== 0);
    if (current.length === 0) {
        return {};
    }
    const obj = Object.assign(
        {},
        ...current.map((c: object) => {
            const entry = {};
            entry[c[TEXT_MAP_KEY]] = c[TEXT_MAP_VALUE];
            return entry;
        })
    );
    return obj;
};
