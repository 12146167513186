import React from 'react';

import {
    IParticipantData,
    IScratchpadData,
    ISynchronizerData,
    ObjectStatus,
} from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import './Breadcrumbs.scss';
import { IconSmallCircle } from './Icons';
import {
    participantStatusIndicator,
    scratchpadStatusInidcator,
    synchronizerStatusIndicator,
} from './displayedStatusesConfig';
import { NavLink } from './routing';
import { useServiceParams } from './utils/useServiceParams';

const serviceIndicators = {
    synchronizer: synchronizerStatusIndicator,
    participant: participantStatusIndicator,
    scratchpad: scratchpadStatusInidcator,
};

export const Breadcrumbs: React.FC = () => {
    const { service, serviceId } = useServiceParams();
    const { participants, synchronizers, activeScratchpads } = useAppContext();
    if (!service || !serviceId) {
        return null;
    }
    const statusIndicator = serviceIndicators[service];

    const services: {
        participant: IParticipantData[];
        synchronizer: ISynchronizerData[];
        scratchpad: IScratchpadData[];
    } = {
        participant: participants,
        synchronizer: synchronizers,
        scratchpad: activeScratchpads,
    };
    const displayedService = services[service].find(
        (p: ISynchronizerData | IParticipantData | IScratchpadData) => p.info.id === serviceId
    );
    if (!displayedService) {
        return null;
    }
    const isPendingConnection =
        displayedService.info.connection && displayedService.info.connection.joined === false;
    const status = isPendingConnection
        ? ObjectStatus.PENDING_CONNECTION
        : displayedService?.objectStatus || displayedService.info.status.ledger;
    const name = displayedService?.info.name;

    const workspaceRouteTo =
        service === ServiceType.SCRATCHPAD ? '/console/scratchpad' : '/console/nodes';
    return (
        <div className="breadcrumbs">
            <div className="path">
                <NavLink className="inline-link" to={workspaceRouteTo}>
                    Workspace
                </NavLink>
                <span className="separator">/</span>
                <NavLink className="inline-link" to={'#'}>
                    {name}
                </NavLink>
                <div className="info-container">
                    <div className="info">
                        <div className="color-indicator">
                            <IconSmallCircle color={statusIndicator[status]?.color || 'disabled'} />
                        </div>
                        <p className="p2">{statusIndicator[status]?.label || ''}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
