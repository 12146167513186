import { useLocation } from '@reach/router';
import classNames from 'classnames';
import queryString from 'query-string';
import React from 'react';

import { ILedgerParty } from '@hub-client-api';

import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { IconRadio } from '@hub-fe/common/Icons';
import PartyDropdown, { formatSelectionData } from '@hub-fe/common/PartyDropdown';
import { useScroll } from '@hub-fe/common/ScrollContext';
import { PartyDetails } from '@hub-fe/common/generated/clients/damlhub';
import { NavLink } from '@hub-fe/common/routing';

import { useLedgerContext } from '../LedgerContext';

const PartySelectorLink = (props: {
    party: ILedgerParty | PartyDetails;
    initializing: boolean;
}) => {
    const {
        party: { identifier, displayName },
        initializing,
    } = props;
    const { selectedParty } = useLedgerContext();
    const { search } = useLocation();
    const { contractFilter } = queryString.parse(search);

    const selected = selectedParty && selectedParty === identifier && !initializing;

    const queries = `?party=${identifier}${
        contractFilter ? `&contractFilter=${contractFilter}` : ''
    }`;

    const handleClick = (e: any) => {
        if (initializing) e.preventDefault();
    };

    return (
        <NavLink
            replace={!!selected}
            className={classNames('party icon-left', { selected }, { disabled: initializing })}
            to={queries}
            onClick={handleClick}
        >
            <IconRadio filled={!!selected} />
            <label>{displayName || identifier}</label>
        </NavLink>
    );
};

const PartySelector: React.FC<{
    initializing: boolean;
    parties: ILedgerParty[] | PartyDetails[];
}> = ({ parties, initializing }) => {
    const { setScrollTop } = useScroll();

    return (
        <div className="party-selector" onScroll={setScrollTop}>
            {parties.map(party => (
                <PartySelectorLink
                    key={party.identifier}
                    initializing={initializing}
                    party={party}
                />
            ))}
        </div>
    );
};

export const PartySelectorDropdown: React.FC<{ parties: ILedgerParty[] | PartyDetails[] }> = ({
    parties,
}) => {
    const { selectedParty, ledger } = useLedgerContext();
    const partyOptions = formatSelectionData(parties);

    return (
        <div className="party-selector-dropdown">
            <h4>Party:</h4>
            <PartyDropdown
                className="party-select"
                onChange={val => {
                    if (val) {
                        if (val.value === selectedParty) {
                            !val?.userPartyItem && history.replace(`?party=${val?.value}`);
                        } else {
                            !val?.userPartyItem && history.push(`?party=${val?.value}`);
                        }
                    }
                }}
                partyOptions={partyOptions}
                ledgerStatus={ledger?.info.status.ledger}
                selectedParty={selectedParty}
            />
        </div>
    );
};

export default PartySelector;
