import React from 'react';

import { Route, Router } from '@hub-fe/common/routing';

import { ActiveContractsComponent } from './ActiveContractsComponent';
import ChoiceTemplate from './ChoiceTemplate';
import CreateContracts from './CreateContracts';

const ActiveContracts: React.FC = () => {
    return (
        <Router>
            <Route path="create/*" render={() => <CreateContracts />} />
            <Route path="create/:currentTemplate" render={() => <CreateContracts />} />
            <Route path=":selectedContract/:choice" render={() => <ChoiceTemplate />} />
            <Route default path=":selectedContract" render={() => <ActiveContractsComponent />} />
        </Router>
    );
};

export default ActiveContracts;
