import React from 'react';

import BackwardsLink from '@hub-fe/common/BackwardsLink';
import { history } from '@hub-fe/common/ControllableBrowserRouter';
import { IconRadio } from '@hub-fe/common/Icons';
import InformationBlock from '@hub-fe/common/InformationBlock';
import { ParticipantPermission } from '@hub-fe/common/generated/clients/damlhub';
import { standardizeParticipantId } from '@hub-fe/common/utils/standardizeParticipantId';
import { useServiceParams } from '@hub-fe/common/utils/useServiceParams';
import { useLedgerActions, useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import './AddParticipant.scss';

const permissionLevels = [
    {
        permission: ParticipantPermission.Submission,
        desc: 'Write, confirm, and read transactions from the Canton blockchain. It is possible to submit commands through the ledger API and the node can confirm transactions.',
    },
    {
        permission: ParticipantPermission.Observation,
        desc: 'Read transactions from the Canton blockchain. The node is guaranteed a consistent view of the Canton blockchain but it does not take an active part in transaction processing.',
    },
];

export const AddParticipant: React.FC = () => {
    const [participantId, setParticipantId] = React.useState('');
    const { serviceId } = useServiceParams();
    const { allowedParticipants } = useLedgerContext();
    const existingPids =
        allowedParticipants?.map(p => standardizeParticipantId(p.participantId)) || [];
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const pid = searchParams.get('participantId');
    React.useEffect(() => {
        pid && setParticipantId(pid);
    }, [pid]);

    const [permissionLevel, setPermissionLevel] = React.useState<ParticipantPermission>(
        ParticipantPermission.Submission
    );
    const { addParticipantToAllowList } = useLedgerActions();
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParticipantId(e.target.value.trim());
    };
    const onAdd = async () => {
        if (!addParticipantToAllowList || !serviceId) {
            return;
        }
        await addParticipantToAllowList(serviceId, participantId, permissionLevel);
        setParticipantId('');
    };
    const onBack = () => {
        history.goBack();
    };
    const isDupeParticipantId = existingPids.includes(participantId);
    return (
        <div className="add-participant-page">
            <BackwardsLink />
            <h1>Add participant</h1>
            <h4>Participant ID</h4>

            <input
                value={pid || participantId}
                onChange={onChange}
                placeholder="Enter Participant ID"
            />

            {isDupeParticipantId && (
                <InformationBlock warning>
                    <p className="caption warning">This Participant ID already exists.</p>
                </InformationBlock>
            )}
            <h4 className="header-container">Select Permission Level</h4>
            <div>
                {permissionLevels.map(permission => {
                    return (
                        <div
                            key={permission.permission}
                            className="permission-level"
                            onClick={() => setPermissionLevel(permission.permission)}
                        >
                            <div>
                                <div className="permission-title">
                                    <div className="radio-container">
                                        <IconRadio
                                            filled={permissionLevel === permission.permission}
                                        />
                                    </div>
                                    <p>{permission.permission}</p>
                                </div>
                                <div className="desc-container">
                                    <div className="radio-container hidden">
                                        <IconRadio
                                            filled={permissionLevel === permission.permission}
                                        />
                                    </div>
                                    <p className="p2">{permission.desc}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="button-container">
                <button onClick={onBack} className="button secondary spacing-right">
                    Cancel
                </button>
                <button
                    disabled={!participantId.length || isDupeParticipantId}
                    onClick={onAdd}
                    className="button primary"
                >
                    Submit
                </button>
            </div>
        </div>
    );
};
