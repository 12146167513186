import React from 'react';

import LoadingButton from '@hub-fe/common/LoadingButton';
import { SavedSynchronizer } from '@hub-fe/common/generated/clients/damlhub';
import { useLedgerActions } from '@hub-fe/ledger/LedgerContext';
import { useMessages } from '@hub-fe/messages/MessageContext';

export interface SavedSynchronizerCustom extends SavedSynchronizer {
    isOwner?: boolean;
}

interface ConnectButtonProps {
    name: string;
    hostName: string;
    serviceId: string;
    disabled?: boolean;
    isConnecting?: boolean;
}
export const ConnectButton: React.FC<ConnectButtonProps> = ({
    isConnecting,
    disabled,
    name,
    hostName,
    serviceId,
}) => {
    const [isLoading, setLoading] = React.useState(isConnecting || false);
    const { connectToSynchronizer } = useLedgerActions();
    const { error } = useMessages();
    const onConnect = async () => {
        setLoading(true);
        try {
            await connectToSynchronizer({
                name,
                hostName,
                serviceId,
            });
        } catch {
            setLoading(false);
            error(`Failed to connect to ${hostName}`);
        }
    };

    return (
        <LoadingButton
            disabled={isConnecting || disabled}
            onClick={onConnect}
            loading={isConnecting || isLoading}
            className="secondary-smaller"
        >
            Connect
        </LoadingButton>
    );
};
