import classNames from 'classnames';
import React, { useState } from 'react';

import { ILedgerData } from '@hub-client-api';

import { isLedgerData, useAppContext } from '@hub-fe/app/AppContext';
import { ScratchpadOverview } from '@hub-fe/app/scratchpad/ScratchpadOverview';
import { ScratchpadSettings } from '@hub-fe/app/scratchpad/ScratchpadSettings';
import BackwardsLink from '@hub-fe/common/BackwardsLink';
import Expander from '@hub-fe/common/Expander';
import { IconAddPlus, IconInformation } from '@hub-fe/common/Icons';
import PopupMenu, { PopupMenuEntry } from '@hub-fe/common/PopupMenu';
import { getLedgerSideBarLinks } from '@hub-fe/common/Sidebar/config';
import { NavLink, Redirect, Route, Router } from '@hub-fe/common/routing';
import { getLedgerSidebarTabFormUrl } from '@hub-fe/common/routing/RoutingContext';

import ActiveContracts from './ActiveContracts/ActiveContracts';
import ActiveContractsHighEventCount from './ActiveContracts/ActiveContractsHighEventCount';
import { useIsHighEventCount } from './ActiveContracts/utils';
import Collaborators from './Collaborators';
import DeployedArtifacts from './Deployments/DeployedArtifacts';
import Identities from './Identities/Identities';
import IntegrationBrowser from './Integrations/IntegrationBrowser';
import { useLedgerContext } from './LedgerContext';
import LedgerSettings from './LedgerSettings/LedgerSettings';
import LedgerSideBarInfo from './LedgerSideBarInfo';
import QuickBuild from './QuickBuild';

const BreadcrumbSeporator = () => <span className="separator">/</span>;

export const LedgerNavigation: React.FC = () => {
    const { activeLedgers, sharedLedgers, isProTier } = useAppContext();
    const { isLedgerOwner, isLedgerCollaborator, project, ledger } = useLedgerContext();
    const [ledgerMenuOpen, setLedgerMenuOpen] = useState(false);
    const [ledgerDetailsMenuOpen, setLedgerDetailsMenuOpen] = useState(false);
    const [showLedgerInfo, setShowLedgerInfo] = useState(false);

    if (!ledger || !isLedgerData(ledger)) {
        return null;
    }
    const sideBarLinks = ledger ? getLedgerSideBarLinks({ isProTier, isLedgerOwner }) : [];

    const ledgerOptions = isLedgerCollaborator
        ? sharedLedgers.filter(({ info: { id } }) => ledger?.info.id !== id)
        : activeLedgers.filter(
              ({ info: { id, projectId } }) =>
                  ledger?.info.id !== id && ledger?.info.projectId === projectId
          );

    const segment = getLedgerSidebarTabFormUrl(ledger?.info.id);
    const currentPage = sideBarLinks.find(p => p.target.includes(segment));
    const menuLinks = sideBarLinks.filter(page => page.label !== currentPage?.label);

    const root = isLedgerOwner
        ? isLedgerCollaborator
            ? { name: 'Shared Ledgers', url: '/console/shared-ledgers' }
            : { name: 'Your Projects', url: '/console' }
        : undefined;
    return (
        <>
            <div className="ledger-navigation">
                <div className="navigation-breadcrumbs p2">
                    {!isLedgerOwner && (
                        <>
                            <BackwardsLink url={'/console'} to={'workspace'} />
                            <BreadcrumbSeporator />
                        </>
                    )}
                    {root && (
                        <NavLink className="inline-link roomy" to={root.url}>
                            {!isLedgerOwner ? <h4>{root.name}</h4> : root.name}
                        </NavLink>
                    )}
                    {!isLedgerCollaborator && root && <BreadcrumbSeporator />}
                    <p className="project-name p2">
                        {isLedgerOwner
                            ? ledger.info.projectName
                                ? ledger.info.projectName
                                : ''
                            : ledger.info.name}
                    </p>
                    {isLedgerOwner && (
                        <>
                            <BreadcrumbSeporator />
                            <LedgerDropdown
                                toggleLedgerMenu={toggleLedgerMenu}
                                ledgerMenuOpen={ledgerMenuOpen}
                                segment={segment}
                                ledgerOptions={ledgerOptions}
                            />
                        </>
                    )}
                    <button
                        className="toggle-ledger-info ghost narrow-width"
                        onClick={() => setShowLedgerInfo(!showLedgerInfo)}
                    >
                        <IconInformation />
                    </button>
                </div>
                <div className="right-side-nav">
                    {isLedgerOwner && ledger.info.secondaryOwners.length > 0 && isProTier && (
                        <div className="shared-ledger-tag p2">Shared Ledger</div>
                    )}
                    {isLedgerOwner && <QuickBuild />}
                    {!isLedgerOwner && (
                        <div className="joined-ledger-warning icon-left caption">
                            <IconInformation /> You have joined this ledger by Ledger ID
                        </div>
                    )}
                </div>

                {showLedgerInfo && <LedgerSideBarInfo ledger={ledger} />}
            </div>
            <div
                className={classNames('icon-right icon-left page-menu', {
                    expanded: ledgerDetailsMenuOpen,
                })}
                onClick={toggleLedgerDetailsMenu}
            >
                {currentPage?.icon}
                {currentPage?.label}
                <Expander expanded={ledgerDetailsMenuOpen} />
            </div>
            <div>
                {isLedgerOwner && ledgerMenuOpen && (
                    <div className="control-menu" onClick={() => setLedgerMenuOpen(false)}>
                        {ledgerOptions.map(({ info }) => (
                            <NavLink
                                className="control-menu-item"
                                key={info.id}
                                to={`/console/ledger/${ledger.info.id}/${segment}`}
                            >
                                <h3> {info.name} </h3>
                            </NavLink>
                        ))}
                        <div className="control-menu-item">
                            <NavLink
                                className="ghost icon-left"
                                to={`/console/projects/create-ledger/${project?.info.id}`}
                            >
                                <IconAddPlus /> Add Ledger
                            </NavLink>
                        </div>
                    </div>
                )}
                {ledgerDetailsMenuOpen && (
                    <div className="control-menu" onClick={() => setLedgerDetailsMenuOpen(false)}>
                        {menuLinks.map(({ label, target, icon }) => (
                            <NavLink
                                className="control-menu-item"
                                key={label}
                                to={`/console/ledger/${ledger.info.id}/${target}`}
                            >
                                <h3 className="icon-left">
                                    {icon}
                                    {label}
                                </h3>
                            </NavLink>
                        ))}
                    </div>
                )}
            </div>
        </>
    );

    function toggleLedgerDetailsMenu() {
        setLedgerDetailsMenuOpen(!ledgerDetailsMenuOpen);
        setLedgerMenuOpen(false);
    }

    function toggleLedgerMenu() {
        setLedgerMenuOpen(!ledgerMenuOpen);
        setLedgerDetailsMenuOpen(false);
    }
};

const LedgerDropdown = (props: {
    ledgerOptions: ILedgerData[];
    ledgerMenuOpen: boolean;
    segment: string;
    toggleLedgerMenu: () => void;
}) => {
    const { ledgerOptions, ledgerMenuOpen, toggleLedgerMenu, segment } = props;

    const { ledger, isLedgerCollaborator, project } = useLedgerContext();

    if (!ledger) {
        return null;
    }

    if (ledgerOptions.length === 0 && isLedgerCollaborator) {
        return <div>{ledger.info.name}</div>;
    }

    return (
        <>
            <PopupMenu
                withExpander
                titleClassname="inline-link roomy icon-right"
                title={<div>{ledger.info.name}</div>}
            >
                {ledgerOptions.map(({ info }) => (
                    <PopupMenuEntry
                        key={info.id}
                        label={info.name}
                        href={'/console/ledger/' + info.id + '/' + segment}
                    />
                ))}
                {!isLedgerCollaborator && (
                    <PopupMenuEntry
                        label={
                            <NavLink
                                className="icon-left add-ledger"
                                to={`/console/projects/create-ledger/${project?.info.id}`}
                            >
                                <IconAddPlus /> Add Ledger
                            </NavLink>
                        }
                    />
                )}
            </PopupMenu>
            <div className="inline-link roomy icon-right menu-item" onClick={toggleLedgerMenu}>
                {ledger.info.name}
                <Expander expanded={ledgerMenuOpen} />
            </div>
        </>
    );
};

export const LedgerRoutes: React.FC = () => {
    const { isLedgerOwner } = useLedgerContext();
    const isHighEventCount = useIsHighEventCount();
    const { isProTier } = useAppContext();

    return (
        <Router>
            <Route path="deployments/*" render={() => <DeployedArtifacts />} />
            <Route path="identities/*" render={() => <Identities />} />
            <Route path="overview/*" render={() => <ScratchpadOverview />} />

            <Route
                path="live-data/*"
                render={() =>
                    isHighEventCount ? <ActiveContractsHighEventCount /> : <ActiveContracts />
                }
            />
            <Route path="ledger-settings/*" render={() => <LedgerSettings />} />
            <Route path="settings/*" render={() => <ScratchpadSettings />} />

            {isProTier && <Route path="collaborators" render={() => <Collaborators />} />}
            {isLedgerOwner && <Route path="integrations/*" render={() => <IntegrationBrowser />} />}
            <Route path="/" render={() => <Redirect to="deployments" />} />
        </Router>
    );
};
