import React from 'react';

import { formatMemory } from '../../utils/formatMemory';
import './DiskUtilization.scss';
import { LinearProgress } from './LinearProgress';

interface DiskUtilizationsProps {
    serviceName: string;
    bytesUsed: number;
    bytesCapacity: number;
}
export const DiskUtilizations: React.FC<DiskUtilizationsProps> = ({
    serviceName,
    bytesCapacity,
    bytesUsed,
}) => {
    const usedInGb = formatMemory(bytesUsed);
    const capacityInGb = formatMemory(bytesCapacity);
    return (
        <div className="disk-utilization">
            <h4 className="label">{serviceName} Storage</h4>
            <div>Disk storage used by contracts on this {serviceName}.</div>

            <div>
                <LinearProgress value={usedInGb} total={capacityInGb} />
            </div>
            <div>
                <p>
                    {usedInGb.toFixed(2)} GB of {capacityInGb.toFixed(2)} GB used
                </p>
            </div>
        </div>
    );
};
