import React from 'react';

import { Route, Router } from '@hub-fe/common/routing';

import { AddParticipant } from './AddParticipant';
import { ParticipantsManagement } from './ParticipantsManagement';

export const Participants: React.FC = () => {
    const P = () => (
        <div className="participants">
            <h1>Participants</h1>
            <ParticipantsManagement />
        </div>
    );
    return (
        <Router>
            <Route path="/" default render={() => <P />} />
            <Route path="add-participant" render={() => <AddParticipant />} />
        </Router>
    );
};
