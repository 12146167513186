import React from 'react';

import { ResourceTokenType, quotaTokensForType } from '@hub-client-api';

import { useAppContext } from '@hub-fe/app/AppContext';

import Banner, { BannerWarningType } from './Banner';
import { CreateServiceRequestServiceTypeEnum } from './generated/clients/damlhub';

export const WorkspaceQuotaWarnings: React.FC = () => {
    const { quotas } = useAppContext();
    const scratchpadTokens = quotaTokensForType(quotas, ResourceTokenType.ScratchpadResource);
    const participantTokens = quotaTokensForType(quotas, ResourceTokenType.ParticipantResource);
    const participantLargeTokens = quotaTokensForType(quotas, ResourceTokenType.LargeParticipant);
    const synchronizerTokens = quotaTokensForType(quotas, ResourceTokenType.SynchronizerResource);

    const hasAllotedScratchpadTokens =
        scratchpadTokens.tokens.length + scratchpadTokens.spentTokens.length > 0;
    const hasAllotedSychronizerTokens =
        synchronizerTokens.tokens.length + synchronizerTokens.spentTokens.length > 0;
    const hasAllotedStandardParticipantTokens =
        participantTokens.tokens.length + participantTokens.spentTokens.length > 0;
    const hasLargeParticipantTokens = !!(
        participantLargeTokens.tokens.length + participantLargeTokens.spentTokens.length
    );
    const atScratchpadLimit = hasAllotedScratchpadTokens && scratchpadTokens.tokens.length == 0;
    const atParticipantLimit =
        hasAllotedStandardParticipantTokens && participantTokens.tokens.length == 0;
    const atSynchronizerLimit =
        hasAllotedSychronizerTokens && synchronizerTokens.tokens.length == 0;
    const atParticipantLargeLimit =
        hasLargeParticipantTokens && participantLargeTokens.tokens.length == 0;

    return (
        <>
            {atScratchpadLimit && (
                <Banner
                    bannerName="upgrade"
                    dismissible
                    warningType={BannerWarningType.WARNING}
                    className="upgrade-warning-banner"
                    header={`${CreateServiceRequestServiceTypeEnum.Scratchpad} Usage Warning`}
                    message={
                        <p className="p2">
                            You have reached your allotment of {scratchpadTokens.spentTokens.length}{' '}
                            {CreateServiceRequestServiceTypeEnum.Scratchpad}s for your account. You
                            will no longer be able to create{' '}
                            {CreateServiceRequestServiceTypeEnum.Scratchpad}s until you delete an
                            existing {CreateServiceRequestServiceTypeEnum.Scratchpad}.
                        </p>
                    }
                />
            )}
            {atParticipantLimit && (
                <Banner
                    bannerName="upgrade"
                    dismissible
                    warningType={BannerWarningType.WARNING}
                    className="upgrade-warning-banner"
                    header={`${CreateServiceRequestServiceTypeEnum.Participant} Usage Warning`}
                    message={
                        <p className="p2">
                            You have reached your allotment of{' '}
                            {participantTokens.spentTokens.length}{' '}
                            {CreateServiceRequestServiceTypeEnum.Participant}s for your account. You
                            will no longer be able to create{' '}
                            {CreateServiceRequestServiceTypeEnum.Participant}s until you delete an
                            existing {CreateServiceRequestServiceTypeEnum.Participant}.
                        </p>
                    }
                />
            )}
            {atParticipantLargeLimit && (
                <Banner
                    bannerName="upgrade"
                    dismissible
                    warningType={BannerWarningType.WARNING}
                    className="upgrade-warning-banner"
                    header={`${CreateServiceRequestServiceTypeEnum.Participant} Usage Warning`}
                    message={
                        <p className="p2">
                            You have reached your allotment of{' '}
                            {participantLargeTokens.spentTokens.length} high capacity{' '}
                            {CreateServiceRequestServiceTypeEnum.Participant}s for your account. You
                            will no longer be able to create{' '}
                            {CreateServiceRequestServiceTypeEnum.Participant}s until you delete an
                            existing high capacity {CreateServiceRequestServiceTypeEnum.Participant}
                            .
                        </p>
                    }
                />
            )}
            {atSynchronizerLimit && (
                <Banner
                    bannerName="upgrade"
                    dismissible
                    warningType={BannerWarningType.WARNING}
                    className="upgrade-warning-banner"
                    header={`${CreateServiceRequestServiceTypeEnum.Synchronizer} Usage Warning`}
                    message={
                        <p className="p2">
                            You have reached your allotment of{' '}
                            {synchronizerTokens.spentTokens.length}{' '}
                            {CreateServiceRequestServiceTypeEnum.Synchronizer}s for your account.
                            You will no longer be able to create{' '}
                            {CreateServiceRequestServiceTypeEnum.Synchronizer}s until you delete an
                            existing {CreateServiceRequestServiceTypeEnum.Synchronizer}.
                        </p>
                    }
                />
            )}
        </>
    );
};
