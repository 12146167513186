import React from 'react';

import { useAppActions, useAppContext } from '@hub-fe/app/AppContext';
import { IconWarning } from '@hub-fe/common/Icons';
import LoadingButton from '@hub-fe/common/LoadingButton';
import { isValidUrl } from '@hub-fe/common/utils/isValidUrl';
import { useLedgerActions, useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import './SynchronizerUrlInput.scss';

interface ErrorMessageProps {
    errorMessage: string;
}
const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage }) => {
    return (
        <div className="p2 error-message">
            <IconWarning />
            {errorMessage}
        </div>
    );
};
interface SynchronizerUrlInputProps {
    onCancel: () => void;
}
export const SynchronizerUrlInput: React.FC<SynchronizerUrlInputProps> = ({ onCancel }) => {
    const [name, setName] = React.useState('');
    const [url, setUrl] = React.useState('');
    const [isConnecting, setConnecting] = React.useState(false);
    const { connectToSynchronizer } = useLedgerActions();
    const { activeSynchronizers } = useAppContext();
    const { saveSynchronizer } = useAppActions();
    const { ledgerId } = useLedgerContext();
    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };
    const onUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value);
        setError(false);
    };
    const [hasError, setError] = React.useState<boolean>(false);
    const isDupeSynchronizerUrl = !!activeSynchronizers.find(s => s.info.hostname === url);
    const disableButton = !name.length || !url?.length || hasError || isDupeSynchronizerUrl;
    const onClick = async () => {
        if (!isValidUrl(url)) {
            setError(true);
            return;
        }
        setConnecting(true);
        try {
            await connectToSynchronizer({
                name,
                hostName: url,
                serviceId: ledgerId,
            });
            if (!activeSynchronizers.find(s => s.info.hostname === url)) {
                await saveSynchronizer({ name, synchronizerUrl: url }, true, true);
            }
            setConnecting(false);
        } catch {
            setConnecting(false);
        }
    };

    return (
        <div className="synchronizer-url-input">
            <div className="form-container">
                <p>Connection Name</p>
                <div className="input-container">
                    <input name="connectionName" id={'connectionName'} onChange={onNameChange} />
                </div>
                <p>Synchronizer URL</p>
                <div className="input-container">
                    <input type="url" name="url" id={'url'} onChange={onUrlChange} />
                </div>
                {hasError && (
                    <ErrorMessage
                        errorMessage={
                            'This url link is invalid. Check your input information and try again.'
                        }
                    />
                )}
                {isDupeSynchronizerUrl && (
                    <ErrorMessage
                        errorMessage={
                            'This Synchronizer URL already exists in your saved synchronizers below.'
                        }
                    />
                )}
                <button onClick={onCancel} className="secondary">
                    Cancel
                </button>
                <LoadingButton
                    className="connect-button"
                    loading={isConnecting}
                    disabled={disableButton}
                    onClick={onClick}
                >
                    Connect
                </LoadingButton>
            </div>
        </div>
    );
};
