import React from 'react';

import { ObjectStatus } from '@hub-client-api';

import ActionHeader from '@hub-fe/common/ActionHeader';
import InformationBlock from '@hub-fe/common/InformationBlock';
import MenuBar from '@hub-fe/common/MenuBar';
import { Redirect, Route, Router } from '@hub-fe/common/routing';
import { isNewService } from '@hub-fe/common/utils/isNewService';
import { ServiceType } from '@hub-fe/workspace/ChooseServiceType';

import { useLedgerContext } from '../LedgerContext';
import Parties from './Parties';
import PartiesNew from './PartiesNew';
import ServiceAccounts from './ServiceAccounts';
import Users from './Users';

const scratchpadBlockMessage = 'Identities will be available once this service is running.';
const ledgerBlockMessage = 'Identities will be available once this ledger is running.';
const participantBlockMessage =
    'Identities will be available once this service is connected to a Synchronizer.';

const placeholderMessage = {
    [ServiceType.LEDGER]: ledgerBlockMessage,
    [ServiceType.PARTICIPANT]: participantBlockMessage,
    [ServiceType.SCRATCHPAD]: scratchpadBlockMessage,
};

const Identities: React.FC = () => {
    const { ledger, serviceType } = useLedgerContext();
    if (!serviceType || !ledger) {
        return null;
    }
    const isService = isNewService(serviceType);
    const displayedMessage = placeholderMessage[serviceType];
    const serviceEntries = [
        { label: 'Parties', to: 'parties' },
        { label: 'Users', to: 'users' },
    ];
    const ledgerEntries = [
        { label: 'Parties', to: 'parties' },
        { label: 'Service Accounts', to: 'service-accounts' },
        { label: 'Users', to: 'users' },
    ];
    const displayedEntries = isService ? serviceEntries : ledgerEntries;
    let body = (
        <>
            <MenuBar entries={displayedEntries} />
            <Router>
                <Route path="parties" render={() => (isService ? <PartiesNew /> : <Parties />)} />
                {isService ? null : (
                    <Route path="service-accounts" render={() => <ServiceAccounts />} />
                )}
                <Route path="users/*" render={() => <Users />} />
                <Route default render={() => <Redirect to="parties" />} />
            </Router>
        </>
    );
    const ledgerNotReady =
        ledger.info.status.ledger === ObjectStatus.RESUMING ||
        ledger.info.status.ledger === ObjectStatus.STARTING ||
        ledger.info.status.ledger === ObjectStatus.PAUSING ||
        ledger.info.status.ledger === ObjectStatus.PAUSED ||
        ledger.info.status.ledger === ObjectStatus.DELETING ||
        ledger.info.status.ledger === ObjectStatus.CONNECTING ||
        ledger.info.status.ledger === ObjectStatus.UNCONNECTED;

    if (ledgerNotReady) {
        body = (
            <div className="identities loading">
                <InformationBlock enableBackground>{displayedMessage}</InformationBlock>
            </div>
        );
    }

    return (
        <div className="identities">
            <ActionHeader title="Identities" />
            {body}
        </div>
    );
};

export default Identities;
