import React from 'react';

import { isSynchronizerData } from '@hub-fe/app/AppContext';
import { CopyButton } from '@hub-fe/common/ClipboardButtons';
import { DetailsTable } from '@hub-fe/common/DetailsTable';
import {
    IconRocket,
    IconSidebarDeployments,
    IconSidebarGlobe,
    IconSidebarUser,
} from '@hub-fe/common/Icons';
import { MonitorDeploymentTile } from '@hub-fe/common/MonitorDeploymentTile';
import { Storage } from '@hub-fe/common/Storage';
import { TwoColLayout } from '@hub-fe/common/TwoColLayout';
import { CreateServiceRequestServiceTypeEnum } from '@hub-fe/common/generated/clients/damlhub';
import { Route, Router } from '@hub-fe/common/routing';
import DefaultDeploymentViewer from '@hub-fe/ledger/Deployments/DefaultDeploymentViewer';
import { useLedgerContext } from '@hub-fe/ledger/LedgerContext';

import en from '../../locales/en.json';
import './SynchronizerOverview.scss';

export const SynchronizerOverview: React.FC = () => {
    const { ledger, domainStatus } = useLedgerContext();

    const rows = [
        {
            icon: <IconSidebarUser />,
            label: 'Name',
            renderAs: <p className="value p2">{ledger?.info.name || ''}</p>,
        },
        {
            icon: <IconRocket />,
            label: 'Canton Version',
            renderAs: (
                <p className="value p2">
                    {ledger && isSynchronizerData(ledger) ? ledger.info.sdkVersion : ''}
                </p>
            ),
        },
        {
            icon: <IconRocket />,
            label: 'Canton Protocol Version',
            renderAs: (
                <p className="value p2">
                    {ledger && isSynchronizerData(ledger) ? ledger.info.cantonProtocol : ''}
                </p>
            ),
        },
        {
            icon: <IconSidebarGlobe />,
            label: 'Synchronizer url',
            renderAs: (
                <div className="value">
                    <div className="copy-area">
                        <p className="p2">
                            {ledger && isSynchronizerData(ledger) ? ledger.info.hostname : ''}
                        </p>
                        <CopyButton
                            content={
                                ledger && isSynchronizerData(ledger) ? ledger.info.hostname : ''
                            }
                            ghost
                        />
                    </div>
                </div>
            ),
        },
        {
            icon: <IconSidebarDeployments />,
            label: 'Service ID',
            renderAs: (
                <div className="value">
                    <div className="copy-area">
                        <p className="p2">{ledger?.info.id || ''}</p>
                        <CopyButton content={ledger?.info.id || ''} ghost />
                    </div>
                </div>
            ),
        },
    ];
    const col1 = (
        <>
            <div className="header">
                <h1>Overview</h1>
            </div>
            <div className="section">
                <h2>Synchronizer Details</h2>
                <DetailsTable data={rows} />
            </div>
            {ledger?.info.status.disks && <Storage disks={ledger.info.status.disks} />}
            <h2>Monitoring</h2>
            <MonitorDeploymentTile
                to={CreateServiceRequestServiceTypeEnum.Synchronizer}
                status={domainStatus}
                label="Synchronizer"
                desc={en.defaultDeployments.synchronizerSupportText}
                caption="View Logs"
            />
        </>
    );

    const Settings = () => <TwoColLayout col1={col1} col2={null} />;

    return (
        <div className="overview">
            <Router>
                <Route path="/" render={() => <Settings />} />
                <Route
                    path="synchronizer"
                    render={() => (
                        <DefaultDeploymentViewer
                            defaultDeployment={CreateServiceRequestServiceTypeEnum.Synchronizer}
                        />
                    )}
                />
            </Router>
        </div>
    );
};
